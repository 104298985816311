<template>
    <div class="container">
        <!-- Zariadenia -->
        <jkb-collapse ref="deviceCollapse" show="true">
            <div class="row justify-content-between">
                <div class="col-lg-7 pr-lg-4 mb-lg-0 mb-4">
                    <h2 class="h4 mb-3">
                        <span v-if="antennas.length">1.</span> Výber zariadenia
                    </h2>

                    <!-- Výber zariadenia -->
                    <div class="card-holder" v-if="devices.length">
                        <!-- Loop realnych zariadeni -->
                        <device-card
                            v-for="device in devices"
                            v-model="orderDevice[0]"
                            name="device"
                            :key="`device${device.id}`"
                            :device="device"
                            :checked="isDeviceActive(device.id)"
                        >
                        </device-card>

                        <!-- Static Device - zariadenie uz mam -->
                        <div class="device">
                            <input
                                type="radio"
                                class="custom-checkbox"
                                name="staticAntenna"
                                v-model="orderDevice[0]"
                                :id="`staticInput${staticDevice.id}`"
                                :value="evaluateStaticDeviceValue"
                                :checked="isDeviceActive(staticDevice.id)"
                            />
                        </div>
                        <!-- /Static device -->
                    </div>
                    <!-- /Vyber zariadenia -->

                    <!-- paticka s potvrdenim -->
                    <div class="text-center mt-4" v-if="antennas.length">
                        <button
                            :disabled="!orderDevice[0]"
                            @click="
                                $refs.deviceCollapse.toggle();
                                $refs.antenaCollapse.toggle();
                                scrollToNavigation();
                            "
                            class="btn btn-primary"
                        >
                            2. Prejsť na výber antény
                        </button>
                    </div>
                </div>
                <div class="container col-12 col-md-4">
                    <CartComponent :add-item="orderDevice" :step="2"> </CartComponent>
                    <div class="cart_footer">
                        <button
                            class="btn btn-primary col-12"
                            :disabled="!stepFilledCorrectly"
                            @click="nextStep"
                        >
                            {{ nextBtnContent }}
                        </button>
                    </div>
                </div>
            </div>
        </jkb-collapse>
        <!-- Antény -->
        <jkb-collapse ref="antenaCollapse">
            <div class="offer mb-4">
                <div class="text-center mb-4">
                    <button
                        class="btn btn-primary"
                        @click="
                            $refs.deviceCollapse.toggle();
                            $refs.antenaCollapse.toggle();
                        "
                    >
                        1. Zmeniť výber zariadenia
                    </button>
                </div>

                <h2 class="h3 text-center mb-4">
                    <span>2.</span> Výber antény
                </h2>

                <!-- Výber anteny -->
                <div class="offer__wrapper" v-if="antennas.length">
                    <device-card
                        v-for="device in antennas"
                        v-model="orderDevice[1]"
                        name="antenna"
                        :key="`antenna${device.id}`"
                        :device="device"
                        :checked="isDeviceActive(device.id)"
                    >
                    </device-card>
                </div>
                <!-- / Vyber anteny -->

                <!-- Paticka buttony prev/next -->
                <div class="form__footer mt-5">
                    <!-- Predosly krok -->
                    <button class="btn btn-primary" @click="prevStep">
                        Krok späť
                    </button>
<!--                    &lt;!&ndash; Dalsi krok &ndash;&gt;-->
<!--                    <button-->
<!--                        class="btn btn-primary"-->
<!--                        :disabled="!stepFilledCorrectly"-->
<!--                        @click="nextStep"-->
<!--                    >-->
<!--                        {{ nextBtnContent }}-->
<!--                    </button>-->
                </div>
            </div>
        </jkb-collapse>
        <div class="mb-5">
            <conditions>
                <template v-slot:headline>
                    Podmienky
                </template>
                <template v-slot:content>
                    <p>
                        Poskytovateľ poskytuje Užívateľovi Koncové zariadenie do
                        dočasného užívania. Vyššie uvedená suma zodpovedá výške
                        mesačného nájomného. Nájomná zmluva sa uzatvára ako
                        súčasť Zmluvy a považuje sa za riadne uzavretú okamihom
                        a nadobúda platnosť a účinnosť momentom nadobudnutia
                        platnosti a účinnosti Zmluvy. Nájomná zmluva sa uzatvára
                        na dobu trvania Zmluvy. Ďalšie informácie sú obsiahnuté
                        v dokumente
                        <a
                            href="https://www.plustelka.sk/wp-content/uploads/2018/05/osobitne_podmienky.pdf"
                            target="_blank"
                            >Osobitné podmienky poskytovania služieb spoločnosti
                            Towercom, a.s. – nájom Koncových zariadení.</a
                        >
                    </p>
                </template>
            </conditions>
        </div>
<!--        &lt;!&ndash; Paticka buttony prev/next &ndash;&gt;-->
<!--        <div class="form__footer mt-5" v-if="!antennas.length">-->
<!--            &lt;!&ndash; Predosly krok &ndash;&gt;-->
<!--            <button class="btn btn-primary" @click="prevStep">-->
<!--                Krok späť-->
<!--            </button>-->
<!--            &lt;!&ndash; Dalsi krok &ndash;&gt;-->
<!--            <button-->
<!--                class="btn btn-primary"-->
<!--                :disabled="!stepFilledCorrectly"-->
<!--                @click="nextStep"-->
<!--            >-->
<!--                {{ nextBtnContent }}-->
<!--            </button>-->
<!--        </div>-->
    </div>
</template>

<script>
import deviceCard from "../../components/vue/ponuka/device-card.vue";
import jkbCollapse from "../../components/vue/collapse/collapse";
import conditions from "../../components/vue/conditions/conditions.vue";
import { mapGetters } from "vuex";
import axios from "axios";

//Mixins
import formStep from "../../mixins/formStep";
import { API_URL, DEFAULT_AUTH } from "../../config";
import $ from "jquery";
import qs from "qs";
import AxiosPlugin from "vue-axios-cors";
import Vue from "vue";
import ContactForm from "../ContactForm";
import CartComponent from "../../components/vue/CartComponent";

export default {
    //formStep - obsahuje spolocnu funkcnost ktora plati pre vsetky kroky objednavkoveho formulara ako pre Annetu tak aj pre Antenu
    mixins: [formStep],

    data() {
        return {
            //Vuex a Vue router nastavenia pre spravne fungovanie formularu
            stepName: "vyberZariadenia",
            prevStepName: "annetaStep1",
            nextStepName: "annetaStep4",
            //Lokalny "nakupny kosik" plni sa pomocou watchera a pri ulozeni kroku, sa posiela do Vuex
            cart: [],
            //Array do ktoreho budu padat vsetky objednane zariadenia
            orderDevice: [],
            //Zoznamy zariadeni a anten, ktore su dostupne pod vybranym programom - plnia sa pri "mounted"
            devices: [],
            antennas: [],
            // Nastavenie statickeho zariadenia - "Zariadenie uz mam"
            // v pripade, ze to podmienky umoznia, tak tieto data budu pouzite na to aby sa
            // tato polozka pridala do "kosika" a bola sucastou zuctovania v poslednom kroku
            staticDevice: {
                id: 9999,
                title: "Zariadenie už mám",

                selectedPrice: {
                    interval: "ONCE",
                    discounted: 0,
                    regular: 0
                }
            },
            staticDeviceSerialNo: "",
            staticDeviceValidating: false,
            staticDeviceValidity: 0,
            staticDeviceError: ""
        };
    },

    components: {
        CartComponent,
        ContactForm,
        jkbCollapse,
        deviceCard,
        conditions
    },

    methods: {
        // async verifyDevice() {
        //     //Zapneme stav, kedy sa formular tvari, ze validuje
        //     this.staticDeviceValidating = true;
        //     // Simulacia stavov success a fail
        //
        //     // Success:
        //     // v pripade ze zadame "123" vrati sa stav 1 -> zariadenie je viazane inou zmluvou
        //     // v pripade ze zadame "456" vrati sa stav 2 -> zariadenie nieje mozne pripojit
        //
        //     // Fail:
        //     // ak zadame akukolvek inu hodnotu, tak sa vrati fail (simulacia zlyhania callu na API)
        //
        //     let data = {};
        //     data['method'] = 'GET';
        //     data['headers'] = DEFAULT_AUTH;
        //     data['url'] = API_URL + "Devices/eshop/get-device/";
        //     data['data'] = { 'identifier': this.staticDeviceSerialNo};
        //     this.$http.post('/api/external', data)
        //         .then( data => {
        //             if(data.data.success && data.data.result) {
        //                 this.staticDeviceValidity = 2;
        //             } else {
        //                 this.staticDeviceValidity = 1
        //                 // this.staticDeviceError = 'Pri validácií zariadenia došlo k chybe, skúste to prosím ešte raz. Poprípde nám zavolajte na tel.č. 0650 444 400'
        //             }
        //             //Vypneme stav, kedy sa formular tvari, ze validuje
        //             this.staticDeviceValidating = false;
        //         })
        // },
        isDeviceActive(id) {
            return this.orderDevice.some(arr => arr.id == id);
        }
    },

    computed: {
        ...mapGetters([
            "getStep",
            "getSelectedAnnetaPackage",
            "getDevicesUnderProgram",
            "getAntennasUnderProgram"
        ]),

        stepFilledCorrectly() {
            return (
                // Bool
                // tu zhromazdujeme vsetky podmienky, ktore musia byt
                // splnene na to, aby formular mohol pokracovat dalej
                this.dynamicValidation
            );
        },

        validatingBtnContent() {
            if (this.staticDeviceValidating) {
                return "Overujem ...";
            } else return "Overiť zariadenie";
        },

        nextBtnContent() {
            if (this.stepFilledCorrectly) return "Ďalší krok";
            else return "Prosím vyberte si zariadenie";
        },
        currentStepStateToSave() {
            //Nastavenia, ktore sa maju ulozit do vuex pri prechode na dalsi krok (aj dopredu aj dozadu)
            return {
                step: "vyberZariadenia",
                state: {
                    orderDevice: this.orderDevice,
                    cart: this.cart
                }
            };
        },
        // Funkcia, ktory dynamicky validaje hodnoty podla toho ci ma sluzba dostupne anteny (zariadenia) alebo nie
        dynamicValidation() {
            //Ak existuju dake anteny (zariadenia), tak validuj, ci sa nachadzaju v order device
            if (this.antennas.length) {
                return Boolean(this.orderDevice[0] && this.orderDevice[1]);
            }
            //Ak nie
            else return Boolean(this.orderDevice[0]);
        },

        // Funkcia
        evaluateStaticDeviceValue() {
            // Vracia vzdy false, lebo v pripade annety, nech skonci validacia akokolvek,
            // zakaznik nemoze pokracovat s vybratou moznostou "zariadenie uz mam"
            return false;
        }
    },

    watch: {
        //Sledujeme polozku additional services, a podla nej pridame polozky do arrayu cart z ktore sa na konci vypocita vysledna cena
        orderDevice: {
            handler(value) {
                this.cart = [];
                value.forEach(service => {
                    //console.log(service)
                    if (service != null && service != false) {
                        //Ak existuje sale price, uprednosti ju pred regular price a zapis ju do kosika, aby vysledna cena bola korektna
                        let finalPrice = service.selectedPrice.regular;
                        if (
                            service.selectedPrice.discounted != 0 &&
                            service.selectedPrice.discounted != ""
                        ) {
                            finalPrice = service.selectedPrice.discounted;
                        }

                        let accountedService = {
                            title: service.title,
                            price: parseFloat(finalPrice),
                            payment: service.selectedPrice.interval,
                            description: "zariadenie"
                        };
                        this.cart.push(accountedService);
                    }
                });
            },
            deep: true
        }
    },

    mounted() {
        //Zisti, ci existuje ulozeny stav vo vuex, ak ano nacitaj hodnoty do komponentu
        const stepData = this.getStep("vyberZariadenia");
        if (stepData) {
            this.orderDevice = stepData.orderDevice;
        }

        // Pri nacitani vytiahneme zariadenia a anteny (ak su), ktore su pod programom (id)
        // id programu si vytiahneme pomocou getteru getSelectedAnnetaPackage
        this.devices = this.getDevicesUnderProgram(
            this.getSelectedAnnetaPackage
        );
        this.antennas = this.getAntennasUnderProgram(
            this.getSelectedAnnetaPackage
        );
    }
};
</script>

<style lang="scss" scoped>
.events-none {
    pointer-events: none;
}

.offer__wrapper {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;

    .offer__item {
        position: relative;

        &.slim {
            width: 100%;
            max-width: unset;
            margin: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            h3,
            p,
            .form-row {
                max-width: 500px;
            }

            .slim__content {
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;

                > * {
                    flex: 0 0;
                }
            }

            .form-row {
                display: flex;

                input {
                    flex: 1 0;
                }

                button {
                    min-width: 185px;
                    white-space: nowrap;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                }
            }

            &::after {
                position: absolute;
                right: 1rem;
                margin: 1rem;
            }

            .offer__item__heading {
                min-height: unset;
                margin-bottom: 0;
            }

            @media (max-width: 768px) {
                flex-direction: column;
                padding-bottom: 4rem;

                &::after {
                    margin: 1rem 1rem 0;
                    bottom: 1rem;
                    right: unset;
                }
            }
        }
    }

    // Zaistenie reaktivity prkov ako input a button iba v pripade, ze je nadradeny label sucastou checknuteho inputu
    // v opacnom pripade sa moze stat, ze zakaznik nezaskrtne samotny input, no aj napriek tomu moze interagovat s prvkami vo vnutri (input a button)
    input + label.offer__item {
        input,
        button {
            opacity: 0.3;
            pointer-events: none;
            transition: all 0.3s ease-out;
        }
    }

    input:checked + label.offer__item {
        input,
        button {
            opacity: 1;
            pointer-events: all;
        }
    }
}
</style>
