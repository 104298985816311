<template>
    <div>
        <h4 class="program-widget__heading mb-3">
            Overte si či je u Vás služba dostupná
        </h4>

        <search-select
            class="mb-3"
            v-model="data.location"
            :listData="data.cities"
            :selectedItem="data.location"
        />

        <button
            class="btn btn-primary"
            :disabled="!Object.keys(data.location).length"
            @click="
                $emit('page', 'result');
                setSelectedCity(data.location);
            "
        >
            {{ btnContent }}
        </button>
    </div>
</template>

<script>
//Components
import searchSelect from "../../search-select/searchSelect.vue";
import { mapActions } from "vuex";

export default {
    props: ["data"],
    components: {
        searchSelect
    },
    data() {
        return {
            location: {}
        };
    },
    watch: {
        location(newValue, oldValue) {
            this.$emit("changeLocation", newValue);
        }
    },
    methods: {
        ...mapActions(["setSelectedCity"])
    },
    computed: {
        btnContent() {
            if (!Object.keys(this.data.location).length) {
                return "Prosím vyberte mesto alebo obec";
            } else return "Overiť dostupnosť";
        }
    },

    mounted() {}
};
</script>

<style lang="scss" scoped></style>
