<template>
    <div class="search-select" v-if="filteredListData">
        <select
            @mousedown.prevent="openSearch"
            :id="selectId"
            :name="selectName"
            class="form-control"
            :class="{ required: selectRequired }"
            type="text"
            v-model="selected"
            :data-invalid-response="selectResponse"
        >
            <option :value="{}" disabled selected>
                Prosím vyberte mesto alebo obec
            </option>
            <option
                v-for="(data, index) in listData"
                :value="data"
                :key="`list${index}`"
            >
                {{ data.city }}
            </option>
        </select>

        <div v-show="searchOpen" class="search-select__search">
            <div class="search-select__list" ref="searchlist">
                <input
                    ref="search"
                    @keyup.stop.prevent="focus"
                    @input="filterList"
                    v-model="searchTerm"
                    class="form-control"
                    type="text"
                    placeholder="Vyhľadávanie"
                />

                <div
                    class="search-list"
                    ref="search_list"
                    v-if="filteredListData.length > 0 && searchTerm.length > 1"
                >
                    <!-- Data -->
                    <button
                        v-for="(data, index) in filteredListData"
                        :key="`list${index}`"
                        :class="{ 'active focused': data == selected }"
                        :data-select-value="JSON.stringify(data)"
                        @mousedown.prevent="select(data)"
                        @click.prevent
                        tabindex="0"
                        v-html="boldFinding(data.city)"
                    ></button>
                </div>

                <div
                    v-else-if="searchTerm.length <= 1"
                    ref="search_list"
                    class="searchList"
                >
                    <button class="focused">
                        Zadajte názov obce, v ktorej chcete službu zaviesť
                    </button>
                </div>

                <div v-else ref="search_list" class="search-list">
                    <button class="focused">
                        Ľutujeme, no vami hľadaná lokalita nieje v našom zozname
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import deburr from "lodash/deburr";
export default {
    props: {
        listData: Array,
        selectId: String,
        selectName: String,
        selectRequired: Boolean,
        selectResponse: String,
        selectedItem: Object
    },
    data() {
        return {
            selected: this.selectedItem,
            filteredListData: this.listData,
            searchTerm: "",
            searchOpen: false,
            focusedItem: this.selectedItem
        };
    },
    methods: {
        select(selectedValue) {
            this.selected = selectedValue;
            this.searchOpen = false;
            this.$emit("input", selectedValue);
            this.$emit("placeselected");
        },
        filterList() {
            this.filteredListData = this.listData.filter(data => {
                //console.log(data);

                const searchIn = data.city
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                const term = this.searchTerm
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");

                return searchIn.includes(term);
            });
        },
        boldFinding(value) {
            const searchIn = value
                .normalize("NFD")
                .toLowerCase()
                .replace(/[\u0300-\u036f]/g, "");
            const term = this.searchTerm
                .normalize("NFD")
                .toLowerCase()
                .replace(/[\u0300-\u036f]/g, "");
            //console.log(term);

            const regex = new RegExp(term, "gi");
            const normalizedSplit = searchIn.replace(
                regex,
                match => `%${match}%`
            );
            //console.log(normalizedSplit);

            //Pomocna funkcia na rozdelenie stringu na danych indexoch
            const splitAt = (slicable, indices) =>
                [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]));

            //Najdeme miesta kde je string rozdeleny znakom % aby sme vedeli kde mame vkladat html na nenormalizovany string
            let indexesOfSplit = [];

            [...normalizedSplit].forEach((letter, index) => {
                if (letter === "%") {
                    //let ind = index == 0 ? 0 : index+1;
                    if (index == 0) {
                        indexesOfSplit.push(0);
                    } else {
                        indexesOfSplit.push(index - indexesOfSplit.length);
                    }
                }
            });

            //Naparujeme splity na nenormalizovany string
            const boldedParts = splitAt(value, indexesOfSplit).filter(
                item => item != ""
            );

            let bolded = "";

            //pridame bold tagy
            boldedParts.map(el => {
                if (deburr(el).toLowerCase() == term) {
                    bolded += `<strong>${el}</strong>`;
                } else bolded += el;
            });

            //console.log(bolded);

            return bolded;
        },
        focus(e) {
            //Zisti ktory element ma classu focused, ten zadefinuj interne ako focusnuty - od neho sa bude odvijat, ktory prvok nasleduje pred nim a po nom
            let focused = this.$refs.search_list.querySelector(".focused");

            // V pripade ze ziaden nenajdes zadefinuj prvy v liste ako focusnuty
            // moze nastat v pripade:
            // - input je novovytvoreny a nema ziadnu defaultnu hodnotu
            // - input list bol vyfiltrovany a element tym padom neexistuje

            if (!focused) {
                focused = this.$refs.search_list.firstChild;
                focused.classList.add("focused");
                //console.log(focused);
            }

            // AK EXISTUJE KEY EVENT
            // Filtrovanie Eventov
            if (e) {
                //SIPKA DOLE
                if (e.code == "ArrowDown") {
                    const next = focused.nextSibling;
                    //V pripade ze tento element neexistuje, tak nerob nic
                    //moze nastat v pripade, ze bol focusnuty posledny element a po nom uz nic neexistuje
                    if (next) {
                        focused.classList.remove("focused");
                        focused = next;
                    }
                    focused.classList.add("focused");
                    //console.log(next);
                }

                // SIPKA HORE
                if (e.code == "ArrowUp") {
                    const previous = focused.previousSibling;
                    //V pripade ze tento element neexistuje, tak nerob nic
                    //moze nastat v pripade, ze bol focusnuty prvy element a pred nim uz nic neexistuje
                    if (previous) {
                        focused.classList.remove("focused");
                        focused = previous;
                    }
                    focused.classList.add("focused");
                    //console.log(previous);
                }

                // ESCAPE
                // Zatvorenie search overlayu
                if (e.code == "Escape") {
                    this.searchOpen = false;
                }

                // ENTER
                // Potvrdenie vyberu
                if (e.code == "Enter") {
                    this.select(JSON.parse(focused.dataset.selectValue));
                }
            }

            // Upravenie zobrazenia, tak aby sa vybrany (focused) element zobrazoval vzdy v strede viewportu
            const listData = this.$refs.search_list.getBoundingClientRect();
            const listHeight = listData.height;

            const focusedData = focused.getBoundingClientRect();
            const focusedHeight = focusedData.height;

            const centerScroll =
                focused.offsetTop - focusedHeight / 2 - listHeight / 2;
            const topScroll = focused.offsetTop - focusedHeight;

            this.$refs.search_list.scrollTop = centerScroll;
        },
        openSearch() {
            this.searchOpen = true;

            this.$nextTick(() => {
                this.$refs.search.focus();
                this.focus();
                this.filterList();
            });
        },
        closeSearch(e) {
            if (!this.$el.contains(e.target)) {
                this.searchOpen = false;

                //Vyresetujeme vyhladavacie pole po jeho zavreti
                this.searchTerm = "";
                this.filterList();
            }
        }
    },
    filters: {
        normalize(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
    },
    mounted() {
        document.addEventListener("click", this.closeSearch);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.closeSearch);
    }
};
</script>
