<template>
    <p v-if="isEnded">Ukončený</p>
    <p v-else>
        <span v-if="days">{{ days }} dní, </span>
        <span v-if="hours">{{ hours }} hodín, </span>
        <span v-if="minutes">{{ minutes }} minút a</span>
        <span v-if="seconds">{{ seconds }} sekúnd</span>
    </p>
</template>

<script>
    export default {
        props: {
            date: {
                type: String
            }
        },

        data () {
            return {
                endDate: Date.parse(this.date),
                days: null,
                hours: null,
                minutes: null,
                seconds: null,
                isEnded: false
            }
        },
        methods: {
            updateRemaining (distance) {
                this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
            },

            tick () {
                const currentTime = new Date();
                const distance = Math.max(this.endDate - currentTime, 0);
                this.updateRemaining(distance);

                if (distance === 0) {
                    clearInterval(this.timer);
                    this.isEnded = true
                }
            }
        },

        mounted () {
            this.tick();
            this.timer = setInterval(this.tick.bind(this), 1000);
        },

        destroy () {
            clearInterval(this.timer);
        }
    }
</script>
