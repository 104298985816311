<template>
    <div class="container">
        <div class="w720">
            <!-- Objednavka -->
            <section class="pricelist-section mb-4">
                <!-- Header -->
                <div class="pricelist-section__header">
                    <h2>Obsah objednávky</h2>

                    <figure class="logo">
                        <img
                            :src="getProgram(getSelectedAnnetaPackage).image"
                            alt=""
                        />
                    </figure>
                </div>

                <!-- Content -->
                <div class="pricelist-section__content decorated">
                    <div
                        class="pricelist-section__content__header row align-items-center"
                    >
                        <div
                            class="col-12 text-left"
                            v-bind:class="{
                                'col-md-9': monthlyItems[0].promo === null,
                                'col-md-6': monthlyItems[0].promo !== null
                            }"
                        >
                            <h3>
                                Súhrn fakturácie
                            </h3>
                        </div>

                        <div
                            class="col-6 col-md-3 text-right"
                            v-if="monthlyItems[0].promo"
                        >
                            <h3>
                                1.-{{ monthlyItems[0].promo.months }}. mesiac
                            </h3>
                        </div>
                        <div
                            class="col-6 col-md-3 text-right"
                            v-if="monthlyItems[0].promo"
                        >
                            <h3>
                                {{
                                    parseInt(monthlyItems[0].promo.months) + 1
                                }}.-24. mesiac
                            </h3>
                        </div>
                        <div
                            class="col-3 col-md-3 text-right"
                            v-if="!monthlyItems[0].promo"
                        >
                            <h3>
                                1.-24. mesiac
                            </h3>
                        </div>
                    </div>
                    <!-- rest of monthly item = package -->
                    <div
                        v-for="(item, index) in monthlyItems"
                        class="row align-items-end"
                        :key="`monthly${index}`"
                    >
                        <div
                            class="col-12 text-left"
                            v-bind:class="{
                                'col-md-9': monthlyItems[0].promo === null,
                                'col-md-6': monthlyItems[0].promo !== null
                            }"
                        >
                            <strong>{{ item.title }}</strong>
                            {{ item.description }}<br />
                            <!--                        {{ monthlyOrOnce(item.payment) }}-->
                        </div>
                        <div
                            class="col-6 col-md-3 text-right"
                            v-if="item.promo && item.promo.price"
                        >
                            {{ item.promo.price }} €
                        </div>
                        <div
                            class="col-6 col-md-3 text-right"
                            v-if="item.promo && !item.promo.price"
                        >
                            <strong style="color: #0ea879">zadarmo</strong>
                        </div>

                        <div
                            class="col-6 col-md-3 text-right"
                            v-if="
                                !item.promo &&
                                    monthlyItems[0].promo &&
                                    item.price
                            "
                        >
                            {{ item.price }} €
                        </div>
                        <div
                            class="col-6 col-md-3 text-right"
                            v-if="
                                !item.promo &&
                                    monthlyItems[0].promo &&
                                    !item.price
                            "
                        >
                            <strong style="color: #0ea879">zadarmo</strong>
                        </div>

                        <div
                            class="col-6 col-md-3 text-right"
                            v-if="item.price"
                        >
                            {{ item.price }} €
                        </div>
                        <div
                            class="col-6 col-md-3 text-right"
                            v-if="!item.price"
                        >
                            <strong style="color: #0ea879">zadarmo</strong>
                        </div>
                    </div>

                    <div
                        v-for="(item, index) in onceItems"
                        class="row align-items-end"
                        :key="`once${index}`"
                    >
                        <div
                            class="col-12 text-left"
                            v-bind:class="{
                                'col-md-9': monthlyItems[0].promo === null,
                                'col-md-6': monthlyItems[0].promo !== null
                            }"
                        >
                            <strong>{{ item.title }}</strong>
                            {{ item.description }}<br />
                            <!--                        {{ monthlyOrOnce(item.payment) }}-->
                        </div>
                        <div
                            class="col-6 col-md-3 ml-auto text-right"
                            v-if="item.price"
                        >
                            {{ item.price }} €
                        </div>
                        <div
                            class="col-6 col-md-3 ml-auto text-right"
                            v-if="!item.price"
                        >
                            <strong style="color: #0ea879">zadarmo</strong>
                        </div>
                        <div
                            class="col-6 col-md-3 ml-auto text-right"
                            v-if="item.price"
                        ></div>
                        <div
                            class="col-6 col-md-3 ml-auto text-right"
                            v-if="!item.price"
                        >
                            <strong style="color: #0ea879"></strong>
                        </div>
                    </div>
                </div>
                <div class="pricelist-section__content decorated mt-2">
                    <div class="row align-items-end" :key="`delivery`">
                        <div
                            class="col-12 text-left"
                            v-bind:class="{
                                'col-md-9': monthlyItems[0].promo === null,
                                'col-md-6': monthlyItems[0].promo !== null
                            }"
                        >
                            <strong>Doprava</strong>– kuriér DPD<br />
                            <!--                        {{ monthlyOrOnce(item.payment) }}-->
                        </div>
                        <div class="col-6 col-md-3 ml-auto text-right">
                            6.00 €
                        </div>
                        <div class="col-6 col-md-3 ml-auto text-right"></div>
                    </div>
                </div>
            </section>

            <!-- Doprava a platba -->
            <!--        <section class='pricelist-section mb-4'>-->
            <!--            <div class="pricelist-section__header">-->
            <!--                <h2>Doprava a platba</h2>-->
            <!--            </div>-->
            <!--            <div class="pricelist-section__content">-->
            <!--                <template v-for="(method, index) in shipping">-->
            <!--                    <div class="row align-items-center" :key="`shipping${index}`">-->
            <!--                        <div class="col">-->
            <!--                            <label class="radio" :for="`shipping${index}`">-->
            <!--                                <input type="radio" name="shipping" v-model="cart[0]" :value="method" :id="`shipping${index}`">-->
            <!--                                <span class="radio-label">{{ method.title }}</span>-->
            <!--                            </label>-->
            <!--                        </div>-->
            <!--                        <div class="col-auto ml-auto text-right">-->
            <!--                            <span class="text-highlighted">-->
            <!--                                {{ method.price }} €-->
            <!--                            </span>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </template>-->
            <!--            </div>-->

            <!--        </section>-->

            <!-- Osobne udaje -->
            <section class="pricelist-section mb-4">
                <div class="pricelist-section__header">
                    <h2>Osobné údaje</h2>
                </div>
                <div class="pricelist-section__content">
                    <div class="row">
                        <div class="col">
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.name
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.surname
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.street
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.streetNr
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.zip
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.city
                            }}
                            <br /><br />

                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.email
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.telephone
                            }}
                            <br />
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.idCard
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.idNr
                            }}
                        </div>
                    </div>
                </div>
            </section>

            <!-- Fakturacne udaje -->
            <section class="pricelist-section mb-4">
                <div class="pricelist-section__header">
                    <h2>Fakturačné údaje</h2>
                </div>
                <div class="pricelist-section__content">
                    <div class="row">
                        <div class="col">
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.name
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.surname
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.street
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.streetNr
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.zip
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.city
                            }}
                            <br /><br />

                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.telephone
                            }}
                        </div>
                    </div>
                </div>
            </section>

            <!-- Dodacia adresa -->
            <section class="pricelist-section mb-4">
                <div class="pricelist-section__header">
                    <h2>Dodacie údaje</h2>
                </div>
                <div class="pricelist-section__content">
                    <div class="row">
                        <div class="col">
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.name
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.surname
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.street
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.streetNr
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.zip
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.city
                            }}
                            <br /><br />

                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.telephone
                            }}
                        </div>
                    </div>
                </div>
            </section>

            <!-- Objednávka -->
            <section class="pricelist-summary mb-5">
                <div class="row">
                    <div class="col-auto text-md-left">
                        <strong
                            >Vaša objednávka spolu - cena za prvý mesiac</strong
                        >
                    </div>
                    <div class="col-auto ml-auto text-md-right price">
                        <!-- Ak sa ukaze .sale a je v poradi DOM pred .regular, tak na .regular sa automaticky prida preskrtnutie -->
                        <!-- <strong class="sale">{{ subTotal }} €</strong> -->
                        <strong class="regular">{{ subTotal }} €</strong>
                        <span class="vat">s DPH</span>
                    </div>
                </div>
            </section>

            <!--  -->
            <section class="mb-5" v-if="legislative.length">
                <label class="checkbox" for="suhlas-obchodne">
                    <input
                        type="checkbox"
                        v-model="formData.suhlasObchodne"
                        name="suhlas-obchodne"
                        id="suhlas-obchodne"
                    />
                    <span class="checkbox-label"
                        >Súhlasím s
                        <a
                            href="/dokument/vseobecne-podmienky-k-sluzbe-plustelka"
                            target="_blank"
                            class="text-highlighted"
                            >obchodnými podmienkami</a
                        ></span
                    >
                </label>

                <br />

                <label class="checkbox" for="suhlas-osobne">
                    <input
                        type="checkbox"
                        v-model="formData.suhlasOsobne"
                        name="suhlas-osobne"
                        id="suhlas-osobne"
                    />
                    <span class="checkbox-label"
                        >Súhlasím so
                        <a
                            :href="getLegislativeLinkByKey('gdpr')"
                            target="_blank"
                            class="text-highlighted"
                            >spracovaním osobných údajov</a
                        ></span
                    >
                </label>

                <br />

                <label class="checkbox" for="suhlas-newsletter">
                    <input
                        type="checkbox"
                        v-model="formData.suhlasNewsletter"
                        name="suhlas-newsletter"
                        id="suhlas-newsletter"
                    />
                    <span class="checkbox-label"
                        >Chcem odoberať novinky z Plustelky</span
                    >
                </label>
                <div>
                    <conditions :headlineCentered="true">
                        <template v-slot:headline>
                            Súhlas s vydávaním elektronickej faktúry za Služby
                        </template>
                        <template v-slot:content>
                            <p>
                                Uskutočnením objednávky udeľuje Užívateľ súhlas
                                s vydávaním elektronickej faktúry za Služby
                                v súlade s ustan. § 71 ods. 1 písm. b) zákona č.
                                222/2004 Z.z. o dani z pridanej hodnoty.
                            </p>
                        </template>
                    </conditions>

                    <conditions :headlineCentered="true">
                        <template v-slot:headline>
                            Poučenie o odstúpení
                        </template>
                        <template v-slot:content>
                            <p>
                                Máte právo odstúpiť od Zmluvy bez uvedenia
                                dôvodu v lehote 14 dní odo dňa uzatvorenia
                                Zmluvy, a to jednoznačným vyhlásením (napríklad
                                listom zaslaným poštou alebo e-mailom) na adresu
                                sídla Poskytovateľa. Na tento účel môže použiť
                                vzorový
                                <a
                                    href="/dokument/informacie-pre-spotrebitela"
                                    target="_blank"
                                    >formulár na odstúpenie od Zmluvy</a
                                >. Lehota na odstúpenie od Zmluvy je zachovaná,
                                ak je oznámenie o uplatnení práva na odstúpenie
                                od Zmluvy zaslané najneskôr v posledný deň
                                lehoty na odstúpenie od Zmluvy. Nie ste
                                oprávnený odstúpiť od Zmluvy, ak sa poskytovanie
                                Služby začalo a došlo k úplnému poskytnutiu
                                Služby s Vašim výslovným súhlasom. V opačnom
                                prípade, ste povinný uhradiť Poskytovateľovi
                                cenu za skutočne poskytnutú Službu do dňa
                                doručenia oznámenia o odstúpení od Zmluvy.
                            </p>
                            <br />
                            <p>
                                Odoslaním objednávky a Registráciou a
                                uzatvorením každej jednotlivej Zmluvy udeľujem
                                vo vzťahu ku každej jednotlivej Zmluve
                                uzatváranej na základe Registrácie svoj výslovný
                                súhlas so začatím poskytovania Služby pred
                                uplynutím 14 dňovej lehoty na odstúpenie od
                                Zmluvy. Zároveň vyhlasujem, že som bol riadne
                                poučený o tom, že udelením súhlasu podľa
                                predchádzajúcej vety strácam právo na odstúpenie
                                od Zmluvy.
                            </p>
                        </template>
                    </conditions>

                    <p>
                        Odoslaním objednávky rovnako vyhlasujem, že som sa v
                        celom rozsahu oboznámil s Cenníkom, Osobitnými
                        podmienkami a VOP a vyslovujem s nimi svoj
                        bezpodmienečný súhlas.
                    </p>
                </div>
            </section>

            <section class="form__footer">
                <button class="btn btn-primary" @click="prevStep">
                    Krok späť
                </button>

                <button
                    class="btn btn-primary"
                    :disabled="!stepFilledCorrectly || loading"
                    @click="registerAndSend"
                >
                    <!--                <i v-if="loading" class="fas fa-circle-notch fa-spin" ></i>-->
                    Objednať s povinnosťou platby
                </button>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import map from "lodash/map";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import conditions from "../../components/vue/conditions/conditions";

//Mixins
import formStep from "../../mixins/formStep";
import { API_URL, JSON_AUTH } from "../../config";

export default {
    mixins: [formStep],
    data() {
        return {
            stepName: "suhrnObjednavky",
            prevStepName: "annetaStep4",
            nextStepName: "annetaStep6",
            bindedMonths: 24,
            legislative: [],
            cart: [],
            userId: null,
            shipping: [
                {
                    title: "Dobierka - kuriér DPD",
                    price: 4.99,
                    payment: "ONCE",
                    description: "doprava"
                },
                {
                    title: "Platba Vopred - kuriér DPD",
                    price: 2.99,
                    payment: "ONCE",
                    description: "doprava"
                }
            ],
            formData: {
                suhlasObchodne: false,
                suhlasOsobne: false,
                suhlasNewsletter: false
            },
            showNotice: false,
            loading: false
        };
    },

    components: {
        conditions
    },

    watch: {
        cart(newValue, oldValue) {
            this.saveStepState(this.currentStepStateToSave);
        },
        companyInformation: {
            handler(val) {
                this.saveStepState(this.currentStepStateToSave);
            },
            deep: true
        }
    },

    computed: {
        ...mapGetters([
            "getStepsState",
            "getStep",
            "getProgram",
            "getSelectedAnnetaPackage"
        ]),
        buttonContent() {
            if (this.stepFilledCorrectly) {
                return "Ďalší krok";
            } else return "Prosím vyplňte všetky položky formulára";
        },
        stepFilledCorrectly() {
            return (
                // Bool
                // tu zhromazdujeme vsetky podmienky, ktore musia byt
                // splnene na to, aby formular mohol pokracovat dalej
                this.cart.length > 0,
                this.formData.suhlasObchodne == true &&
                    this.formData.suhlasOsobne == true
            );
        },

        cartItems() {
            const allSteps = this.getStepsState;
            // console.log(allSteps);

            //Cisty array ktory zhromazdi vsetky objednane produkty
            const allProducts = [];

            //Ocistime produkty z ulozeneho stavu
            const allCarts = map(allSteps, o => {
                if (o.cart != undefined && o.cart != null) {
                    o.cart.forEach(item => {
                        item.duration = this.bindedMonths;
                        item.period = "full";

                        // Optional Anneta
                        if (
                            item.discount &&
                            typeof item.discount === "object"
                        ) {
                            let separatedSale = cloneDeep(item);

                            separatedSale.title = `Zľavnené obodbie - ${separatedSale.title}`;
                            separatedSale.duration =
                                separatedSale.discount.months;
                            separatedSale.price = separatedSale.discount.price;
                            separatedSale.period = "sale";

                            item.duration =
                                this.bindedMonths -
                                separatedSale.discount.months;
                            item.period = "afterSale";

                            //console.log(separatedSale);

                            allProducts.push(separatedSale);
                        }
                        // END Optional Anneta

                        allProducts.push(item);
                    });
                }
            });

            allProducts.push({
                title: "Aktivačný poplatok",
                description: "",
                payment: "ONCE",
                price: 0
            });

            return allProducts;
        },
        monthlyItems() {
            let mItems = this.cartItems.filter(arr => {
                return arr.payment == "PM";
            });
            let annetaProgram = this.getProgram(this.getSelectedAnnetaPackage);

            mItems[0].promo = annetaProgram.promo;
            return mItems;
        },
        monthlyItemsBeforeSale() {
            return this.cartItems.filter(arr => {
                return (
                    arr.payment == "PM" &&
                    (arr.period == "full" || arr.period == "sale")
                );
            });
        },
        monthlyItemsAfterSale() {
            return this.cartItems.filter(arr => {
                return (
                    arr.payment == "PM" &&
                    (arr.period == "full" || arr.period == "afterSale")
                );
            });
        },
        onceItems() {
            return this.cartItems.filter(arr => {
                return arr.payment == "ONCE";
            });
        },
        accumulatedMonthlyItems() {
            let num = this.monthlyItems
                .map(item => parseFloat(item.price))
                .reduce((a, b) => a + b, 0);

            return parseFloat(num.toFixed(2));
        },
        accumulatedMonthlyItemsBeforeSale() {
            let num = this.monthlyItemsBeforeSale
                .map(item => parseFloat(item.price))
                .reduce((a, b) => a + b, 0);
            return parseFloat(num.toFixed(2));
        },
        accumulatedMonthlyItemsAfterSale() {
            const num = this.monthlyItemsAfterSale
                .map(item => {
                    let result =
                        item.promo && item.promo.price
                            ? item.promo.price
                            : item.price
                            ? item.price
                            : 0;
                    return parseFloat(result);
                })
                .reduce((a, b) => a + b, 0);
            return parseFloat(num.toFixed(2));
        },
        accumulatedOnceItems() {
            let num = this.onceItems
                .map(item => parseFloat(item.price))
                .reduce((a, b) => a + b, 0);

            return parseFloat(num.toFixed(2));
        },
        subTotal() {
            let subTotal = this.accumulatedMonthlyItemsAfterSale + 6; //*this.bindedMonths + this.accumulatedOnceItems;

            return parseFloat(subTotal.toFixed(2));
        },
        currentStepStateToSave() {
            //Nastavenia, ktore sa maju ulozit
            return {
                step: "suhrnObjednavky",
                state: {
                    //location: this.location

                    formData: this.formData,
                    cart: this.cart
                }
            };
        }
    },

    methods: {
        showHideNotice() {
            this.showNotice = !this.showNotice;
        },
        registerAndSend() {
            let customerId = this.$store.state.ponuka.stepState.osobneUdaje
                .userId;
            if (customerId) {
                this.sendOrder();
            } else {
                this.registerAccount();
            }
        },
        registerAccount() {
            let data = {};
            data["method"] = "POST";
            data["headers"] = JSON_AUTH;
            data["url"] = API_URL + "Users/create-user";
            let personalInformation = this.$store.state.ponuka.stepState
                .osobneUdaje.personalInformation;
            let zip = personalInformation.zip;
            if (zip[3] !== " ") {
                zip = zip.slice(0, 3) + " " + zip.slice(3, 5);
            }

            data["data"] = {
                firstName: personalInformation.name,
                lastName: personalInformation.surname,
                registerNumber: personalInformation.streetNr,
                isCompany: false,
                street: personalInformation.street,
                houseNumber: personalInformation.streetNr,
                city: personalInformation.city,
                postalCode: zip,
                mobilePhoneNumber: personalInformation.telephone,
                email: personalInformation.email,
                documentNumber: personalInformation.idCard
                    ? personalInformation.idCard
                    : null,
                identificationNumber: personalInformation.idNr
                    ? personalInformation.idNr
                    : null,
                isActive: true
            };

            this.loading = true;
            this.$http.post("/api/external", data).then(data => {
                if (data.data.success) {
                    this.userId = data.data.result;
                    // Object.assign(this.userId, data.data.result)
                    // this.formIsValid = true;
                    // this.nextStep();
                    this.sendOrder();
                }
            });
        },
        monthlyOrOnce(value) {
            if (value == "PM") return "mesačne";
            if (value == "ONCE") return "jednorazovo";
        },
        validateTel(value) {
            if (value.match(/(\+)*(\d{3,4}\s?\d{3}\s?\d{3}\s?\d{0,3})/g))
                return true;
            else return false;
        },
        validateEmail(value) {
            if (value.match(/.+\@.+\.\w+/g)) return true;
            else return false;
        },
        getLegislativeLinkByKey(key) {
            return this.legislative.find(el => el.identifier == key).link;
        },
        sendOrder() {
            let customerId = this.$store.state.ponuka.stepState.osobneUdaje
                .userId
                ? this.$store.state.ponuka.stepState.osobneUdaje.userId
                : this.userId;

            let personalInfo = this.$store.state.ponuka.stepState.osobneUdaje;
            let deliveryInformation = personalInfo.deliveryInformation;
            let packages = this.$store.state.ponuka.stepState.vyberBalikov
                .service;
            let promoMonths = this.$store.state.ponuka.stepState.vyberBalikov
                .cart[0]?.promo?.months
                ? parseInt(
                      this.$store.state.ponuka.stepState.vyberBalikov.cart[0]
                          ?.promo?.months
                  )
                : 0;
            let services = [];

            let date2y = new Date();
            date2y.setFullYear(date2y.getFullYear() + 2);
            let year2y = new Intl.DateTimeFormat("en", {
                year: "numeric"
            }).format(date2y);
            let month2y = new Intl.DateTimeFormat("en", {
                month: "2-digit"
            }).format(date2y);
            let day2y = new Intl.DateTimeFormat("en", {
                day: "2-digit"
            }).format(date2y);

            let date15d = new Date();
            date15d.setDate(date15d.getDate() + 15);
            let year15d = new Intl.DateTimeFormat("en", {
                year: "numeric"
            }).format(date15d);
            let month15d = new Intl.DateTimeFormat("en", {
                month: "2-digit"
            }).format(date15d);
            let day15d = new Intl.DateTimeFormat("en", {
                day: "2-digit"
            }).format(date15d);

            let date3M = new Date();
            date3M.setMonth(date3M.getMonth() + promoMonths);
            let year3M = new Intl.DateTimeFormat("en", {
                year: "numeric"
            }).format(date3M);
            let month3M = new Intl.DateTimeFormat("en", {
                month: "2-digit"
            }).format(date3M);
            let day3M = new Intl.DateTimeFormat("en", {
                day: "2-digit"
            }).format(date3M);

            let date = new Date();
            let year = new Intl.DateTimeFormat("en", {
                year: "numeric"
            }).format(date);
            let month = new Intl.DateTimeFormat("en", {
                month: "2-digit"
            }).format(date);
            let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
                date
            );

            let annetaProgram = this.getProgram(this.getSelectedAnnetaPackage);
            //hlavny package je vzdy v cene
            let freePackages = 0;
            let packageName = "";
            if (packages[0].name === "Štandardná") {
                freePackages = 0;
                packageName = "Anneta S";
            } else if (packages[0].name === "Rozšírená") {
                freePackages = 2;
                packageName = "Anneta Rozšírená";
            } else if (packages[0].name === "Prémiová") {
                freePackages = 4;
                packageName = "Anneta Prémiová";
            }
            if (packages.length > 1) {
                let counter = 0;
                for (let i = 1; i <= freePackages; i++) {
                    switch (packages[i].name) {
                        case "Filmový": {
                            counter += 2;
                            break;
                        }
                        case "Pre deti": {
                            counter += 4;
                            break;
                        }
                        case "Šport": {
                            counter += 16;
                            break;
                        }
                        case "Dokumenty": {
                            counter += 32;
                            break;
                        }
                        case "Pre dospelých": {
                            counter += 8;
                            break;
                        }
                    }
                }
                if (counter > 0) {
                    let serv = {
                        specificSymbol: `${counter}`,
                        serviceCode: packageName,
                        boundUntil: `${year2y}-${month2y}-${day2y}`,
                        paymentFrequencyId: "6",
                        paymentTypeId: "7",
                        nextInvoicingDate: `${year15d}-${month15d}-${day15d}`,
                        activationDate: `${year}-${month}-${day}`,
                        endDate: "2222-02-02",
                        invoicingTypeId: "1",
                        fixedDate: "0"
                    };
                    if (annetaProgram.promo) {
                        serv["specialPrice"] = "0"; //`${annetaProgram.promo.price}`;
                        let fromDate = annetaProgram.promo.from.split(".");
                        let toDate = annetaProgram.promo.to.split(".");
                        for (let i = 0; i < fromDate.length; i++) {
                            if (fromDate[i].length < 2) {
                                fromDate[i] = "0" + parseInt(fromDate[i]);
                            }
                        }
                        for (let i = 0; i < toDate.length; i++) {
                            if (toDate[i].length < 2) {
                                toDate[i] = "0" + parseInt(toDate[i]);
                            }
                        }
                        serv["specialPriceStart"] =
                            year + "-" + month + "-" + day;
                        serv["specialPriceEnd"] =
                            year3M + "-" + month3M + "-" + day3M;
                    }
                    services.push(serv);
                }
            }
            if (packages.length > freePackages + 1) {
                for (let i = freePackages + 1; i < packages.length; i++) {
                    let counter = 0;

                    switch (packages[i].name) {
                        case "Filmový": {
                            counter += 2;
                            break;
                        }
                        case "Pre deti": {
                            counter += 4;
                            break;
                        }
                        case "Šport": {
                            counter += 16;
                            break;
                        }
                        case "Dokumenty": {
                            counter += 32;
                            break;
                        }
                        case "Pre dospelých": {
                            counter += 8;
                            break;
                        }
                    }
                    services.push({
                        specificSymbol: `${counter}`,
                        serviceCode: "",
                        // "boundUntil": `${year2y}-${month2y}-${day2y}`,
                        paymentFrequencyId: "6",
                        paymentTypeId: "7",
                        nextInvoicingDate: `${year15d}-${month15d}-${day15d}`,
                        activationDate: `${year}-${month}-${day}`,
                        endDate: "2222-02-02",
                        invoicingTypeId: "1",
                        fixedDate: "0"
                    });
                }
            }

            let data = {
                campaignCode: "P24ANNE",
                storeCode: "HS",
                deviceId: "Postpaid kupón",
                customerId: customerId,
                internalNote: "Amiko A5",
                services: services,
                endpoint: {
                    street: deliveryInformation.street,
                    city: deliveryInformation.city,
                    cityPart: deliveryInformation.street,
                    registerNumber: deliveryInformation.streetNr,
                    houseNumber: deliveryInformation.streetNr,
                    postalCode: deliveryInformation.zip,
                    floor: "0",
                    aptNumber: "0"
                }
            };
            // this.nextStep();

            let postData = {};
            postData["method"] = "POST";
            postData["headers"] = JSON_AUTH;
            postData["url"] = API_URL + "Orders/eshop/process-order";
            postData["data"] = data;
            //mail data
            postData["order-detail"] = {};
            if (this.monthlyItems[0].promo) {
                postData["order-detail"]["discount"] = true;
                postData["order-detail"][
                    "discount_length"
                ] = this.monthlyItems[0].promo.months;
            }
            let items = [];
            let onceItems = [];
            for (let i = 0; i < this.monthlyItems.length; i++) {
                let item = this.monthlyItems[i];
                let promoPrice = "zadarmo";
                if (item.promo && item.promo.price) {
                    promoPrice = item.promo.price + "€";
                }
                let price = "zadarmo";
                if (item.price > 0) {
                    price = item.price + "€";
                }
                items.push({
                    title: this.monthlyItems[i].title,
                    description: this.monthlyItems[i].description,
                    price_discount: promoPrice,
                    price: price
                });
            }
            for (let i = 0; i < this.onceItems.length; i++) {
                let item = this.onceItems[i];
                let price = "zadarmo";
                if (item.price > 0) {
                    price = item.price + "€";
                }
                onceItems.push({
                    title: item.title,
                    description: item.description,
                    price_discount: "",
                    price: price
                });
            }
            // onceItems.push();
            postData["order-detail"]["delivery_price"] = {
                title: "kuriér DPD",
                price_discount: "",
                price: "6.00€"
            };
            postData["order-detail"]["items"] = items;
            postData["order-detail"]["onceItems"] = onceItems;
            postData["order-detail"]["complete"] = this.subTotal + "€";
            postData["order-detail"][
                "email"
            ] = this.$store.state.ponuka.stepState.osobneUdaje.personalInformation.email;
            postData["order-detail"]["personal_info"] = [
                this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.name +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .personalInformation.surname,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.street +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .personalInformation.streetNr,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.zip +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .personalInformation.city,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.email,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.telephone,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.idCard,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.idNr
            ];
            postData["order-detail"]["invoicing_info"] = [
                this.$store.state.ponuka.stepState.osobneUdaje
                    .billingInformation.name +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .billingInformation.surname,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .billingInformation.street +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .billingInformation.streetNr,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .billingInformation.zip +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .billingInformation.city,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .billingInformation.telephone
            ];
            postData["order-detail"]["delivery_info"] = [
                this.$store.state.ponuka.stepState.osobneUdaje
                    .deliveryInformation.name +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .deliveryInformation.surname,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .deliveryInformation.street +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .deliveryInformation.streetNr,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .deliveryInformation.zip +
                    " " +
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .deliveryInformation.city,
                this.$store.state.ponuka.stepState.osobneUdaje
                    .deliveryInformation.telephone
            ];

            this.$http.post("/api/external", postData).then(data => {
                if (data.data.success) {
                    this.loading = false;
                    this.nextStep();
                }
            });
        }
    },

    async mounted() {
        const legislative = await axios.post("/api/legislative");
        this.legislative = legislative.data.data;

        //Object.assign(this.companyInformation, this.getStep('osobneUdaje').personalInformation)
        this.bindedMonths = parseFloat(24);
        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        const stepData = this.getStep("suhrnObjednavky");
        if (stepData) {
            //this.location = stepData.location;
            this.cart = stepData.cart;
            this.userId = stepData.userId;
            this.companyInformation = stepData.companyInformation;
            this.formData = stepData.formData;
            // console.log(this.formData, " >>> ", this.userId, ">>>");
        }
    }
};
</script>

<style lang="scss" scoped></style>
