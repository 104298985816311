<template>
    <div>
        <!-- Nazov aktualnej podstranky -->
        <div class="title title--secondary">
            <div class="container">
                <h1 class="h2 title__heading">{{ this.$route.meta.title }}</h1>
                <p class="title__subheading" v-if="this.$route.meta.subtitle">
                    {{ this.$route.meta.subtitle }}
                </p>
            </div>
        </div>

        <!-- Kroky -->
        <transition
            name="inter-fade"
            mode="out-in"
            v-if="this.$route.name !== 'antenaStep6'"
        >
            <div class="container">
                <div class="order-steps">
                    <router-link
                        :class="{ disabled: !getStep('vyberBalikov') }"
                        class="order-steps__item"
                        :to="{ name: 'antenaStep1' }"
                    >
                        <span class="order-steps__number">1.</span>
                        <span class="order-steps__title">Výber balíkov</span>
                    </router-link>
                    <router-link
                        :class="{ disabled: !getStep('vyberZariadenia') }"
                        class="order-steps__item"
                        :to="{ name: 'antenaStep2' }"
                    >
                        <span class="order-steps__number">2</span>
                        <span class="order-steps__title">Výber zariadenia</span>
                    </router-link>

                    <!-- <router-link
                        :class="{ disabled: !getStep('vyberAnteny') }"
                        class="order-steps__item"
                        :to="{ name: 'antenaStep3' }"
                        v-if="displayAntennas"
                    >
                        <span class="order-steps__number">3</span>
                        <span class="order-steps__title"
                            >Výber antény</span
                        >
                    </router-link> -->

                    <router-link
                        :class="{ disabled: !getStep('osobneUdaje') }"
                        class="order-steps__item"
                        :to="{ name: 'antenaStep4' }"
                    >
                        <!-- <span
                            v-if="!displayAntennas"
                            class="order-steps__number"
                            >3</span
                        >
                        <span v-if="displayAntennas" class="order-steps__number"
                            >4</span
                        > -->
                        <span class="order-steps__number">3</span>
                        <span class="order-steps__title">Osobné údaje</span>
                    </router-link>
                    <router-link
                        :class="{
                            disabled: !$store.state.ponuka.allStepsValid
                        }"
                        class="order-steps__item"
                        :to="{ name: 'antenaStep5' }"
                    >
                        <!-- <span
                            v-if="!displayAntennas"
                            class="order-steps__number"
                            >4</span
                        >
                        <span v-if="displayAntennas" class="order-steps__number"
                            >5</span
                        > -->
                        <span class="order-steps__number">4</span>
                        <span class="order-steps__title">Súhrn objednávky</span>
                    </router-link>
                </div>
            </div>
        </transition>

        <!-- {{ $store.state.ponuka.validSteps.firstStep }}
        {{ $store.state.ponuka }}
         <pre>
            {{ $store.state.ponuka }}
        </pre>
        -->

        <!-- {{ $router.currentRoute.fullPath }} -->

        <transition name="inter-fade" mode="out-in">
            <!-- View -->
            <router-view v-if="isLoadingData && isLoadingCities"> </router-view>

            <!-- Loading -->
            <div class="section section--small" v-else>
                <div class="container">
                    <div class="mx-auto" :class="loadingTheme">
                        Načítavam ...
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import VueRouter from "vue-router";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            darkBg: ["antenaStep6"],
            displayAntennas: false
        };
    },
    computed: {
        ...mapGetters([
            "getProgram",
            "getStep",
            "getSelectedProgram",
            "isLoadingData",
            "isLoadingCities",
            "getAntennasUnderProgram"
        ]),
        darkBgAllowed() {
            return this.darkBg.includes(this.$route.name);
        },
        loadingTheme() {
            return this.darkBgAllowed
                ? "loading-pulse__light"
                : "loading-pulse__dark";
        },
        isAntena() {
            return this.getSelectedProgram.id == 1 ? true : false;
        }
    },
    methods: {
        ...mapActions(["fetchPrograms", "fetchCities"]),
        setDisplayAntennas(value) {
            this.displayAntennas = value;
            // console.log("called...");
            // console.log(value);
        }
    },
    created() {
        if (!this.isLoadingData) {
            //FETCHNE API programov a naplni store
            this.fetchPrograms().then(data => {
                this.setDisplayAntennas(data[0].antennas.length > 0);
            });
        }

        if (!this.isLoadingCities) {
            //Store API call ktory fetchne zoznam vsetkych miest
            this.fetchCities();
        }

        // Ak nemame udaj z prveho kroku, tak resetni appku na zaciatok, aby user neostal
        if (
            !this.getStep("vyberBalikov") &&
            this.$route.path != "/antena/objednavka-antena"
        ) {
            this.$router.push("/antena/objednavka-antena");
        }

        // Zamedzi prepnutiu na nepovoleny route ak nieje vyplneny prvky krok
        // this.$router.beforeEach((to, from, next) => {
        //     //Ak neexistuje udaj z prveho kroku, vrat sa na zaciatok aplikacie
        //     if(!this.getStep('overenieDostupnosti')){
        //         next('/');
        //     }
        //     else {
        //         next();
        //     }
        // })
    }
};
</script>
