import { render, staticRenderFns } from "./4-osobne_udaje.vue?vue&type=template&id=491d89f8&scoped=true"
import script from "./4-osobne_udaje.vue?vue&type=script&lang=js"
export * from "./4-osobne_udaje.vue?vue&type=script&lang=js"
import style0 from "./4-osobne_udaje.vue?vue&type=style&index=0&id=491d89f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "491d89f8",
  null
  
)

export default component.exports