<template>
    <div class="mb-3">
        <h2 class="h4 mb-3">Overte svoju lokalitu</h2>
        <search-select
            v-model="location"
            :listData="cities"
            :selectedItem="location"
            @placeselected="
                checkAvailablePrograms();
                setSelectedCity(location);
            "
        />

        <Notification
            v-if="showNotification"
            :type="type"
            :text="text"
        ></Notification>
    </div>
</template>

<script>
import Notification from "./notification/Notification";
import searchSelect from "./search-select/searchSelect.vue";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "ConfirmLocation",
    components: { Notification, searchSelect },
    props: {
        selectedItem: Object
    },
    watch: {
        selected(newValue, oldValue) {
            this.$emit("changeLocation", newValue);
        }
    },
    data() {
        return {
            showNotification: false,
            type: "",
            text: "",
            location: {},
            cities: [],
            selected: this.selectedItem
        };
    },
    created() {
        //Store API call ktory fetchne zoznam vsetkych miest
        this.fetchCities().then(() => {
            this.cities = this.getCities;
        });
    },
    computed: {
        ...mapGetters(["getCities"]),
        btnContent() {
            if (!Object.keys(this.location).length) {
                return "Prosím vyberte mesto alebo obec";
            } else return "Overiť dostupnosť";
        }
    },
    methods: {
        ...mapActions(["fetchCities", "setSelectedCity"]),
        checkAvailablePrograms() {
            // Rozhodni ktore programy budu dostupne, pre danu obec
            let num = this.location.mux_1 > this.location.mux_4 ? this.location.mux_4 : this.location.mux_1;

            if(num >= 50) {
                this.showNotification = true;
                this.type = "success";
                this.text = "Vo vašej lokalite sú dostupné naše služby";
                this.$emit("success", true);
            } else if( num < 50 && num >= 30  ) {
                this.showNotification = true;
                this.type = "warning";
                this.text = "Vo Vašej lokalite je vysoká pravdepodobnosť dostupnosti našich služieb, zanechajte nám prosím Váš kontakt pre detailnejšie overenie";
                this.$emit("success", false);
            } else {
                this.showNotification = true;
                this.type = "error";
                this.text = "Vaša lokalita sa nachádza v okrajovej oblasti dostupnosti našich služieb, zanechajte nám prosím Váš kontakt pre detailnejšie overenie";
                this.$emit("success", false);
            }

        }
    }
};
</script>

<style scoped></style>
