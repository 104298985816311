<template>
    <div>
        <form v-if="!sent">
            <div class="row mb-4">
                <div class="col-md-11">
                    <label for="email">Meno a priezvisko*</label>
                    <input type="text" class="form-control" v-bind:class="{ invalid: !validName }" @blur="validateName" v-model="name" id="name" name="name" placeholder="Meno a priezvisko">
                    <span v-if="!validName">Prosím zadajte Vaše meno a priezvisko</span>
                </div>
                <div class="col-md-11">
                    <label for="email">E-mail*</label>
                    <input type="text" class="form-control" v-bind:class="{ invalid: !validEmail }" @blur="validateEmail" v-model="email" id="email" name="email" placeholder="E-mail">
                    <span v-if="!validEmail">Prosím zadajte Váš email</span>
                </div>
                <div class="col-md-11">
                    <label for="email">Telefón*</label>
                    <input type="text" class="form-control" v-bind:class="{ invalid: !validPhone }" @blur="validatePhone" v-model="phone" id="telephone" name="telephone" placeholder="Telefón">
                    <span v-if="!validPhone">Prosím zadajte Vaše tel. číslo</span>
                </div>
                <div class="col-md-11">
                    <button :disabled="!validName && !validEmail && !validPhone" class="btn btn-primary" @click.prevent="submit"> Odoslať </button>
                </div>
            </div>
        </form>
        <div v-if="sent">
            <div class="row mb-4">
                <div class="col-md-11">
                    <p>
                        Ďakujeme, budeme Vás kontaktovať.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactForm",
    props: {
        formType: {
            type: String
        }
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            validName: true,
            validEmail: true,
            validPhone: true,
            sent: false
        };
    },
    methods: {
        validateEmail() {
            this.validEmail = !!this.email.match(/.+\@.+\.\w+/g);
        },
        validateName() {
            this.validName = this.name.length > 3;
        },
        validatePhone() {
            this.validPhone = this.phone.length > 5;
        },
        submit() {
            this.validateEmail();
            this.validateName();
            this.validatePhone();
            if(this.validEmail && this.validName && this.validPhone) {
                let data = {
                    service: this.formType,
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                }
                this.$http.post('/api/send-contact', data)
                    .then( response => {
                       if(response.success) {
                           this.sent = true;
                       }
                    });
            }
        }
    }
}
</script>

<style scoped>
.invalid {
    border: solid 1px red;
}
</style>
