<template>
    <div>
        <div class="reseller-table__header">
            <div>
                Názov
            </div>

            <div>
                Adresa
            </div>

            <div>
                Telefón
            </div>

            <div>
                E-mail
            </div>

            <div>
                Web
            </div>

            <div>
                Služby
            </div>
        </div>
        <marker-btn
            v-for="(reseller, index) in shared.resellers"
            :key="index"
            class="reseller-table__row"
            :shared="shared"
            :reseller="reseller"
            :google="shared.google"
            :map="shared.map"
        />
    </div>
</template>

<script>
//Components
import markerBtn from "../components/markerBtn.vue";

export default {
    props: ["shared"],
    components: {
        markerBtn
    }
};
</script>

<style lang="scss">
.reseller-table__header {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1em;
    align-items: center;
    font-size: 0.88em;
    font-weight: 700;
    background: #ecf8fa;

    > div {
        padding: 1em 0;

        &:first-of-type {
            padding-left: 1.5em;
        }
    }

    @media (max-width: 990px) {
        display: none;
    }
}

.reseller-table__row {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1em;
    align-items: center;
    font-size: 0.88em;

    border-bottom: 1px solid #dadada;

    @media (max-width: 990px) {
        grid-template-columns: 1fr;
        text-align: center;

        .cell {
            justify-content: center;
            flex-direction: column;
        }
    }

    > div {
        padding: 1em 0;
    }

    .eye-btn {
        margin: 1em;
        background: none;
        border: 0;
        outline: none;

        &:focus,
        &:active {
            outline: none;
        }
    }

    .cell {
        display: flex;
        align-items: center;
        overflow-wrap: break-word;
        word-wrap: anywhere;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
}
</style>
