// Router base
import Vue from "vue";
import VueRouter from "vue-router";

//Router modules
import antenaRoutes from "./modules/antenaRoutes";
import annetaRoutes from "./modules/annetaRoutes";

//Vue router init
Vue.use(VueRouter);

const router = new VueRouter({
    routes: [...antenaRoutes, ...annetaRoutes],
    scrollBehavior: function(to, _from, savedPosition) {
        //console.log(to);
        const URLsplit = to.path.split("/");
        const elementName = [...URLsplit].pop();
        //console.log(document.getElementById(elementName));

        if (document.getElementById(elementName)) {
            return {
                selector: "#" + elementName
            };
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
