<template>
    <div class="jkb-collapse">
        <div class="jkb-collapse__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                observer: null,
                elHeight: String,
                show:  false,
                timing: 300
            }
        },

        // ##     ## ######## ######## ##     ##  #######  ########   ######
        // ###   ### ##          ##    ##     ## ##     ## ##     ## ##    ##
        // #### #### ##          ##    ##     ## ##     ## ##     ## ##
        // ## ### ## ######      ##    ######### ##     ## ##     ##  ######
        // ##     ## ##          ##    ##     ## ##     ## ##     ##       ##
        // ##     ## ##          ##    ##     ## ##     ## ##     ## ##    ##
        // ##     ## ########    ##    ##     ##  #######  ########   ######
        methods: {
            toggle() {

                // Musi tu byt timeout, pretoze ked sa dynamicky zmeni content
                // slot-u, tak program nestihne vypocitat novu max height
                setTimeout(

                    ()=>{
                        this.$el.style.transition = `all ${this.timing/1000}s ease`;
                        this.show = !this.show;
                        this.$emit('collapse', this.show);


                        if(this.show){
                            this.$el.style.maxHeight = this.elHeight+'px';
                            this.$el.classList.add('animating');
                            setTimeout(()=>{
                                this.$el.classList.add('show')
                                this.$el.classList.remove('animating')
                            }, this.timing)
                        }
                        else{
                            this.$el.classList.remove('show')
                            this.$el.style.maxHeight = '0px';
                        }
                    }, 1
                )



            },

            silentToggle() {
                // Funkcia ktora bez animacie prepne collapse element - vyuziva sa hlavne pri tom, ked ma byt collapse element otvoreny uz pri nacitani stranky
                // Musi tu byt timeout, pretoze ked sa dynamicky zmeni content
                // slot-u, tak program nestihne vypocitat novu max height
                setTimeout(
                    ()=>{
                        this.$el.style.transition = `none`;
                        this.show = !this.show;
                        this.$emit('collapse', this.show);

                        if(this.show){
                            this.$el.style.maxHeight = 'unset';
                            this.$el.classList.add('show')
                        }
                        else{
                            this.$el.classList.remove('show')
                            this.$el.style.maxHeight = '0px';
                        }
                    }, 1
                )
            },

            calculateMaxHeight(){
                let el = this.$el.querySelector('.jkb-collapse__content');
                this.$nextTick(()=>{
                    this.elHeight = (el.getBoundingClientRect().height * 3);
                })

                // console.log(el);
                // console.log(this.elHeight);
            }
        },

        // ##     ##  #######  ##     ## ##    ## ######## ######## ########
        // ###   ### ##     ## ##     ## ###   ##    ##    ##       ##     ##
        // #### #### ##     ## ##     ## ####  ##    ##    ##       ##     ##
        // ## ### ## ##     ## ##     ## ## ## ##    ##    ######   ##     ##
        // ##     ## ##     ## ##     ## ##  ####    ##    ##       ##     ##
        // ##     ## ##     ## ##     ## ##   ###    ##    ##       ##     ##
        // ##     ##  #######   #######  ##    ##    ##    ######## ########
        mounted () {
            let el = this.$el;

            // const pt = parseInt(window.getComputedStyle(el, null).getPropertyValue('padding-top'));
            // const pb = parseInt(window.getComputedStyle(el, null).getPropertyValue('padding-bottom'));
            //console.log(this.timing/1000);
            this.calculateMaxHeight();
            this.$el.style.maxHeight = '0px';

            if(this.$attrs.show){
                this.silentToggle();
            }

            //Content OBSERVER
            // vypocitava vysku
            // Create the observer (and what to do on changes...)
            this.observer = new MutationObserver(function(mutations) {
                this.$nextTick(()=>{
                    this.calculateMaxHeight()
                    //console.log('changing collapse max height');
                })
            }.bind(this));



            // Setup the observer
            this.observer.observe(
                this.$el.querySelector('.jkb-collapse__content'),
                { attributes: true, childList: true, characterData: true, subtree: true }
            );


            // Budeme sledovat, ci su vsetky obrazky nacitane, akonahle budu nacitane prepocitaj maximalnu vysku
            Promise.all(Array.from(el.querySelectorAll('img')).map(img => {
                if (img.complete)
                    return Promise.resolve(img.naturalHeight !== 0);
                return new Promise(resolve => {
                    img.addEventListener('load', () => resolve(true));
                    img.addEventListener('error', () => resolve(false));
                });
            })).then(results => {
                this.calculateMaxHeight()

                //if (results.every(res => res))
                    //console.log('all images loaded successfully');
                //else
                    //console.log('some images failed to load, all finished loading');
            });

        },

        // ########  ######## ########  #######  ########  ######## ########  ########  ######  ######## ########   #######  ##    ##
        // ##     ## ##       ##       ##     ## ##     ## ##       ##     ## ##       ##    ##    ##    ##     ## ##     ##  ##  ##
        // ##     ## ##       ##       ##     ## ##     ## ##       ##     ## ##       ##          ##    ##     ## ##     ##   ####
        // ########  ######   ######   ##     ## ########  ######   ##     ## ######    ######     ##    ########  ##     ##    ##
        // ##     ## ##       ##       ##     ## ##   ##   ##       ##     ## ##             ##    ##    ##   ##   ##     ##    ##
        // ##     ## ##       ##       ##     ## ##    ##  ##       ##     ## ##       ##    ##    ##    ##    ##  ##     ##    ##
        // ########  ######## ##        #######  ##     ## ######## ########  ########  ######     ##    ##     ##  #######     ##

        beforeDestroy: function() {
            // Clean up
            this.observer.disconnect();
        }

    }
</script>


<style lang="scss" scoped>
    //Collapse element
    .jkb-collapse{
        opacity: 0;
        overflow: hidden;

        &.animating{
            opacity: 1;
        }

        &.show{
            //max-height: unset !important;
            overflow: visible;
            opacity: 1;
        }

        &__content{

        }
    }
</style>
