<template>
    <div>
        <input
            type="checkbox"
            class="card-input"
            v-bind="$attrs"
            ref="inputCheck"
            :id="`pckg${pckg.id}`"
            :value="pckg"
            :true-value="pckg"
            @input="$emit('input', dataPass())"
            hidden
        />

        <label class="card card--label mb-3" :for="`pckg${pckg.id}`">
            <div class="package-card">
                <div class="package-card__header">
                    <div>
                        <span class="text-bigger">
                            {{ packageCategory }}
                        </span>
                        <h3 class="h4">{{ pckg.name }}</h3>
                    </div>
                    <div class="package-card__price">
                        <span>{{ pckg.price }} €</span>
                        <span>/mesiac</span>
                    </div>
                </div>

                <ul class="package-card__list">
                    <li v-for="(channel, index) in channelsFiltered">
                        <img
                            :alt="channel.name"
                            :src="channel.logo"
                            :key="`img${index}`"
                            class="package-card__img"
                        />
                    </li>
                </ul>
            </div>
        </label>
    </div>
</template>

<script>
import _orderBy from "lodash/orderby";

export default {
    props: ["pckg", "currentMonths", "omitFreeStreaming"],
    inheritAttrs: false,
    methods: {
        dataPass() {
            return {
                value: this.pckg,
                checked: this.$refs.inputCheck.checked
            };
        }
    },

    watch: {
        currentMonths(newValue, oldValue) {
            this.$emit("input", this.dataPass());
        }
    },

    computed: {
        packageCategory() {
            return this.pckg.additional == 1
                ? "Doplnkový balík"
                : "Základný balík";
        },
        channelsFiltered() {
            if (this.omitFreeStreaming) {
                let filteredChannels = this.pckg.channels.filter(
                    ar => ar.free_streaming != true
                );
                filteredChannels = _orderBy(
                    filteredChannels,
                    ["order"],
                    ["asc"]
                );

                return filteredChannels;
            } else {
                let orderedChannels = _orderBy(
                    this.pckg.channels,
                    ["order"],
                    ["asc"]
                );
                return orderedChannels;
            }
        }
    },

    mounted() {
        this.dataPass();
    }
};
</script>

<style lang="scss" scoped>
.package {
    @media (max-width: 576px) {
        max-width: 100% !important;
    }
}

.offer__item {
    text-align: center;
    width: auto;
    margin: 0;
    width: 100%;
    max-width: 100%;

    &__disclaimer {
        margin-top: 0.75em;
        font-weight: 700;
    }

    .main-package & {
        max-width: 100%;

        // Horizontalne zobrazenie baliku
        @media (min-width: 992px) {
            display: grid;
            grid-template-columns: 15% auto 20% min-content;
            align-items: center;
            padding: 3rem;

            &__heading {
                text-align: left;
                width: 13rem;
                margin: 0;
            }

            &__logoset {
                margin: 0 3rem;
            }

            &__footer {
                margin: 0 3rem 0 0;
            }
        }

        .offer__item {
            //background: hotpink;
            max-width: 100%;
        }
    }
}
</style>
