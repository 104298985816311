<template>
    <div>
        <transition
            name="inter-fade"
            mode="out-in"
        >
            <div class="customer-helper" v-if="this.legislative.length">
                <div class="customer-helper__popup">
                    <jkb-collapse ref="popup" style="position:relative;">
                        <div class="customer-helper__popup__content">
                            <!-- Formular -->
                            <div class="formPart1" v-if="!formSent">
                                <h3>Potrebujete poradiť ohľadom Plustelky?</h3>

                                <p>Radi Vám zavoláme späť.</p>

                                <div class="input-submit">
                                    <input
                                        class="form-control"
                                        v-model="personalInformation.tel"
                                        type="text"
                                        placeholder="Vaše telefónne číslo"
                                        @input="$v.personalInformation.tel.$touch"
                                    />
                                    <button
                                        class="btn btn-primary"
                                        @click="sendForm"
                                        v-if="!attemtingToSendForm"
                                    >
                                        Odoslať
                                    </button>

                                    <button
                                            class="btn btn-primary"
                                            disabled
                                            v-else
                                        >
                                            Odosielam
                                        </button>
                                </div>

                                <!-- Tel errors -->
                                <transition-group
                                    name="inter-fade"
                                    mode="out-in"
                                >
                                    <div key="errorTel1" v-if="!$v.personalInformation.tel.required && $v.personalInformation.tel.$error" class="form-msg">
                                        Prosím zadajte Vaše telefónne číslo
                                    </div>

                                    <div key="errorTel2" v-if="!$v.personalInformation.tel.minLength" class="form-msg">
                                        Telefónne číslo musí mať aspoň {{$v.personalInformation.tel.$params.minLength.min}} znakov.
                                    </div>

                                    <div key="errorTel3" v-if="!$v.personalInformation.tel.maxLength" class="form-msg">
                                        Telefónne číslo musí mať najviac {{$v.personalInformation.tel.$params.maxLength.max}} znakov.
                                    </div>

                                    <div key="errorTel4" v-if="!$v.personalInformation.tel.numeric" class="form-msg">
                                        Prosím zadajte Vaše telefónne číslo v číselnom formáte (bez medzier a inych než číslených znakov)
                                    </div>
                                </transition-group>


                                <label class="checkbox" for="suhlas-osobne">
                                    <input
                                        type="checkbox"
                                        name="suhlas-osobne"
                                        id="suhlas-osobne"
                                        v-model="personalInformation.gdpr"
                                        @change="$v.personalInformation.gdpr.$touch"
                                    >
                                    <span class="checkbox-label">
                                        Odoslaním čísla vyjadrujete súhlas so spracovaním <a :href="getLegislativeLinkByKey('gdpr')" target="_blank" class="text-highlighted">Vaších osobných údajov</a>
                                    </span>
                                </label>
                                <!-- GDPR errors -->
                                <transition name="inter-fade" mode="out-in">
                                    <div v-if="$v.personalInformation.gdpr.$error" class="form-msg">
                                        Bez vášho súhlasu nieje možné formulár odoslať
                                    </div>
                                </transition>

                                <!-- Form errors -->
                                <transition-group
                                    name="inter-fade"
                                    mode="out-in"
                                >
                                <span v-for="(message, index) in formMessages" :key="`message${index}`" class="form-msg">
                                    {{ message }}
                                </span>
                                </transition-group>
                            </div>

                            <!-- Success message -->
                            <div key="formPart2" class="" v-else>
                                <div>
                                    <h4 class="mb-2">
                                        Ďakujeme, vaše telefónne číslo bolo úspešne odoslané!
                                    </h4>
                                    <p>
                                        Čoskoro Vás budeme kontaktovať
                                    </p>
                                </div>
                            </div>

                        </div>
                    </jkb-collapse>
                </div>
                <div class="customer-helper__button" @click="togglePopup">
                    <div class="support-girl">
                        <img src="/img/customer-helper/support-girl.png" alt="">
                    </div>

                    Potrebujete poradiť?

                    <div class="icon">
                        <img src="/img/icons/bubble-phone.svg" alt="">
                    </div>
                </div>

            </div>
        </transition>
    </div>

</template>

<script>
    import jkbCollapse from './../collapse/collapse.vue';
    import { required, numeric, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
    import axios from 'axios';

    export default {
        // ########     ###    ########    ###
        // ##     ##   ## ##      ##      ## ##
        // ##     ##  ##   ##     ##     ##   ##
        // ##     ## ##     ##    ##    ##     ##
        // ##     ## #########    ##    #########
        // ##     ## ##     ##    ##    ##     ##
        // ########  ##     ##    ##    ##     ##
        data() {
            return {
                suhlasOsobne: null,
                isPopupOpen: false,
                personalInformation: {
                    gdpr: false,
                    tel: '',
                },
                formMessages: [],
                legislative: [],
                attemtingToSendForm: false,
                formSent: false,

                //sendDummyForm: false// ODSTRANIT V PRODUKCII, tato premenna rozhoduje o tom, ci sa dummy promise "odosle"
            }
        },

        //  ######   #######  ##     ## ########   #######  ##    ## ######## ##    ## ########  ######
        // ##    ## ##     ## ###   ### ##     ## ##     ## ###   ## ##       ###   ##    ##    ##    ##
        // ##       ##     ## #### #### ##     ## ##     ## ####  ## ##       ####  ##    ##    ##
        // ##       ##     ## ## ### ## ########  ##     ## ## ## ## ######   ## ## ##    ##     ######
        // ##       ##     ## ##     ## ##        ##     ## ##  #### ##       ##  ####    ##          ##
        // ##    ## ##     ## ##     ## ##        ##     ## ##   ### ##       ##   ###    ##    ##    ##
        //  ######   #######  ##     ## ##         #######  ##    ## ######## ##    ##    ##     ######
        components: {
            jkbCollapse,
        },

        // ##     ## ######## ######## ##     ##  #######  ########   ######
        // ###   ### ##          ##    ##     ## ##     ## ##     ## ##    ##
        // #### #### ##          ##    ##     ## ##     ## ##     ## ##
        // ## ### ## ######      ##    ######### ##     ## ##     ##  ######
        // ##     ## ##          ##    ##     ## ##     ## ##     ##       ##
        // ##     ## ##          ##    ##     ## ##     ## ##     ## ##    ##
        // ##     ## ########    ##    ##     ##  #######  ########   ######
        methods: {
            getLegislativeLinkByKey(key){
                return this.legislative.find(el => el.identifier == key).link;
            },
            togglePopup(){
                this.$refs.popup.silentToggle();
                this.isPopupOpen = !this.$refs.popup.show;

                if(this.isPopupOpen){
                    document.addEventListener('click', function(){
                        //console.log('pocuvam klikacku mimo')
                    }, {once: true})
                }
            },

            async sendForm() {
                this.$v.personalInformation.$touch();
                this.formMessages = [];

                // Ak je formular vporiadku
                if(!this.$v.$anyError){
                    // Prepneme form do stavu kedy sa tvari ze odosiela
                    this.attemtingToSendForm = true;

                    //console.log('ODOSIELAM')
                    //DUMMY REQUEST
                    // const that = this;
                    // const dummyPromise = new Promise(function(resolve, reject){
                    //     setTimeout(function(){
                    //         if(that.sendDummyForm == true){
                    //             resolve('valid')
                    //         }
                    //         else reject(new Error('error'));
                    //     }
                    //     , 1000)
                    // })

                    try{

                        let formData = new FormData();

                        formData.append("gdpr", this.personalInformation.gdpr);
                        formData.append("phone", this.personalInformation.tel);

                        const result = await axios.post(
                            `/api/custom-helper/submit`,
                            formData,
                            {
                                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                            }
                        )
                        // Prepneme formular do stavu, kedy
                        this.formSent = true;
                    }

                    catch(e) {
                        console.log(e.response.data)
                        this.formMessages.push('Pri odosielaní formuláru došlo k chybe. Skúste to prosím ešte raz, v prípade, že problém pretrváva, nás kontaktujte.')
                    }
                    // END DUMMY REQUEST

                    // Vypneme stav formu kedy sa tvari
                    this.attemtingToSendForm = false;



                }
                else {
                    //console.log('NEODOSIELAM')
                }
            }
        },

        // ##     ##  #######  ##     ## ##    ## ######## ######## ########
        // ###   ### ##     ## ##     ## ###   ##    ##    ##       ##     ##
        // #### #### ##     ## ##     ## ####  ##    ##    ##       ##     ##
        // ## ### ## ##     ## ##     ## ## ## ##    ##    ######   ##     ##
        // ##     ## ##     ## ##     ## ##  ####    ##    ##       ##     ##
        // ##     ## ##     ## ##     ## ##   ###    ##    ##       ##     ##
        // ##     ##  #######   #######  ##    ##    ##    ######## ########
        async mounted () {
            const legislative = await axios.post('/api/legislative');
            this.legislative = legislative.data.data;
        },

        // ##     ##    ###    ##       #### ########     ###    ######## ####  #######  ##    ##  ######
        // ##     ##   ## ##   ##        ##  ##     ##   ## ##      ##     ##  ##     ## ###   ## ##    ##
        // ##     ##  ##   ##  ##        ##  ##     ##  ##   ##     ##     ##  ##     ## ####  ## ##
        // ##     ## ##     ## ##        ##  ##     ## ##     ##    ##     ##  ##     ## ## ## ##  ######
        //  ##   ##  ######### ##        ##  ##     ## #########    ##     ##  ##     ## ##  ####       ##
        //   ## ##   ##     ## ##        ##  ##     ## ##     ##    ##     ##  ##     ## ##   ### ##    ##
        //    ###    ##     ## ######## #### ########  ##     ##    ##    ####  #######  ##    ##  ######

        validations: {
            personalInformation: {
                tel: {
                    required,
                    numeric,
                    minLength: minLength(10),
                    maxLength: maxLength(12)
                },
                gdpr: {
                    sameAs: sameAs( () => true )
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
    .customer-helper{
        position: fixed;
        bottom: 0;
        right: 1.5rem;
        z-index: 20;
        max-width: 300px;
        //margin-right: 1.5rem;



        @media (max-width: 900px){
            flex-direction: column;
            left: calc(50vw + .5rem);
            max-width: none;
            //margin-right: 6rem;
        }

        &__button{
            background-image: linear-gradient(-45deg, #0EA879, #26B8EB);
            color: white;
            height: 3rem;
            position: relative;

            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 1em;
            padding-right: 1em;
            border-radius: .25em .25em 0 0;

            margin-left: auto;

            @media (max-width: 576px){
                line-height: 1.2em;
                height: 3rem;
            }
            .support-girl{
                z-index: -1;
                position: absolute;
                bottom: 3rem;
                right: 1rem;

                @media (max-width: 992px){
                    display: none;
                }
            }

            .icon{
                display: flex;
                justify-content: center;
                align-items: center;

                max-width: 2.5em;
                max-height: 2.5em;
                width: 100%;
                height: 100%;

                flex-shrink: 0;
                flex-grow: 0;
                //background: white;

                //margin-right: .5rem;
                margin-left: 1rem;

                img{
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                }

                @media (max-width: 450px){
                    display: none;
                }
            }
        }



        &__popup__content{
            position: relative;
            padding: 1rem;
            background: white;
            border-radius: .25em ;
            //max-width: 400px;

            @media (max-width: 576px){
                position: absolute;
                width: calc(100vw - 3rem);
                //transform: translateX(-50vw);
                right: 0;
                bottom: 0;
            }

            h3{
                margin-bottom: .5rem;
                font-size: 1.25rem;
                font-weight: bold;
            }

            p{
                font-size: .9rem;
            }

            .input-submit{
                display: flex;
                margin: .5rem 0;

                .btn{
                    margin-left: .5em;
                }

                .checkbox{
                    font-size: .9em;
                }

                @media (max-width: 576px){
                    flex-direction: column;

                    .btn{
                        margin-left: 0;
                        margin-top: .5rem;
                    }
                }
            }

            .form-msg{
                color: red;
                margin-bottom: .5em;
                display: block;
                font-size: .8em;
            }
        }
    }
</style>
