<template>
   <div class="jkb-modal">
        <!-- Darken texture -->
            <div class="jkb-modal__backdrop" @click.prevent="toggle"></div>

        <div class="jkb-modal__container">
            <div class="jkb-modal__container__header">
                <button class="close-modal" @click="toggle()">
                    <img src="/img/icons/close.svg" alt="">
                </button>
            </div>
            <div class="jkb-modal__container__body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import { enablePageScroll, disablePageScroll } from 'scroll-lock';

    export default {
        data() {
            return {
                elHeight: String,
                show:  false,
                timing: 300
            }
        },
        methods: {
            toggle() {
                this.show = !this.show;

                if(this.show){
                    this.$el.classList.add('animating')
                    setTimeout(()=>{
                        this.$el.classList.add('show')
                        this.$el.classList.remove('animating')
                        disablePageScroll(this.$el.querySelector('.jkb-modal__container__body'))
                    }, this.timing)
                }
                else{
                    this.$el.classList.remove('show')
                    enablePageScroll(this.$el.querySelector('.jkb-modal__container__body'))
                }

            },
        },
        mounted () {
            let el = this.$el;

            if(this.$attrs.show){
                this.toggle();
            }





        }
    }
</script>

<style lang="scss" scoped>

    .jkb-modal{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 1001;
        display: flex;


        transition: all .25s ease-out;
        pointer-events: none;
        opacity: 0;

        color:#2D4A4B;

        padding: 2rem;


        &.show{
            opacity: 1;
            pointer-events: all;

            .jkb-modal__container{
                transform: translateY(0);
            }
        }


        //Backdrop
        &__backdrop{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(#2D4A4B, .8);
            content: "";
            z-index: 0;
        }




        &__container{
            position: relative;
            z-index: 1;

            transform: translateY(1rem);
            transition: all .25s ease-out;

            display: flex;
            flex-direction: column;

            max-width: 1120px;

            .small &{
                max-width: 600px;
            }
            width: 100%;
            max-height: 100%;

            margin: auto;
            background: white;

            //overflow: auto;


            &__header{
                display: flex;
                padding: 0 2rem;
                min-height: 6rem;

                .close-modal{
                    border: 0;
                    background: 0;

                    margin-left: auto;
                }
            }

            &__body{
                padding: 2rem;
                padding-top: 0;
                overflow: auto;
                overscroll-behavior: contain;

                .body-p0 &{
                    padding: 0;
                }

                .body-visible &{
                    overflow: visible;
                }
            }

        }
    }
</style>
