<template>
    <div>
        <!-- Modals  -->
        <!-- Modal overenie dostupnosti -->
        <modal ref="availability" class="body-visible">
            <check-availability />
        </modal>

        <!-- Antena services modals -->
        <modal
            :ref="`modal${service.id}`"
            :key="service.id"
            v-for="service in antenaServices"
        >
            <search-channels :program-id="service.id" :show-public="true" />
        </modal>
        <!-- Anneta services modals -->
        <modal
            :ref="`modal${service.id}`"
            :key="service.id"
            v-for="service in annetaServices"
        >
            <search-channels :program-id="service.id" :show-filter="true" />
        </modal>

        <transition name="inter-fade" mode="out-in">
            <div key="1" class="row" v-if="isLoadingData">
                <!-- <div class="offer__disclaimer" v-if="!Object.values(getSelectedCity).length">
            <h2 class="h4">
                Overte si dostupnosť
            </h2>
            <p>
                Zistite, ktoré služby sú vo vašej lokalite dostupné
            </p>
            <button class="btn btn-primary btn-primary--active" @click.prevent="$refs.availability.toggle()">
                Overiť dostupnosť
            </button>
        </div>

        <div class="offer__disclaimer" v-else>
            <h2 class="h4">
                Hotovo!
            </h2>

            <p>
                Na overenej adrese sú dostupné nižšie uvedené služby
            </p>

            <button class="btn btn-primary btn-primary--active" @click.prevent="$refs.availability.toggle()">
                Zmeniť lokalitu
            </button>
        </div> -->

                <!-- ANNETA CARD TEMPLATE -->
                <template v-for="(service, index) in annetaServices">
                    <div class="col-lg-6 my-2" :key="service.id">
                        <div
                            class="offer-card offer-card--primary"
                            :class="{
                                featured: service.featured,
                                hidden: service.hiddenService
                            }"
                        >
                            <span
                                class="offer-card__tag offer-card__tag--primary"
                                v-if="service.featured"
                            >
                                Obľúbená TV
                            </span>
                            <div class="offer-card__content">
                                <h2 class="h4 mb-3">{{ service.name }}</h2>
                                <p class="mb-3">
                                    <span
                                        >až do
                                        {{
                                            getAllProgramsChannelCounts(
                                                service.id
                                            )
                                        }}
                                        programov
                                        <span class="d-inline-block">
                                            (
                                            {{
                                                getHdProgramsChannelCounts(
                                                    service.id
                                                )
                                            }}
                                            v HD kvalite
                                            <template
                                                v-if="
                                                    get4KProgramsChannelCounts(
                                                        service.id
                                                    )
                                                "
                                            >
                                                ,
                                                {{
                                                    get4KProgramsChannelCounts(
                                                        service.id
                                                    )
                                                }}
                                                v 4K kvalite </template
                                            >)
                                        </span>
                                    </span>
                                </p>
                                <!-- <div class="offer__item__list">
                                    <div class="offer__item__list__item" v-for="(benefit ,index) in  filterBenefits(service.benefits)" :key="index">
                                        <img :src="benefit.icon" alt="" class="offer__item__list__item__icon">
                                        <span class="offer__item__list__item__text">{{ benefit.title }}</span>
                                    </div>
                                </div> -->
                                <div class="offer-card__show-channels">
                                    <span @click="openModal(service.id)"
                                        >Zobraziť programy</span
                                    >
                                </div>
                                <a
                                    :href="`objednavka-antena`"
                                    class="btn btn-primary mt-4"
                                >
                                    <span>Pozrieť ponuku</span>
                                    <img
                                        src="/img/icons/arrow-right.svg"
                                        alt=""
                                        class="btn__icon"
                                    />
                                </a>
                            </div>
                            <div class="offer-card__aside">
                                <span>
                                    <span class="h3 offer-card__price"
                                        >{{
                                            evaluateServicePrice(service.id)
                                        }}€</span
                                    >
                                    /mesiac
                                </span>
                                <small v-if="service.promo">
                                    Od
                                    {{ parseInt(service.promo.months) + 1 }}.
                                    mesiaca {{ service.price }} €
                                </small>
                            </div>

                            <!-- <div class="offer__item__footer"> -->
                            <!-- <div class="offer__item__price"></div> -->
                            <!-- <div class="offer__item__btn"> -->
                            <!-- Ak si zakaznik este neoveril dostupnost ukaz overenie dostupnosti -->
                            <!-- Ak ano, tak mu ponukni rovno moznost objednat sluzbu -->
                            <!-- <button class="btn btn-primary" v-if="!Object.values(getSelectedCity).length" @click="$refs.availability.toggle()">
                                    <span>Overiť dostupnosť</span>
                                </button> -->

                            <!-- <a v-else-if="service.serviceAvailable" class="btn btn-primary btn-primary--active" @click="setSelectedAnnetaPackage(service.id)" href="vue-anneta">
                                    Prejsť na objednávku
                                </a> -->

                            <!-- <span v-else>
                                    Služba nie je na uvedenej adrese dostupná
                                </span> -->
                            <!-- </div> -->
                            <!-- </div> -->
                        </div>
                    </div>
                </template>

                <!-- ANTENA CARD TEMPLATE -->
                <template v-for="(service, index) in antenaServices">
                    <div
                        class="my-2"
                        :class="
                            antenaServices.length == 1 ? 'col-lg-7' : 'col-lg-6'
                        "
                        :key="`antena${index}`"
                    >
                        <div
                            class="offer-card offer-card--secondary"
                            :class="{
                                hidden: service.hiddenService,
                                single: antenaServices.length == 1
                            }"
                        >
                            <span
                                class="offer-card__tag offer-card__tag--secondary"
                                v-if="service.featured"
                            >
                                Obľúbená TV
                            </span>

                            <div class="offer-card__content">
                                <h2 class="h4 mb-3">{{ service.name }}</h2>
                                <p class="mb-3">
                                    <span
                                        >až do
                                        {{
                                            getAllProgramsChannelCounts(
                                                service.id,
                                                { includePublic: true }
                                            )
                                        }}
                                        programov
                                    </span>
                                    <span class="d-inline-block">
                                        (
                                        {{
                                            getHdProgramsChannelCounts(
                                                service.id,
                                                { includePublic: true }
                                            )
                                        }}
                                        v HD kvalite
                                        <template
                                            v-if="
                                                get4KProgramsChannelCounts(
                                                    service.id,
                                                    { includePublic: false }
                                                )
                                            "
                                        >
                                            ,
                                            {{
                                                get4KProgramsChannelCounts(
                                                    service.id,
                                                    { includePublic: false }
                                                )
                                            }}
                                            v 4K kvalite </template
                                        >)
                                    </span>
                                </p>
                                <!-- <div class="offer__item__list">
                                    <div class="offer__item__list__item" v-for="(benefit ,index) in  filterBenefits(service.benefits)" :key="index">
                                        <img :src="benefit.icon" alt="" class="offer__item__list__item__icon">
                                        <span class="offer__item__list__item__text">{{ benefit.title }}</span>
                                    </div>
                                </div> -->
                                <div class="offer-card__show-channels">
                                    <span @click="openModal(service.id)"
                                        >Zobraziť programy</span
                                    >
                                </div>
                                <a
                                    :href="`objednavka-antena`"
                                    class="btn btn-secondary mt-4"
                                >
                                    <span>Pozrieť ponuku</span>
                                    <img
                                        src="/img/icons/arrow-right.svg"
                                        alt=""
                                        class="btn__icon"
                                    />
                                </a>
                            </div>
                            <div class="offer-card__aside">
                                <span>
                                    <span class="h3 offer-card__price"
                                        >{{
                                            evaluateServicePrice(service.id)
                                        }}€</span
                                    >
                                    /mesiac
                                </span>
                                <a
                                    :href="`objednavka-antena`"
                                    class="btn btn-secondary mt-4"
                                >
                                    <span>Pozrieť ponuku</span>
                                    <img
                                        src="/img/icons/arrow-right.svg"
                                        alt=""
                                        class="btn__icon"
                                    />
                                </a>
                            </div>

                            <!-- Ak si zakaznik este neoveril dostupnost ukaz overenie dostupnosti -->
                            <!-- Ak ano, tak mu ponukni rovno moznost objednat sluzbu -->
                            <!-- <button class="btn btn-secondary" v-if="!Object.values(getSelectedCity).length" @click="$refs.availability.toggle()">
                                <span>Overiť dostupnosť</span>
                            </button> -->

                            <!-- <a v-else-if="service.serviceAvailable" class="btn btn-secondary btn-secondary--active" href="vue-antena">
                                Prejsť na objednávku
                            </a> -->

                            <!-- <strong v-else>
                                Služba nie je na uvedenej adrese dostupná
                            </strong> -->
                        </div>
                    </div>
                </template>

                <div v-if="antenaServices.length == 1" class="col-lg-5 my-2">
                    <a href="/objednavka-antena?mesiace=12">
                        <div class="offer-promo">
                            <span class="offer-promo__text"
                                >V našom e-shope pri objednaní</span
                            >
                            <span
                                class="offer-promo__text offer-promo__text--bigger"
                                >12 mesiacov</span
                            >
                            <span class="offer-promo__text"
                                >získate 1 mesiac zadarmo</span
                            >
                        </div>
                    </a>
                </div>
            </div>

            <!-- Loading -->
            <div key="2" class="loading-container" v-else>
                <div class="loading-pulse__dark">
                    Načítavam ...
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// Vue imports
import { mapGetters, mapActions } from "vuex";
//import _orderBy from "lodash/orderby";

import modal from "../../vue/modal/modal.vue";
import searchChannels from "../../vue/search-channels/searchChannels.vue";
import checkAvailability from "../../vue/check-availability/checkAvailability.vue";

export default {
    components: {
        modal,
        searchChannels,
        checkAvailability
    },
    // ########     ###    ########    ###
    // ##     ##   ## ##      ##      ## ##
    // ##     ##  ##   ##     ##     ##   ##
    // ##     ## ##     ##    ##    ##     ##
    // ##     ## #########    ##    #########
    // ##     ## ##     ##    ##    ##     ##
    // ########  ##     ##    ##    ##     ##
    data() {
        return {
            allBenefits: [],
            allPrograms: {},
            annetaBlocked: false,
            antenaBlocked: false,
            filterCriteria: "internet", // internet / antenna
            isMobile: false
        };
    },
    // ##      ##    ###    ########  ######  ##     ##
    // ##  ##  ##   ## ##      ##    ##    ## ##     ##
    // ##  ##  ##  ##   ##     ##    ##       ##     ##
    // ##  ##  ## ##     ##    ##    ##       #########
    // ##  ##  ## #########    ##    ##       ##     ##
    // ##  ##  ## ##     ##    ##    ##    ## ##     ##
    //  ###  ###  ##     ##    ##     ######  ##     ##
    watch: {},

    //  ######   #######  ##     ## ########  ##     ## ######## ######## ########
    // ##    ## ##     ## ###   ### ##     ## ##     ##    ##    ##       ##     ##
    // ##       ##     ## #### #### ##     ## ##     ##    ##    ##       ##     ##
    // ##       ##     ## ## ### ## ########  ##     ##    ##    ######   ##     ##
    // ##       ##     ## ##     ## ##        ##     ##    ##    ##       ##     ##
    // ##    ## ##     ## ##     ## ##        ##     ##    ##    ##       ##     ##
    //  ######   #######  ##     ## ##         #######     ##    ######## ########
    computed: {
        ...mapGetters([
            "getStep",
            "isLoadingData",
            "getAvailablePrograms",
            "getSelectedCity",
            "getDefaultPackagesUnderProgram",
            "getProgramByName",
            "getAdditionalPackagesUnderProgram",
            "getProgram",
            "getPrograms",
            "getProgramsLowestPackagePrice",
            "getHdProgramsChannelCounts",
            "get4KProgramsChannelCounts",
            "getAllProgramsChannelCounts"
        ]),

        //Antena a Anneta musia byt oddelene, lebo ich funkcnosti a vzhlad su velmi rozdielne a nedaju sa zlucit do jedneho objektu
        //Sluzby Anteny
        antenaServices() {
            // Vytiahne vsetky sluzby, ktore podla pokrytia MUX vyhovuju zadanej obci
            const allPrograms = this.getPrograms;
            const onlyAvailable = this.getAvailablePrograms;

            // Staticky nastavene aby vyfiltroval iba program Antena (id 1)
            let filtered = allPrograms.filter(arr => {
                return arr.id == 1;
            });
            // Zmena poradia
            // filtered = _orderBy(filtered, ['id'], ['desc']);

            // Zistime ci je tento program medzi dostupnymi podla mux kriterii, priradime im parameter serviceAvaliable: true/false
            filtered.map(el => {
                if (onlyAvailable.some(available => available === el)) {
                    el.serviceAvailable = true;
                } else {
                    el.serviceAvailable = false;
                }
            });

            // Filtracia cez antenu / internet
            if (this.filterCriteria == "antenna") {
                filtered.map(el => {
                    el.hiddenService = false;
                });
            } else {
                filtered.map(el => {
                    el.hiddenService = true;
                });
            }

            return filtered;
        },
        // Sluzby Annety
        annetaServices() {
            // Vytiahne vsetky sluzby, ktore podla pokrytia MUX vyhovuju zadanej obci
            const allPrograms = this.getPrograms;
            const onlyAvailable = this.getAvailablePrograms;

            // Staticky nastavene aby vyfiltroval iba program Anneta (id 2)
            let filtered = allPrograms.filter(arr => {
                return arr.id == 2;
            });

            // Ak ma sluzba sub-sluzby tak sub sluzby ukaz miesto rodica
            // zobrazit Anneta S, M, L miesto Anneta

            if (filtered[0] && filtered[0].childs.length) {
                filtered = filtered[0].childs;
            }

            // Zmena poradia ak sme na mobilnych zariadeniach < 990 px
            // if(this.isMobile){
            //     filtered = _orderBy(filtered, ['featured'], ['desc']);
            // }

            // Zistime ci je tento program medzi dostupnymi podla mux kriterii, priradime im parameter serviceAvaliable: true/false
            filtered.map(el => {
                if (onlyAvailable.some(available => available === el)) {
                    el.serviceAvailable = true;
                } else {
                    el.serviceAvailable = false;
                }
            });

            // Filtracia schovavanie cez antenu / internet
            if (this.filterCriteria == "internet") {
                filtered.map(el => {
                    el.hiddenService = false;
                });
            } else {
                filtered.map(el => {
                    el.hiddenService = true;
                });
            }

            return filtered;
        }
    },

    // ##     ## ######## ######## ##     ##  #######  ########   ######
    // ###   ### ##          ##    ##     ## ##     ## ##     ## ##    ##
    // #### #### ##          ##    ##     ## ##     ## ##     ## ##
    // ## ### ## ######      ##    ######### ##     ## ##     ##  ######
    // ##     ## ##          ##    ##     ## ##     ## ##     ##       ##
    // ##     ## ##          ##    ##     ## ##     ## ##     ## ##    ##
    // ##     ## ########    ##    ##     ##  #######  ########   ######
    methods: {
        ...mapActions([
            "fetchPrograms",
            "setSelectedCity",
            "setSelectedAnnetaPackage"
        ]),

        filterBenefits(inputArr) {
            return inputArr.filter(arr => {
                return arr.show_in_card;
            });
        },

        openModal(serviceId) {
            const modalRef = `modal${serviceId}`;
            //console.log(this.$refs[modalRef]);
            this.$refs[modalRef][0].toggle();
        },

        isViewPortMobile() {
            const viewportWidth = window.innerWidth;

            if (viewportWidth < 768) {
                this.isMobile = true;
            } else this.isMobile = false;

            //console.log(this.isMobile);
        },

        evaluateServicePrice(serviceId) {
            const service = this.getProgram(serviceId);
            const packagePrice = this.getProgramsLowestPackagePrice(serviceId);

            // if (service.promo) {
            //     return service.promo.price;
            // } else {
            return packagePrice;
            // }
        }
    },

    // ##     ##  #######  ##     ## ##    ## ######## ######## ########
    // ###   ### ##     ## ##     ## ###   ##    ##    ##       ##     ##
    // #### #### ##     ## ##     ## ####  ##    ##    ##       ##     ##
    // ## ### ## ##     ## ##     ## ## ## ##    ##    ######   ##     ##
    // ##     ## ##     ## ##     ## ##  ####    ##    ##       ##     ##
    // ##     ## ##     ## ##     ## ##   ###    ##    ##       ##     ##
    // ##     ##  #######   #######  ##    ##    ##    ######## ########
    async mounted() {
        if (!this.isLoadingData) {
            // Vytiahnutie benefitov z API
            // pridanie ich do vseobecnych dat
            const benefits = await this.$http.post("/api/benefits/slider");
            this.allBenefits = benefits.data.data;

            //FETCHNE API programov a naplni store
            const programs = await this.fetchPrograms().then(() => {
                //Zisti, ci nieje rozmer okna mobilny prehliadac - meni poradie zobrazenych balickov
                //window.addEventListener('resize', this.isViewPortMobile)
                //this.isViewPortMobile();
            });
        }
    },

    beforeDestroy() {
        //window.removeEventListener('resize', this.isViewPortMobile)
    }
};
</script>
