var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"channel-header"},[_c('h2',{staticClass:"h4 mb-1"},[_vm._v("\n            Dostupné programy\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-3"},[_vm._v("\n            Hľadáte konkrétny program?\n        ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"form-control",attrs:{"placeholder":"Zadajte názov TV programu, ktorý hľadáte","type":"text"},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value}}}),_vm._v(" "),(_vm.showFilter)?_c('div',{staticClass:"news__nav mt-4"},[_c('div',{staticClass:"news__nav__item",class:{ active: _vm.filterCriteria == 'antennaAndInternet' },on:{"click":function($event){_vm.filterCriteria = 'antennaAndInternet'}}},[_c('span',[_vm._v("Cez anténu a internet")])]),_vm._v(" "),_c('div',{staticClass:"news__nav__item",class:{ active: _vm.filterCriteria == 'antenna' },on:{"click":function($event){_vm.filterCriteria = 'antenna'}}},[_c('span',[_vm._v("Cez anténu")])])]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"inter-fade","mode":"out-in"}},[(!_vm.isLoading)?_c('div',{key:"1",staticClass:"genres-container"},[_vm._l((_vm.genresUnderDefaultPackages),function(genre,index){return _c('div',{key:`${_vm.programId}defaultPackageGenre${index}`,staticClass:"genre pt-5",attrs:{"id":`defaultPackageGenre-${index}`}},[(_vm.programId === 1 && index === 0)?_c('h3',{staticClass:"h6"},[_vm._v("\n                    Programová ponuka Plustelka Štandard\n                ")]):_c('h3',{staticClass:"h6"},[_vm._v(_vm._s(genre.name))]),_vm._v(" "),_c('div',{staticClass:"channel-grid"},_vm._l((genre.channels),function(channel,index){return _c('div',{key:`${_vm.programId}default${index}`,staticClass:"channel-grid__channel",class:{
                            hidden: !_vm.filteredChannels.includes(channel)
                        }},[_c('figure',{staticClass:"channel-grid__channel__logo"},[_c('img',{attrs:{"src":channel.logo,"alt":""}})]),_vm._v(" "),_c('small',{staticClass:"channel-grid__channel__name"},[_vm._v("\n                            "+_vm._s(channel.name)+"\n                        ")])])}),0)])}),_vm._v(" "),(
                    _vm.getProgram(_vm.programId).additional_packages &&
                        _vm.getProgram(_vm.programId).additional_packages > 0
                )?_c('div',{staticClass:"pt-5"},[_c('h2',{staticClass:"h6 packages-separator"},[_vm._v("Doplnkové balíky")]),_vm._v(" "),_c('p',{staticClass:"text-highlighted large-text"},[_vm._v("\n                    V cene služby máte\n                    "+_vm._s(_vm.getProgram(_vm.programId).additional_packages)+"\n                    doplnkové balíky podľa vlastného výberu\n                ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.genresUnderAdditionalPackages),function(genre,index){return _c('div',{key:`${_vm.programId}additionalPackageGenre${index}`,staticClass:"genre pt-5",attrs:{"id":`additionalPackageGenre-${index}`}},[_c('h3',{staticClass:"h6"},[_vm._v(_vm._s(genre.name))]),_vm._v(" "),_c('div',{staticClass:"channel-grid"},_vm._l((genre.channels),function(channel,index){return _c('div',{key:`${_vm.programId}additional${index}`,staticClass:"channel-grid__channel",class:{
                            hidden: !_vm.filteredChannels.includes(channel)
                        }},[_c('figure',{staticClass:"channel-grid__channel__logo"},[_c('img',{attrs:{"src":channel.logo,"alt":""}})]),_vm._v(" "),_c('small',{staticClass:"channel-grid__channel__name"},[_vm._v("\n                            "+_vm._s(channel.name)+"\n                        ")])])}),0)])})],2):_c('div',{key:"2",staticClass:"loading-container"},[_c('div',{staticClass:"loading-pulse__dark"},[_vm._v("\n                Načítavam ...\n            ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }