<template>
    <div class="h-100">
        <!-- Modals  -->
        <!-- Modal overenie dostupnosti -->
        <modal ref="availability">
            <search-channels :program-id="1" :show-public="true" />
        </modal>

        <div
            class="banner-card banner-card--channels"
            @click="$refs.availability.toggle()"
        >
            <h3 class="h4 banner-card__title">
                Pozrite si zoznam kanálov v ponuke Plustelky
            </h3>
            <div class="text-smaller banner-card__action">Zobraziť kanály</div>
            <img src="/img/bg-2.jpg" alt="" class="banner-card__bg" />
        </div>
    </div>
</template>

<script>
import modal from "../modal/modal.vue";
import searchChannels from "../search-channels/searchChannels.vue";

export default {
    components: {
        modal,
        searchChannels
    }
};
</script>

<style lang="scss" scoped></style>
