<script src="../../../../../public/js/speed/speedtest_worker.js"></script>
<template>
    <div class="speedtest">
        <transition name="scale-fade" mode="out-in">
            <div class="speedtest-intro" v-if="step == 1" key="1">
                <p>
                    Pre využívanie služby Anneta, je nutné internetové
                    pripojenie s minimálnou rýchlosťou pripojenia 5Mbit/s.
                </p>
                <span class="speedtest-intro__btn" @click="nextStep">
                    Overte si teraz rýchlosť vášho internetového pripojenia.
                </span>
                <small
                    >(test rýchlosti zrealizujte z internetového pripojenia, kde
                    budete službu Anneta využívať)</small
                >
            </div>

            <div class="speedtest-test" v-else-if="step == 2" key="2">
                <div class="result">
                    <div class="result__item">
                        <span class="result__data">{{
                            data && data.dlStatus ? data.dlStatus : "-"
                        }}</span>
                        <span class="result__unit">Mbit/s</span>
                    </div>
                </div>
                <div class="speedtest-progress">
                    <div class="progress-bar">
                        <div class="progress-bar__bg"></div>
                        <div
                            class="progress-bar__line"
                            :style="{ width: data.dlStatus + '%' }"
                        ></div>
                    </div>
                    <transition name="scale-fade" mode="out-in">
                        <button
                            @click="startStop()"
                            class="btn btn-primary"
                            v-if="!running"
                        >
                            <span>Zmerať rýchlosť</span>
                        </button>
                        <div class="speedtest-progress__loader" v-else></div>
                    </transition>
                </div>
            </div>

            <div class="speedtest-result" v-else key="3">
                <div class="result">
                    <div class="result__item">
                        <span class="result__data">{{
                            data && data.dlStatus ? data.dlStatus : "-"
                        }}</span>
                        <span class="result__unit">Mbit/s</span>
                    </div>
                </div>
                <SpeedtestNotification
                    :type="notificationType"
                ></SpeedtestNotification>
                <div class="speedtest-result__btn">
                    <button @click="startStop()" class="btn btn-primary">
                        <span>Reštartovať test</span>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import SpeedtestNotification from "./SpeedtestNotification.vue";

export default {
    name: "Speedtest",
    components: { SpeedtestNotification },
    data() {
        return {
            step: 1,
            data: {},
            w: null,
            running: false,
            minSpeed: 5
        };
    },
    methods: {
        nextStep() {
            this.step++;
        },
        startStop() {
            this.running = true;

            if (this.step !== 2) {
                this.step = 2;
            }

            if (this.w != null) {
                this.w.postMessage("abort");
                this.w = null;
                this.data = null;
            } else {
                this.w = new Worker("/js/speed/speedtest_worker.js");

                this.w.postMessage("start");

                this.w.postMessage("");
                this.w.onmessage = e => {
                    this.data = JSON.parse(e.data);

                    if (this.data.dlProgress === 1) {
                        this.running = false;
                        this.nextStep();
                    }

                    this.w = null;
                };
            }
        }
    },
    computed: {
        notificationType() {
            if (this.data.dlStatus > this.minSpeed) {
                return "success";
            } else {
                return "warning";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.speedtest {
    max-width: 35rem;
    margin: 0 auto;
    padding: 4rem 1rem;
}

.speedtest-intro {
    font-size: 0.9rem;
    text-align: center;

    &__btn {
        display: block;
        font-weight: bold;
        text-decoration: underline;
        margin-top: 0.5rem;
        cursor: pointer;
    }
}

.speedtest-progress {
    position: relative;
    width: 100%;
    text-align: center;

    &__loader {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        background: url("./assets/icons/loader.svg");
        background-repeat: no-repeat;
        background-size: 95%;
        background-position: center;
    }
}

.speedtest-result {
    &__btn {
        text-align: center;
    }
}

.result {
    display: flex;
    justify-content: center;

    margin-bottom: 0.5rem;

    &__item {
        font-weight: 400;
    }

    &__data {
        font-weight: 700;
        font-size: 1.8rem;
    }
}

.progress-bar {
    position: relative;

    width: 100%;
    height: 2.5rem;
    border-radius: 4px;
    margin: 1rem 0;
    overflow: hidden;

    &__bg,
    &__line {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }
    &__bg {
        width: 100%;
        background: #ecf8fa;
    }

    &__line {
        max-width: 100%;
        background-image: linear-gradient(to right, #0ea879, #26b8eb);
        transition: width 0.3s;
    }
}

.scale-fade-enter-active,
.scale-fade-leave-active {
    transition: all 0.3s ease;
}
.scale-fade-enter,
.scale-fade-leave-to {
    transform: scale(0.9);
    opacity: 0;
}
</style>
