<template>
    <div>
        <modal ref="annetaModal">
            <search-channels
                :program-id="data.currentProgramId"
                :show-filter="true"
            />
        </modal>
        <h2 class="progrma-widget__main-heading mb-3">{{ data.currentProgram.name }}</h2>
        <h4 class="program-widget__sub-heading mb-3 pointer" @click="$refs.annetaModal.toggle()">
            Až <span class="text-highlighted">{{ data.allChannelsCount }} programov</span><br>

            <template v-if="data.currentProgram.id == 3">
                na začiatok
            </template>
            <template v-else-if="data.currentProgram.id == 4">
                pre celú rodinu
            </template>
            <template v-else-if="data.currentProgram.id == 5">
                pre fajnšmekrov
            </template>
            <br />
            ( {{ data.hdChannelsCount }} v HD kvalite<template v-if="data.is4kChannelsCount != ''">, {{ data.is4kChannelsCount }} v 4K kvalite</template> )
        </h4>
        <span class="program-widget__price mb-3">
            <!-- <span>{{ getProgramsLowestPackagePrice(data.currentProgramId) }} €</span> / mesačne -->
            <span>
                <span class="offer__item__price__total">{{ evaluateServicePrice }}</span> € / mesačne
            </span>
            <br>
            <small v-if="service.promo">
                Od {{ parseInt(service.promo.months)+1 }}. mesiaca {{ service.price }} €
            </small>
        </span>


        <a class="btn btn-primary btn-primary--active mt-2" @click="setSelectedAnnetaPackage(service.id)" :href="$annetaOrderUrl">
            Toto chcem
        </a>

        <speed-test>

        </speed-test>
    </div>
</template>

<script>
    import modal from '../../modal/modal.vue';
    import searchChannels from '../../search-channels/searchChannels.vue';
    import speedTest from '../../speedtest/Speedtest.vue';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props: ['data'],
        components: {
            modal,
            searchChannels,
            speedTest
        },
        computed: {
            ...mapGetters(['getSelectedCity', 'isProgramAvailable', 'getProgram', 'getProgramsLowestPackagePrice']),
            service(){
                return this.getProgram(this.data.currentProgram.id);
            },
            evaluateServicePrice(){
                const service = this.getProgram(this.data.currentProgram.id)
                const packagePrice = this.getProgramsLowestPackagePrice(this.data.currentProgram.id);

                if(service.promo){
                    return service.promo.price;
                }
                else{
                    return packagePrice;
                }
            }
        },
        methods: {
            ...mapActions(['setSelectedAnnetaPackage'])
        },
    }
</script>

<style lang="scss" scoped>

</style>
