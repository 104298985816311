<template>
    <div class="container wraper">
<!--        <div class="w480 mx-auto text-center" v-show="loggingWithToken">-->
<!--            <div class="container">-->
<!--                <div class="mx-auto" :class="loadingTheme">-->
<!--                    Načítavam ...-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div-->
<!--            class="w480 mx-auto text-center"-->
<!--            v-show="!loggingWithToken && !isUserLoggedIn"-->
<!--        >-->
<!--&lt;!&ndash;            <h3 class="h3 mb-4">&ndash;&gt;-->
<!--&lt;!&ndash;                Prihláste sa&ndash;&gt;-->
<!--&lt;!&ndash;            </h3>&ndash;&gt;-->

<!--&lt;!&ndash;            <div class="mb-2">&ndash;&gt;-->
<!--&lt;!&ndash;                <p>&ndash;&gt;-->
<!--&lt;!&ndash;                    Pre úspešné dokončenie objednávky, <br />&ndash;&gt;-->
<!--&lt;!&ndash;                    je potrebné sa prihlásiť alebo registrovať.&ndash;&gt;-->
<!--&lt;!&ndash;                </p>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--&lt;!&ndash;            <input&ndash;&gt;-->
<!--&lt;!&ndash;                autocomplete="off"&ndash;&gt;-->
<!--&lt;!&ndash;                placeholder="Prihlasovacie meno"&ndash;&gt;-->
<!--&lt;!&ndash;                class="form-control mb-2"&ndash;&gt;-->
<!--&lt;!&ndash;                type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="userLogin"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--&lt;!&ndash;            <input&ndash;&gt;-->
<!--&lt;!&ndash;                autocomplete="off"&ndash;&gt;-->
<!--&lt;!&ndash;                placeholder="Heslo"&ndash;&gt;-->
<!--&lt;!&ndash;                class="form-control"&ndash;&gt;-->
<!--&lt;!&ndash;                type="password"&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="userPass"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->

<!--&lt;!&ndash;            <div class="mt-2">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="mb-4">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a&ndash;&gt;-->
<!--&lt;!&ndash;                        href="https://moja.plustelka.sk"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="text-highlighted"&ndash;&gt;-->
<!--&lt;!&ndash;                        target="_blank"&ndash;&gt;-->
<!--&lt;!&ndash;                    >&ndash;&gt;-->
<!--&lt;!&ndash;                        Zabudnuté heslo&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                <button&ndash;&gt;-->
<!--&lt;!&ndash;                    @click="&ndash;&gt;-->
<!--&lt;!&ndash;                        loginUser();&ndash;&gt;-->
<!--&lt;!&ndash;                        registerNew = false;&ndash;&gt;-->
<!--&lt;!&ndash;                    "&ndash;&gt;-->
<!--&lt;!&ndash;                    class="btn btn-primary"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ loginButtonContent }}&ndash;&gt;-->
<!--&lt;!&ndash;                </button>&ndash;&gt;-->

<!--&lt;!&ndash;                &lt;!&ndash;                <button @click="hideLogin = true; registerNew = true" class="btn btn-primary">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash;                    Zaregistrovať sa&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                &lt;!&ndash;                </button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--            &lt;!&ndash; Login Messages &ndash;&gt;-->
<!--            &lt;!&ndash; Sem sa budu vypisovat vsetky chyby, ktore sa udiali pri pokuse prihlasit sa &ndash;&gt;-->
<!--            &lt;!&ndash; Inicializuje ich funkcia loginUser &ndash;&gt;-->

<!--&lt;!&ndash;            <div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div&ndash;&gt;-->
<!--&lt;!&ndash;                    v-for="(message, index) in loginMessages"&ndash;&gt;-->
<!--&lt;!&ndash;                    :key="`loginMessage${index}`"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                    <span>{{ message }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--            &lt;!&ndash; /Login Messages &ndash;&gt;-->
<!--        </div>-->

        <div
            class="row justify-content-between"
            :class="{ hidden: loggingWithToken }"
            ref="registrationSection"
        >
            <div class="mb-5">
                <!-- Form Messages -->
                <!-- Sem sa budu vypisovat vsetky chyby, ktore sa udiali vo formulari -->
                <!-- Inicializuje ich centralna funkcia validateForm -->
                <!--                <div>-->
                <!--                    <div v-for="(message, index) in formMessages" :key="`message${index}`">-->

                <!--                        <span>{{ message }}</span>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!-- /Form Messages -->
            </div>
            <div class="col-lg-7 pr-lg-4 mb-lg-0 mb-4">
                <!-- Osobne udaje -->
                <!-- row -->
                <h2 class="h4 mb-3">Osobné údaje</h2>
                <form>
                    <div class="row mb-4">
                        <div class="col-md-12">
                            <label for="email">E-mail*</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                @blur="liveValidateEmail"
                                v-model="personalInformation.email"
                                :disabled="isUserLoggedIn"
                                id="email"
                                name="email"
                                placeholder="E-mail"
                            />

                            <!-- Ak su dake spravy ulozene v email messages a zaroven nieje uzivatel prihlaseny -->
                            <div v-if="emailMessages.length && !isUserLoggedIn">
                        <span
                            v-for="(message, index) in emailMessages"
                            :key="`emailMessages${index}`"
                            class="alert alert-warning"
                        >
                            <Notification
                                type="error"
                                :text="message"
                            ></Notification>
                        </span>
                                <div v-if="formMessages['email']">
                                    <Notification
                                        type="error"
                                        :text="formMessages['email']"
                                    ></Notification>
                                </div>

                                <!-- Button pre opatovne prihlasenie sa, ukaze sa iba v pripade, ze mail je nevyhovujuci -->
                                <button
                                    v-if="emailStatus == 'invalid'"
                                    @click="
                                hideLogin = false;
                                registerNew = false;
                            "
                                >
                                    Prihlasiť sa
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- row -->
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label for="name">Meno*</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.name"
                                :disabled="isUserLoggedIn"
                                id="name"
                                name="name"
                                placeholder="Meno"
                                v-bind:class="{ error: formMessages['name'] }"
                            />
                        </div>
                        <div class="col-md-6">
                            <label for="surname">Priezvisko*</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.surname"
                                :disabled="isUserLoggedIn"
                                id="surname"
                                name="surname"
                                placeholder="Priezvisko"
                                v-bind:class="{ error: formMessages['surname'] }"
                            />
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12" v-if="formMessages['name']">
                            <Notification
                                type="error"
                                :text="formMessages['name']"
                            ></Notification>
                        </div>
                        <div class="col-12" v-if="formMessages['surname']">
                            <Notification
                                type="error"
                                :text="formMessages['surname']"
                            ></Notification>
                        </div>
                    </div>

                    <!-- row -->
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <label for="telephone">Telefón*</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.telephone"
                                :disabled="isUserLoggedIn"
                                id="telephone"
                                name="telephone"
                                placeholder="Telefón"
                                v-bind:class="{ error: formMessages['telephone'] }"
                            />
                        </div>
                        <div class="col-md-6">
                            <label for="street">Ulica*</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.street"
                                :disabled="isUserLoggedIn"
                                id="street"
                                name="street"
                                placeholder="Ulica"
                                v-bind:class="{ error: formMessages['street'] }"
                            />
                        </div>
                        <div class="col-md-2">
                            <label for="street-nr">Č.domu*</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.streetNr"
                                :disabled="isUserLoggedIn"
                                id="street-nr"
                                name="street-nr"
                                placeholder="Č.domu"
                                v-bind:class="{ error: formMessages['street-nr'] }"
                            />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12" v-if="formMessages['telephone']">
                            <Notification
                                type="error"
                                :text="formMessages['telephone']"
                            ></Notification>
                        </div>
                        <div class="col-12" v-if="formMessages['street']">
                            <Notification
                                type="error"
                                :text="formMessages['street']"
                            ></Notification>
                        </div>
                        <div class="col-12" v-if="formMessages['street-nr']">
                            <Notification
                                type="error"
                                :text="formMessages['street-nr']"
                            ></Notification>
                        </div>
                    </div>

                    <div class="row mb-5">
                        <div class="col-md-8">
                            <label for="city">Mesto*</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.city"
                                :disabled="isUserLoggedIn"
                                id="city"
                                name="city"
                                placeholder="Mesto"
                                v-bind:class="{ error: formMessages['city'] }"
                            />
                        </div>
                        <div class="col-md-4">
                            <label for="zip">PSČ*</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.zip"
                                :disabled="isUserLoggedIn"
                                id="zip"
                                name="zip"
                                placeholder="PSČ"
                                v-bind:class="{ error: formMessages['zip'] }"
                            />
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12" v-if="formMessages['city']">
                            <Notification
                                type="error"
                                :text="formMessages['city']"
                            ></Notification>
                        </div>
                        <div class="col-12" v-if="formMessages['zip']">
                            <Notification
                                type="error"
                                :text="formMessages['zip']"
                            ></Notification>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label for="id-card">Číslo občianskeho preukazu</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.idCard"
                                :disabled="isUserLoggedIn"
                                id="id-card"
                                name="id-card"
                                placeholder="Číslo občianskeho preukazu"
                            />
                            <small>
                                Vyplnením tohto údaju urýchlite vybavenie Vašej
                                objednávky
                            </small>
                        </div>
                        <div class="col-md-6">
                            <label for="id-nr">Rodné číslo</label>
                            <input
                                autocomplete="off"
                                type="text"
                                class="form-control"
                                v-model="personalInformation.idNr"
                                :disabled="isUserLoggedIn"
                                id="id-nr"
                                name="id-nr"
                                placeholder="Rodné číslo"
                            />
                            <small>
                                Vyplnením tohto údaju urýchlite vybavenie Vašej
                                objednávky
                            </small>
                        </div>
                    </div>

                    <!-- / OSobne udaje -->

                    <!-- Fakturačná adresa -->
                    <!-- row -->
                    <h2 class="section-separator mt-5">Fakturačné údaje</h2>
                    <label
                        for="billing-is-same"
                        class="checkbox"
                        v-if="!isUserLoggedIn"
                    >
                        <input
                            autocomplete="off"
                            v-model="billingIsSame"
                            type="checkbox"
                            name="billing-is-same"
                            id="billing-is-same"
                        />
                        <span class="checkbox-label"
                        >Fakturačné údaje sú rovnaké ako osobné</span
                        >
                    </label>
                    <jkb-collapse ref="billingInfo" :show="!billingIsSame">
                        <div class="row mb-4">
                            <div class="col-md-6">
                                <label for="billing-name">Meno*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="billingInformation.name"
                                    :disabled="isUserLoggedIn"
                                    id="billing-name"
                                    name="billing-name"
                                    placeholder="Meno"
                                    v-bind:class="{
                                error: formMessages['billing-name']
                            }"
                                />
                            </div>
                            <div class="col-md-6">
                                <label for="billing-surname">Priezvisko*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="billingInformation.surname"
                                    :disabled="isUserLoggedIn"
                                    id="billing-surname"
                                    name="billing-surname"
                                    placeholder="Priezvisko"
                                    v-bind:class="{
                                error: formMessages['billing-surname']
                            }"
                                />
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-12" v-if="formMessages['billing-name']">
                                <Notification
                                    type="error"
                                    :text="formMessages['billing-name']"
                                ></Notification>
                            </div>
                            <div class="col-12" v-if="formMessages['billing-surname']">
                                <Notification
                                    type="error"
                                    :text="formMessages['billing-surname']"
                                ></Notification>
                            </div>
                        </div>

                        <!-- row -->
                        <div class="row mb-4">
                            <div class="col-md-6">
                                <label for="billing-telephone">Telefón*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="billingInformation.telephone"
                                    :disabled="isUserLoggedIn"
                                    id="billing-telephone"
                                    name="billing-telephone"
                                    placeholder="Telefón"
                                    v-bind:class="{
                                error: formMessages['billing-telephone']
                            }"
                                />
                            </div>

                            <div class="col-md-4">
                                <label for="billing-street">Ulica*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="billingInformation.street"
                                    :disabled="isUserLoggedIn"
                                    id="billing-street"
                                    name="billing-street"
                                    placeholder="Ulica"
                                    v-bind:class="{
                                error: formMessages['billing-street']
                            }"
                                />
                            </div>
                            <div class="col-md-2">
                                <label for="billing-street-nr">Č.domu*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="billingInformation.streetNr"
                                    :disabled="isUserLoggedIn"
                                    id="billing-street-nr"
                                    name="billing-street-nr"
                                    placeholder="Č.domu"
                                    v-bind:class="{
                                error: formMessages['billing-street-nr']
                            }"
                                />
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div
                                class="col-12"
                                v-if="formMessages['billing-telephone']"
                            >
                                <Notification
                                    type="error"
                                    :text="formMessages['billing-telephone']"
                                ></Notification>
                            </div>
                            <div class="col-12" v-if="formMessages['billing-street']">
                                <Notification
                                    type="error"
                                    :text="formMessages['billing-street']"
                                ></Notification>
                            </div>
                            <div
                                class="col-12"
                                v-if="formMessages['billing-street-nr']"
                            >
                                <Notification
                                    type="error"
                                    :text="formMessages['billing-street-nr']"
                                ></Notification>
                            </div>
                        </div>

                        <!-- row -->
                        <div class="row">
                            <div class="col-md-4">
                                <label for="billing-city">Mesto*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="billingInformation.city"
                                    :disabled="isUserLoggedIn"
                                    id="billing-city"
                                    name="billing-city"
                                    placeholder="Mesto"
                                    v-bind:class="{
                                error: formMessages['billing-city']
                            }"
                                />
                            </div>

                            <div class="col-md-2">
                                <label for="billing-zip">PSČ*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="billingInformation.zip"
                                    :disabled="isUserLoggedIn"
                                    id="billing-zip"
                                    name="billing-zip"
                                    placeholder="PSČ"
                                    v-bind:class="{
                                error: formMessages['billing-zip']
                            }"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12" v-if="formMessages['billing-city']">
                                <Notification
                                    type="error"
                                    :text="formMessages['billing-city']"
                                ></Notification>
                            </div>
                            <div class="col-12" v-if="formMessages['billing-zip']">
                                <Notification
                                    type="error"
                                    :text="formMessages['billing-zip']"
                                ></Notification>
                            </div>
                        </div>
                    </jkb-collapse>

                    <!-- / Fakturačná adresa -->

                    <!-- Dodacia adresa -->
                    <!-- row -->
                    <h2 class="section-separator mt-5">Dodacia adresa</h2>
                    <label for="delivery-is-same" class="checkbox">
                        <input
                            v-model="deliveryIsSame"
                            type="checkbox"
                            name="delivery-is-same"
                            id="delivery-is-same"
                        />
                        <span class="checkbox-label"
                        >Dodacie údaje sú rovnaké ako osobné</span
                        >
                    </label>
                    <jkb-collapse ref="deliveryInfo" :show="!deliveryIsSame">
                        <div class="row mb-4">
                            <div class="col-md-6">
                                <label for="delivery-name">Meno*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="deliveryInformation.name"
                                    id="delivery-name"
                                    name="delivery-name"
                                    placeholder="Meno"
                                    v-bind:class="{
                                error: formMessages['delivery-name']
                            }"
                                />
                            </div>
                            <div class="col-md-6">
                                <label for="delivery-surname">Priezvisko*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="deliveryInformation.surname"
                                    id="delivery-surname"
                                    name="delivery-surname"
                                    placeholder="Priezvisko"
                                    v-bind:class="{
                                error: formMessages['delivery-surname']
                            }"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12" v-if="formMessages['delivery-name']">
                                <Notification
                                    type="error"
                                    :text="formMessages['delivery-name']"
                                ></Notification>
                            </div>
                            <div class="col-12" v-if="formMessages['delivery-surname']">
                                <Notification
                                    type="error"
                                    :text="formMessages['delivery-surname']"
                                ></Notification>
                            </div>
                        </div>
                        <!-- row -->
                        <div class="row mb-4">
                            <div class="col-md-6">
                                <label for="delivery-telephone">Telefón*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="deliveryInformation.telephone"
                                    id="delivery-telephone"
                                    name="delivery-telephone"
                                    placeholder="Telefón"
                                    v-bind:class="{
                                error: formMessages['delivery-telephone']
                            }"
                                />
                            </div>

                            <div class="col-md-4">
                                <label for="delivery-street">Ulica*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="deliveryInformation.street"
                                    id="delivery-street"
                                    name="delivery-street"
                                    placeholder="Ulica"
                                    v-bind:class="{
                                error: formMessages['delivery-street']
                            }"
                                />
                            </div>
                            <div class="col-md-2">
                                <label for="delivery-street-nr">Č.domu*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="deliveryInformation.streetNr"
                                    id="delivery-street-nr"
                                    name="delivery-street-nr"
                                    placeholder="Č.domu"
                                    v-bind:class="{
                                error: formMessages['delivery-street-nr']
                            }"
                                />
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div
                                class="col-12"
                                v-if="formMessages['delivery-telephone']"
                            >
                                <Notification
                                    type="error"
                                    :text="formMessages['delivery-telephone']"
                                ></Notification>
                            </div>
                            <div class="col-12" v-if="formMessages['delivery-street']">
                                <Notification
                                    type="error"
                                    :text="formMessages['delivery-street']"
                                ></Notification>
                            </div>
                            <div
                                class="col-12"
                                v-if="formMessages['delivery-street-nr']"
                            >
                                <Notification
                                    type="error"
                                    :text="formMessages['delivery-street-nr']"
                                ></Notification>
                            </div>
                        </div>

                        <!-- row -->
                        <div class="row">
                            <div class="col-md-4">
                                <label for="delivery-city">Mesto*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="deliveryInformation.city"
                                    id="delivery-city"
                                    name="delivery-city"
                                    placeholder="Mesto"
                                    v-bind:class="{
                                error: formMessages['delivery-city']
                            }"
                                />
                            </div>

                            <div class="col-md-2">
                                <label for="delivery-zip">PSČ*</label>
                                <input
                                    autocomplete="off"
                                    type="text"
                                    class="form-control"
                                    v-model="deliveryInformation.zip"
                                    id="delivery-zip"
                                    name="delivery-zip"
                                    placeholder="PSČ"
                                    v-bind:class="{
                                error: formMessages['delivery-zip']
                            }"
                                />
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12" v-if="formMessages['delivery-city']">
                                <Notification
                                    type="error"
                                    :text="formMessages['delivery-city']"
                                ></Notification>
                            </div>
                            <div class="col-12" v-if="formMessages['delivery-zip']">
                                <Notification
                                    type="error"
                                    :text="formMessages['delivery-zip']"
                                ></Notification>
                            </div>
                        </div>
                    </jkb-collapse>

                    <!-- / Dodacia adresa -->
                    <div class="form__footer mt-5">
                        <button class="btn btn-primary" @click="prevStep">
                            Krok späť
                        </button>

                    </div>
                </form>

            </div>
            <div  class="container col-12 col-md-4">
                <CartComponent :step="4"> </CartComponent>
                <div class="cart_footer">
                    <button class="btn btn-primary" @click="validateForm">
                        {{ buttonContent }}
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import jkbCollapse from "../../components/vue/collapse/collapse.vue";
import _debounce from "lodash/debounce";
import axios from "axios";

//Mixins
import formStep from "../../mixins/formStep";
import { API_URL, DEFAULT_AUTH, JSON_AUTH } from "../../config";
import Notification from "../../components/vue/notification/Notification";
import CartComponent from "../../components/vue/CartComponent";

export default {
    mixins: [formStep],
    components: {
        Notification,
        jkbCollapse,
        CartComponent
    },
    data() {
        return {
            // Zakladne nastavenia pre Vuex a Vue router pre spravne fungovanie kroku formulara
            stepName: "osobneUdaje",
            prevStepName: "annetaStep2",
            nextStepName: "annetaStep5",

            //Premenna ktora urcuje ci sa uzivatel pokusa registrovat ako novy /ovlada ci sa ma zobrazit a validovat heslo a potvrdzujuce heslo/
            registerNew: false,

            //Premenna ktora kontroluje, ci sa ma ukazat login sekcia formularu resp ci sa ma ukazat registracna sekcia
            hideLogin: false,

            // Login a Password // pouzite pri logine, na zaciatku kroku
            userLogin: "",
            userPass: "",
            loginMessages: [],
            attemptingLogin: false,

            // Premenna ktorá oznaci ci sa uzivatel overil pomocou API a ci ma formular predvyplnene udaje
            // tato premenna sa pouziva neskor na pripadnu ignoraciu validacie udajov, ktore su prebrate z API a teda nieje ich treba znova validovat (kedze su navyse tieto udaje aj zamknute)
            isUserLoggedIn: false,

            //Objekty ktore obsahuju osobne, fakturacne a dodacie udaje
            personalInformation: {
                name: "",
                surname: "",
                email: "",
                telephone: "",
                street: "",
                streetNr: "",
                city: "",
                zip: "",
                idNr: "",
                idCard: ""
                // password: '',
                // passwordConfirm: ''
            },
            emailMessages: [],
            emailStatus: "",
            userId: "",
            billingInformation: {
                name: "",
                surname: "",
                telephone: "",
                street: "",
                streetNr: "",
                city: "",
                zip: ""
            },
            billingIsSame: true, //ak je true, tak sa fakturacne udaje vzdy rovnaju tym, ktore su zadane v personalInformation

            deliveryInformation: {
                name: "",
                surname: "",
                telephone: "",
                street: "",
                streetNr: "",
                city: "",
                zip: ""
            },
            deliveryIsSame: true, //ak je true, tak sa dorucovacie udaje vzdy rovnaju tym, ktore su zadane v personalInformation

            //Premenna, ktora oznacuje, ci sa komponent uz donacitaval (bypass watcherov, inak hadzu error)
            doneInitializing: false,

            // Tu sa zhromazduju vsetky upozornenia, ak pri validacii udajov pride k chybe napr. "Prosím vyplnte emailovú adresu"
            formMessages: [],
            // Premenna ktora hovori o tom, ci v tomto momente prebieha validacia dat
            formIsValidating: false,
            //Centralna premenna ktora rozhoduje o tom, ci je formular validny (vsetky podmienky sa skontroluju vo funkcii validateForm)
            formIsValid: false,
            loggingWithToken: false
        };
    },

    watch: {
        personalInformation: {
            handler(newValue, oldValue) {
                //Ak je zaskrnute, ze su fakturacne rovnake ako osobne, tak ich naviazeme na seba
                if (this.billingIsSame) {
                    Object.assign(
                        this.billingInformation,
                        this.personalInformation
                    );
                }
                //Ak je zaskrnute, ze su dodacie rovnake ako osobne, tak ich naviazeme na seba
                if (this.deliveryIsSame) {
                    Object.assign(
                        this.deliveryInformation,
                        this.personalInformation
                    );
                }
            },
            deep: true
        },

        billingIsSame(newValue, oldValue) {
            //console.log('zmenil sa billing')
            if (this.doneInitializing) {
                //Ak uzivatel zaskrtne ze su fakturacne rovnake, tak naviazeme objekt osobnych udajov na faktruacne
                if (newValue == true) {
                    Object.assign(
                        this.billingInformation,
                        this.personalInformation
                    );
                } else {
                    this.billingInformation = {
                        name: "",
                        surname: "",
                        telephone: "",
                        street: "",
                        streetNr: "",
                        city: "",
                        zip: ""
                    };
                }

                // Prepne collapse element - otvori/zavrie sekciu formularu
                this.$refs.billingInfo.toggle();
            }
        },

        deliveryIsSame(newValue, oldValue) {
            //console.log('zmenil sa delivery');
            if (this.doneInitializing) {
                //Ak uzivatel zaskrtne ze su dodacie rovnake, tak naviazeme objekt osobnych udajov na faktruacne
                if (newValue == true) {
                    Object.assign(
                        this.deliveryInformation,
                        this.personalInformation
                    );
                } else {
                    this.deliveryInformation = {
                        name: "",
                        surname: "",
                        telephone: "",
                        street: "",
                        streetNr: "",
                        city: "",
                        zip: ""
                    };
                }

                // Prepne collapse element - otvori/zavrie sekciu formularu
                this.$refs.deliveryInfo.toggle();
            }
        }
    },

    computed: {
        ...mapGetters(["getStep"]),
        stepFilledCorrectly() {
            return (
                // Bool
                // tu zhromazdujeme vsetky podmienky, ktore musia byt
                // splnene na to, aby formular mohol pokracovat dalej
                this.formIsValid
            );
        },

        loadingTheme() {
            return this.darkBgAllowed
                ? "loading-pulse__light"
                : "loading-pulse__dark";
        },

        buttonContent() {
            if (this.formIsValidating) {
                return "Overujem údaje...";
            } else return "Ďalší krok";
        },

        loginButtonContent() {
            if (this.attemptingLogin) {
                return "Prihlasujem ...";
            } else return "Prihlásiť sa";
        },

        currentStepStateToSave() {
            //Nastavenia, ktore sa maju ulozit
            return {
                step: "osobneUdaje",
                state: {
                    //location: this.location
                    personalInformation: this.personalInformation,
                    billingInformation: this.billingInformation,
                    billingIsSame: this.billingIsSame,
                    deliveryInformation: this.deliveryInformation,
                    deliveryIsSame: this.deliveryIsSame,
                    isUserLoggedIn: this.isUserLoggedIn,
                    registerNew: this.registerNew,
                    hideLogin: this.hideLogin,
                    userId: this.userId
                }
            };
        }
    },

    methods: {
        validateTel(value) {
            let noSpaces = value.replace(/\s/g, "");
            if (noSpaces.match(/(\+)*(\d{9,12})/g) && noSpaces.length <= 13)
                return true;
            else return false;
        },

        async validateAddress(address = { city: "", zip: "" }) {
            // Statusy, ktore moze validacna funkcia vratit
            // 'emptyZip' - zip nema ziadnu hodnotu
            // 'emptyCity' - zip nema ziadnu hodnotu
            // 'invalid' - email nevyhovuje - uz bol v DB pouzity
            // 'valid' - email vyhovuje - nebol pouzity v DB
            // 'error' - doslo k chybe
            let regex = new RegExp("^[0-9]{3}\\s[0-9]{2}$");

            if (address.zip == "") {
                // Ak zip nema ziadnu hodnotu
                return "emptyZip";
            } else if (!regex.test(address.zip)) {
                return "wrongZipFormat";
            } else if (address.city == "") {
                // Ak city nema ziadnu hodnotu
                return "emptyCity";
            } else {
                // Dummy promise, nahradi sa realnym axios callom
                //axios.post()

                // Simulacia stavov success a fail

                // Success:
                // v pripade ze zadame mesto "true" a zip "12345" vrati sa stav true -> adresa je vyhovujuca
                // v pripade ze zadame "false" a zip "12345" vrati sa stav false -> adresa je nevyhovujuca

                // Fail:
                // ak zadame akukolvek inu hodnotu, tak sa vrati fail (simulacia zlyhania callu na API)

                // const that = this;
                // const dummyPromise = new Promise(function(resolve, reject){
                //     setTimeout(function(){
                //         if(address.city == "true" && address.zip == "12345"){
                //             resolve('valid')
                //         }
                //         else if(address.city == "false" && address.zip == "12345"){
                //             resolve('invalid')
                //         }
                //         else reject(new Error('error'));
                //     }
                //     , 1000)
                // })

                // Ak ma zip aj city daku hodnotu, tak tuto adresu posleme cez API na validaciu, ci sedi nazov mesta s PSC
                // Resolve a Error handling
                // try{
                // const result = await dummyPromise;
                // return result;
                return "valid";
                // }
                // catch(e) {
                //     return 'error'
                // }
            }
        },

        liveValidateEmail() {
            this.emailMessages = [];
            // Validaciu emailu
            this.validateEmail(this.personalInformation.email);
            // Prazdny mail
            if (!this.personalInformation.email.match(/.+\@.+\.\w+/g)) {
                this.emailMessages.push("Prosím zadajte váš e-mail");
            }
        },

        async validateEmail(value) {
            // Statusy, ktore moze validacna funkcia vratit
            // 'empty' - email nema správny tvar, alebo je prázdny
            // 'invalid' - email nevyhovuje - uz bol v DB pouzity
            // 'valid' - email vyhovuje - nebol pouzity v DB
            // 'error' - doslo k chybe
            //Skontroluje ci ma mail spravny tvar
            let data = {};
            if (value == "") {
                return;
            }
            data["method"] = "GET";
            data["headers"] = DEFAULT_AUTH;
            data["url"] = API_URL + "Users/check-user/" + value;
            // console.log(data);
            return this.$http.post("/api/external", data).then(data => {
                this.staticDeviceValidating = false;
                if (data.data.result && data.data.result === "not found") {
                    this.emailStatus = "valid";
                } else {
                    this.emailMessages.push(
                        "Pod týmto e-mailom je už u nás vytvorený účet, prosím prihláste sa"
                    );
                }
            });
        },

        loginUser(token = false) {
            this.attemptingLogin = true;
            this.loginMessages = [];

            const username = this.userLogin;
            const password = this.userPass;
            if (username != "" || password != "" || token) {
                let data = {};
                data["method"] = "POST";
                data["headers"] = JSON_AUTH;
                data["url"] = API_URL + "Users/login";
                if (token) {
                    data["data"] = {
                        token: token
                    };
                } else {
                    data["data"] = {
                        name: username,
                        password: password,
                        additionalCustomerInfoRequest: {
                            endpoints: true,
                            addressTypes: "KOR",
                            notificationPreferences: true
                        }
                    };
                }

                let user = {};
                this.$http.post("/api/external", data).then(data => {
                    this.attemptingLogin = false;
                    if (data.data.success) {
                        let userData = data.data.result;
                        user = {
                            personalInformation: {
                                name: userData.firstName,
                                surname: userData.lastName,
                                email: userData.email,
                                telephone: userData.mobilePhoneNumber,
                                street: userData.address.street,
                                streetNr: userData.address.houseNumber,
                                city: userData.address.city,
                                zip: userData.address.postalCode,
                                idNr: "",
                                idCard: ""
                            },
                            billingInformation: {
                                name: userData.firstName,
                                surname: userData.lastName,
                                email: userData.email,
                                telephone: userData.mobilePhoneNumber,
                                street: userData.address.street,
                                streetNr: userData.address.houseNumber,
                                city: userData.address.city,
                                zip: userData.address.postalCode
                            },
                            deliveryInformation: {
                                name: userData.firstName,
                                surname: userData.lastName,
                                email: userData.email,
                                telephone: userData.mobilePhoneNumber,
                                street: userData.address.street,
                                streetNr: userData.address.houseNumber,
                                city: userData.address.city,
                                zip: userData.address.postalCode
                            },
                            userId: userData.primaryKey
                        };
                        this.billingIsSame = false;
                        this.deliveryIsSame = false;
                        this.userId = userData.primaryKey;
                        // zapneme, ze je uzivatel prihlaseny a teda musime zamknut vybrane inputy
                        this.isUserLoggedIn = true;
                        this.loggingWithToken = false;
                        window.sessionStorage.removeItem("token");
                        // Zapiseme API data do lokalnych dat
                        // Musi byt nextTick, pretoze sa inak nevyplnia schovane casti formularu (fakturacne udaje a dodacie udaje)
                        this.$nextTick(() => {
                            Object.assign(
                                this.personalInformation,
                                user.personalInformation
                            );
                            Object.assign(
                                this.billingInformation,
                                user.billingInformation
                            );
                            Object.assign(
                                this.deliveryInformation,
                                user.deliveryInformation
                            );
                            this.userId = user.userId;
                        });

                        // zapneme, ze sa ma schovat uvodna loginova cast a ma sa ukazat cast s formularom
                        this.hideLogin = true;
                    } else {
                        this.loginMessages.push(
                            "Zadané údaje nie sú platné, pre pokračovanie zadajte prosím Vaše osobné údaje."
                        );
                    }
                });
            }
            //Ak nema spravny tvar
            else {
                this.loginMessages.push(
                    "Zadajte prosím vaše prihlasovacie údaje"
                );
            }
            this.attemptingLogin = false;
        },

        async validateForm() {
            await this.validateEmail();
            // Zapneme premennu, ze sa form pokusa validovat data
            this.formIsValidating = true;
            //Pred kazdou kontrolou vynulujeme vsetky spravy od formulara, aby sa nam neduplikovali a kontrolo
            this.formMessages = {};
            //Pocitadlo chyb vo formulari, na konci formularu sa skontroluje a ak je vacsie ako 0, zamedzi sa odoslaniu udajov na dalsi krok
            let invalidCounter = 0;
            // console.log(this.personalInformation);
            // OSOBNE UDAJE
            if (!this.isUserLoggedIn) {
                //Validacia meno
                if (this.personalInformation.name == "") {
                    this.formMessages["name"] =
                        "Osobné údaje: Vyplňte prosím Vaše meno";
                    invalidCounter++;
                }
                // Validacia priezvisko
                if (this.personalInformation.surname == "") {
                    this.formMessages["surname"] =
                        "Osobné údaje: Vyplňte prosím Vaše priezvisko";
                    invalidCounter++;
                }
                // Validacia telefonneho cisla
                if (!this.validateTel(this.personalInformation.telephone)) {
                    this.formMessages["telephone"] =
                        "Osobné údaje: Vyplňte prosím Vaše telefónne číslo";
                    invalidCounter++;
                }

                // Validacia ulice
                if (this.personalInformation.street == "") {
                    this.formMessages["street"] =
                        "Osobné údaje: Vyplňte prosím názov ulice";
                    invalidCounter++;
                }

                // Validacia cisla domu
                if (this.personalInformation.streetNr == "") {
                    this.formMessages["street-nr"] =
                        "Osobné údaje: Vyplňte prosím číslo domu ";
                    invalidCounter++;
                }

                // Validacia Mesta a PSC
                const cityZip = {
                    city: this.personalInformation.city,
                    zip: this.personalInformation.zip
                };
                const personalCityValidation = await this.validateAddress(
                    cityZip
                );
                // Prazdne mesto
                if (this.personalInformation.city === "") {
                    this.formMessages["city"] =
                        "Osobné údaje: Prosím zadajte názov Mesta";
                    invalidCounter++;
                }

                // Prazdny zip
                if (personalCityValidation == "emptyZip") {
                    this.formMessages["zip"] =
                        "Osobné údaje: Prosím zadajte vaše PSČ";
                    invalidCounter++;
                }

                // Sekundarna validacia emailu - v pripade ze si zakaznik nevsimne live validaciu, ktora je na
                if (this.emailStatus != "valid") {
                    this.formMessages["email"] =
                        "Osobné údaje: E-mail, ktorý ste zadali nieje možné použiť pri registrácií. Skontrolujte si prosím zadané údaje";
                    invalidCounter++;
                }
            }
            // END osobne udaje

            // FAKTURACNE UDAJE
            // Ak je vypnute, ze su fakturacne udaje zhodne s osobnymi, validuj samostatne fakturacne udaje
            // zaroven musi platit, ze pouzivatel nezobral udaje z ineho API
            if (!this.billingIsSame && !this.isUserLoggedIn) {
                //Validacia meno
                if (this.billingInformation.name == "") {
                    this.formMessages["billing-name"] =
                        "Fakturačné údaje: Vyplňte prosím Vaše meno";
                    invalidCounter++;
                }
                // Validacia priezvisko
                if (this.billingInformation.surname == "") {
                    this.formMessages["billing-surname"] =
                        "Fakturačné údaje: Vyplňte prosím Vaše priezvisko";
                    invalidCounter++;
                }
                // Validacia telefonneho cisla
                if (!this.validateTel(this.billingInformation.telephone)) {
                    this.formMessages["billing-telephone"] =
                        "Fakturačné údaje: Vyplňte prosím Vaše telefónne číslo";
                    invalidCounter++;
                }

                // Validacia ulice
                if (this.billingInformation.street == "") {
                    this.formMessages["billing-street"] =
                        "Fakturačné údaje: Vyplňte prosím názov ulice";
                    invalidCounter++;
                }

                // Validacia cisla domu
                if (this.billingInformation.streetNr == "") {
                    this.formMessages["billing-street-nr"] =
                        "Fakturačné údaje: Vyplňte prosím číslo domu ";
                    invalidCounter++;
                }

                // Validacia Mesta a PSC
                const cityZip = {
                    city: this.billingInformation.city,
                    zip: this.billingInformation.zip
                };
                const billingCityValidation = await this.validateAddress(
                    cityZip
                );
                // Prazdne mesto
                if (this.billingInformation.city === "") {
                    this.formMessages["billing-city"] =
                        "Fakturačné údaje: Prosím zadajte názov Mesta";
                    invalidCounter++;
                }
                // Prazdny zip
                if (billingCityValidation == "emptyZip") {
                    this.formMessages["billing-zip"] =
                        "Fakturačné údaje: Prosím zadajte vaše PSČ";
                    invalidCounter++;
                }
                // Nevyhovujuci email
                else if (billingCityValidation == "invalid") {
                    this.formMessages["billing-city"] =
                        "Fakturačné údaje: PSČ sa nezhoduje s názvom mesta, ktoré ste zadali. Skontrolujte si prosím údaje";
                    invalidCounter++;
                }
                // Error pri kontakte s API - timeout atd.
                else if (billingCityValidation == "error") {
                    this.formMessages["billing-city"] =
                        "Fakturačné údaje: Pri validácií Vášho mesta došlo k chybe, skúste to prosím ešte raz. Ak problém pretrváva, kontaktujte nás.";
                    invalidCounter++;
                }
            }
            // END FAKTURACNE UDAJE

            // DODACIE UDAJE
            // Ak je vypnute, ze su fakturacne udaje zhodne s osobnymi, validuj samostatne fakturacne udaje
            if (!this.deliveryIsSame) {
                //Validacia meno
                if (this.deliveryInformation.name == "") {
                    this.formMessages["delivery-name"] =
                        "Dodacie údaje: Vyplňte prosím Vaše meno";
                    invalidCounter++;
                }
                // Validacia priezvisko
                if (this.deliveryInformation.surname == "") {
                    this.formMessages["delivery-surname"] =
                        "Dodacie údaje: Vyplňte prosím Vaše priezvisko";
                    invalidCounter++;
                }
                // Validacia telefonneho cisla
                if (!this.validateTel(this.deliveryInformation.telephone)) {
                    this.formMessages["delivery-telephone"] =
                        "Dodacie údaje: Vyplňte prosím Vaše telefónne číslo";
                    invalidCounter++;
                }

                // Validacia ulice
                if (this.deliveryInformation.street == "") {
                    this.formMessages["delivery-street"] =
                        "Dodacie údaje: Vyplňte prosím názov ulice";
                    invalidCounter++;
                }

                // Validacia cisla domu
                if (this.deliveryInformation.streetNr == "") {
                    this.formMessages["delivery-street-nr"] =
                        "Dodacie údaje: Vyplňte prosím číslo domu ";
                    invalidCounter++;
                }

                // Validacia Mesta a PSC
                const cityZip = {
                    city: this.deliveryInformation.city,
                    zip: this.deliveryInformation.zip
                };
                const deliveryCityValidation = await this.validateAddress(
                    cityZip
                );
                // Prazdne mesto
                if (this.deliveryInformation.city === "") {
                    this.formMessages["delivery-city"] =
                        "Dodacie údaje: Prosím zadajte názov Mesta";
                    invalidCounter++;
                }
                // Prazdny zip
                if (deliveryCityValidation == "emptyZip") {
                    this.formMessages["delivery-zip"] =
                        "Dodacie údaje: Prosím zadajte vaše PSČ";
                    invalidCounter++;
                } else if (deliveryCityValidation == "wrongZipFormat") {
                    this.formMessages["delivery-city"] =
                        "Dodacie údaje: Prosím zadajte vaše PSČ vo formate CCC CC (napr.: 811 03)";
                    invalidCounter++;
                }
                // Nevyhovujuci email
                else if (deliveryCityValidation == "invalid") {
                    this.formMessages["delivery-city"] =
                        "Dodacie údaje: PSČ sa nezhoduje s názvom mesta, ktoré ste zadali. Skontrolujte si prosím údaje";
                    invalidCounter++;
                }
                // Error pri kontakte s API - timeout atd.
                else if (deliveryCityValidation == "error") {
                    this.formMessages["delivery-city"] =
                        "Dodacie údaje: Pri validácií Vášho mesta došlo k chybe, skúste to prosím ešte raz. Ak problém pretrváva, kontaktujte nás.";
                    invalidCounter++;
                }
            }

            // Vypneme premennu. ze sa form pokusa validovat data
            this.formIsValidating = false;

            // Vyhodnotenie a posun na dalsi krok
            // Ak došlo k akejkolvek chybe pri validacii formularu, vytiahni uživateľa k error logingu nad formular
            if (invalidCounter > 0) {
                this.billingIsSame = !this.billingIsSame;
                this.deliveryIsSame = !this.deliveryIsSame;
                this.billingIsSame = !this.billingIsSame;
                this.deliveryIsSame = !this.deliveryIsSame;
                this.scrollToNavigation();
                this.formIsValid = false;
            } else {
                this.formIsValid = true;
                this.nextStep();
                // }
            }
        }
    },
    created() {
        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        const stepData = this.getStep("osobneUdaje");

        let token = window.sessionStorage.getItem("token");
        if (token) {
            // console.log(token);
            this.loggingWithToken = true;
            this.loginUser(token);
        }
        if (stepData) {
            if (stepData.billingIsSame != null) {
                this.billingIsSame = stepData.billingIsSame;
                //console.log(this.billingIsSame);
            }
            if (stepData.deliveryIsSame != null) {
                this.deliveryIsSame = stepData.deliveryIsSame;
                //console.log(this.deliveryIsSame);
            }
        }
    },

    mounted() {
        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        const stepData = this.getStep("osobneUdaje");
        if (stepData) {
            this.registerNew = stepData.registerNew;
            this.billingIsSame = stepData.billingIsSame;
            this.deliveryIsSame = stepData.deliveryIsSame;
            this.isUserLoggedIn = stepData.isUserLoggedIn;
            this.hideLogin = stepData.hideLogin;

            if (stepData.personalInformation) {
                this.personalInformation = stepData.personalInformation;
            }

            // Nastavi fakturacne udaje zo store ak nema ziadne, tak nastavi to iste co je nastavene v personal info
            if (stepData.billingInformation) {
                Object.assign(
                    this.billingInformation,
                    stepData.billingInformation
                );
                //console.log('mam data billing')
            } else {
                Object.assign(
                    this.billingInformation,
                    this.personalInformation
                );
            }

            // Nastavi dodacie udaje zo store ak nema ziadne, tak nastavi to iste co je nastavene v personal info
            if (stepData.deliveryInformation) {
                Object.assign(
                    this.deliveryInformation,
                    stepData.deliveryInformation
                );
                //console.log('mam data delivery')
            } else {
                Object.assign(
                    this.deliveryInformation,
                    this.personalInformation
                );
            }
        }

        //Prepne stav komponentu na dokoncene nacitavanie (bypass watcherov)
        this.$nextTick(() => {
            this.doneInitializing = true;
        });
    }
};
</script>

<style lang="scss" scoped>
.wraper {
    position: relative;
}

.registration-section {
    transition: all 0.3s ease-out;
    position: relative;

    &.hidden {
        position: absolute;
        opacity: 0;
    }
}
</style>
