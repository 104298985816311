<template>
    <form @submit.prevent="submitForm">
        <div id="quizz__comp">
            <div class="container" v-if="currentStep === 0">
                <div class="row">
                    <div class="col-12 col-lg-6 text-left mb-4">
                        <img
                            v-if="quiz.image2"
                            :src="quiz.image2"
                            class="w-100 quizz-img"
                            alt=""
                        />
                        <img
                            v-else-if="quiz.image"
                            :src="quiz.image"
                            class="w-100 quizz-img"
                            alt=""
                        />
                    </div>
                    <div class="col-12 col-lg-6 text-left">
                        <div v-if="quiz.is_finished" v-html="quiz.content_after"></div>
                        <div v-else v-html="quiz.content"></div>

                        <button
                            v-if="!quiz.is_finished"
                            type="button"
                            class="btn btn-primary mt-4" @click="currentStep++">
                            <span>Začať kvíz teraz</span>
                            <img
                                src="https://plustelka.sk/img/icons/arrow-right.svg"
                                alt=""
                                class="btn__icon"
                            />
                        </button>
                        <div v-if="quiz.conditions_link" class="mt-2">
                            <a :href="quiz.conditions_link" target="_blank" class="quizz-a">Podmienky súťaže</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" v-if="currentStep > 0">
                <div v-for="(question, index) in data" :key="question.id">
                    <div v-if="(index + 1) === currentStep">
                        <h2 class="mb-4">{{ currentStep }}. {{ question.text }}</h2>
                        <div class="row">
                            <div
                                class="col-12 col-lg-6 mt-2"
                                v-for="(answer, answerIndex) in question.answers"
                                :key="answer.id"
                            >
                                <div
                                    v-bind:class="{
                                        'question__wrap--selected':
                                            currentSelectedAnswer === answer.id,
                                    }"
                                    class="question__wrap w-100"
                                >
                                    <input
                                        type="radio"
                                        class="w-100"
                                        :value="answer.id"
                                        :id="answer.id"
                                        v-model="selectedAnswers[question.id]"
                                        @click="handleClick(answer)"
                                    />
                                    <label class="w-100" :for="answer.id"
                                    >{{
                                        numberToLetter(answerIndex + 1).toUpperCase()
                                        }}. {{ answer.text }}</label
                                    >
                                    <div
                                        class="answer-ico"
                                        v-bind:class="{
                                            'answer-ico--correct':
                                                answer.is_correct,
                                        }"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="vyhodnotenie" v-if="currentStep > data.length">
                    <div v-if="!showSuccessSendForm">
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-10 text-center">
                                <h1 class="vyhodnotenie__heading">
                                    {{ score }} z {{ data.length }}
                                </h1>
                                <h2 class="vyhodnotenie__subheading">
                                    správnych odpovedí
                                </h2>
                                <h2 class="font-weight-bold mt-4 mb-2">
                                    Ďakujeme, že ste sa zúčastnili nášho kvízu
                                </h2>
                                <p>
                                    Vyplňte prosím, formulár nižšie aby sme Vás mohli
                                    zaradiť do žrebovania.
                                </p>
                            </div>
                        </div>
                        <div
                            class="row mt-4 justify-content-center flex-row align-items-center"
                        >
                            <div class="col-12 col-lg-5 text-center">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Váš e-mail"
                                    required="required"
                                    class="form-control"
                                />
                                <input
                                    v-for="(formInput, index) in formInputs"
                                    :key="index"
                                    type="text"
                                    :name="`answers[`+formInput.id+`][answer]`"
                                    :placeholder="formInput.text"
                                    required="required"
                                    class="form-control mt-2"
                                />

                                <button
                                    v-if="buttonIsLoading"
                                    type="submit"
                                    disabled
                                    class="btn btn-primary btn--big mt-3">
                                        <span><i
                                            class="mx-3 fa-lg fa fa-spinner fa-spin loader-icon"></i></span>
                                </button>

                                <button
                                    v-if="!buttonIsLoading"
                                    type="submit"
                                    class="btn btn-primary btn--big mt-3">
                                    <span>Zapojiť sa</span>
                                    <img
                                        src="https://plustelka.sk/img/icons/arrow-right.svg"
                                        alt=""
                                        class="btn__icon"
                                    />
                                </button>

                            </div>
                        </div>
                    </div>
                    <Transition>
                    <div v-if="showSuccessSendForm">
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-10 text-center">
                                <h2 class="vyhodnotenie__heading-h3 font-weight-bold mt-5">
                                    Gratulujeme
                                </h2>
                                <h3 class="vyhodnotenie__subheading-h3 mt-4">
                                    boli ste úspešne zapojený do žrebovania
                                </h3>
                                <p class="mt-5">Na identifikáciu vám bol vygenerovaný jedinečný kód,<br> ktorý vám bol zaslaný na zadanú e-mailovú adresu</p>
                                <h2 class="vyhodnotenie__subheading mt-4" v-text="respondentCode"></h2>
                            </div>
                        </div>
                    </div>
                    </Transition>
                </div>

                <div
                    class="d-flex flex-column align-items-center mt-5"
                    v-if="currentStep <= data.length"
                >
                    <button
                        type="button"
                        :disabled="!evaluateButton"
                        class="btn btn-primary"
                        @click="evaluateAnswer()"
                    >
                        <span>Označiť odpoveď</span>
                        <img
                            src="https://plustelka.sk/img/icons/arrow-right.svg"
                            alt=""
                            class="btn__icon"
                        />
                    </button>
                    <button
                        type="button"
                        :disabled="!nextButton"
                        v-if="currentStep != data.length"
                        class="btn btn-primary mt-2"
                        @click="nextQuestion()"
                    >
                        <span>Ďalšia otázka</span>
                        <img
                            src="https://plustelka.sk/img/icons/arrow-right.svg"
                            alt=""
                            class="btn__icon"
                        />
                    </button>

                    <button
                        type="button"
                        v-if="currentStep === data.length"
                        :disabled="!vyhodnotenieButton"
                        class="btn btn-primary mt-2"
                        @click="submitAnswers()"
                    >
                        <span>Vyhodnotenie</span>
                        <img
                            src="https://plustelka.sk/img/icons/arrow-right.svg"
                            alt=""
                            class="btn__icon"
                        />
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
    import axios from "axios";
    import '@fortawesome/fontawesome-free/js/all';
    import "../../../toastify";

    export default {
        props: {
            quiz: Object,
            questions: Array,
            formInputs: Array,
            endpoint: String,
        },

        data() {
            return {
                currentStep: 0,
                score: 0,
                inputsClickable: true,
                nextButton: false,
                evaluateButton: false,
                vyhodnotenieButton: false,
                showSuccessSendForm: false,
                currentSelectedAnswer: null,
                data: this.questions,
                selectedAnswers: {},
                csrf: document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute("content"),
                buttonIsLoading: false,
                respondentCode: '',
            };
        },

        created() {
        },

        methods: {
            handleClick(answer) {
                if (this.inputsClickable === true) {
                    this.currentSelectedAnswer = answer.id;
                    this.evaluateButton = true;
                }
            },

            numberToLetter(id) {
                return String.fromCharCode(96 + id);
            },

            evaluateAnswer() {
                document
                    .querySelector(".answer-ico--correct")
                    .classList.add("dvisible");

                document
                    .querySelector(".question__wrap--selected .answer-ico")
                    .classList.add("answer-ico--selected-not-correct");

                this.nextButton = true;
                this.evaluateButton = false;
                this.inputsClickable = false;

                if (this.currentStep === this.data.length) {
                    this.vyhodnotenieButton = true;
                }
            },

            nextQuestion() {
                this.currentStep++;
                this.nextButton = false;
                this.evaluateButton = false;
                this.inputsClickable = true;
            },

            submitAnswers() {
                let score = 0;
                for (let question of this.data) {
                    for (let answer of question.answers) {
                        if (
                            answer.id === this.selectedAnswers[question.id] &&
                            answer.is_correct
                        ) {
                            score++;
                            break;
                        }
                    }
                }
                this.currentStep++;
                this.score = score;
            },

            submitForm(e) {
                this.buttonIsLoading = true;
                axios
                    .post(this.endpoint, new FormData(e.target), {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-CSRF-TOKEN": this.csrf,
                        },
                    })
                    .then((response) => {
                        if (response.data.status) {
                            this.buttonIsLoading = false;
                            this.showSuccessSendForm = true;
                            this.respondentCode = response.data.data.description.code;
                        } else {
                            this.buttonIsLoading = false;
                            console.log(response.data.data.description);
                            showToast(response.data.data.title, response.data.data.type);
                        }
                        console.log(this.buttonIsLoading ? 'true' : 'false');
                    })
                    .catch((e) => {
                        console.log(e);
                        showToast('Pri odoslení výsledkov nastala chyba!', 'error');
                        this.buttonIsLoading = false;
                    });
            },
        },
    };
</script>

<style lang="scss">
    #quizz__comp {
        margin: 50px 0 150px;
    }

    .question__wrap {
        background: #ffffff;
        border: 1px solid #f2f2f2;
        border-radius: 6px;
        display: flex;

        &:hover {
            border: 1px solid #3dacac;
            cursor: pointer;
        }

        input,
        label {
            cursor: pointer;
            margin: 0;
            padding: 18px 46px 18px 16px;
            color: #333333;
            font-size: 18px;
            font-weight: 500;
        }
    }

    .answer-ico {
        display: none;
        visibility: hidden;
        height: 20px;
        position: absolute;
        right: 47px;

        &.answer-ico--correct {
            display: none;
        }

        &.answer-ico--selected-not-correct {
            visibility: visible;
            display: block;

            &::before {
                content: url(/img/icons/xko.svg);
                position: absolute;
                top: 50%;
                margin-top: 6px;
            }
        }

        &.dvisible {
            visibility: visible;
            display: block;

            &::before {
                content: url(/img/icons/fajka.svg);
                position: absolute;
                top: 50%;
                margin-top: 6px;
            }
        }
    }

    .question__wrap--selected {
        background: linear-gradient(135deg, #0ea879 0%, #26b8eb 100%);
        border: 1px solid #f2f2f2;
        color: #fff;
    }

    input[type="radio"] {
        display: none;
    }

    .question__wrap--correct {
        background: green;
    }

    .question__wrap--incorrect {
        background: red;
    }

    .vyhodnotenie__heading {
        font-size: 96px;
    }

    .vyhodnotenie__heading-h3 {
        font-size: 3rem;
    }

    .vyhodnotenie__subheading {
        font-size: 32px;
        color: #333333;
    }

    .vyhodnotenie__subheading-h3 {
        font-size: 24px;
        color: #333333;
    }

    .quizz-img {
        max-width: 489px;
    }

    .quizz-a {
        text-decoration-line: underline;
        font-size: 18px;
        font-weight: 400;
        color: #1eb3c2;
    }
</style>
