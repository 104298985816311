<template>
    <div class="section section--small m-5">
        <!-- Vyber Zakladnych sluzieb -->
        <div class="container">
            <jkb-collapse
                ref="packagesSelection"
                :show="!pckgsShowing && !recoveringFromState"
                class=""
            >
                <div class="text-center">
                    <div class="offer">
                        <div class="child-services">
                            <div
                                class="offer__item"
                                v-for="child in childServices"
                                :key="`childService${child.id}`"
                                :class="{
                                    active: child.id == getSelectedAnnetaPackage
                                }"
                                @click="setPackagesForProgram(child.id)"
                            >
                                <span class="tag" v-if="child.featured">
                                    Obľúbená TV
                                </span>

                                <div class="offer__item__logo">
                                    <img
                                        :src="child.image"
                                        alt=""
                                        class="offer__item__logo__img"
                                    />
                                </div>

                                <h3 class="text-center">
                                    {{ child.name }}
                                </h3>

                                <div class="offer__item__info">
                                    <span
                                        >až do
                                        <span
                                            class="text-highlighted text-highlighted--green font-weight-inherit"
                                            >{{
                                                getAllProgramsChannelCounts(
                                                    child.id
                                                )
                                            }}
                                            programov</span
                                        >
                                        <br />
                                        <span class="d-inline-block">
                                            (
                                            {{
                                                getHdProgramsChannelCounts(
                                                    child.id
                                                )
                                            }}
                                            v HD kvalite
                                            <template
                                                v-if="
                                                    get4KProgramsChannelCounts(
                                                        child.id
                                                    )
                                                "
                                            >
                                                ,
                                                {{
                                                    get4KProgramsChannelCounts(
                                                        child.id
                                                    )
                                                }}
                                                v 4K kvalite </template
                                            >)
                                        </span>
                                    </span>
                                </div>
                                <div class="offer__item__list">
                                    <div
                                        class="offer__item__list__item"
                                        v-for="(benefit,
                                        index) in filterBenefits(
                                            child.benefits
                                        )"
                                        :key="index"
                                    >
                                        <img
                                            :src="benefit.icon"
                                            alt=""
                                            class="offer__item__list__item__icon"
                                        />
                                        <span
                                            class="offer__item__list__item__text"
                                            >{{ benefit.title }}</span
                                        >
                                    </div>
                                </div>
                                <div class="offer__item__footer">
                                    <div class="offer__item__price">
                                        <span>
                                            <span
                                                class="offer__item__price__total"
                                                >{{
                                                    evaluateServicePrice(
                                                        child.id
                                                    )
                                                }}</span
                                            >
                                            € / mesačne
                                        </span>
                                        <small v-if="child.promo">
                                            Od
                                            {{
                                                parseInt(child.promo.months) +
                                                    1
                                            }}. mesiaca {{ child.price }} €
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button
                        class="btn btn-primary"
                        :disabled="!getSelectedAnnetaPackage"
                        @click="
                            $refs.packagesCollapse.silentToggle();
                            $refs.packagesSelection.silentToggle();
                        "
                    >
                        Zvoliť službu
                    </button>
                </div>
            </jkb-collapse>
        </div>
        <!-- /Vyber Zakladnych sluzieb -->

        <!-- Vyber Balikov -->
        <jkb-collapse
            class="mb-5"
            v-if="getSelectedAnnetaPackage"
            id="pckgCollapse"
            ref="packagesCollapse"
            @collapse="pckgsShowing = $event"
            :show="recoveringFromState"
        >
            <div class="row justify-content-between">
                <div class="col-lg-7 pr-lg-4 mb-lg-0 mb-4">
                    <!-- Overenie lokality -->
                    <ConfirmLocation
                        @success="success = true"
                        :selectedItem="selectedCity"
                    ></ConfirmLocation>
                    <div class="offer text-center">
                        <div class="mx-auto pb-5">
                            <h2 class="h3 mb-2">Základný balík</h2>

                            <div class="offer__wrapper">
                                <!-- Main Offer item -->
                                <package-card
                                    v-for="(defaultPackage,
                                    index) in getDefaultPackagesUnderProgram(
                                        currentProgram.id
                                    )"
                                    class="main-package"
                                    :key="`defaultPackage${defaultPackage.id}`"
                                    :pckg="defaultPackage"
                                    :checked="defaultPackage.additional == 0"
                                    @input="checkInServices($event)"
                                    disabled
                                >
                                    <template v-slot:price v-if="promotion">
                                        <div class="package-card__price">
                                            <span
                                                >už od
                                                {{
                                                    getProgram(
                                                        getSelectedAnnetaPackage
                                                    ).promo.price
                                                }}€</span
                                            >
                                            <span>/mesiac</span>
                                        </div>
                                        <div
                                            class="offer__item__price__description"
                                        >
                                            s DPH / mesačne
                                        </div>

                                        <small>
                                            Od
                                            {{
                                                parseInt(
                                                    getProgram(
                                                        getSelectedAnnetaPackage
                                                    ).promo.months
                                                ) + 1
                                            }}. mesiaca
                                            {{ defaultPackage.price }}€
                                        </small>
                                    </template>
                                </package-card>
                                <!-- /Main Offer item -->
                            </div>
                            <div
                                class="user-messages"
                                v-if="defaultFreePackagesCount > 0"
                            >
                                <!-- User message -->
                                <div class="user-message">
                                    <div class="icon">
                                        <img src="/img/icons/info.svg" alt="" />
                                    </div>
                                    <span v-if="defaultFreePackagesCount > 0">
                                        V cene Anneta
                                        {{
                                            getProgram(getSelectedAnnetaPackage)
                                                .name
                                        }}
                                        máte {{ defaultFreePackagesCount }}
                                        {{
                                            packagesDeclension(
                                                defaultFreePackagesCount
                                            )
                                        }}
                                        podľa vlastného výberu.
                                    </span>
                                </div>
                                <!-- /user message -->
                            </div>
                        </div>
                    </div>
                    <div class="pb-5">
                        <h2 class="h3 mb-2">Doplnkové balíky</h2>

                        <!-- Upozornenie na volne balicky -->

                        <p v-show="defaultFreePackagesCount > 0">
                            Vyberte si ľubovoľnú kombináciu doplnkových balíkov,
                            {{ defaultFreePackagesCount }} máte v cene služby.
                        </p>

                        <!--
                        <p v-show="promotion">
                            Počet dostupných balíkov, ktoré máte nad ramec programu (promo kampan): {{ availableSemiFreePackagesCount }}
                        </p>
                        -->

                        <div class="offer__wrapper">
                            <package-card
                                v-for="(additionalPackage,
                                index) in getAdditionalPackagesUnderProgram(
                                    currentProgram.id
                                )"
                                :key="
                                    `additionalPackage${additionalPackage.id}`
                                "
                                :pckg="additionalPackage"
                                :checked="
                                    service.some(
                                        srvc => srvc.id == additionalPackage.id
                                    )
                                "
                                :class="{
                                    free: isFreePackage(additionalPackage.id)
                                }"
                                @input="
                                    checkInServices($event);
                                    checkIfThisisFreePackage($event);
                                    setSumPrice($event);
                                "
                            >
                                <!-- Cena -->
                                <!-- Zobrazi sa iba v pripade ze je balicek budto v cene hlavneho balika, alebo je nad ramec (v pripade promo kampane) -->
                                <template
                                    v-slot:price
                                    v-if="isFreePackage(additionalPackage.id)"
                                >
                                    <!-- Ak je balik zahrnuty v balikoch v cene -->
                                    <template
                                        v-if="
                                            isFreePackage(additionalPackage.id)
                                        "
                                    >
                                        <div class="price">
                                            <!-- <template v-if=" pckg.discount != null ">
                                                <span v-if="currentMonths >= pckg.discount.months" class="sale">{{ pckg.discount.price }}€</span>
                                            </template> -->
                                            <span class="regular">V cene</span>
                                        </div>
                                        <div
                                            class="offer__item__price__description"
                                        >
                                            s DPH / mesačne
                                        </div>
                                    </template>
                                </template>
                            </package-card>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 pl-lg-4">
                    <div class="card-wrapper">
                        <div class="cart">
                            <div class="card-header">
                                <h2 class="h4 card-header__title">
                                    Zvoľte si počet mesiacov využívania služby
                                </h2>
                            </div>

                            <div class="card-item">
                                <div class="card-quantity">
                                    <div class="card-quantity__price">
                                        <span class="total"
                                            >{{ itemprice }} €</span
                                        >
                                        <span>/mesiac</span>
                                    </div>
                                    <div class="card-quantity__box">
                                        <button
                                            @click.prevent="removeMonths()"
                                            class="card-quantity__button card-quantity__button--minus"
                                        ></button>
                                        <div class="card-quantity__input-group">
                                            <input
                                                type="number"
                                                class="card-quantity__input"
                                                :value="months"
                                                min="1"
                                            />
                                            <span
                                                class="card-quantity__placeholder"
                                                >mesiacov</span
                                            >
                                        </div>
                                        <div class="col-4">
                                            <button
                                                @click.prevent="addMonths()"
                                                class="card-quantity__button card-quantity__button--plus"
                                            ></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">Splou:</div>
                                <div class="col-8">
                                    <p>
                                        <strong>{{ fullPrice() }}</strong>
                                    </p>
                                    <p>
                                        /s DPH na {{ months }}
                                        {{ months === 1 ? "mesiac" : "" }}
                                        {{
                                            months > 1 && months < 5
                                                ? "mesiace"
                                                : ""
                                        }}
                                        {{ months >= 5 ? "mesiacov" : "" }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="cart_footer">
                            <button
                                class="btn btn-primary col-12"
                                :disabled="
                                    !stepFilledCorrectly ||
                                        availableFreePackagesCount > 0
                                "
                                @click="nextStep"
                            >
                                {{ nextBtnContent }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-5">
                <conditions>
                    <template v-slot:headline>
                        Podmienky
                    </template>
                    <template v-slot:content>
                        <p>
                            Podmienkou je objednanie a uzavretie Zmluvy a/alebo
                            Dodatku na príslušnú službu Anneta v balíku
                            Štandardná alebo Rozšírená alebo Prémiová s dobou
                            viazanosti 24 mesiacov. Úplné znenie ponuky je
                            uvedené v
                            <a href="/dokument/cennik-sluzieb" target="_blank"
                                >Cenníku</a
                            >. Ceny sú uvádzané s DPH. Poskytovateľom Služby
                            Plustelka je spoločnosť Towercom, a.s., so sídlom
                            Cesta na Kamzík 14, 831 01 Bratislava, zap. v OR OS
                            Bratislava I, oddiel Sa, vl. č.: 3885/B, IČO: 36 364
                            568 (ďalej len „Poskytovateľ“). Služba Plustelka je
                            poskytovaná na základe Zmluvy o poskytovaní Služby,
                            ktorej neoddeliteľnou súčasťou sú
                            <a
                                href="/dokument/vseobecne-obchodne-podmienky"
                                target="_blank"
                                >Všeobecné podmienky</a
                            >
                            poskytovania služieb spoločnosti Towercom, a.s.
                            (ďalej len „VOP“).
                            <a
                                href="/dokument/informacie-pre-spotrebitela"
                                target="_blank"
                                >Základné informácie pre Vás ako spotrebiteľa</a
                            >. Pojmy označené s veľkým začiatočným písmenom,
                            ktoré sú definované vo VOP a nie sú výslovne
                            definované v tomto formulári, majú v tomto formulári
                            ten istý význam.
                        </p>
                    </template>
                </conditions>
            </div>
        </jkb-collapse>
        <!-- /Vyber balikov -->
    </div>
</template>

<script>
import packageCard from "../../components/vue/ponuka/package-card__anneta.vue";
import jkbCollapse from "../../components/vue/collapse/collapse";
import conditions from "../../components/vue/conditions/conditions.vue";
import { mapActions, mapGetters } from "vuex";

//Mixins
import formStep from "../../mixins/formStep";
import ConfirmLocation from "../../components/vue/ConfirmLocation";

export default {
    //formStep - obsahuje spolocnu funkcnost ktora plati pre vsetky kroky objednavkoveho formulara ako pre Annetu tak aj pre Antenu
    mixins: [formStep],

    data() {
        return {
            childServices: [], //Array kde sa ulozia vsetky sub-sluzby hlavnej sluzby (anneta S, M, L), pozor nie baliky !
            pckgsShowing: false, //premmenna ktora oznacuje, ci je otvorena sekcia s vyberom balikov
            recoveringFromState: false, //premenna ktora oznacuje, ci ma tento krok data nacitane z Vuex teda z pamati, alebo ide o nove nacitanie ... rozhoduje ktora cast formularu sa zobrazi pri nacitani komponentu
            stepName: "vyberBalikov", //interny nazov tohoto kroku, pod jeho menom sa uklada do Vuex stav tohoto kroku
            prevStepName: "annetaStep1", //nazov predchadzajuceho kroku, podla neho sa Vue router rozhoduje na aku view sa ma prepnut
            nextStepName: "annetaStep2", //nazov nasledujuceho kroku, podla neho sa Vue router rozhoduje na aku view sa ma prepnut
            cart: [], // kosik tohoto kroku, v sumarizacii sa z tohoto kosika vypocitava finalna cena
            service: [], //array vsetkych "objednanych" balikov
            freePackages: [], //array balikov, ktore su v cene
            freePackagesDepleted: false, // pomocna premenna ktora sa nastavuje s miernym opozdenim aby sa zamedzilo oznaceniu balika ako free a semi-free zaroven // IBA POCAS PROMOCNEJ KAMPANE
            semiFreePackages: [], //array balikov, ktore su nad ramec ponuky // IBA POCAS PROMOCNEJ KAMPANE
            promotion: true, //vyhodnotenie, ci je aktivovana akcia na baliky, kedy nizsie baliky preberaju vyhody tych vyssich (pocet balikov v cene)
            itemprice: 0,
            extraItems: 0,
            months: 1,
            success: false,
            selectedCity: {}
        };
    },

    components: {
        ConfirmLocation,
        jkbCollapse,
        packageCard,
        conditions
    },

    watch: {
        //Sledujeme polozku  services, a podla nej pridame polozky do arrayu cart z ktore sa na konci vypocita vysledna cena
        service: {
            handler(value) {
                this.cart = [];
                value.forEach(service => {
                    //console.log(service);

                    // DOPLNKOVE BALIKY + HLAVNY BALIK
                    // ak sa balik nachadza v doplnkovych balikoch nadradeneho programu, zahrn ich do kosika - obchadza to bug v-modelu kedy ostanu zaskrtnute aj balicky, ktore sa uz v DOM-e nemaju ukazovat
                    // alebo ak ma balik sub-baliky -> je to hlavny balik/program

                    let desc =
                        service.additional == 1
                            ? "doplnkový balík"
                            : "základný balík";
                    let finalPrice = service.price;

                    // Ak sa tento balik nachadza zaroven v array free packages, tak mu zmen cenu na 0, pretoze je v cene
                    if (this.freePackages.some(arr => arr.id == service.id)) {
                        finalPrice = 0;
                    }

                    // Objekt do ktoreho budeme vkladat parametre, ktore sa budu v poslednom kroku zuctovavat
                    let accountedService = {};

                    // Ak sa tento balik nachadza zaroven v array semifree packages, tak mu zmen cenu na 0, pretoze je v cene po dobu X mesiacov
                    // zaroven pridaj 2 nove parametre,
                    // priceAfterDiscountPeriod - cena po uplnuti akcioveho obdobia
                    // discountPeriod - pocet mesiacov po ktore bude tento balik zlavneny
                    // if(this.semiFreePackages.some(arr => arr.id == service.id)){
                    //     finalPrice = 0;
                    //     accountedService.priceAfterDiscountPeriod = service.price;
                    //     accountedService.discountPeriod = this.currentProgram.promo.months;
                    // }

                    // Ak ide o zakladny balik a su splnene podmienky na aplikaciu zlavy
                    // tak zmen cenu zakladneho baliku a aplikuj tu ktora je uvedena v akcii
                    // if (service.additional == 0 && this.promotion) {
                    finalPrice = this.getProgram(this.getSelectedAnnetaPackage)
                        .price; //promo.price
                    // }

                    accountedService.title = service.name;
                    accountedService.price = parseFloat(finalPrice);
                    accountedService.payment = "PM";
                    accountedService.description = desc;
                    accountedService.months = this.months;

                    //Prepocitanie ceny zlavy
                    // if(service.discount != null && this.months >= service.discount.months){
                    //     finalPrice = service.discount.price
                    // }

                    //accountedService.discount = service.discount;

                    this.cart.push(accountedService);
                });
            },
            deep: true
        },

        availableFreePackagesCount(newValue) {
            //console.log('pocet balikov v cene sa zmenil')
            this.areFreePackagesDepleted();
        }
    },

    computed: {
        ...mapGetters([
            "getStep",
            "getStepsState",
            "getProgram",
            "getSelectedProgram",
            "getSelectedAnnetaPackage",
            "getAvailablePrograms",
            "getAdditionalPackages",
            "getNextChildProgram",
            "getChildrenUnderProgram",
            "getDefaultPackages",
            "getDefaultPackagesUnderProgram",
            "getAdditionalPackagesUnderProgram",
            "getAllProgramsChannelCounts",
            "getHdProgramsChannelCounts",
            "get4KProgramsChannelCounts",
            "getProgramsLowestPackagePrice"
        ]),

        currentProgram() {
            if (this.getSelectedAnnetaPackage) {
                const currentProgram = this.getProgram(
                    this.getSelectedAnnetaPackage
                );

                // Zistime, ci ma program spustenu promo akciu, ak ano tak prehodime premennu promotion na true (premenna ovlada zobrazenie zlavovych upozorneni)
                // ak program promo nema, prehodime na false
                if (currentProgram.promo != null) {
                    this.promotion = true;
                } else this.promotion = false;

                return currentProgram;
            } else return false;
        },

        //Obsah buttonu dalsi krok
        nextBtnContent() {
            if (this.stepFilledCorrectly) return "Ďalší krok";
            else return "Prosím vyberte si balík";
        },

        // tu zhromazdujeme vsetky podmienky, ktore musia byt
        // splnene na to, aby formular mohol pokracovat dalej
        stepFilledCorrectly() {
            return (
                // Bool
                this.service.length, this.isDefaultInSelection, this.success
            );
        },

        //Nastavenia, ktore sa maju ulozit do Vuex
        currentStepStateToSave() {
            return {
                step: "vyberBalikov",
                state: {
                    service: this.service,
                    cart: this.cart,
                    months: this.months,
                    freePackages: this.freePackages,
                    semiFreePackages: this.semiFreePackages,
                    bindedMonths: this.months
                }
            };
        },

        //Kontroluje ci je zaskrnuty hlavny balik pre sluzbu
        isDefaultInSelection() {
            const defaultPackage = this.getDefaultPackagesUnderProgram(
                this.getSelectedAnnetaPackage
            );
            return this.service.includes(defaultPackage[0]);
        },

        //Definuje zakladny pocet doplnkovych balikov v cene
        defaultFreePackagesCount() {
            if (this.getSelectedAnnetaPackage) {
                const selectedProgram = this.getProgram(
                    this.getSelectedAnnetaPackage
                );
                let freeAdditionalPackagesCount =
                    selectedProgram.additional_packages;

                return freeAdditionalPackagesCount;
            }
        },

        //Kontroluje pocet zostavajucich balikov v cene
        availableFreePackagesCount() {
            if (this.getSelectedAnnetaPackage) {
                let finalCount =
                    this.defaultFreePackagesCount - this.freePackages.length;
                return finalCount;
            }
        }
    },

    methods: {
        ...mapActions(["setSelectedAnnetaPackage"]),

        // Skontroluje ci sa tento konkretny balik nachadza v array free packages a teda je v cene hlavneho baliku
        isFreePackage(packageId) {
            if (packageId) {
                return this.freePackages.some(arr => arr.id == packageId);
            }
        },
        fullPrice() {
            // console.log(this.freePackages)
            let result = (this.itemprice + this.extraItems) * this.months;
            result = result.toFixed(2);
            return result;
        },

        // Vrati cenu sluzby podla toho ci ma sluzba spustene promo
        evaluateServicePrice(serviceId) {
            const service = this.getProgram(serviceId);
            const packagePrice = this.getProgramsLowestPackagePrice(serviceId);

            if (service.promo) {
                return service.promo.price;
            } else {
                return packagePrice;
            }
        },

        // Filtracia benefitov
        filterBenefits(inputArr) {
            return inputArr.filter(arr => {
                return arr.show_in_card;
            });
        },

        //Sklonovanie slova doplnkove balicky podla poctu
        packagesDeclension(value) {
            if (value == 1) {
                return "doplnkový balík";
            }

            if (value > 1 && value < 5) {
                return "doplnkové balíky";
            }

            if (value > 5) {
                return "doplnkových balíkov";
            }
        },

        //Skontroluje, ci su baliky "v cene" vycerpane
        areFreePackagesDepleted() {
            // Iba ak je predulozeny balicek
            if (this.getSelectedAnnetaPackage) {
                // tu musi byt nextTick, pretoze ak tam nieje tak sa hodnoty updatnu prilis rychlo a potom sa konkretny balik oznaci naraz ako free a semi-free (ak je spustena promo akcia)
                this.$nextTick(() => {
                    if (this.availableFreePackagesCount == 0) {
                        this.freePackagesDepleted = true;
                    } else this.freePackagesDepleted = false;
                });
            }
        },

        // Skontroluje ci sa tento konkretny balik nachadza v array semiFreePackages a teda je v cene nad ramec hlavneho baliku
        //zatial je to vypnute
        isSemiFreePackage(packageId) {
            return false;
            if (packageId) {
                return this.semiFreePackages.some(arr => arr.id == packageId);
            }
        },

        //Funkcia, ktora resetuje vyber v kosiku, sluzbach a balikoch v cene
        //Spusta sa pri zmene sub-sluzby tzn. prepnutie Anneta S na M
        setPackagesForProgram(programID) {
            if (programID != this.getSelectedAnnetaPackage) {
                //1. Najprv vysypeme vsetko co mame ulozene v service[]
                this.service = [];

                //2. nasledne vsetko co je ulozene v balikoch zadarmo
                this.freePackages = [];
                this.semiFreePackages = [];

                //3. Nasledne nastavime novu "sub-sluzbu"
                this.setSelectedAnnetaPackage(programID);
            }
        },

        //Funkcia ktora pridava a ubera sluzby do array services
        //ustredny array kam sa zberaju vsetky objednane sluzby bez ohladu na ich status (v cene, v cene na obmedzeny cas, bezne pridany balik)
        checkInServices(evt) {
            //Existuje v services ID prave zaskrtnutej hodnoty ? true/false
            const doesArrayContainValue = this.service.some(
                arr => arr.id == evt.value.id
            );

            // Ponechame zaskrtnute v pripade, ze sa ID nachadza v services
            if (evt.checked == true) {
                if (!doesArrayContainValue) {
                    this.service.push(evt.value);
                }
            }

            // Pokial je input odskrtnuty, tak prehladame array, najdeme ho podla id a vyhodime ho, resp vratime array bez neho
            if (evt.checked == false) {
                if (doesArrayContainValue) {
                    this.service = this.service.filter(arr => {
                        return evt.value.id != arr.id;
                    });
                }
            }
        },
        setSumPrice(event) {
            const doesArrayContainValue = this.freePackages.some(
                arr => arr.id == event.value.id
            );
            if (!doesArrayContainValue) {
                if (event.checked) {
                    this.extraItems += event.value.price;
                } else {
                    this.extraItems -= event.value.price;
                }
                if (this.extraItems < 0) {
                    this.extraItems = 0;
                }
            }
        },
        //Funkcia ktora pridava a ubera sluzby z arrayu freePackages
        //ustredny array kde sa zberaju baliky, ktore su v cene
        checkIfThisisFreePackage(evt) {
            //Existuje v services ID prave zaskrtnutej hodnoty ? true/false
            const doesArrayContainValue = this.freePackages.some(
                arr => arr.id == evt.value.id
            );

            // Ponechame zaskrtnute v pripade, ze sa ID nachadza v freePackages
            // a zaroven ak su k dispozicii este volne balicky (vypocitava sa cez computed)
            if (evt.checked == true && this.availableFreePackagesCount) {
                if (!doesArrayContainValue) {
                    this.freePackages.push(evt.value);
                }
            }

            // Pokial je input odskrtnuty, tak prehladame array, najdeme ho podla id a vyhodime ho, resp vratime array bez neho
            if (evt.checked == false) {
                if (doesArrayContainValue) {
                    this.freePackages = this.freePackages.filter(arr => {
                        return evt.value.id != arr.id;
                    });
                }
            }
        },
        addMonths() {
            this.months = this.months + 1;
            this.changePriceIfNeeded();
        },
        removeMonths() {
            if (this.months > 1) {
                this.months = this.months - 1;
            }
            this.changePriceIfNeeded();
        },
        changePriceIfNeeded() {
            for (let i = 0; i < this.cart.length; i++) {
                this.cart[i].months = this.months;
            }
        }
    },

    created() {
        this.childServices = this.getChildrenUnderProgram(2);
        this.selectedCity = JSON.parse(
            window.localStorage.getItem("selectedCity")
        );
        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        //musi byt v "Created" lebo v "mounted" ho prepisu ine data
        const stepData = this.getStep(this.stepName);
        if (stepData) {
            // console.log(stepData)
            this.service = stepData.service;
            this.freePackages = stepData.freePackages;
            this.semiFreePackages = stepData.semiFreePackages;
            this.recoveringFromState = true;
        }

        // Ak je v localStorage definovany vybraty anneta balicek, tak zobraz rovno vyber programovych balikov
        if (this.getSelectedAnnetaPackage) {
            this.recoveringFromState = true;
            this.itemprice = this.getProgram(
                this.getSelectedAnnetaPackage
            ).price;
        }
    },

    mounted() {
        // Funkcia ktora zbehne zakazdym, ked sa komponent nacita
        // je to tu pre pripad, ze ma sub sluzba 0 balikov v cene a zaroven bezi promo akcia
        this.areFreePackagesDepleted();
        this.selectedCity = JSON.parse(
            window.localStorage.getItem("selectedCity")
        );
    }
};
</script>

<style lang="scss" scoped>
.container {
    //max-width: 102rem;
}

.child-services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    //grid-auto-rows: 300px;

    margin-bottom: 3rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    .offer__item {
        overflow: visible;
        position: relative;
        padding: 4rem 1.5rem 3rem;
        margin: 0;
        border-color: #ccc;
        max-width: 100%;

        .tag {
            position: absolute;
            top: 1rem;
            left: 0rem;
            margin: auto;
            text-align: center;

            background: #0ea879;
            padding: 0.5em 1.5em;
            transform: translateX(-0.5rem);

            border-radius: 0.25rem;

            display: block;
            color: white;
        }

        &.active {
            border-color: #26b8eb;
            //pointer-events: none;
        }

        small {
            color: black;
        }
    }
}
.user-messages {
    //max-width: 100rem;
    padding: 0 1rem;
    margin: 0 auto;

    @media (max-width: 768px) {
        padding: 0;
    }
}

.user-message {
    background: #effff6;

    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 0.25rem;

    display: flex;
    align-items: center;

    text-align: left;

    .icon {
        margin-right: 1rem;
    }
}

.offer__wrapper {
    display: flex;
    // grid-gap: 2rem;
    // grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));

    .package {
        flex: 1 0 100%;
        max-width: 25rem;
        display: flex;

        &.main-package {
            max-width: none;
        }
    }

    .offer__item {
        margin: 1rem;
        width: 100%;

        small {
            color: black;
        }

        @media (max-width: 768px) {
            margin: 1rem 0;
        }
    }
}

.service-includes {
    &__headline {
        color: #15ad96;
        margin-bottom: 0.5rem;
    }

    &__services {
        //max-width: 100rem;
        margin: 0 auto;
        border: 2px solid #cccccc;
        border-radius: 0.25rem;

        padding: 0.5rem 2rem;

        margin-bottom: 3rem;

        .service {
            display: flex;
            align-items: center;
            margin: 1.5rem 0;

            .icon {
            }

            .content {
                margin: 0 1rem;
            }

            .price {
                margin-left: auto;
                font-weight: bold;

                margin-right: 1rem;
                white-space: nowrap;
            }
        }
    }
}
.cart_div {
    border: 2px solid #f2f2f2;
    border-radius: 3px;
    .cart_header {
        color: white;
        background: #212121;
        font-size: 24px;
        font-weight: bold;
    }
    .cart_comment {
        color: #4e555b;
    }
    .cart_item_price {
        font-size: 24px;
    }
    .cart-button {
        width: 30px;
        height: 30px;
    }
}
</style>
