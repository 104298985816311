<template>
    <div class="card">
        <div class="card-header card-header--primary">
            <h2 class="h4 card-header__title">Súhrn objednávky</h2>
        </div>
        <div class="card-item" v-for="item in cart">
            <div class="card-item-inner">
                <div class="card-item-inner__content">
                    <h3 class="h6">{{ item.title }}</h3>
                    <p>
                        {{ months }}
                        {{ months === 1 ? "mesiac" : "" }}
                        {{ months > 1 && months < 5 ? "mesiace" : "" }}
                        {{ months >= 5 ? "mesiacov" : "" }}
                        predplateného balíka
                    </p>
                </div>
                <div class="card-item-inner__price">
                    <span>{{ item.price.toFixed(2) }}€</span>
                </div>
            </div>
        </div>
        <div class="card-item" v-for="item in devices.cart">
            <div class="card-item-inner">
                <div class="card-item-inner__content">
                    <h3 class="h6">{{ item.title }}</h3>
                </div>
                <div class="card-item-inner__price">
                    <span>{{ item.price.toFixed(2) }}€</span>
                </div>
            </div>
        </div>
        <!--        <div class="card-item" v-for="item in addItem" v-if="itemNotInCart(item)">-->
        <!--            <div class="card-item-inner" >-->
        <!--                <div class="card-item-inner__content">-->
        <!--                    <h3 class="h6">{{ item.title }}</h3>-->
        <!--                </div>-->
        <!--                <div class="card-item-inner__price">-->
        <!--                    <span>{{ item.prices[1].regular }}€</span>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="card-item">
            <div class="card-item-inner">
                <div class="card-item-inner__content">
                    <h3 class="h6">Spolu:</h3>
                </div>
                <div class="card-item-inner__price">
                    <span class="total">{{ fullPrice }}€</span>
                    <span>s DPH</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import packageCard from "../../components/vue/ponuka/package-card__anneta.vue";
import jkbCollapse from "../../components/vue/collapse/collapse";
import conditions from "../../components/vue/conditions/conditions.vue";
import { mapActions, mapGetters } from "vuex";

//Mixins
import formStep from "../../mixins/formStep";

export default {
    //formStep - obsahuje spolocnu funkcnost ktora plati pre vsetky kroky objednavkoveho formulara ako pre Annetu tak aj pre Antenu
    mixins: [formStep],
    props: {
        addItem: {
            type: Object | Array,
            required: false,
            // default: []
        },
        step: {
            type: Number,
        }
    },
    data() {
        return {
            childServices: [], //Array kde sa ulozia vsetky sub-sluzby hlavnej sluzby (anneta S, M, L), pozor nie baliky !
            pckgsShowing: false, //premmenna ktora oznacuje, ci je otvorena sekcia s vyberom balikov
            recoveringFromState: false, //premenna ktora oznacuje, ci ma tento krok data nacitane z Vuex teda z pamati, alebo ide o nove nacitanie ... rozhoduje ktora cast formularu sa zobrazi pri nacitani komponentu
            cart: [], // kosik tohoto kroku, v sumarizacii sa z tohoto kosika vypocitava finalna cena
            service: [], //array vsetkych "objednanych" balikov
            freePackages: [], //array balikov, ktore su v cene
            freePackagesDepleted: false, // pomocna premenna ktora sa nastavuje s miernym opozdenim aby sa zamedzilo oznaceniu balika ako free a semi-free zaroven // IBA POCAS PROMOCNEJ KAMPANE
            semiFreePackages: [], //array balikov, ktore su nad ramec ponuky // IBA POCAS PROMOCNEJ KAMPANE
            itemprice: 0,
            devices: [],
            months: 1,
            products: [],
        };
    },

    components: {
        jkbCollapse,
        packageCard,
        conditions
    },

    watch: {
        //Sledujeme polozku  services, a podla nej pridame polozky do arrayu cart z ktore sa na konci vypocita vysledna cena
        service: {
            handler(value) {
                this.cart = [];

                value.forEach(service => {
                    // console.log(service);

                    // DOPLNKOVE BALIKY + HLAVNY BALIK
                    // ak sa balik nachadza v doplnkovych balikoch nadradeneho programu,
                    // zahrn ich do kosika - obchadza to bug v-modelu
                    // kedy ostanu zaskrtnute aj balicky, ktore sa uz v DOM-e nemaju ukazovat
                    // alebo ak ma balik sub-baliky -> je to hlavny balik/program

                    let desc =
                        service.additional == 1
                            ? "doplnkový balík"
                            : "základný balík";
                    let finalPrice = service.price;

                    // console.log(service);
                    // Objekt do ktoreho budeme vkladat parametre, ktore sa budu v poslednom kroku zuctovavat
                    let accountedService = {};

                    accountedService.title = service.name;
                    // accountedService.price = parseFloat(
                    //     parseFloat(service.price) * this.months
                    // ).toFixed(2);

                    // AKCIA: 3,6,12 zlava
                    if (parseInt(this.months) === 3 && service.additional !== 1)
                    {
                        // 5% zlava
                        accountedService.price = 6.65 * this.months;
                    }
                    else if (parseInt(this.months) === 6 && service.additional !== 1)
                    {
                        // 7% zlava
                        accountedService.price = 6.49 * this.months;
                    }
                    else if (parseInt(this.months) === 12 && service.additional !== 1)
                    {
                        // 10% zlava
                        accountedService.price = 6.29 * this.months;
                    }


                    //  AKCIA: 12ty mesiac zadarmo
                    // if (parseInt(this.months) === 12 && service.additional !== 1)
                    // {
                    //     accountedService.price =
                    //         parseFloat(service.price).toFixed(2) * 11;
                    // }
                    //  AKCIA: 6ty mesiac
                    // else if (parseInt(this.months) === 6 && service.additional !== 1)
                    // {
                    //     accountedService.price =
                    //         parseFloat(parseFloat(service.price) - 0.5).toFixed(2) * 6;
                    // }
                    else {
                        accountedService.price =
                            parseFloat(service.price).toFixed(2) * this.months;
                    }


                    // if (this.months < 12 || service.additional == 1) {
                    //     accountedService.price =
                    //         parseFloat(service.price).toFixed(2) * this.months;
                    //         // parseFloat(parseFloat(service.price) - 1).toFixed(2) * this.months;
                    // } else {
                    //     accountedService.price =
                    //         // parseFloat(parseFloat(service.price) - 1).toFixed(2) * 11;
                    //         parseFloat(service.price).toFixed(2) * 11;
                    // }

                    // if (service.name === "Plustelka Premium") {
                    //     if (this.months >= 12) {
                    //         accountedService.price = parseFloat(service.price).toFixed(2) * (this.months * 0.5);
                    //     } else {
                    //         accountedService.price = parseFloat(service.price).toFixed(2) * this.months;
                    //     }
                    // }

                    // if(this.isFreePackage(service.name)) {
                    //     accountedService.price = 0.0;
                    // }

                    accountedService.payment = "PM";
                    accountedService.description = desc;
                    accountedService.months = this.$store.state.ponuka.stepState.vyberBalikov.bindedMonths;
                    accountedService.promo = service.promo;
                    // console.log(accountedService);
                    this.cart.push(accountedService);
                    // console.log(this.months);
                    // console.log(service);
                    // let id = service.name;
                    // this.products.push(
                    //     {
                    //         'name': service.name,
                    //         'id': ''+this.getHash( service.name),
                    //         'price': service.price,
                    //         'quantity': this.months
                    //     }
                    // );

                });

            },
            deep: true
        },
        addItem: {
            handler(value) {
                this.childServices = this.getChildrenUnderProgram(2);

                //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
                //musi byt v "Created" lebo v "mounted" ho prepisu ine data
                const stepData = this.getStep("vyberBalikov");
                // console.log(stepData);
                if (stepData) {
                    // console.log(stepData);
                    this.service = stepData.service;
                    this.freePackages = stepData.freePackages;
                }
                this.months = this.$store.state.ponuka.stepState.vyberBalikov.bindedMonths;
                const devices = this.getStep("vyberZariadenia");
                if (devices) {
                    this.devices = devices;
                }
            },
            deep: true
        }
    },

    computed: {
        ...mapGetters([
            "getStep",
            "getStepsState",
            "getProgram",
            "getSelectedProgram",
            "getSelectedAnnetaPackage",
            "getAvailablePrograms",
            "getAdditionalPackages",
            "getNextChildProgram",
            "getChildrenUnderProgram",
            "getDefaultPackages",
            "getAdditionalPackagesUnderProgram",
            "getAllProgramsChannelCounts",
            "getHdProgramsChannelCounts",
            "get4KProgramsChannelCounts",
            "getProgramsLowestPackagePrice"
        ]),

        fullPrice() {
            let result = 0;
            for (let i = 0; i < this.cart.length; i++) {
                result += parseFloat(this.cart[i].price);
            }

            if (this.devices && this.devices.cart) {
                for (let i = 0; i < this.devices.cart.length; i++) {
                    result += parseFloat(this.devices.cart[i].price);
                }
            }

            return parseFloat(result).toFixed(2);
        },

        // tu zhromazdujeme vsetky podmienky, ktore musia byt
        // splnene na to, aby formular mohol pokracovat dalej
        stepFilledCorrectly() {
            return (
                // Bool
                this.service.length, this.isDefaultInSelection
            );
        }
    },

    methods: {
        getHash(input){
            let hash = 0, len = input.length;
            for (let i = 0; i < len; i++) {
                hash  = ((hash << 5) - hash) + input.charCodeAt(i);
                hash |= 0; // to 32bit integer
            }
            return hash;
        },
        //Funkcia ktora pridava a ubera sluzby do array services
        //ustredny array kam sa zberaju vsetky objednane sluzby bez ohladu na ich status (v cene, v cene na obmedzeny cas, bezne pridany balik)
        checkInServices(evt) {
            //Existuje v services ID prave zaskrtnutej hodnoty ? true/false
            const doesArrayContainValue = this.service.some(
                arr => arr.id == evt.value.id
            );

            // Ponechame zaskrtnute v pripade, ze sa ID nachadza v services
            if (evt.checked == true) {
                if (!doesArrayContainValue) {
                    this.service.push(evt.value);
                }
            }

            // Pokial je input odskrtnuty, tak prehladame array, najdeme ho podla id a vyhodime ho, resp vratime array bez neho
            if (evt.checked == false) {
                if (doesArrayContainValue) {
                    this.service = this.service.filter(arr => {
                        return evt.value.id != arr.id;
                    });
                }
            }
        },

        calculatePrice(price) {
            let result = 0;
            if (this.months < 12) {
                result = parseFloat(price) * this.months;
            } else {
                result = parseFloat(price) * 11;
            }
            result = result.toFixed(2);
            return result;
        },
        itemNotInCart(item) {
            let result = this.cart.filter(
                element => element.title === item.title
            );
            return result.length === 0;
        }
    },

    created() {
        this.childServices = this.getChildrenUnderProgram(2);

        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        //musi byt v "Created" lebo v "mounted" ho prepisu ine data
        const stepData = this.getStep("vyberBalikov");
        // console.log(stepData);

        this.months = this.$store.state.ponuka.stepState.vyberBalikov.bindedMonths;
        if (stepData) {
            // console.log(stepData);
            this.service = stepData.service;
            this.freePackages = stepData.freePackages;
            for (let i = 0; i < this.service.length; i++) {
                this.products.push(
                    {
                        'name': this.service[i].name,
                        'id': ''+this.getHash( this.service[i].name),
                        'price': this.service[i].price,
                        'quantity': this.months
                    }
                );
            }
        }
        const devices = this.getStep("vyberZariadenia");
        if (devices) {
            this.devices = devices;
            for(let i = 0; i < devices.cart.length; i++) {
                this.products.push(
                    {
                        'name': devices.cart[i].title,
                        'id': ''+this.getHash( devices.cart[i].title),
                        'price': devices.cart[i].price,
                        'quantity': 1
                    }
                );
            }
        }
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'actionField': { 'step':this.step},
                'checkout': {
                    'products': this.products,
                }
            },
        });
    },

    mounted() {}
};
</script>

<style lang="scss" scoped>
.container {
    //max-width: 102rem;
}

.child-services {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    //grid-auto-rows: 300px;

    margin-bottom: 3rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    .offer__item {
        overflow: visible;
        position: relative;
        padding: 4rem 1.5rem 3rem;
        margin: 0;
        border-color: #ccc;
        max-width: 100%;

        .tag {
            position: absolute;
            top: 1rem;
            left: 0rem;
            margin: auto;
            text-align: center;

            background: #0ea879;
            padding: 0.5em 1.5em;
            transform: translateX(-0.5rem);

            border-radius: 0.25rem;

            display: block;
            color: white;
        }

        &.active {
            border-color: #26b8eb;
            //pointer-events: none;
        }

        small {
            color: black;
        }
    }
}
.user-messages {
    //max-width: 100rem;
    padding: 0 1rem;
    margin: 0 auto;

    @media (max-width: 768px) {
        padding: 0;
    }
}

.user-message {
    background: #effff6;

    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 0.25rem;

    display: flex;
    align-items: center;

    text-align: left;

    .icon {
        margin-right: 1rem;
    }
}

.offer__wrapper {
    display: flex;
    // grid-gap: 2rem;
    // grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));

    .package {
        flex: 1 0 100%;
        max-width: 25rem;
        display: flex;

        &.main-package {
            max-width: none;
        }
    }

    .offer__item {
        margin: 1rem;
        width: 100%;

        small {
            color: black;
        }

        @media (max-width: 768px) {
            margin: 1rem 0;
        }
    }
}

.service-includes {
    &__headline {
        color: #15ad96;
        margin-bottom: 0.5rem;
    }

    &__services {
        //max-width: 100rem;
        margin: 0 auto;
        border: 2px solid #cccccc;
        border-radius: 0.25rem;

        padding: 0.5rem 2rem;

        margin-bottom: 3rem;

        .service {
            display: flex;
            align-items: center;
            margin: 1.5rem 0;

            .icon {
            }

            .content {
                margin: 0 1rem;
            }

            .price {
                margin-left: auto;
                font-weight: bold;

                margin-right: 1rem;
                white-space: nowrap;
            }
        }
    }
}
.cart_div {
    border: 2px solid #f2f2f2;
    border-radius: 3px;
    .cart_header {
        color: white;
        background: #212121;
        font-size: 24px;
        font-weight: bold;
    }
    .cart_comment {
        color: #4e555b;
    }
    .cart_item_price {
        font-size: 24px;
    }
    .cart-button {
        width: 30px;
        height: 30px;
    }
}
</style>
