import Vue from "vue";
import axios from "axios";
import flatten from "lodash/flatten";
import _findindex from "lodash/findIndex";
import _orderBy from "lodash/orderby";

const state = {
    //API CONTAINERS
    //Sem sa stiahne array programov a v nich ich balicky, zariadenia a benefity
    programs: [],
    // tu sa stiahnu vsetky mesta
    cities: [],

    //Sem sa ulozia vsetky stavy jednotlivych krokov v nakupnom
    stepState: {},

    // Toto zbiera udaje o spravnom vyplneni jednotlivych krokoch
    validSteps: {
        overenieDostupnosti: true,
        vysledokDostupnosti: true,
        vyberBalikov: true,
        vyberZariadenia: true,
        vyberAnteny: true,
        osobneUdaje: true
        //seventhStep: false,
    },

    allStepsValid: false,

    // Sem sa ulozi vybrane mesto, ktore si navstevnik vyplni pri overeni dostupnosti.
    selectedCity: {},

    // Sem sa ulozi vybrane id sub-baliku annety, ktory sa bude prejavovat na podstranke objednavky
    selectedAnnetaPackage: false
};

//  ######   ######## ######## ######## ######## ########   ######
// ##    ##  ##          ##       ##    ##       ##     ## ##    ##
// ##        ##          ##       ##    ##       ##     ## ##
// ##   #### ######      ##       ##    ######   ########   ######
// ##    ##  ##          ##       ##    ##       ##   ##         ##
// ##    ##  ##          ##       ##    ##       ##    ##  ##    ##
//  ######   ########    ##       ##    ######## ##     ##  ######
const getters = {
    getStore: state => state.storeVar,
    //Zisti, ci existuje zapisany stav pozadovaneho kroku
    getStep: state => stepName => {
        //console.log(state.stepState[stepName]);
        return state.stepState[stepName];
    },
    getStepsState: state => state.stepState,
    getPrograms: state => state.programs,
    getAvailablePrograms: (state, getters) => {
        //Vyfiltruj ktore vyhovuju filtracii vyssie
        const allPrograms = getters.getPrograms;

        //console.log(state);
        let availablePrograms = [];

        // Rozhodni ktore programy budu dostupne, pre danu obec na zaklade kriterii MUX1 a MUX4
        if (state.selectedCity.mux_1 > 50 && state.selectedCity.mux_4 > 50) {
            availablePrograms = [1, 2];
        } else if (
            (state.selectedCity.mux_1 > 30 && state.selectedCity.mux_1 < 49) ||
            (state.selectedCity.mux_4 > 30 && state.selectedCity.mux_4 < 49)
        ) {
            availablePrograms = [2];
        } else if (
            state.selectedCity.mux_1 < 30 ||
            state.selectedCity.mux_4 < 30
        ) {
            availablePrograms = [2];
        }

        //Ak nieje zadane ziadne mesto oznac obe sluzby aby sa tam aspon nieco ukazalo
        //zobrazenie musi byt filtrovane cez dalsie podmienky
        if (!Object.values(state.selectedCity).length) {
            availablePrograms = [1, 2];
        }

        const filteredPrograms = allPrograms.filter(arr => {
            return availablePrograms.includes(arr.id);
        });

        //Vytiahnutie pod balikov
        let programsChildren = allPrograms.map(arr => {
            if (arr.childs.length && arr.parent_name == null) {
                return arr.childs;
            }
        });

        //Odstranenie undefined
        programsChildren = programsChildren.filter(item => item);

        let finalAvailablePrograms = filteredPrograms.concat(
            flatten(programsChildren)
        );
        //console.log(finalAvailablePrograms);

        return finalAvailablePrograms;
    },

    isProgramAvailable: (state, getters) => programId => {
        const availablePrograms = getters.getAvailablePrograms;
        //console.log(availablePrograms);

        return availablePrograms.some(arr => {
            //console.log(arr.id)
            return arr.id == programId;
        });
    },

    getChildrenUnderProgram: (state, getters) => programId => {
        //Ak existuje vybrany program
        if (getters.getProgram(programId)) {
            const mainProgram = getters.getProgram(programId);

            //console.log(mainProgram);

            if (mainProgram.childs.length) {
                return mainProgram.childs;
            }
        } else return null;
    },

    getSelectedProgram: state => {
        if (state.stepState.vysledokDostupnosti) {
            const selectedProgram =
                state.stepState.vysledokDostupnosti.selectedProgram;

            return selectedProgram;
        } else return false;
    },

    getDefaultPackages: (state, getters) => {
        //console.log(getters.getSelectedProgram);
        //Ak existuje vybrany program
        if (getters.getSelectedProgram) {
            const selectedProgram = getters.getSelectedProgram;
            //Najdi iba zakladne baliky
            const onlyDefaultPackages = selectedProgram.packages.filter(arr => {
                return arr.additional == 0;
            });
            return onlyDefaultPackages;
        } else return false;
    },

    getAllPackagesUnderProgram: (state, getters) => programId => {
        //Ak existuje vybrany program
        if (getters.getProgram(programId)) {
            // Vytiahnem si aj zakladne baliky aj doplnkove
            // je to kvoli tomu, ze sub-sluzby nemaju v API doplnkove baliky
            // preto ich musime pomocou funkcie getAdditionalPackagesUnderProgram na nu prilepit
            const desiredProgram = getters.getProgram(programId);
            const defaultPackagesUnderProgram = getters.getDefaultPackagesUnderProgram(
                programId
            );
            let additionalPackagesUnderProgram = getters.getAdditionalPackagesUnderProgram(
                programId
            );

            // ANNETA
            // ak ma program additional_packages ( plati hlavne pre Annetu )
            // tak sa pozri kolko ich je a rataj iba tolko doplnkovych balikov
            // if(desiredProgram && desiredProgram.additional_packages != null){
            //     // Vezmeme si pocet doplnkovych balikov v cene
            //     const additionalPackages = desiredProgram.additional_packages;

            //     // Zoradime programove baliky od najpocetnejsich po tie najmenej pocetne
            //     let orderedPackagesByChannelCount = additionalPackagesUnderProgram.sort((a, b) => {
            //         return b.channels.length - a.channels.length;
            //     })

            //     // Vyrezeme iba pocet balikov, ktore chceme ratat - odvija sa od poctu balikov v cene
            //     const sliceByAdditionalPackagesCount = orderedPackagesByChannelCount.slice(0, additionalPackages);

            //     // Prepiseme array doplnkovych balickov, ktore sa maju ratat podla
            //     additionalPackagesUnderProgram = sliceByAdditionalPackagesCount;
            // }

            // Spojime doplnkove a hlavne dokopy
            const allPackagesUnderProgram = defaultPackagesUnderProgram.concat(
                additionalPackagesUnderProgram
            );
            //Vratime vsetky
            return allPackagesUnderProgram;
        }
    },

    getDefaultPackagesUnderProgram: (state, getters) => programId => {
        //Ak existuje vybrany program
        if (getters.getProgram(programId)) {
            const selectedProgram = getters.getProgram(programId);
            //Najdi iba zakladne baliky
            const onlyAdditionalPackages = selectedProgram.packages.filter(
                arr => {
                    return arr.additional == 0;
                }
            );

            return onlyAdditionalPackages;
        } else return false;
    },

    getAdditionalPackages: (state, getters) => {
        //Ak existuje vybrany program
        if (getters.getSelectedProgram) {
            const selectedProgram = getters.getSelectedProgram;
            //Najdi iba zakladne baliky
            const onlyAdditionalPackages = selectedProgram.packages.filter(
                arr => {
                    return arr.additional == 1;
                }
            );

            return onlyAdditionalPackages;
        } else return false;
    },

    getAdditionalPackagesUnderProgram: (state, getters) => programId => {
        //Ak existuje vybrany program
        if (getters.getProgram(programId)) {
            const selectedProgram = getters.getProgram(programId);
            //Najdi iba zakladne baliky
            const onlyAdditionalPackages = selectedProgram.packages.filter(
                arr => {
                    return arr.additional == 1;
                }
            );

            //console.log(onlyAdditionalPackages);
            //console.log();

            // Ak ma sluzba rodica, teda sa jedna o sub sluzbu
            // tak vyber z jej rodica vsetky pridavne baliky
            // kedze v adminovi nejde priradzovat sub sluzbe doplnkove baliky
            if (
                selectedProgram.parent_name != null &&
                !selectedProgram.childs.length
            ) {
                const parentName = selectedProgram.parent_name;
                // Najdi kto je jeho roidc
                const parent = getters.getProgramByName(parentName);
                //Vezmi z neho vsetky pridavne baliky
                const additionalPackagesUnderParent = getters.getAdditionalPackagesUnderProgram(
                    parent.id
                );

                //Pripoj ich do objektu vsetkych balikov
                additionalPackagesUnderParent.forEach(el => {
                    onlyAdditionalPackages.push(el);
                });
            }

            return onlyAdditionalPackages;
        } else return false;
    },

    getDevicesUnderProgram: (state, getters) => programId => {
        //Ak existuje vybrany program
        if (getters.getProgram(programId)) {
            const selectedProgram = getters.getProgram(programId);
            //Najdi iba zakladne baliky
            // const onlyDevices = selectedProgram.devices.filter(arr=>{
            //     return arr.additional == 1;
            // })

            const onlyDevices = selectedProgram.devices;

            // Ak ma sluzba rodica, teda sa jedna o sub sluzbu
            // tak vyber z jej rodica vsetky zariadenia
            // kedze v adminovi nejde priradzovat sub sluzbe doplnkove baliky
            if (
                selectedProgram.parent_name != null &&
                !selectedProgram.childs.length
            ) {
                const parentName = selectedProgram.parent_name;
                // Najdi kto je jeho roidc
                const parent = getters.getProgramByName(parentName);
                //Vezmi z neho vsetky pridavne baliky
                const parentDevices = parent.devices;

                //Pripoj ich do objektu vsetkych balikov
                parentDevices.forEach(el => {
                    if (!onlyDevices.some(arr => arr.id == el.id)) {
                        onlyDevices.push(el);
                    }
                });

                // Prilepime selectedPrice ktory vyberie hodnotu podla nadradeneho programu
                onlyDevices.forEach(el => {
                    el.selectedPrice = el.prices.filter(price => {
                        return price.program == selectedProgram.parent_name;
                    })[0];
                });
            }
            //Ak sluzba nema rodica, a teda ide o hlavnu sluzbu
            else {
                // Prilepime selectedPrice ktory vyberie hodnotu podla nadradeneho programu
                onlyDevices.forEach(el => {
                    el.selectedPrice = el.prices.filter(price => {
                        return price.program == selectedProgram.name;
                    })[0];
                });
            }

            return onlyDevices;
        } else return false;
    },

    getAntennasUnderProgram: (state, getters) => programId => {
        //Ak existuje vybrany program
        if (getters.getProgram(programId)) {
            const selectedProgram = getters.getProgram(programId);
            //Najdi iba zakladne baliky
            // const onlyDevices = selectedProgram.devices.filter(arr=>{
            //     return arr.additional == 1;
            // })

            const onlyDevices = selectedProgram.antennas;

            // Ak ma sluzba rodica, teda sa jedna o sub sluzbu
            // tak vyber z jej rodica vsetky zariadenia
            // kedze v adminovi nejde priradzovat sub sluzbe doplnkove baliky
            if (
                selectedProgram.parent_name != null &&
                !selectedProgram.childs.length
            ) {
                const parentName = selectedProgram.parent_name;
                // Najdi kto je jeho roidc
                const parent = getters.getProgramByName(parentName);
                //Vezmi z neho vsetky pridavne baliky
                const parentDevices = parent.antennas;

                //Pripoj ich do objektu vsetkych balikov
                parentDevices.forEach(el => {
                    if (!onlyDevices.some(arr => arr.id == el.id)) {
                        onlyDevices.push(el);
                    }
                });

                // Prilepime selectedPrice ktory vyberie hodnotu podla nadradeneho programu
                onlyDevices.forEach(el => {
                    el.selectedPrice = el.prices.filter(price => {
                        return price.program == selectedProgram.parent_name;
                    })[0];
                });
            }
            //Ak sluzba nema rodica, a teda ide o hlavnu sluzbu
            else {
                // Prilepime selectedPrice ktory vyberie hodnotu podla nadradeneho programu
                onlyDevices.forEach(el => {
                    el.selectedPrice = el.prices.filter(price => {
                        return price.program == selectedProgram.name;
                    })[0];
                });
            }

            return onlyDevices;
        } else return false;
    },

    getDevicesPricesUnderSelectedProgram: state => () => {
        //console.log(state.stepState.vyberBalikov.service);

        if (state.stepState.vysledokDostupnosti.selectedProgram) {
            const selectedProgram =
                state.stepState.vysledokDostupnosti.selectedProgram.name;

            const devices =
                state.stepState.vysledokDostupnosti.selectedProgram.devices;
            devices.forEach(el => {
                el.selectedPrice = el.prices.filter(price => {
                    return price.program == selectedProgram;
                })[0];
            });

            return devices;
        } else return false;
    },
    getAntennasPricesUnderSelectedProgram: state => () => {
        //Vytiahneme info o zvolenom programe z kroku vyber balikov
        if (state.stepState.vysledokDostupnosti.selectedProgram) {
            const selectedProgram =
                state.stepState.vysledokDostupnosti.selectedProgram.name;

            const antennas =
                state.stepState.vysledokDostupnosti.selectedProgram.antennas;

            antennas.forEach(el => {
                el.selectedPrice = el.prices.filter(price => {
                    return price.program == selectedProgram;
                })[0];
            });

            return antennas;
        } else return false;
    },
    isLoadingData: state => state.programs.length,
    isLoadingCities: state => state.cities.length,

    getCities: state => state.cities,

    getProgram: state => programId => {
        const desiredProgram = state.programs.filter(ar => {
            return ar.id == programId;
        });
        // console.log('desiredProgram >>> ');
        // console.log(desiredProgram);
        return desiredProgram[0];
    },

    getProgramByName: state => programName => {
        const desiredProgram = state.programs.filter(ar => {
            return ar.name == programName;
        });

        return desiredProgram[0];
    },

    getAvailableProgram: (state, getters) => programId => {
        const desiredProgram = getters.getAvailablePrograms.filter(ar => {
            return ar.id == programId;
        });

        return desiredProgram[0];
    },

    // NAJDE Balik s najnizsou cenou - pre ukazanie "cena od"
    getProgramsLowestPackagePrice: (state, getters) => programId => {
        //* Najdeme program (ID)
        //* Najdeme balik sluzieb, ktory nieje doplnkovy
        //* Ak je ich nahodou viac ako jeden, pomocou reduce ich porovname a ziskame ten s najnizsou cenou
        //* RETURN (INT) cena

        const desiredProgram = getters.getProgram(programId);

        const basicPackages = desiredProgram.packages.filter(ar => {
            return ar.additional == 0;
        });

        if (basicPackages.length) {
            const packageWithLowestPrice = basicPackages.reduce(
                (prev, curr) => {
                    return parseFloat(prev.price) < parseFloat(curr.price)
                        ? prev
                        : curr;
                }
            );

            return packageWithLowestPrice.price;
        } else return "- - -";
    },

    getProgramsChildsPackageWithLowestPrice: (
        state,
        getters
    ) => parentProgramId => {
        // Najde najlacnejsi balik spadajuci pod sub sluzby hlavnej sluzby
        // Sluzi hlavne na vyhladavanie najlacnejsieho baliku sluzby annety

        //* Najdeme program (ID)
        //* Najdeme vsetky Childy
        //* Vytiahneme si ich package (hlavne)
        //* Porovname ich ceny a vratime najnizsiu
        //* Ak je ich nahodou viac ako jeden, pomocou reduce ich porovname a ziskame ten s najnizsou cenou
        //* RETURN (INT) cena

        const desiredProgram = getters.getProgram(parentProgramId);
        const allChildrenDefaultPackages = [];

        //Ak ma parent sluzba sub-sluzby
        if (desiredProgram.childs.length) {
            // Vytiahni vsetky jeho sub-sluzby a pre kazdu z nich vytiahni hlavne baliky
            // a vloz ich do premennej allChildrenDefaultPackages
            desiredProgram.childs.forEach(el => {
                const onlyDefaultPackages = el.packages.map(arr => {
                    if (arr.additional == 0) {
                        allChildrenDefaultPackages.push(arr);
                    }
                });
            });
        }

        //Ak su dake hodnoty v default packages
        if (allChildrenDefaultPackages.length) {
            // Porovnaj ich ceny a vrat ten package s najnizsou cenou
            const packageWithLowestPrice = allChildrenDefaultPackages.reduce(
                (prev, curr) => {
                    return parseFloat(prev.price) < parseFloat(curr.price)
                        ? prev
                        : curr;
                }
            );

            return packageWithLowestPrice;
        } else return "- - -";
    },

    getProgramsChildWithHighestPackagePrice: (
        state,
        getters
    ) => parentProgramId => {
        // Najde najdrahsi balik spadajuci pod sub sluzby hlavnej sluzby
        // Sluzi hlavne na vyhladavanie najdrahsieho baliku sluzby annety

        //* Najdeme program (ID)
        //* Najdeme vsetky Childy
        //* Vytiahneme si ich package (hlavne)
        //* Porovname ich ceny a vratime najnizsiu
        //* Ak je ich nahodou viac ako jeden, pomocou reduce ich porovname a ziskame ten s najnizsou cenou
        //* RETURN (INT) cena

        const desiredProgram = getters.getProgram(parentProgramId);
        const allChildrenDefaultPackages = [];

        //Ak ma parent sluzba sub-sluzby
        if (desiredProgram.childs.length) {
            // Vytiahni vsetky jeho sub-sluzby a pre kazdu z nich vytiahni hlavne baliky
            // a vloz ich do premennej allChildrenDefaultPackages
            desiredProgram.childs.forEach(el => {
                const onlyDefaultPackages = el.packages.map(arr => {
                    if (arr.additional == 0) {
                        allChildrenDefaultPackages.push(arr);
                    }
                });
            });
        }

        //Ak su dake hodnoty v default packages
        if (allChildrenDefaultPackages.length) {
            // Porovnaj ich ceny a vrat ten package s najvyssou cenou
            const packageWithHighestPrice = allChildrenDefaultPackages.reduce(
                (prev, curr) => {
                    return parseFloat(prev.price) > parseFloat(curr.price)
                        ? prev
                        : curr;
                }
            );

            const programChildWithLowestPackagePrice = desiredProgram.childs.filter(
                arr => {
                    return arr.packages.includes(packageWithHighestPrice);
                }
            );

            //console.log(programChildWithLowestPackagePrice)

            return programChildWithLowestPackagePrice[0];
            //return packageWithHighestPrice;
        } else return "- - -";
    },

    getNextChildProgram: (state, getters) => childId => {
        // podla Id sub-sluzby najde susediacu sluzbu
        // pouziva sa hlavne pri vyhodnocovani akcii sub sluzieb, kde nizsia sluzba prebera vyhody (volne baliky) tej vyssej

        if (getters.getProgram(childId)) {
            //Program s danym id existuje
            const desiredProgram = getters.getProgram(childId);
            //console.log('desiredProgram: ' + desiredProgram.name)

            //Najdi rodica podla mena (parameter "parent")
            const parentProgram = getters.getProgramByName(
                desiredProgram.parent_name
            );
            //console.log('parentProgram: ' + parentProgram.name)

            //Ak ma tento program priradene sub sluzby
            if (parentProgram.childs.length) {
                const parentProgramChildren = parentProgram.childs;
                //console.log('parentProgramChildren: ' + parentProgramChildren)

                //Zisti index pozadovaneho programu
                const levelOfDesiredProgram = parentProgramChildren.find(
                    arr => arr.id == desiredProgram.id
                ).level;
                //console.log('levelOfDesiredProgram: ' + levelOfDesiredProgram)

                //Ak sa pozadovany program v array nachadza
                if (levelOfDesiredProgram) {
                    //Prirataj +1 aby sme nasli next index
                    const nextLevel = levelOfDesiredProgram + 1;
                    //console.log('nextLevel: ' + nextLevel)

                    //Ak takyto element existuje
                    // console.log('nextChild:');
                    // console.log(parentProgramChildren.find( arr => arr.level == nextLevel));

                    if (
                        parentProgramChildren.find(
                            arr => arr.level == nextLevel
                        )
                    ) {
                        const nextChildProgram = parentProgramChildren.find(
                            arr => arr.level == nextLevel
                        );
                        return nextChildProgram;
                    } else return false;
                }
            }
        } else {
            //Neexsitujuca sluzba
            console.log("Neexistujuca sub-sluzba");
        }
    },

    // Podla id programu vypocita, kolko HD a kolko Vsetkych kanalov ma program
    getAllProgramsChannelCounts: (state, getters) => (
        programId,
        params = { includePublic: true }
    ) => {
        //* Najdeme program (ID)
        //* Zo vsetkych balikov programu extrahujeme vsetky kanaly
        //* Zredukujeme vsetky pripadne duplikaty
        //* Zratame vsetky programy a z nich vsetky HD programy
        //* RETURN (int) vsetkych kanalov

        const desiredProgram = getters.getProgram(programId);
        const desiredProgramDefaultPackages = getters.getDefaultPackagesUnderProgram(
            programId
        );
        let desiredProgramAdditionalPackages = getters.getAdditionalPackagesUnderProgram(
            programId
        );

        // ANNETA
        // ak ma program additional_packages ( plati hlavne pre Annetu )
        // tak sa pozri kolko ich je a rataj iba tolko doplnkovych balikov
        if (desiredProgram && desiredProgram.additional_packages != null) {
            // Vezmeme si pocet doplnkovych balikov v cene
            const additionalPackages = desiredProgram.additional_packages;

            // Zoradime programove baliky od najpocetnejsich po tie najmenej pocetne
            let orderedPackagesByChannelCount = desiredProgramAdditionalPackages.sort(
                (a, b) => {
                    return b.channels.length - a.channels.length;
                }
            );

            // Vyrezeme iba pocet balikov, ktore chceme ratat - odvija sa od poctu balikov v cene
            const sliceByAdditionalPackagesCount = orderedPackagesByChannelCount.slice(
                0,
                additionalPackages
            );

            // Prepiseme array doplnkovych balickov, ktore sa maju ratat podla
            desiredProgramAdditionalPackages = sliceByAdditionalPackagesCount;
        }

        if (desiredProgramDefaultPackages) {
            // S dodatocnymi balickami
            // const allPackages = desiredProgramDefaultPackages.concat(
            //     desiredProgramAdditionalPackages
            // );

            // Bez dodatocnych balickov
            const allPackages = desiredProgramDefaultPackages;

            //Agregacia vsetkych kanalov zo vsetkych balikov spadajucich pod program
            let allChannels = allPackages.map(pckg => {
                return pckg.channels.map(arr => arr);
            });

            //Splostenie povodne viacrozmeroveho pola
            allChannels = flatten(allChannels);

            //Vyhodenie duplikatov na zaklade kriteria ID
            let noDuplicates = allChannels.filter((obj, index, self) => {
                return index === self.findIndex(t => t.id === obj.id);
            });

            // Ak je pritomny parameter includePublic a je true, tak nezapocitaj do celkovej dlzky kanaly, ktore su dostupne vo volnom vysielani
            if (params.includePublic == false) {
                noDuplicates = noDuplicates.filter(arr => {
                    return arr.free_streaming != true;
                });
            }

            return noDuplicates.length;
        }
    },

    // Podla id programu vypocita, kolko HD a kolko Vsetkych kanalov ma program
    getHdProgramsChannelCounts: (state, getters) => (
        programId,
        params = { includePublic: true }
    ) => {
        //* Najdeme program (ID)
        //* Zo vsetkych balikov programu extrahujeme vsetky kanaly
        //* Zredukujeme vsetky pripadne duplikaty
        //* Zratame vsetky programy a z nich vsetky HD programy
        //* RETURN (int) pocet HD kanalov

        const desiredProgram = getters.getProgram(programId);
        const desiredProgramDefaultPackages = getters.getDefaultPackagesUnderProgram(
            programId
        );
        let desiredProgramAdditionalPackages = getters.getAdditionalPackagesUnderProgram(
            programId
        );

        // ANNETA
        // ak ma program additional_packages ( plati hlavne pre Annetu )
        // tak sa pozri kolko ich je a rataj iba tolko doplnkovych balikov
        if (desiredProgram && desiredProgram.additional_packages != null) {
            // Vezmeme si pocet doplnkovych balikov v cene
            const additionalPackages = desiredProgram.additional_packages;

            // Zoradime programove baliky od najpocetnejsich po tie najmenej pocetne
            let orderedPackagesByChannelCount = desiredProgramAdditionalPackages.sort(
                (a, b) => {
                    return b.channels.length - a.channels.length;
                }
            );

            // Vyrezeme iba pocet balikov, ktore chceme ratat - odvija sa od poctu balikov v cene
            const sliceByAdditionalPackagesCount = orderedPackagesByChannelCount.slice(
                0,
                additionalPackages
            );

            // Prepiseme array doplnkovych balickov, ktore sa maju ratat podla
            desiredProgramAdditionalPackages = sliceByAdditionalPackagesCount;
        }

        const allPackages = desiredProgramDefaultPackages.concat(
            desiredProgramAdditionalPackages
        );

        //Agregacia vsetkych kanalov zo vsetkych balikov spadajucich pod program
        let allChannels = allPackages.map(pckg => {
            return pckg.channels.map(arr => arr);
        });

        //Splostenie povodne viacrozmeroveho pola
        allChannels = flatten(allChannels);

        //Vyhodenie duplikatov na zaklade kriteria ID
        let noDuplicates = allChannels.filter((obj, index, self) => {
            return index === self.findIndex(t => t.id === obj.id);
        });

        // Ak je pritomny parameter includePublic a je true, tak nezapocitaj do celkovej dlzky kanaly, ktore su dostupne vo volnom vysielani
        if (params.includePublic == false) {
            noDuplicates = noDuplicates.filter(arr => {
                return arr.free_streaming != true;
            });
        }

        return noDuplicates.filter(arr => {
            return arr.hd == true;
        }).length;
    },

    // Podla id programu vypocita, kolko HD a kolko Vsetkych kanalov ma program
    get4KProgramsChannelCounts: (state, getters) => (
        programId,
        params = { includePublic: true }
    ) => {
        //* Najdeme program (ID)
        //* Zo vsetkych balikov programu extrahujeme vsetky kanaly
        //* Zredukujeme vsetky pripadne duplikaty
        //* Zratame vsetky programy a z nich vsetky HD programy
        //* RETURN (int) pocet HD kanalov

        const desiredProgram = getters.getProgram(programId);
        const desiredProgramDefaultPackages = getters.getDefaultPackagesUnderProgram(
            programId
        );
        let desiredProgramAdditionalPackages = getters.getAdditionalPackagesUnderProgram(
            programId
        );

        // ANNETA
        // ak ma program additional_packages ( plati hlavne pre Annetu )
        // tak sa pozri kolko ich je a rataj iba tolko doplnkovych balikov
        if (desiredProgram && desiredProgram.additional_packages != null) {
            // Vezmeme si pocet doplnkovych balikov v cene
            const additionalPackages = desiredProgram.additional_packages;

            // Zoradime programove baliky od najpocetnejsich po tie najmenej pocetne
            let orderedPackagesByChannelCount = desiredProgramAdditionalPackages.sort(
                (a, b) => {
                    return b.channels.length - a.channels.length;
                }
            );

            // Vyrezeme iba pocet balikov, ktore chceme ratat - odvija sa od poctu balikov v cene
            const sliceByAdditionalPackagesCount = orderedPackagesByChannelCount.slice(
                0,
                additionalPackages
            );

            // Prepiseme array doplnkovych balickov, ktore sa maju ratat podla
            desiredProgramAdditionalPackages = sliceByAdditionalPackagesCount;
        }

        const allPackages = desiredProgramDefaultPackages.concat(
            desiredProgramAdditionalPackages
        );

        //Agregacia vsetkych kanalov zo vsetkych balikov spadajucich pod program
        let allChannels = allPackages.map(pckg => {
            return pckg.channels.map(arr => arr);
        });

        //Splostenie povodne viacrozmeroveho pola
        allChannels = flatten(allChannels);

        //Vyhodenie duplikatov na zaklade kriteria ID
        let noDuplicates = allChannels.filter((obj, index, self) => {
            return index === self.findIndex(t => t.id === obj.id);
        });

        // Ak je pritomny parameter includePublic a je true, tak nezapocitaj do celkovej dlzky kanaly, ktore su dostupne vo volnom vysielani
        if (params.includePublic == false) {
            noDuplicates = noDuplicates.filter(arr => {
                return arr.free_streaming != true;
            });
        }

        return noDuplicates.filter(arr => {
            return arr.is_4k == true;
        }).length;
    },

    //Globalne zadefinovanie vybraneho mesta, bude ovplyvnovat ktore sluzby sa budu na stranke zobrazovat
    getSelectedCity: state => {
        return state.selectedCity;
    },

    //Globalne zadefinovanie vybraneho mesta, bude ovplyvnovat ktore sluzby sa budu na stranke zobrazovat
    getSelectedAnnetaPackage: state => {
        return state.selectedAnnetaPackage;
    }
};

// ##     ## ##     ## ########    ###    ######## ####  #######  ##    ##  ######
// ###   ### ##     ##    ##      ## ##      ##     ##  ##     ## ###   ## ##    ##
// #### #### ##     ##    ##     ##   ##     ##     ##  ##     ## ####  ## ##
// ## ### ## ##     ##    ##    ##     ##    ##     ##  ##     ## ## ## ##  ######
// ##     ## ##     ##    ##    #########    ##     ##  ##     ## ##  ####       ##
// ##     ## ##     ##    ##    ##     ##    ##     ##  ##     ## ##   ### ##    ##
// ##     ##  #######     ##    ##     ##    ##    ####  #######  ##    ##  ######
const mutations = {
    //Ulozi stav pozadovaneho kroku
    saveStep: (state, payload) =>
        //state.stepState[payload.step] = payload.state
        Vue.set(state.stepState, payload.step, payload.state),

    //Validacia jednotlivych krokov
    validateStep: (state, payload) => {
        const stepName = payload.step;
        state.validSteps[stepName] = payload.valid;
    },

    allStepsValid: (state, payload) => {
        state.allStepsValid = payload;
    },

    SET_PROGRAMS: (state, payload) => {
        state.programs = payload;
    },

    SET_CITIES: (state, payload) => {
        state.cities = payload;
    },

    SET_AVAILABLE_PROGRAMS: (state, payload) => {
        state.availablePrograms = payload;
    },

    SET_SELECTED_CITY: (state, payload) => {
        state.selectedCity = payload;
    },

    SET_SELECTED_ANNETA_PACKAGE: (state, payload) => {
        state.selectedAnnetaPackage = payload;
    }
};

//    ###     ######  ######## ####  #######  ##    ##  ######
//   ## ##   ##    ##    ##     ##  ##     ## ###   ## ##    ##
//  ##   ##  ##          ##     ##  ##     ## ####  ## ##
// ##     ## ##          ##     ##  ##     ## ## ## ##  ######
// ######### ##          ##     ##  ##     ## ##  ####       ##
// ##     ## ##    ##    ##     ##  ##     ## ##   ### ##    ##
// ##     ##  ######     ##    ####  #######  ##    ##  ######
const actions = {
    //Validacia konkretneho kroku
    validateStep: ({ commit, dispatch }, payload) => {
        commit("validateStep", payload);

        // Ak dostanes impulz ze krok vo formulari je chybny
        // tak oznac ako chybne vsetky nasledujuce kroky
        if (payload.valid == false) {
            //console.log('nasiel som chybu oznacujem vsetky vyssie ako chybne')
            let pos = Object.entries(state.validSteps)
                .map(e => {
                    return e[0];
                })
                .indexOf(payload.step);

            for (
                let i = pos + 1;
                i < Object.entries(state.validSteps).length;
                i++
            ) {
                commit("validateStep", {
                    step: Object.entries(state.validSteps)[i][0],
                    valid: false
                });
            }
        }

        dispatch("validateAllSteps");
    },
    //Validacia vsetkych krokov formularu
    validateAllSteps({ commit, state }) {
        const validatedSteps = Object.entries(state.validSteps);

        //Zisti, ci su vsetky kroky formularu true
        let areAllStepsValid = validatedSteps.filter(step => {
            return step[1] == false;
        });

        //Zapis ci su vsetky kroky formulara true -> povol posledny krok formularu
        commit("allStepsValid", !areAllStepsValid.length);
    },

    setAvailablePrograms({ commit, state }, payload) {
        commit("SET_AVAILABLE_PROGRAMS", payload);
    },

    setSelectedCity({ commit, state }, payload) {
        window.localStorage.setItem("selectedCity", JSON.stringify(payload));
        commit("SET_SELECTED_CITY", payload);
    },

    setSelectedAnnetaPackage({ commit, state }, payload) {
        window.localStorage.setItem(
            "selectedAnnetaPackage",
            JSON.stringify(payload)
        );
        commit("SET_SELECTED_ANNETA_PACKAGE", payload);
    },

    async fetchPrograms({ commit, state }) {
        let programs = await axios.post("/api/programs");
        programs = programs.data.data;
        commit("SET_PROGRAMS", programs);
        return programs;
    },

    async fetchCities({ commit, state }) {
        let cities = await axios.post("/api/availability");
        cities = cities.data.data;

        commit("SET_CITIES", cities);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
