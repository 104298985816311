import { render, staticRenderFns } from "./Antena.vue?vue&type=template&id=5c841ab3"
import script from "./Antena.vue?vue&type=script&lang=js"
export * from "./Antena.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports