<template>
    <section class="ponuka">
        <transition
            name="inter-fade"
            mode="out-in"
        >
            <nav id="navigation" class="numbered-navigation" v-if="isLoadingData && isLoadingCities">
                <router-link :class="{disabled: !getStep('vyberBalikov')}" class="numbered-navigation__item" :to="{name: 'annetaStep1'}">
                    <span class="numbered-navigation__item__number">1<small>/5</small></span>
                    <span class="numbered-navigation__item__title">Výber balíkov</span>
                </router-link>
                <router-link :class="{disabled: !getStep('vyberZariadenia')}" class="numbered-navigation__item" :to="{name: 'annetaStep2'}">
                    <span class="numbered-navigation__item__number">2<small>/5</small></span>
                    <span class="numbered-navigation__item__title">Výber zariadenia</span>
                </router-link>
                <!-- <router-link v-if="false" :class="{disabled: !getStep('vyberAnteny')}" class="numbered-navigation__item" :to="{name: 'annetaStep3'}">
                    <span class="numbered-navigation__item__number">3<small>/5</small></span>
                    <span class="numbered-navigation__item__title">Výber antény</span>
                </router-link> -->
                <router-link :class="{disabled: !getStep('osobneUdaje')}" class="numbered-navigation__item" :to="{name: 'annetaStep4'}">
                    <span class="numbered-navigation__item__number">3<small>/5</small></span>
                    <span class="numbered-navigation__item__title">Osobné údaje</span>
                </router-link>
                <router-link :class="{disabled: !$store.state.ponuka.allStepsValid}" class="numbered-navigation__item" :to="{name: 'annetaStep5'}">
                    <span class="numbered-navigation__item__number">4<small>/5</small></span>
                    <span class="numbered-navigation__item__title">Súhrn objednávky</span>
                </router-link>


            </nav>
        </transition>


    <section class="section" :class="{'section--dark': darkBgAllowed, 'loading': !(isLoadingData && isLoadingCities)}" >
        <div>
        <transition
            name="inter-fade"
            mode="out-in"
        >
            <!-- View -->
            <router-view v-if="isLoadingData && isLoadingCities">
            </router-view>

            <!-- Loading -->
            <div class="container" v-else>
                <div class="mx-auto" :class="loadingTheme">
                    Načítavam ...
                </div>
            </div>


        </transition>
        </div>
    </section>

    </section>
</template>

<script>
    import VueRouter from 'vue-router';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                darkBg: [
                    'annetaStep6'
                ],
            }
        },
        computed: {
            ...mapGetters(['getProgram', 'getStep', 'getSelectedProgram', 'getSelectedAnnetaPackage', 'isLoadingData', 'isLoadingCities']),
            darkBgAllowed() {
                return this.darkBg.includes(this.$route.name);
            },
            loadingTheme(){
                return this.darkBgAllowed ? 'loading-pulse__light' : 'loading-pulse__dark';
            },
            isAntena(){
                return this.getSelectedProgram.id == 1 ? true : false;
            }
        },
        methods: {
            ...mapActions(['fetchPrograms', 'fetchCities']),
        },
        created () {
            if(!this.isLoadingData){
                //FETCHNE API programov a naplni store
                this.fetchPrograms();
            }

            if(!this.isLoadingCities){
                //Store API call ktory fetchne zoznam vsetkych miest
                this.fetchCities();
            }

            // Ak nemame udaj z prveho kroku, tak resetni appku na zaciatok, aby user neostal vysiet na nepovolenom kroku
            if(!this.getStep('vyberBalikov') && this.$route.path != '/anneta/objednavka-anneta' ){
                this.$router.push('/anneta/objednavka-anneta');
            }

            let token = this.$route.query.token;
            if(token) {
                window.sessionStorage.setItem('token', token);
            }
            // Zamedzi prepnutiu na nepovoleny route ak nieje vyplneny prvky krok
            // this.$router.beforeEach((to, from, next) => {
            //     //Ak neexistuje udaj z prveho kroku, vrat sa na zaciatok aplikacie
            //     if(!this.getStep('overenieDostupnosti')){
            //         next('/');
            //     }
            //     else {
            //         next();
            //     }
            // })

        },
   }


</script>

<style lang="scss" scoped>
    .section{
        min-height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .h2, .h3{
        font-weight: 700;
    }

    @media (min-width: 768px) {
        .ponuka {
            margin-top: -27px;
        }
        .ponuka > nav{
            width: 85% !important;
            padding-right: 5% !important;
        }
        .numbered-navigation__item {
            margin: 0 auto !important;
        }
        .numbered-navigation::before {
            background: transparent !important;
        }
    }




</style>

