<template>
    <div class="h-100">
        <!-- Modals  -->
        <!-- Modal overenie dostupnosti -->
        <modal ref="availability" class="body-visible">
            <ConfirmLocation
                @success="success = true"
                :selectedItem="selectedCity"
            ></ConfirmLocation>
            <!-- <check-availability /> -->
        </modal>

        <div
            class="banner-card banner-card--coverage"
            @click="$refs.availability.toggle()"
        >
            <h3 class="h4 banner-card__title">
                Overte si dostupnosť Plustelky vo vašej domácnosti.
            </h3>
            <div class="text-smaller banner-card__action">Zmeniť lokalitu</div>
            <img src="/img/location-bg.svg" alt="" class="banner-card__bg" />
        </div>

        <!-- Ak je sluzba nedostupna -->
        <!-- <div
            class="banner-card banner-card--coverage"
            v-if="!getAvailableProgram(programId)"
            @click="$refs.availability.toggle()"
        >
            <h3 class="h4 banner-card__title">
                Overte si dostupnosť Plustelky vo vašej domácnosti.
            </h3>
            <div class="text-smaller banner-card__action">Zmeniť lokalitu</div>
            <img src="/img/location-bg.svg" alt="" class="banner-card__bg" />
        </div> -->
        <!-- Ak nieje zadana ziadna lokalita, a treba ju overit -->
        <!-- <div
            class="banner-card banner-card--coverage"
            v-else-if="evaluateAvailability"
            @click="$refs.availability.toggle()"
        >
            <h3 class="h4 banner-card__title">
                Overte si dostupnosť Plustelky vo vašej domácnosti.
            </h3>
            <div class="text-smaller banner-card__action">
                Overiť dostupnosť
            </div>
            <img src="/img/location-bg.svg" alt="" class="banner-card__bg" />
        </div> -->
        <!-- Ak je uz zadana lokalita -->
        <!-- <div
            class="banner-card banner-card--coverage"
            @click="$refs.availability.toggle()"
            v-else
        >
            <h3 class="h4 banner-card__title">
                Plustelka je dostupná vo vašej domácnosti.
            </h3>
            <div class="text-smaller banner-card__action">
                Overiť dostupnosť
            </div>
            <img src="/img/location-bg.svg" alt="" class="banner-card__bg" />
        </div> -->

        <!-- Ak je sluzba nedostupna -->
        <!-- <div v-if="!getAvailableProgram(programId)">
            Služba NIE je dostupná vo Vašej lokalite
            <br />
            <button
                class="btn btn-primary mt-2"
                @click="$refs.availability.toggle()"
            >
                <span>Zmeniť lokalitu</span>
            </button>
        </div> -->
        <!-- Ak nieje zadana ziadna lokalita, a treba ju overit -->
        <!-- <button
            v-else-if="evaluateAvailability"
            class="btn btn-primary btn-primary--active"
            @click="$refs.availability.toggle()"
        >
            <span>Overiť dostupnosť</span>
        </button> -->

        <!-- Ak je uz zadana lokalita -->
        <!-- <a
            v-else
            class="btn btn-primary btn-primary--active"
            @click="setSelectedAnnetaPackage(programId)"
            :href="evaluateServiceHrefFromId"
        >
            Toto chcem
        </a> -->
    </div>
</template>

<script>
import modal from "../modal/modal.vue";
import checkAvailability from "../check-availability/checkAvailability.vue";
import { mapActions, mapGetters } from "vuex";

import ConfirmLocation from "../../vue/ConfirmLocation.vue";

export default {
    props: ["programId"],
    data() {
        return {
            selectedCity: {}
        };
    },
    //  ######   #######  ##     ## ########   #######  ##    ## ######## ##    ## ########  ######
    // ##    ## ##     ## ###   ### ##     ## ##     ## ###   ## ##       ###   ##    ##    ##    ##
    // ##       ##     ## #### #### ##     ## ##     ## ####  ## ##       ####  ##    ##    ##
    // ##       ##     ## ## ### ## ########  ##     ## ## ## ## ######   ## ## ##    ##     ######
    // ##       ##     ## ##     ## ##        ##     ## ##  #### ##       ##  ####    ##          ##
    // ##    ## ##     ## ##     ## ##        ##     ## ##   ### ##       ##   ###    ##    ##    ##
    //  ######   #######  ##     ## ##         #######  ##    ## ######## ##    ##    ##     ######
    components: {
        modal,
        checkAvailability,
        ConfirmLocation
    },
    // ##     ## ######## ######## ##     ##  #######  ########   ######
    // ###   ### ##          ##    ##     ## ##     ## ##     ## ##    ##
    // #### #### ##          ##    ##     ## ##     ## ##     ## ##
    // ## ### ## ######      ##    ######### ##     ## ##     ##  ######
    // ##     ## ##          ##    ##     ## ##     ## ##     ##       ##
    // ##     ## ##          ##    ##     ## ##     ## ##     ## ##    ##
    // ##     ## ########    ##    ##     ##  #######  ########   ######
    methods: {
        ...mapActions(["setSelectedAnnetaPackage"])
    },

    //  ######   #######  ##     ## ########  ##     ## ######## ######## ########
    // ##    ## ##     ## ###   ### ##     ## ##     ##    ##    ##       ##     ##
    // ##       ##     ## #### #### ##     ## ##     ##    ##    ##       ##     ##
    // ##       ##     ## ## ### ## ########  ##     ##    ##    ######   ##     ##
    // ##       ##     ## ##     ## ##        ##     ##    ##    ##       ##     ##
    // ##    ## ##     ## ##     ## ##        ##     ##    ##    ##       ##     ##
    //  ######   #######  ##     ## ##         #######     ##    ######## ########
    computed: {
        ...mapGetters([
            "isLoadingData",
            "getAvailablePrograms",
            "getAvailableProgram",
            "getSelectedCity"
        ]),

        evaluateServiceHrefFromId() {
            if (this.programId == 1) {
                return this.$antenaOrderUrl;
            } else return this.$annetaOrderUrl;
        },

        evaluateAvailability() {
            if (
                !Object.values(this.getSelectedCity).length &&
                this.programId == 1
            ) {
                return true;
            } else return false;
        }
    },

    mounted() {
        this.selectedCity = JSON.parse(
            window.localStorage.getItem("selectedCity")
        );
    }
};
</script>

<style lang="scss" scoped></style>
