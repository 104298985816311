<template>
    <div class="card-holder">
        <input
            type="checkbox"
            class="card-input hidden"
            v-bind="$attrs"
            ref="inputCheck"
            :id="`pckg${pckg.id}`"
            :value="pckg"
            :true-value="pckg"
            @input="$emit('input', dataPass())"
            hidden
        />

        <label :for="`pckg${pckg.id}`" class="card card--label mb-3">
            <div class="package-card">
                <div class="package-card__header">
                    <div>
                        <span class="text-bigger">{{ packageCategory }} </span>
                        <h3>{{ pckg.name }}</h3>
                    </div>
                    <div class="package-card__price">
                        <span>{{ pckg.price }}€</span>
                        <span>/mesiac</span>
                    </div>
                </div>
            </div>

            <ul class="package-card__list">
                <li v-for="(channel, index) in orderedChannels">
                    <img
                        :alt="channel.name"
                        :src="channel.logo"
                        :key="`img${index}`"
                        class="package-card__img"
                    />
                </li>
            </ul>
        </label>
    </div>
</template>

<script>
import _orderBy from "lodash/orderby";

export default {
    props: ["pckg", "currentMonths", "free"],
    inheritAttrs: false,
    methods: {
        dataPass() {
            return {
                value: this.pckg,
                checked: this.$refs.inputCheck.checked
            };
        }
    },

    watch: {
        currentMonths(newValue, oldValue) {
            this.$emit("input", this.dataPass());
        }
    },

    computed: {
        packageCategory() {
            return this.pckg.additional == 1
                ? "Doplnkový balík"
                : "Základný balík";
        },
        orderedChannels() {
            let orderedChannels = _orderBy(
                this.pckg.channels,
                ["order"],
                ["asc"]
            );
            return orderedChannels;
        }
    },

    mounted() {
        this.dataPass();
        this.$emit("input", this.dataPass());
    },

    created() {}
};
</script>

<style lang="scss" scoped>
.package {
    @media (max-width: 576px) {
        max-width: 100% !important;
    }
}

.offer__item {
    text-align: center;
    width: 100%;
    max-width: 100%;
    //margin: 0;

    &__disclaimer {
        margin-top: 0.75em;
        font-weight: 700;
    }

    .free & {
        box-shadow: inset 0 0 0 5px #26b8eb;
    }

    .semi-free & {
        box-shadow: inset 0 0 0 5px #10a87d;
        border-color: #10a87d !important;
    }

    .main-package & {
        max-width: 100%;

        // Horizontalne zobrazenie baliku
        @media (min-width: 992px) {
            display: grid;
            grid-template-columns: 15% auto 20% min-content;
            align-items: center;
            padding: 3rem;

            &__heading {
                text-align: left;
                width: 13rem;
                margin: 0;
            }

            &__logoset {
                margin: 0 3rem;
            }

            &__footer {
                margin: 0 3rem 0 0;
            }
        }

        & {
            //background: hotpink;
            max-width: 100%;
        }
    }
}
</style>
