<template>
    <div class="program-widget orange-theme">

        <transition name="inter-fade" mode="out-in">
            <figure class="program-widget__logo mb-4" v-if="isLoadingData">
                <img :src="getProgram(sharedData.currentProgramId).image" />
            </figure>
        </transition>

        <keep-alive>
            <transition name="inter-fade" mode="out-in">

                <component
                    @page="switchView($event)"
                    @changeLocation="setLocation($event)"
                    :data="sharedData"
                    :is="component"
                    v-if="isLoadingCities && isLoadingData"
                />

                <!-- Loading -->
                <div class="loading-container" v-else>
                    <div class="loading-pulse__dark">
                        Načítavam ...
                    </div>
                </div>

            </transition>
        </keep-alive>
    </div>
</template>

<script>
    //Vuex
    import { mapActions, mapGetters } from 'vuex';

    // Views
    import home from './views/home.vue';
    import evaluate from './views/evaluate.vue';
    import result from './views/result.vue';





    export default {
        data() {
            return {
                component: home,
                sharedData: {
                    'currentProgram': [],
                    'currentProgramId': 1,
                    'avaliable' : false,
                    'location' : {},
                    'cities' : [],
                    'hdChannelsCount' : '',
                    'allChannelsCount' : '',
                    'is4kChannelsCount': '',
                    'programLowestPrice' : '',

                    'avaliabilityTrueURL' : this.$antenaOrderUrl,
                    'avaliabilityFalseURL' : this.$annetaOrderUrl
                }
            }
        },

        props: ['programId'],

        components: {
            home,
            evaluate,
            result,
        },

        watch: {
            getSelectedCity(newValue, oldValue) {
                //console.log('zmenil sa selected city')

                //Zistime, ci je zadane uz mesto v local storage
                //ak ano, tak zadaj lokalne mesto a nasledne presmeruj komponent na posledny krok - vyhodnotenie
                if(Object.values(this.getSelectedCity).length){
                    //console.log('mam mesto v LS')
                    this.sharedData.location = this.getSelectedCity;
                    this.component = result;
                }
            }
        },

        methods: {
            ...mapActions(['fetchCities', 'fetchPrograms']),
            switchView(evt) {
                this.component = evt;
            },
            setLocation(evt) {
                this.sharedData.location = evt;
            }
        },

        computed: {
            ...mapGetters([
                            'isLoadingCities',
                            'isLoadingData',
                            'getCities',
                            'getProgram',
                            'getPrograms',
                            'getSelectedCity',
                            'getAllProgramsChannelCounts',
                            'getHdProgramsChannelCounts',
                            'get4KProgramsChannelCounts',
                            'getProgramsLowestPackagePrice'
                        ])
        },

        created() {
            if(!this.isLoadingData){
                //FETCHNE API programov a naplni store
                this.fetchPrograms().then(()=>{

                    //Pocet vsetkych kanalov pre aktualny program
                    this.sharedData.allChannelsCount = this.getAllProgramsChannelCounts(this.sharedData.currentProgramId, {includePublic: false});

                    //Pocet HD kanalov pre aktualny program
                    this.sharedData.hdChannelsCount = this.getHdProgramsChannelCounts(this.sharedData.currentProgramId, {includePublic: false});

                    //Pocet 4k kanalov pre aktualny program
                    this.sharedData.is4kChannelsCount = this.get4KProgramsChannelCounts(this.sharedData.currentProgramId, {includePublic: false});

                    //Najnizsia cena defaultneho baliku
                    this.sharedData.programLowestPrice = this.getProgramsLowestPackagePrice(this.sharedData.currentProgramId);

                    // Zoberieme ID z prop a dame ho do objektu shared data, ktory sa zdiela nasledne napriec Views
                    this.sharedData.currentProgramId = this.programId;

                    // Vezmeme konkretny program
                    this.sharedData.currentProgram = this.getProgram(this.programId);

                })
            }

            if(!this.isLoadingCities){
                //Store API call ktory fetchne zoznam vsetkych miest
                this.fetchCities().then(()=>{
                    this.sharedData.cities = this.getCities;

                    //Zistime, ci je zadane uz mesto v local storage
                    //ak ano, tak zadaj lokalne mesto a nasledne presmeruj komponent na posledny krok - vyhodnotenie
                    if(Object.values(this.getSelectedCity).length){
                        //console.log('mam mesto v LS')
                        this.sharedData.location = this.getSelectedCity;
                        this.component = result;
                    }

                })

            }
        },

    }
</script>

<style lang="scss">
    .loading-container{
        min-height: 200px;
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }

    .program-widget{
        text-align: center;

        &__logo{
            height: 70px;

            img{
                object-fit: contain;
                width: 100%;
                height: 100%;

            }
        }

        &__heading{
            font-size: 2rem;
            font-weight: 700;
        }

        &__price{
            display: block;
            font-weight: 600;

            span{
                font-size: 1.5em;
            }

            small{
                font-size: 1em;
            }
        }
    }
</style>
