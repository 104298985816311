<template>
    <div>
        <div class="google-map" ref="googleMap"></div>
    </div>
</template>

<script>
//Vendors
import axios from "axios";
import GoogleMapsApiLoader from "google-maps-api-loader";
import MarkerClusterer from "@googlemaps/markerclustererplus";

// Components
import googleMapMarker from "../components/googleMapMarker.vue";

export default {
    components: {
        googleMapMarker
    },
    props: ["shared"],
    data() {
        return {
            mapConfig: {
                center: {
                    lat: 48.71034187898951,
                    lng: 19.911210504358902
                },
                styles: [
                    {
                        featureType: "poi.business",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }]
                    },
                    {
                        featureType: "administrative",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#444444"
                            }
                        ]
                    },

                    {
                        featureType: "landscape",
                        elementType: "all",
                        stylers: [
                            {
                                color: "#f2f2f2"
                            }
                        ]
                    },
                    {
                        featureType: "poi",
                        elementType: "all",
                        stylers: [
                            {
                                visibility: "off"
                            }
                        ]
                    },
                    {
                        featureType: "road",
                        elementType: "all",
                        stylers: [
                            {
                                saturation: -100
                            },
                            {
                                lightness: 45
                            }
                        ]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "all",
                        stylers: [
                            {
                                visibility: "simplified"
                            }
                        ]
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "labels.icon",
                        stylers: [
                            {
                                visibility: "off"
                            }
                        ]
                    },
                    {
                        featureType: "transit",
                        elementType: "all",
                        stylers: [
                            {
                                visibility: "off"
                            }
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "all",
                        stylers: [
                            {
                                color: "#b7b7b7"
                            },
                            {
                                visibility: "on"
                            }
                        ]
                    }
                ],
                zoom: 8
            },
            geoLocation: false
        };
    },
    watch: {
        geoLocation: {
            handler: function(val, oldVal) {
                this.initializeMap();
                // Enable Clustering
                new MarkerClusterer(this.shared.map, this.shared.markers, {
                    imagePath: "img/icons/grp"
                });
            },
            deep: true
        }
    },
    // ##     ## ######## ######## ##     ##  #######  ########   ######
    // ###   ### ##          ##    ##     ## ##     ## ##     ## ##    ##
    // #### #### ##          ##    ##     ## ##     ## ##     ## ##
    // ## ### ## ######      ##    ######### ##     ## ##     ##  ######
    // ##     ## ##          ##    ##     ## ##     ## ##     ##       ##
    // ##     ## ##          ##    ##     ## ##     ## ##     ## ##    ##
    // ##     ## ########    ##    ##     ##  #######  ########   ######
    methods: {
        checkGeoLocation() {
            navigator.geolocation.getCurrentPosition(position => {
                this.geoLocation = true;
                this.mapConfig.center.lat = position.coords.latitude;
                this.mapConfig.center.lng = position.coords.longitude;
                this.mapConfig.zoom = 12;
            });
        },
        initializeMap() {
            const mapContainer = this.$refs.googleMap;

            this.shared.map = new this.shared.google.maps.Map(
                mapContainer,
                this.mapConfig
            );
            const map = this.shared.map;

            // Add a marker clusterer to manage the markers.
            new MarkerClusterer(this.shared.map, this.shared.markers, {
                imagePath: "img/icons/grp"
            });

            // Create markers on map
            this.shared.markers = this.shared.resellers.map((reseller, i) => {
                var marekrLatLng = new this.shared.google.maps.LatLng(
                    this.resolveGPS(reseller.gps_coordinates, 0),
                    this.resolveGPS(reseller.gps_coordinates, 1)
                );

                const photoContent = `
                        <figure class="reseller-grid__photo">
                            <img src="${reseller.photo}" loading="lazy" />
                        </figure>`;

                // Array kontaktnych cisiel
                // ak su data array vypis ich za sebou spolocne s line breakami
                // ak nie, tak ich len jednoducho vypis
                let phoneNrs = ``;

                if (typeof reseller.phone == "object") {
                    reseller.phone.forEach(el => {
                        if (el) {
                            phoneNrs += `<a target="_blank" follow="nofollow" href="tel:${el}">${el}</a><br />`;
                        }
                    });
                } else {
                    phoneNrs = `<a target="_blank" follow="nofollow" href="tel:${reseller.phone}">${reseller.phone}</a><br>`;
                }

                // Array kontaktnych emailov
                // ak su data array vypis ich za sebou spolocne s line breakami
                // ak nie, tak ich len jednoducho vypis
                let emailAdrs = ``;

                if (typeof reseller.email == "object") {
                    reseller.email.forEach(el => {
                        if (el) {
                            emailAdrs += `<a target="_blank" follow="nofollow" href="mailto:${el}">${el}</a><br />`;
                        }
                    });
                } else {
                    emailAdrs = `<a target="_blank" follow="nofollow" href="mailto:${reseller.email}">${reseller.email}</a><br>`;
                }
                //console.log(phoneNrs);

                let infoWindowContent = String.raw`
                    <div class="reseller-grid">

                        ${reseller.photo ? photoContent : ""}
                        <div class="reseller-grid__info">
                            <p>
                                ${reseller.name} <br />
                                ${reseller.zip} ${reseller.city} <br />
                                ${reseller.address}
                            </p>

                            <h6>Kontakty</h6>
                            <p>
                                ${reseller.phone ? phoneNrs : ""}
                                ${reseller.email ? emailAdrs : ""}
                                ${
                                    reseller.web
                                        ? `<a href="//${reseller.web}" target="_blank">${reseller.web}</a>`
                                        : ""
                                }
                            </p>

                            <h6>Služby</h6>
                            <p>
                                ${
                                    reseller.service
                                        ? "Servisné služby <br />"
                                        : ""
                                }
                                ${reseller.store ? "Kamenný obchod <br />" : ""}
                                ${
                                    reseller.unlocks
                                        ? "Odblokovanie a aktivácia  <br />"
                                        : ""
                                }
                                ${
                                    reseller.active
                                        ? "Aktívny a dostupný predajca <br />"
                                        : ""
                                }
                            </p>

                            ${
                                reseller.anneta == 1 || reseller.antena == 1
                                    ? `<h6>Predajca služieb</h6>`
                                    : ""
                            }
                            <p>
                                ${reseller.anneta == 1 ? "Anneta<br />" : ""}
                                ${
                                    reseller.antena == 1
                                        ? "Plustelka Štandard<br />"
                                        : ""
                                }
                            </p>
                        </div>
                    </div>
                    `;

                //console.log(infoWindowContent);
                //info window
                const infoWindow = new this.shared.google.maps.InfoWindow({
                    content: infoWindowContent
                });

                //Marker
                let marker = new this.shared.google.maps.Marker({
                    position: marekrLatLng,
                    map: this.shared.map,
                    icon: "img/icons/marker.svg"
                });

                reseller.marker = marker;

                //connect info popup to marker
                marker.addListener("click", () => {
                    if (this.shared.activeInfoWindow) {
                        this.shared.activeInfoWindow.close();
                    }
                    this.shared.activeInfoWindow = infoWindow;

                    infoWindow.open(this.map, marker);
                    //this.shared.map.setCenter(marekrLatLng)
                });

                return marker;
            });

            const input = document.getElementById("pac-input");
            console.log(this.shared.google.maps.places)
            const searchBox = new this.shared.google.maps.places.SearchBox(input);
            this.shared.map.controls[this.shared.google.maps.ControlPosition.TOP_LEFT].push(input);
            // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            // Bias the SearchBox results towards current map's viewport.
            this.shared.map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });
            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                // Clear out the old markers.
                this.shared.markers.forEach((marker) => {
                    marker.setMap(null);
                });


                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();

                places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        console.log("Returned place contains no geometry");
                        return;
                    }

                    const icon = {
                        url: place.icon,
                        size: new this.shared.google.maps.Size(71, 71),
                        origin: new this.shared.google.maps.Point(0, 0),
                        anchor: new this.shared.google.maps.Point(17, 34),
                        scaledSize: new this.shared.google.maps.Size(25, 25),
                    };

                    // Create a marker for each place.
                    this.shared.markers.push(
                        new this.shared.google.maps.Marker({
                            map,
                            icon,
                            title: place.name,
                            position: place.geometry.location,
                        })
                    );
                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });
                this.shared.map.fitBounds(bounds);
            })
        },
        resolveGPS(gps, position) {
            if (gps) {
                let gpsArray = gps.split(/, ?/);
                return parseFloat(gpsArray[position]);
            }
        }
    },

    // ##     ##  #######  ##     ## ##    ## ######## ######## ########
    // ###   ### ##     ## ##     ## ###   ##    ##    ##       ##     ##
    // #### #### ##     ## ##     ## ####  ##    ##    ##       ##     ##
    // ## ### ## ##     ## ##     ## ## ## ##    ##    ######   ##     ##
    // ##     ## ##     ## ##     ## ##  ####    ##    ##       ##     ##
    // ##     ## ##     ## ##     ## ##   ###    ##    ##       ##     ##
    // ##     ##  #######   #######  ##    ##    ##    ######## ########
    mounted() {
        this.checkGeoLocation();
        this.initializeMap();

        // Enable Clustering
        new MarkerClusterer(this.shared.map, this.shared.markers, {
            imagePath: "img/icons/grp"
        });
    }
};
</script>

<style lang="scss">
.google-map {
    min-height: 750px;

    .reseller-grid {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, auto);

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        &__photo {
            max-width: 250px;
            max-height: 350px;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .gm-style {
        // Info window
        &-iw {
            padding: 1rem !important;

            p:not(:last-of-type) {
                margin-bottom: 1rem;
            }

            &-c {
                min-width: 220px !important;
                overflow: visible !important;
                background-color: #224b4b !important;
            }

            &-d {
                overflow: visible !important;
                color: white;
                line-height: 1.5em;

                font-family: "Montserrat", sans-serif;

                h6 {
                    font-size: 1em;
                    color: #fff;
                    font-weight: bold;
                }
            }

            &-t {
                &::after {
                    background: linear-gradient(
                        45deg,
                        rgba(#224b4b, 1) 50%,
                        rgba(#224b4b, 0) 51%,
                        rgba(#224b4b, 0) 100%
                    ) !important;
                }
            }

            .gm-ui-hover-effect {
                background-color: white !important;
                border-radius: 5rem;
                opacity: 1;

                top: -1em !important;
                right: -1em !important;
            }
        }

        .cluster {
            align-items: center;
            justify-content: center;
            display: flex;

            span {
                font-size: 1rem;
                font-family: "Montserrat", sans-serif;
                color: white;
            }
        }
    }
}
</style>
