<template>
    <section class="section">
        <div class="container">
            <div class="text-center">
                <h2 class="h2 mb-4">
                    Ďakujeme za Vašu objednávku
                </h2>
                <p class="mb-4">
                    Do 3 pracovných dní Vás budeme telefonicky kontaktovať na
                    Vami uvedené telefónne číslo za účelom dokončenia Vašej
                    objednávky. Tím Plustelka
                </p>
                <a href="/" class="btn btn-primary">
                    <span>Späť na domovskú stránku</span>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style scoped>
.section--dark {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
