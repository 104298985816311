<template>
    <div class="section section--small">
        <div class="container container--small">
            <h2 class="h4 mb-3">Informácie o balíku</h2>

            <!-- Objednavka -->
            <div
                class="card card--highlighted mb-3"
                v-for="(item, index) in monthlyItems"
                :key="`monthly${index}`"
            >
                <div class="card-item">
                    <div class="card-item-inner card-item-inner--center">
                        <div class="card-item-inner__content">
                            <p>{{ item.description }}</p>
                            <h3 class="h4">{{ item.title }}</h3>
                        </div>
                        <div
                            class="card-item-inner__price">
                            <p class="d-inline-flex">
<!--                                &lt;!&ndash;  AKCIA: 12ty mesiac zadarmo &ndash;&gt;-->
<!--                                <span class="span-gray" v-if="item.duration === 12 && index === 0">-->
<!--                                    {{ ((item.price * (item.duration - 1)) / item.duration).toFixed(2) }} € x {{ item.duration }}-->
<!--                                </span>-->
                                <!--  AKCIA: 12ty mesiac zadarmo ak nie je BF -->
<!--                                <span class="span-gray" v-if="item.duration === 12 && index === 0 && !bf_discount">-->
<!--                                    {{ ((item.price * (item.duration - 1)) / item.duration).toFixed(2) }} € x {{ item.duration }}-->
<!--                                </span>-->
<!--                                <span class="span-gray" v-else-if="item.duration === 12 && index === 0 && bf_discount">-->
<!--                                    6.29 € x {{ item.duration }}-->
<!--                                </span>-->
                                <!--  AKCIA: 6ty mesiac -->
<!--                                <span class="span-gray" v-else-if="item.duration === 6 && index === 0">-->
<!--                                    {{ (((item.price - 0.5) * (item.duration)) / item.duration).toFixed(2) }} € x {{ item.duration }}-->
<!--                                </span>-->
<!--                                <span class="span-gray" v-else>{{parseFloat(item.price).toFixed(2) }} € x {{ item.duration }}</span>-->

                                <!-- AKCIA: 3,6,12 akcia  -->
                                <span class="span-gray" v-if="(item.duration === 3) && (index === 0)">
                                    6.65 € x {{ item.duration }}
                                </span>
                                <span class="span-gray" v-else-if="(item.duration === 6) && (index === 0)">
                                    6.49 € x {{ item.duration }}
                                </span>
                                <span class="span-gray" v-else-if="(item.duration === 12) && (index === 0)">
                                    6.29 € x {{ item.duration }}
                                </span>

                                <!-- Zlavovy kod PLUSTELKA50 - 50% zlava na premium pri 12tich mesiacoch-->
                                <span class="span-gray" v-else-if="(item.duration === 12) && (item.title === 'Plustelka Premium') && bf_discount">
                                    0.50 € x {{ item.duration }}
                                </span>

                                <span class="span-gray" v-else>{{parseFloat(item.price).toFixed(2) }} € x {{ item.duration }}</span>


                                <!-- <span class="span-gray" v-else-if="item.duration === 6 && index === 0">-->
                                <!--     {{ (((item.price - 0.5) * (item.duration)) / item.duration).toFixed(2) }} € x {{ item.duration }}-->
                                <!-- </span>-->

                                <span class="span-gray pl-1">
                                    {{ item.duration === 1 ? "mesiac" : "" }}
                                    {{
                                    item.duration > 1 && item.duration < 5
                                    ? "mesiace"
                                    : ""
                                    }}
                                    {{ item.duration >= 5 ? "mesiacov" : "" }}
                                </span>
<!--                                <span class="span-gray pl-2" v-if="item.duration === 12 && item.title === 'Plustelka Premium'">-->
<!--                                    -50%-->
<!--                                </span>-->

<!--                                <span class="span-gray pl-2" v-if="bf_discount && item.duration >= 12 && index === 0">-->
<!--                                    -10%-->
<!--                                </span>-->
                            </p>

                            <!--  AKCIA: 3,6,12 zlava -->
                            <h3 class="h4" v-if="(item.duration === 3) && (index === 0)">
                                <!-- 5% zlava-->
                                {{ (item.duration * 6.65).toFixed(2) }} €
                            </h3>
                            <h3 class="h4" v-else-if="(item.duration === 6) && (index === 0)">
                                <!-- 7% zlava-->
                                {{ (item.duration * 6.49).toFixed(2) }} €
                            </h3>
                            <h3 class="h4" v-else-if="(item.duration === 12) && (index === 0)">
                                <!-- 10% zlava-->
                                {{ (item.duration * 6.29).toFixed(2) }} €
                            </h3>

                            <!-- Zlavovy kod PLUSTELKA50 - 50% zlava na premium pri 12tich mesiacoch-->
                            <h3 class="h4" v-else-if="(item.duration === 12) && (item.title === 'Plustelka Premium') && bf_discount">
                                {{ parseFloat(item.price * 12 * 0.5).toFixed(2) }} €
                            </h3>

                            <h3 class="h4" v-else>
                                {{ (item.price * item.duration).toFixed(2) }} €
                            </h3>
                             <!--  AKCIA: 12ty mesiac zadarmo -->
<!--                            <h3 class="h4" v-if="item.duration >= 12 && index === 0">-->
<!--                                {{ parseFloat(item.price * 11).toFixed(2) }} €-->
<!--                            </h3>-->
                            <!--  AKCIA: 6ty mesiac -->
<!--                            <h3 class="h4" v-else-if="item.duration === 6 && index === 0">-->
<!--                                {{ parseFloat((item.price - 0.5) * item.duration).toFixed(2) }} €-->
<!--                            </h3>-->

<!--                             &lt;!&ndash;  AKCIA: 12ty mesiac zadarmo + BF AKCIA zadany KOD &ndash;&gt;-->
<!--                            <div v-if="(index === 0) && ((item.duration >= 12) || bf_discount)">-->

<!--                                &lt;!&ndash;  AKCIA: 12ty mesiac zadarmo + BF zadany kod &ndash;&gt;-->
<!--                                 <h3 class="h4" v-if="item.duration >= 12 && bf_discount">-->
<!--                                     {{ parseFloat(item.price * 11 * parseFloat(0.9)).toFixed(2) }} €-->
<!--                                 </h3>-->

<!--                                &lt;!&ndash;  AKCIA: 12ty mesiac zadarmo &ndash;&gt;-->
<!--                                 <h3 class="h4" v-else-if="item.duration >= 12">-->
<!--                                     {{ parseFloat(item.price * 11).toFixed(2) }} €-->
<!--                                 </h3>-->

<!--                                &lt;!&ndash;  AKCIA: BF zadany KOD &ndash;&gt;-->
<!--                                 <h3 class="h4" v-else-if="bf_discount">-->
<!--                                     {{ parseFloat(item.price * item.duration * parseFloat(0.9)).toFixed(2) }} €-->
<!--                                 </h3>-->

<!--                                 <h3 class="h4" v-else>-->
<!--                                     {{ (item.price * item.duration).toFixed(2) }} €-->
<!--                                 </h3>-->
<!--                            </div>-->

                            <!-- AKCIA: BF AKCIA na rocny balik-->
<!--                            <h3 class="h4" v-if="bf_discount && item.duration >= 12 && index === 0">-->
<!--                                {{ parseFloat(6.29 * item.duration).toFixed(2) }} €-->
<!--                            </h3>-->
                            <!--  AKCIA: 12ty mesiac zadarmo ale ak nie je BF AKCIA-->
<!--                            <h3 class="h4" v-else-if="item.duration >= 12 && index === 0 && !bf_discount">-->
<!--                                {{ parseFloat(item.price * 11).toFixed(2) }} €-->
<!--                            </h3>-->
<!--                            <h3 class="h4" v-else>-->
<!--                                {{ (item.price * item.duration).toFixed(2) }} €-->
<!--                            </h3>-->
<!--                            <h3 class="h4" v-else-if="item.duration === 6 && index === 0">-->
<!--                                {{ parseFloat((item.price - 0.5) * item.duration).toFixed(2) }}-->
<!--                            </h3>-->
<!--                            <h3 class="h4" v-else-if="item.duration === 12 && item.title === 'Plustelka Premium'">-->
<!--                                {{ parseFloat(item.price * 12 * 0.5).toFixed(2) }}-->
<!--                            </h3>-->

<!--                            <h3 class="h4" v-if="item.duration === 12 && item.title === 'Plustelka Premium' && bf_discount">-->
<!--                                {{ parseFloat(item.price * 12 * 0.5).toFixed(2) }} €-->
<!--                            </h3>-->
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="card card--highlighted mb-3"
                v-for="(item, index) in onceItems"
                :key="`once${index}`"
            >
                <div class="card-item">
                    <div class="card-item-inner card-item-inner--center">
                        <div class="card-item-inner__content">
                            <p>{{ item.description }} v</p>
                            <h3 class="h4">{{ item.title }}</h3>
                        </div>
                        <div class="card-item-inner__price">
                            <h3 class="h4">{{ parseFloat(item.price).toFixed(2) }} €</h3>
                            <p>s DPH</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Doprava a platba -->
            <div class="summary-card mb-3">
                <div class="summary-card-head">
                    <h3 class="h5">Doprava a spôsob platby</h3>
                </div>
                <div class="summary-card-body">
                    <div class="summary-card-body__inner">
                        <template v-for="(method, index) in shipping">
                            <div
                                class="summary-card-body__item my-1"
                                :key="`shipping${index}`"
                            >
                                <label
                                    class="radio mb-0"
                                    :for="`shipping${index}`"
                                >
                                    <input
                                        type="radio"
                                        name="shipping"
                                        v-model="cart[0]"
                                        :value="method"
                                        :id="`shipping${index}`"
                                    />
                                    <span class="radio-label">{{
                                        method.title
                                    }}</span>
                                </label>
                                <span class="summary-card-body__price"
                                    >{{ method.price }} €
                                    <small>s DPH</small></span
                                >
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <!-- Osobne udaje -->
            <div class="summary-card mb-3">
                <div class="summary-card-head">
                    <h3 class="h5">Osobné údaje</h3>
                </div>
                <div class="summary-card-body">
                    <div class="summary-card-body__inner">
                        <div class="summary-card-body__item">
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.name
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.surname
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.street
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.streetNr
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.zip
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.city
                            }}
                            <br /><br />

                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.email
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .personalInformation.telephone
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje.personalInformation.ref_number ? 'Ref. číslo:' +$store.state.ponuka.stepState.osobneUdaje.personalInformation.ref_number : ''
                            }}

                            <!-- AKCIA: BF akcia -->
                            <!-- {{-->
                            <!--     $store.state.ponuka.stepState.osobneUdaje.personalInformation.ref_number ? 'Zľavový kód: ' +$store.state.ponuka.stepState.osobneUdaje.personalInformation.ref_number : ''-->
                            <!-- }}-->
                            <span
                                v-if="
                                    $store.state.ponuka.stepState.osobneUdaje
                                        .personalInformation.idCard ||
                                        $store.state.ponuka.stepState
                                            .osobneUdaje.personalInformation
                                            .idNr
                                "
                            >
                                <br />
                                <br />
                                {{
                                    $store.state.ponuka.stepState.osobneUdaje
                                        .personalInformation.idCard
                                }}
                                <br />
                                {{
                                    $store.state.ponuka.stepState.osobneUdaje
                                        .personalInformation.idNr
                                }}


                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Fakturacne udaje -->
            <div class="summary-card mb-3">
                <div class="summary-card-head">
                    <h3 class="h5">Fakturačné údaje</h3>
                </div>
                <div class="summary-card-body">
                    <div class="summary-card-body__inner">
                        <div class="summary-card-body__item">
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.name
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.surname
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.street
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.streetNr
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.zip
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.city
                            }}
                            <br /><br />

                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .billingInformation.telephone
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dodacia adresa -->
            <div class="summary-card mb-3">
                <div class="summary-card-head">
                    <h3 class="h5">Dodacie údaje</h3>
                </div>
                <div class="summary-card-body">
                    <div class="summary-card-body__inner">
                        <div class="summary-card-body__item">
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.name
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.surname
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.street
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.streetNr
                            }}
                            <br />
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.zip
                            }}
                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.city
                            }}
                            <br /><br />

                            {{
                                $store.state.ponuka.stepState.osobneUdaje
                                    .deliveryInformation.telephone
                            }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Objednávka -->
            <div class="card card--highlighted mb-3">
                <div class="card-item">
                    <div class="card-item-inner card-item-inner--center">
                        <div class="card-item-inner__content">
                            <h3 class="h4">Vaša objednávka spolu</h3>
                        </div>
                        <div class="card-item-inner__price">
                            <!-- Ak sa ukaze .sale a je v poradi DOM pred .regular, tak na .regular sa automaticky prida preskrtnutie -->
                            <!-- <strong class="sale">{{ subTotal }} €</strong> -->
                            <span class="total">{{ subTotal }} €</span>
                            <span>s DPH</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Checkboxy -->
            <div class="my-4" v-if="legislative.length">
                <label class="checkbox" for="suhlas-obchodne">
                    <input
                        type="checkbox"
                        v-model="formData.suhlasObchodne"
                        name="suhlas-obchodne"
                        id="suhlas-obchodne"
                    />
                    <span class="checkbox-label"
                        >Súhlasím s
                        <a
                            href="/dokument/vseobecne-podmienky-k-sluzbe-plustelka"
                            target="_blank"
                            class="text-highlighted"
                            >obchodnými podmienkami.</a
                        ></span
                    >
                </label>
                <!-- getLegislativeLinkByKey('vop')" -->

                <br />

                <label class="checkbox" for="suhlas-osobne">
                    <input
                        type="checkbox"
                        v-model="formData.suhlasOsobne"
                        name="suhlas-osobne"
                        id="suhlas-osobne"
                    />
                    <span class="checkbox-label"
                        >Súhlasím so
                        <a
                            :href="getLegislativeLinkByKey('gdpr')"
                            target="_blank"
                            class="text-highlighted"
                            >spracovaním osobných údajov.</a
                        ></span
                    >
                </label>

                <br />

                <label class="checkbox" for="suhlas-newsletter">
                    <input
                        type="checkbox"
                        v-model="formData.suhlasNewsletter"
                        name="suhlas-newsletter"
                        id="suhlas-newsletter"
                    />
                    <span class="checkbox-label"
                        >Chcem odoberať novinky z Plustelky.</span
                    >
                </label>
            </div>

            <div class="my-4">
                <conditions :headlineCentered="true">
                    <template v-slot:headline>
                        <div class="text-center">
                            Poučenie o odstúpení
                        </div>
                    </template>
                    <template v-slot:content>
                        <p>
                            Máte právo odstúpiť od Zmluvy bez uvedenia dôvodu v
                            lehote 14 dní odo dňa uzatvorenia Zmluvy, a to
                            jednoznačným vyhlásením (napríklad listom zaslaným
                            poštou alebo e-mailom) na adresu sídla
                            Poskytovateľa. Na tento účel môže použiť vzorový
                            <a href="/dokument/informacie-pre-spotrebitela"
                                >formulár na odstúpenie od Zmluvy</a
                            >. Lehota na odstúpenie od Zmluvy je zachovaná, ak
                            je oznámenie o uplatnení práva na odstúpenie od
                            Zmluvy zaslané najneskôr v posledný deň lehoty na
                            odstúpenie od Zmluvy. Nie ste oprávnený odstúpiť od
                            Zmluvy, ak sa poskytovanie Služby začalo a došlo k
                            úplnému poskytnutiu Služby s Vašim výslovným
                            súhlasom. V opačnom prípade, ste povinný uhradiť
                            Poskytovateľovi cenu za skutočne poskytnutú Službu
                            do dňa doručenia oznámenia o odstúpení od Zmluvy.
                        </p>
                        <br />
                        <p>
                            Odoslaním objednávky a Registráciou a uzatvorením
                            každej jednotlivej Zmluvy udeľujem vo vzťahu ku
                            každej jednotlivej Zmluve uzatváranej na základe
                            Registrácie svoj výslovný súhlas so začatím
                            poskytovania Služby pred uplynutím 14 dňovej lehoty
                            na odstúpenie od Zmluvy. Zároveň vyhlasujem, že som
                            bol riadne poučený o tom, že udelením súhlasu podľa
                            predchádzajúcej vety strácam právo na odstúpenie od
                            Zmluvy.
                        </p>
                        <br />
                        <p>
                            Odoslaním objednávky a Registráciou vyhlasujem, že
                            som sa v celom rozsahu oboznámil so Všeobecnými
                            podmienkami nákupu koncových zariadení SORTEC a
                            vyslovujem s nimi svoj bezpodmienečný súhlas. (Vaša
                            požiadavka na objednanie Koncových zariadení bude
                            odoslaná Dodávateľovi SORTEC EUROPE, s. r. o., ktorý
                            zabezpečuje distribúciu pre celú SR. Na základe
                            platnej legislatívy máte nárok odstúpiť od kúpnej
                            zmluvy s Dodávateľom do 14 dní od prevzatia tovaru.
                        </p>
                    </template>
                </conditions>
                <conditions :headlineCentered="true">
                    <template v-slot:headline>
                        Súhlas s vydávaním elektronickej faktúry za Služby
                    </template>
                    <template v-slot:content>
                        <p>
                            Uskutočnením objednávky udeľuje Užívateľ súhlas
                            s vydávaním elektronickej faktúry za Služby v súlade
                            s ustan. § 71 ods. 1 písm. b) zákona č. 222/2004
                            Z.z. o dani z pridanej hodnoty.
                        </p>
                    </template>
                </conditions>
                <conditions :headlineCentered="true">
                    <template v-slot:headline>
                        Súhlas so spracovaním osobných údajov Dodávateľom
                        koncového zariadenia
                    </template>
                    <template v-slot:content>
                        <p>
                            V prípade, ak ste si zároveň zvolili kúpu Koncového
                            zariadenia od Dodávateľa, ktorým je spoločnosť
                            SORTEC EUROPE, s.r.o., odoslaním objednávky začne
                            spracúvať Vaše osobné údaje rovnako Dodávateľ za
                            účelom plnenia predzmluvných vzťahov.
                            <a
                                href="https://www.sortec.sk/obchodne-podmienky_ma109.html"
                                target="_blank"
                            >
                                Bližšie informácie týkajúce sa spracúvania
                                osobných údajov sú dostupné na webovej stránke
                                Dodávateľa.</a
                            >
                        </p>
                    </template>
                </conditions>
                <p>
                    Odoslaním objednávky rovnako vyhlasujem, že som sa v celom
                    rozsahu oboznámil s Cenníkom, Osobitnými podmienkami a VOP a
                    vyslovujem s nimi svoj bezpodmienečný súhlas.
                </p>
            </div>

            <div class="row">
                <div
                    class="col-md-6 col-sm-4 order-sm-3 order-4 align-self-center"
                >
                    <button class="step-back" @click="prevStep">
                        <img
                            src="/img/icons/back.svg"
                            alt=""
                            class="step-back__icon"
                        />
                        <span class="step-back__title">Krok späť</span>
                    </button>
                </div>
                <div
                    class="col-md-6 col-sm-8 order-sm-4 order-3 align-self-center"
                >
                    <button
                        class="btn btn-primary btn--big btn--full"
                        :disabled="!stepFilledCorrectly"
                        @click="sendOrder"
                    >
                        <span>{{buttonContent}}</span>
                        <img
                            src="/img/icons/arrow-right.svg"
                            alt=""
                            class="btn__icon"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import map from "lodash/map";
import axios from "axios";
import conditions from "../../components/vue/conditions/conditions.vue";

//Mixins
import formStep from "../../mixins/formStep";
import { API_URL } from "../../config";

export default {
    mixins: [formStep],
    data() {
        return {
            stepName: "suhrnObjednavky",
            prevStepName: "antenaStep4",
            nextStepName: "antenaStep6",
            bindedMonths: 1,
            legislative: [],
            cart: [],
            shipping: [
                {
                    title: "Dobierka - kuriér UPS",
                    price: 3.6,
                    payment: "ONCE",
                    description: "doprava"
                },
                {
                    title: "Platba Vopred - kuriér UPS",
                    price: 3.0,
                    payment: "ONCE",
                    description: "doprava"
                }
            ],
            formData: {
                suhlasObchodne: false,
                suhlasOsobne: false,
                suhlasNewsletter: false
            },
            sending: false,

            // AKCIA: BF -10%, plustelka standart
            // bf_discount: ((this.$store.state.ponuka.stepState.osobneUdaje.personalInformation.ref_number === "EURO24") || (this.$store.state.ponuka.stepState.osobneUdaje.personalInformation.ref_number === "EURO2024")),
            // AKCIA: BF PLUSTELKA50 - 50% zlava na Premium
            bf_discount: (this.$store.state.ponuka.stepState.osobneUdaje.personalInformation.ref_number === "PLUSTELKA50"),
        };
    },

    components: {
        conditions
    },

    watch: {
        cart(newValue, oldValue) {
            this.saveStepState(this.currentStepStateToSave);
        },
        companyInformation: {
            handler(val) {
                this.saveStepState(this.currentStepStateToSave);
            },
            deep: true
        }
    },

    computed: {
        ...mapGetters(["getStepsState", "getStep", "getProgram"]),
        buttonContent() {
            if (this.stepFilledCorrectly) {
                return "Dokončit objednávku s povinosťou platby";
            }
            if(this.cart.length === 0){
                return "Prosím vyberte spôsob platby";
            }
            if(this.sending) {
                return 'Prosím počkajte...';
            }
            return "Prosím vyplňte všetky položky formulára";
        },
        stepFilledCorrectly() {
            // console.log(this.cart);
            return (
                // Bool
                // tu zhromazdujeme vsetky podmienky, ktore musia byt
                // splnene na to, aby formular mohol pokracovat dalej
                this.cart.length > 0 &&
                this.formData.suhlasObchodne == true &&
                    this.formData.suhlasOsobne == true &&
                    this.sending === false
            );
        },

        cartItems() {
            const allSteps = this.getStepsState;
            const that = this;

            //Cisty array ktory zhromazdi vsetky objednane produkty
            const allProducts = [];

            //Ocistime produkty z ulozeneho stavu
            const allCarts = map(allSteps, function(o) {
                if (o.cart != undefined && o.cart != null) {
                    o.cart.forEach(function(item) {
                        item.duration = that.bindedMonths;
                        item.period = "full";

                        // Optional Antena
                        if (
                            item.discount &&
                            typeof item.discount === "object" &&
                            that.bindedMonths >= item.discount.months
                        ) {
                            item.price = item.discount.price;
                        }
                        // END Optional Antena

                        allProducts.push(item);
                    });
                }
            });

            return allProducts;
        },
        monthlyItems() {
            return this.cartItems.filter(arr => {
                return arr.payment == "PM";
            });
        },
        monthlyItemsBeforeSale() {
            return this.cartItems.filter(arr => {
                return (
                    arr.payment == "PM" &&
                    (arr.period == "full" || arr.period == "sale")
                );
            });
        },
        monthlyItemsAfterSale() {
            return this.cartItems.filter(arr => {
                return (
                    arr.payment == "PM" &&
                    (arr.period == "full" || arr.period == "afterSale")
                );
            });
        },
        onceItems() {
            return this.cartItems.filter(arr => {
                return arr.payment == "ONCE";
            });
        },
        accumulatedMonthlyItems() {
            const num = this.monthlyItems
                .map(item => item.price)
                .reduce((a, b) => a + b, 0);
            return parseFloat(num.toFixed(2));
        },
        accumulatedMonthlyItemsBeforeSale() {
            const num = this.monthlyItemsBeforeSale
                .map(item => item.price)
                .reduce((a, b) => a + b, 0);
            return parseFloat(num.toFixed(2));
        },
        accumulatedMonthlyItemsAfterSale() {
            const num = this.monthlyItemsAfterSale
                .map(item => item.price)
                .reduce((a, b) => a + b, 0);
            return parseFloat(num.toFixed(2));
        },
        accumulatedOnceItems() {
            const num = this.onceItems
                .map(item => item.price)
                .reduce((a, b) => a + b, 0);
            return parseFloat(num.toFixed(2));
        },
        // subTotal() {
        //     const subTotal =
        //         this.accumulatedMonthlyItems * this.bindedMonths +
        //         this.accumulatedOnceItems;
        //
        //     return parseFloat(subTotal.toFixed(2));
        // },
        // subTotal() {
        //     let num = 0.0;
        //     for (let i = 0; i < this.monthlyItemsAfterSale.length; i++) {
        //         if (i === 0 && this.bindedMonths >= 11) {
        //             num += (this.monthlyItemsAfterSale[i].price - 1) * 11;
        //         } else {
        //             num +=
        //                 this.monthlyItemsAfterSale[i].price * this.bindedMonths;
        //         }
        //     }
        //     num += this.accumulatedOnceItems;
        //     let step = this.$store.state.ponuka.stepState;
        //     // if(step?.suhrnObjednavky?.cart[0]?.price) {
        //     //     num += step.suhrnObjednavky.cart[0].price;
        //     // }
        //     return parseFloat(num.toFixed(2));
        // },
        subTotal() {
            let num = 0.0;
            for (let i = 0; i < this.monthlyItemsAfterSale.length; i++) {
                // console.log(this.monthlyItemsAfterSale[i]);
                // AKCIA: 12 mesiac zadarmo
                // if (i === 0 && this.bindedMonths === 12) {
                //     num += this.monthlyItemsAfterSale[i].price * 11;
                // }
                // AKCIA: 6ty mesiac
                // else if (i === 0 && this.bindedMonths === 6) {
                //     num += ((this.monthlyItemsAfterSale[i].price - 0.5) * this.bindedMonths);
                // }
                // AKCIA: BF -10%, plustelka standart -> na rocny balik
                // if (i === 0 && this.bf_discount && this.bindedMonths === 12) {
                //     num += (6.29 * this.bindedMonths);
                // }
                // AKCIA: 12 mesiac zadarmo ak nie je BF Kod
                // else if (i === 0 && this.bindedMonths === 12 && !this.bf_discount) {
                //     num += this.monthlyItemsAfterSale[i].price * 11;
                // }
                // else if (i === 0 && this.bindedMonths === 6) {
                //     num += ((this.monthlyItemsAfterSale[i].price - 0.5) * this.bindedMonths);
                // }


                // // AKCIA: 12 mesiac zadarmo + BF Kod
                // if ((i === 0) && ((this.bindedMonths === 12) || this.bf_discount)) {
                //
                //     // AKCIA: 12 mesiac zadarmo + BF Kod
                //     if ((this.bindedMonths === 12) && this.bf_discount) {
                //         num += this.monthlyItemsAfterSale[i].price * 11 * 0.9;
                //     }
                //
                //     // AKCIA: len 12 mesiac zadarmo
                //     else if (this.bindedMonths === 12) {
                //         num += this.monthlyItemsAfterSale[i].price * 11;
                //     }
                //
                //     // AKCIA: len zlavovy kod
                //     else if (this.bf_discount) {
                //         num += (this.monthlyItemsAfterSale[i].price * this.bindedMonths * 0.9);
                //     }
                //
                //     else {
                //         num += this.monthlyItemsAfterSale[i].price * this.bindedMonths;
                //     }
                // }
                // AKCIA: 3,6,12 zlava
                if ((i === 0) && this.bindedMonths === 3) {
                    // 5% zlava
                    num += 6.65 * this.bindedMonths;
                }
                else if ((i === 0) && this.bindedMonths === 6) {
                    // 7% zlava
                    num += 6.49 * this.bindedMonths;
                }
                else if ((i === 0) && this.bindedMonths === 12) {
                    // 10% zlava
                    num += 6.29 * this.bindedMonths;
                }
                else {
                    if ((this.monthlyItemsAfterSale[i].title === "Plustelka Premium") && (this.bindedMonths === 12) && this.bf_discount) {
                        num += (this.monthlyItemsAfterSale[i].price * 12 * 0.5);
                    }
                    else {
                        num += this.monthlyItemsAfterSale[i].price * this.bindedMonths;
                    }
                }
            }
            num += this.accumulatedOnceItems;
            let step = this.$store.state.ponuka.stepState;
            // if(step?.suhrnObjednavky?.cart[0]?.price) {
            //     num += step.suhrnObjednavky.cart[0].price;
            // }
            return parseFloat(num.toFixed(2));
        },
        currentStepStateToSave() {
            //Nastavenia, ktore sa maju ulozit
            return {
                step: "suhrnObjednavky",
                state: {
                    //location: this.location

                    formData: this.formData,
                    cart: this.cart
                }
            };
        }
    },

    methods: {
        getHash(input){
            let hash = 0, len = input.length;
            for (let i = 0; i < len; i++) {
                hash  = ((hash << 5) - hash) + input.charCodeAt(i);
                hash |= 0; // to 32bit integer
            }
            return hash;
        },
        monthlyOrOnce(value) {
            if (value == "PM") return "mesačne";
            if (value == "ONCE") return "jednorazovo";
        },
        validateTel(value) {
            return value.match(/(\+)*(\d{3,4}\s?\d{3}\s?\d{3}\s?\d{0,3})/g);
        },
        validateEmail(value) {
            return value.match(/.+\@.+\.\w+/g);
        },
        getLegislativeLinkByKey(key) {
            return this.legislative.find(el => el.identifier == key).link;
        },
        sendOrder() {
            //google picus
            let productsGTM = [];

            // <Order CodeO="0000000003" ZDmDemIdO="_WS_DIST_001">
            //     <ComShipTo Id="494" Name="Nazev firmy/osoby 2" Street="Ulice XX/YYYY" City="Mesto" PostCode="AAA BB" Tel="+420 000 000 000" EMail="firma@firma.cz" />
            //     <OrdItemList>
            //         <OrdItem StiCode="0620533" Qty="1" />
            //         <OrdItem StiCode="0620552" Qty="3" />
            //     </OrdItemList>
            // </Order>
            // console.log(this.$store.state.ponuka.stepState);
            //TODO
            let step = this.$store.state.ponuka.stepState;
            let deliveryInfo = step.osobneUdaje.deliveryInformation;
            let personalInformation = step.osobneUdaje.personalInformation;
            let device = step.vyberZariadenia.cart[0];
            // let antena = step.vyberAnteny.cart[0];
            let packages = step.vyberBalikov;
            let data = {};
            data["Order"] = {};
            data["Order"]["OrdItemList"] = [];
            if (
                step.suhrnObjednavky.cart[0].title === "Dobierka - kuriér UPS"
            ) {

                data["Order"]["ZDmDemIdO"] = "_WS_DIST_005";
                data["Order"]["NoteInt"] = "STOOP";
                data["Order"]["Note"] = personalInformation.ref_number;
                data["Order"]["Type"] = "1";
                data["Order"]["PawId"] = "1";

                //shipping
                data["Order"]["OrdItemList"].push({
                    OrdItem: {
                        StiCode: "1560",
                        Qty: "1",
                        Prc: step.suhrnObjednavky.cart[0].price
                    }
                });

            } else {
                data["Order"]["ZDmDemIdO"] = "_WS_DIST_004";
                data["Order"]["NoteInt"] = "STOOP";
                data["Order"]["Note"] = personalInformation.ref_number;
                data["Order"]["Type"] = "1";
                data["Order"]["PawId"] = "3";
                //shipping
                data["Order"]["OrdItemList"].push({
                    OrdItem: {
                        StiCode: "1560",
                        Qty: "1",
                        Prc: step.suhrnObjednavky.cart[0].price
                    }
                });
            }
            // productsGTM.push({
            //     'name': step.suhrnObjednavky.cart[0].title,
            //     'id': ''+this.getHash( step.suhrnObjednavky.cart[0].title),
            //     'price': step.suhrnObjednavky.cart[0].price,
            //     'quantity': "1"
            // });

            //step.suhrnObjednavky.cart[0].title === 'Dobierka - kuriér UPS' ? '_WS_DIST_002' : '_WS_DIST_000';
            //<ComShipTo Name="Nazev firmy/osoby 2" Street="Ulice XX/YYYY" City="Mesto" PostCode="AAA BB" Tel="+420 000 000 000" EMail="firma@firma.cz" />
            data["Order"]["ComShipTo"] = {
                Name: deliveryInfo.name + " " + deliveryInfo.surname,
                Street: deliveryInfo.street + " " + deliveryInfo.streetNr,
                City: deliveryInfo.city,
                PostCode: deliveryInfo.zip,
                Tel: deliveryInfo.telephone,
                EMail: this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.email //deliveryInfo.email
            };
            data["Order"]["XCompany"] = {
                Name:
                    personalInformation.name +
                    " " +
                    personalInformation.surname,
                Street:
                    personalInformation.street +
                    " " +
                    personalInformation.streetNr,
                City: personalInformation.city,
                PostCode: personalInformation.zip,
                Tel: personalInformation.telephone,
                EMail: this.$store.state.ponuka.stepState.osobneUdaje
                    .personalInformation.email //deliveryInfo.email
            };

            //zariadenie
            if (device.title === "Domáce multimediálne centrum AMIKO A5") {
                data["Order"]["OrdItemList"].push({
                    OrdItem: {
                        StiCode: "4947",
                        Qty: "1",
                        Prc: device.price
                    }
                });
            } else if (
                device.title === "Full HD DVB-T/T2/C prijímač AMIKO Impulse"
            ) {
                data["Order"]["OrdItemList"].push({
                    OrdItem: {
                        StiCode: "7318",
                        Qty: "1",
                        Prc: device.price
                    }
                });
            } else if (
                device.title === "Full HD DVB-T/T2 príjmač Amiko Impulse 3 H.265"
            ) {
                data["Order"]["OrdItemList"].push({
                    OrdItem: {
                        StiCode: "5442",
                        Qty: "1",
                        Prc: device.price
                    }
                });
            } else {
                data["Order"]["OrdItemList"].push({
                    OrdItem: {
                        StiCode: "5233",
                        Qty: "1",
                        Prc: device.price
                    }
                });
            }
            //device
            productsGTM.push({
                'name': device.title,
                'id': ''+this.getHash( device.title),
                'price': device.price,
                'quantity': "1"
            });
            //antena ???
            // if (antena.title === "Vnútorná anténa Funke DSC550") {
            //     data["OrdItemList"].push({
            //         OrdItem: {
            //             StiCode: "6076",
            //             Qty: "1"
            //         }
            //     });
            // }
            // 4089	Plustelka Štandard - Predplatné na 1 mesiac (nedaňový náklad)
            // 4090	Plustelka DUO - Predplatné na 1 mesiac (nedaňový náklad)
            // 4093	Plustelka PREMIUM - Predplatné na 1 mesiac (nedaňový náklad)
            // 5322	Plustelka Plustelka PREMIUM - Ročné predplatné (nedaňový náklad)
            // 4475	Plustelka Viac - Predplatné na 1 mesiac (nedaňový náklad)
            // 4534	Plustelka Štandard - Predplatné na 6 mesiacov (nedaňový náklad)

            let qty = packages.bindedMonths;
            let pckCart = packages.cart;
            for (let i = 0; i < pckCart.length; i++) {
                productsGTM.push({
                    'name': pckCart[i].title,
                    'id': ''+this.getHash( pckCart[i].title),
                    'price': pckCart[i].price,
                    'quantity': qty
                });
                if (pckCart[i].title === "Plustelka Štandard") {

                    // } else if(qty === 13) {
                    //     data["Order"]["OrdItemList"].push({
                    //         OrdItem: {
                    //             StiCode: "4091",//TODO CODE
                    //             Qty: `1`,
                    //             Prc: pckCart[i].price*11
                    //         }
                    //     });
                    // }

                    // // AKCIA: 12ty mesiac zadarmo
                    // if(qty === 12) {
                    //     data["Order"]["OrdItemList"].push({
                    //         OrdItem: {
                    //             StiCode: "4091",
                    //             Qty: `1`,
                    //             Prc: pckCart[i].price * 11
                    //         }
                    //     });
                    // }
                    // AKCIA: 6ty mesiac
                    // else if(qty === 6) {
                    //     data["Order"]["OrdItemList"].push({
                    //         OrdItem: {
                    //             StiCode: "4534",
                    //             Qty: `1`,
                    //             // Qty: `${qty}`,
                    //             Prc: (pckCart[i].price - 0.5) * qty
                    //             // Prc: (pckCart[i].price - 0.5)
                    //         }
                    //         // 6 x 6,49€ = 38,94 €
                    //     });
                    // }
                    // AKCIA: BF -10%, plustelka standart -> na rocny balik
                    // if(this.bf_discount && (qty === 12)) {
                    //     data["Order"]["OrdItemList"].push({
                    //         OrdItem: {
                    //             StiCode: "4089",
                    //             Qty: `${qty}`,
                    //             Prc: 6.29
                    //         }
                    //     });
                    // }
                    // // AKCIA: BF zlavovy kod + 12ty mesiac zadarmo
                    // if (this.bf_discount && (qty === 12)) {
                    //     data["Order"]["OrdItemList"].push({
                    //         OrdItem: {
                    //             StiCode: "4091",
                    //             Qty: `1`,
                    //             Prc: pckCart[i].price * 11 * 0.9
                    //         }
                    //     });
                    // }
                    // AKCIA: 12ty mesiac zadarmo, ak nie je zadany kod BF akcia
                    // else if (!this.bf_discount && (qty === 12)) {
                    //     data["Order"]["OrdItemList"].push({
                    //         OrdItem: {
                    //             StiCode: "4091",
                    //             Qty: `1`,
                    //             Prc: pckCart[i].price * 11
                    //         }
                    //     });
                    // }
                    // // AKCIA: BF zlavovy kod
                    // else if (this.bf_discount) {
                    //     data["Order"]["OrdItemList"].push({
                    //         OrdItem: {
                    //             StiCode: "4089",
                    //             Qty: `${qty}`,
                    //             Prc: pckCart[i].price * 0.9
                    //         }
                    //     });
                    // }

                    // AKCIA: 3,6,12 zlava
                    if (qty === 3) {
                        data["Order"]["OrdItemList"].push({
                            OrdItem: {
                                StiCode: "4089",
                                Qty: `${qty}`,
                                Prc: 6.65
                            }
                        });
                    }
                    else if (qty === 6) {
                        data["Order"]["OrdItemList"].push({
                            OrdItem: {
                                StiCode: "4089",
                                Qty: `${qty}`,
                                Prc: 6.49
                            }
                        });
                    }
                    else if (qty === 12) {
                        data["Order"]["OrdItemList"].push({
                            OrdItem: {
                                StiCode: "4089",
                                Qty: `${qty}`,
                                Prc: 6.29
                            }
                        });
                    }
                    // -- koniec --- AKCIA: 3,6,12 zlava
                    else {
                        data["Order"]["OrdItemList"].push({
                            OrdItem: {
                                StiCode: "4089",
                                Qty: `${qty}`,
                                Prc: pckCart[i].price
                            }
                        });
                    }
                }
                if (pckCart[i].title === "Plustelka Viac") {
                    data["Order"]["OrdItemList"].push({
                        OrdItem: {
                            StiCode: "4475",
                            Qty: `${qty}`,
                            Prc: pckCart[i].price
                        }
                    });
                }
                if (pckCart[i].title === "Plustelka Premium") {
                    if((qty === 12) && this.bf_discount) {
                        data["Order"]["OrdItemList"].push({
                            OrdItem: {
                                // StiCode: "5322",
                                // Qty: `1`,
                                // Prc: 6
                                StiCode: "4093",
                                Qty: `${qty}`,
                                Prc: pckCart[i].price * 0.5
                            }
                        });
                    }
                    else {
                        data["Order"]["OrdItemList"].push({
                            OrdItem: {
                                StiCode: "4093",
                                Qty: `${qty}`,
                                Prc: pckCart[i].price
                            }
                        });
                    }
                }
            }
            // dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            // dataLayer.push({
            //     'event': 'checkout',
            //     'ecommerce': {
            //         'actionField': { 'step':5},
            //         'checkout': {
            //             'products': productsGTM,
            //         }
            //     },
            // });

            console.log(data);
            this.sending = true;
            if (this.formData.suhlasNewsletter) {
                let formData = new FormData();
                formData.set(
                    "email",
                    this.$store.state.ponuka.stepState.osobneUdaje
                        .personalInformation.email
                );
                this.$http.post("newsletter/api/subscribe", formData);
            }
            this.$http.post("/api/soapSendOrder", data).then(data => {
                if (data.data.success) {
                    dataLayer.push({
                        'event': 'purchase',
                        'ecommerce': {
                            'purchase': {
                                'actionField': {
                                    'id': data.data.order_no,
                                    'revenue': this.subTotal,
                                    'shipping': step.suhrnObjednavky.cart[0].price,
                                    'step':4
                                },
                                'products': productsGTM
                            }
                        }
                    });
                    if (
                        step.suhrnObjednavky.cart[0].title ===
                        "Dobierka - kuriér UPS"
                    ) {
                        this.nextStep();
                    } else {
                        this.sendPayment(
                            data.data.order_no,
                            data.data.order_slug
                        );
                    }
                }
            });
        },
        sendPayment(order_no, slug) {
            let step = this.$store.state.ponuka.stepState;
            let data = {
                amount: this.subTotal,
                order_number: order_no,
                order_slug: slug,
                fullName: step.osobneUdaje.deliveryInformation.name
            };
            this.$http.post("/api/sendPayment", data).then(response => {
                window.location.href = response.data.url;
            });
        },
    },

    async mounted() {
        const stepDataVB = this.getStep("vyberBalikov");
        // console.log(stepData);
        let products = [];
        let months = this.$store.state.ponuka.stepState.vyberBalikov.bindedMonths;
        // console.log('---');
        if (stepDataVB) {
            // console.log(stepData);
            let service = stepDataVB.service;
            let freePackages = stepDataVB.freePackages;
            for (let i = 0; i < service.length; i++) {
                console.log(service[i]);
                products.push(
                    {
                        'name': service[i].name,
                        'id': ''+this.getHash( service[i].name),
                        'price': service[i].price,
                        'quantity': months
                    }
                );
            }
        }
        let devices = this.getStep("vyberZariadenia");
        if (devices) {
            for(let i = 0; i < devices.cart.length; i++) {
                products.push(
                    {
                        'name': devices.cart[i].title,
                        'id': ''+this.getHash( devices.cart[i].title),
                        'price': devices.cart[i].price,
                        'quantity': 1
                    }
                );
            }
        }
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'actionField': { 'step':4},
                'checkout': {
                    'products': products,
                }
            },
        });


        const legislative = await axios.post("/api/legislative");
        //console.log(legislative);
        this.legislative = legislative.data.data;

        //Object.assign(this.companyInformation, this.getStep('osobneUdaje').personalInformation)
        this.bindedMonths = parseFloat(
            this.$store.state.ponuka.stepState.vyberBalikov.bindedMonths
        );
        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        const stepData = this.getStep("suhrnObjednavky");
        if (stepData) {
            //this.location = stepData.location;
            this.cart = stepData.cart;
            this.companyInformation = stepData.companyInformation;
            this.formData = stepData.formData;
        }
    }
};
</script>

<style lang="scss" scoped></style>
