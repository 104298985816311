// Vuex base
import Vue from 'vue';
import Vuex from 'vuex';

//Vuex stores
import ponuka from './modules/ponuka';

Vue.use(Vuex);

export default new Vuex.Store({
    modules : {
        ponuka
    }
});
