export const API_URL = 'https://moja.plustelka.sk/ZakNew/api/';
const bearer = 'Authorization: Bearer LVPYX/tBWLeg+DQIFLtYCZZZ6RjPq+dpKH6252FQr5I=';
export const DEFAULT_AUTH = [
    // bearer,
    'Content-Type: application/x-www-form-urlencoded',
];
export const JSON_AUTH = [
    // bearer,
    'Content-Type: application/json',
];
