<template>
    <div>
        <div class="channel-header">
            <h2 class="h4 mb-1">
                Dostupné programy
            </h2>
            <p class="mb-3">
                Hľadáte konkrétny program?
            </p>
            <input
                v-model="searchTerm"
                class="form-control"
                placeholder="Zadajte názov TV programu, ktorý hľadáte"
                type="text"
            />

            <div class="news__nav mt-4" v-if="showFilter">
                <div
                    class="news__nav__item"
                    :class="{ active: filterCriteria == 'antennaAndInternet' }"
                    @click="filterCriteria = 'antennaAndInternet'"
                >
                    <span>Cez anténu a internet</span>
                </div>

                <div
                    class="news__nav__item"
                    :class="{ active: filterCriteria == 'antenna' }"
                    @click="filterCriteria = 'antenna'"
                >
                    <span>Cez anténu</span>
                </div>
            </div>
        </div>

        <transition name="inter-fade" mode="out-in">
            <!-- Genres container -->
            <div key="1" class="genres-container" v-if="!isLoading">
                <!-- Genre default packages -->
                <div
                    class="genre pt-5"
                    v-for="(genre, index) in genresUnderDefaultPackages"
                    :key="`${programId}defaultPackageGenre${index}`"
                    :id="`defaultPackageGenre-${index}`"
                >
                    <h3 class="h6" v-if="programId === 1 && index === 0">
                        Programová ponuka Plustelka Štandard
                    </h3>
                    <h3 class="h6" v-else>{{ genre.name }}</h3>

                    <div class="channel-grid">
                        <div
                            class="channel-grid__channel"
                            :class="{
                                hidden: !filteredChannels.includes(channel)
                            }"
                            v-for="(channel, index) in genre.channels"
                            :key="`${programId}default${index}`"
                        >
                            <figure class="channel-grid__channel__logo">
                                <img :src="channel.logo" alt="" />
                            </figure>
                            <small class="channel-grid__channel__name">
                                {{ channel.name }}
                            </small>
                        </div>
                    </div>
                </div>
                <!-- /Genre default packages -->

                <!-- Packages separator -->
                <div
                    class="pt-5"
                    v-if="
                        getProgram(programId).additional_packages &&
                            getProgram(programId).additional_packages > 0
                    "
                >
                    <h2 class="h6 packages-separator">Doplnkové balíky</h2>
                    <p class="text-highlighted large-text">
                        V cene služby máte
                        {{ getProgram(programId).additional_packages }}
                        doplnkové balíky podľa vlastného výberu
                    </p>
                </div>
                <!-- /Packages separator -->

                <!-- Genre additional packages -->
                <div
                    class="genre pt-5"
                    v-for="(genre, index) in genresUnderAdditionalPackages"
                    :key="`${programId}additionalPackageGenre${index}`"
                    :id="`additionalPackageGenre-${index}`"
                >
                    <h3 class="h6">{{ genre.name }}</h3>

                    <div class="channel-grid">
                        <div
                            class="channel-grid__channel"
                            :class="{
                                hidden: !filteredChannels.includes(channel)
                            }"
                            v-for="(channel, index) in genre.channels"
                            :key="`${programId}additional${index}`"
                        >
                            <figure class="channel-grid__channel__logo">
                                <img :src="channel.logo" alt="" />
                            </figure>
                            <small class="channel-grid__channel__name">
                                {{ channel.name }}
                            </small>
                        </div>
                    </div>
                </div>
                <!-- /Genre additional packages -->
            </div>
            <!-- /Genres container -->

            <!-- Loading -->
            <div key="2" class="loading-container" v-else>
                <div class="loading-pulse__dark">
                    Načítavam ...
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
//Vendors
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import _flatten from "lodash/flatten";
import _orderBy from "lodash/orderby";

export default {
    data() {
        return {
            allGenres: [],
            genresUnderDefaultPackages: [],
            genresUnderAdditionalPackages: [],

            onlyGenresWithChannels: [],
            onlyChannels: [],
            searchTerm: "",
            isLoading: true,
            showPublicStreaming: false,
            filterCriteria: "antennaAndInternet"
        };
    },

    props: ["programId", "showPublic", "showFilter"],

    computed: {
        ...mapGetters([
            "getProgram",
            "getAllPackagesUnderProgram",
            "getDefaultPackagesUnderProgram",
            "getAdditionalPackagesUnderProgram",
            "isLoadingData"
        ]),
        filteredChannels() {
            let result = this.onlyChannels;

            if (this.filterCriteria == "antenna" && this.showFilter) {
                result = result.filter(el => {
                    return el.antenna == true;
                });
            }

            if (
                this.filterCriteria == "antennaAndInternet" &&
                this.showFilter
            ) {
                result = result.filter(el => {
                    return el.antenna == true || el.internet == true;
                });
            }

            // asladasd
            result = result.filter(el => {
                const searchIn = el.name
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                const term = this.searchTerm
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");

                return searchIn.includes(term);
            });

            return result;
        }
    },

    // ##     ## ######## ######## ##     ##  #######  ########   ######
    // ###   ### ##          ##    ##     ## ##     ## ##     ## ##    ##
    // #### #### ##          ##    ##     ## ##     ## ##     ## ##
    // ## ### ## ######      ##    ######### ##     ## ##     ##  ######
    // ##     ## ##          ##    ##     ## ##     ## ##     ##       ##
    // ##     ## ##          ##    ##     ## ##     ## ##     ## ##    ##
    // ##     ## ########    ##    ##     ##  #######  ########   ######

    methods: {
        ...mapActions(["fetchPrograms"]),

        searchInChannels(event) {
            var filter;
        },

        getOnlyGenresWithChannels() {
            let filtered = this.allGenres.filter(el => {
                //console.log(el);
                return el.channels && el.channels.length > 0;
            });

            return filtered;
        },

        getOnlyChannels() {
            let channels = this.onlyGenresWithChannels.map(el => {
                return el.channels;
            });

            //Splostime array - aby nebol array->array->object ale rovno array -> object
            channels = _flatten(channels);

            //Odstranime duplikaty na zaklade ID
            channels = channels.filter(
                (thing, index, self) =>
                    index === self.findIndex(t => t.id === thing.id)
            );

            return channels;
        },

        convertPackagesToGenres(packages) {
            let onlyChannels = packages.map(arr => {
                // Pridame jednotlivym kanalom ci su sucastou

                return arr.channels;
            });

            //Splostime array, odstranime prazdne polia
            onlyChannels = _flatten(onlyChannels);
            //console.log(allPackages);

            //Odstranime duplikaty na zaklade ID
            onlyChannels = onlyChannels.filter(
                (thing, index, self) =>
                    index === self.findIndex(t => t.id === thing.id)
            );

            // Zoradime kanaly podla ich poradia
            onlyChannels = _orderBy(onlyChannels, ["order"], ["asc"]);

            ///console.log(onlyChannels);

            let genres = [];

            //Pridame zanre existujucich kanalov bez duplikatov
            onlyChannels.map(arr => {
                // V pripade ze je zapnute, aby komponent zobrazoval kanale volneho vysielania a zaroven ak je kanal
                if (this.showPublic && arr.free_streaming) {
                    //Ak neexistuje zaner vseobecneho volneho vysielania, vytvorime ho s id 9999 aby nekolidovalo nahodou s inym zanrom
                    if (!genres.some(el => el.id === 9999)) {
                        genres.push({
                            id: 9999,
                            name:
                                "Programová ponuka voľného vysielania cez anténu",
                            slug: "volne-vysielanie"
                        });
                    }

                    //Do vytvoreneho zanru pushneme samotny kanal
                    const targetGenre = genres.find(x => x.id == 9999);

                    //Ak neexistuju kanale pre zaner, tak ich vytvor
                    if (!targetGenre.channels) {
                        targetGenre.channels = [];
                    }

                    //Pridaj do kanalov pod zaner, konkretny kanal
                    //Aby sa predchadzalo duplikatom, najprv pozri, ci tam nahodou ten kanal uz nieje
                    if (!targetGenre.channels.some(el => el.id == arr.id)) {
                        targetGenre.channels.push(arr);
                    }
                }

                //V pripade ze ide o bezny kanal
                else if (arr.genre) {
                    //Ak neexistuje zaner, vytvorime ho
                    if (!genres.some(el => el.id === arr.genre.id)) {
                        genres.push(arr.genre);
                    }

                    //Do vytvoreneho zanru pushneme samotny kanal
                    const targetGenre = genres.find(x => x.id == arr.genre.id);

                    //Ak neexistuju kanale pre zaner, tak ich vytvor
                    if (!targetGenre.channels) {
                        targetGenre.channels = [];
                    }

                    //Pridaj do kanalov pod zaner, konkretny kanal
                    //Aby sa predchadzalo duplikatom, najprv pozri, ci tam nahodou ten kanal uz nieje
                    if (!targetGenre.channels.some(el => el.id == arr.id)) {
                        targetGenre.channels.push(arr);
                    }
                }
            });

            return genres;
        }
    },

    async mounted() {
        //let fetchPrograms = await axios.post(`api/packages/program/${this.programId}`);
        //Ak neexistuje v store zapis s kanalmi, tak fetchni vsetky programy - toto je tu na to aby sa komponent dal pouzit aj samostatne
        if (!this.isLoadingData) {
            const data = await this.fetchPrograms();
        }

        let allPackages = this.getAllPackagesUnderProgram(this.programId); //fetchPrograms.data.data;
        let defaultPackages = this.getDefaultPackagesUnderProgram(
            this.programId
        );
        let additionalPackages = this.getAdditionalPackagesUnderProgram(
            this.programId
        );
        // console.log(allPackages);

        this.allGenres = this.convertPackagesToGenres(allPackages);
        this.genresUnderDefaultPackages = this.convertPackagesToGenres(
            defaultPackages
        );
        this.genresUnderAdditionalPackages = this.convertPackagesToGenres(
            additionalPackages
        );

        // Otocenie poradia aby bol zakladny balik 1 a vysielanie cez antenu 2
        this.genresUnderDefaultPackages = this.genresUnderDefaultPackages.reverse();
        //console.log(this.allGenres);

        this.onlyGenresWithChannels = this.getOnlyGenresWithChannels();
        this.onlyChannels = this.getOnlyChannels();

        this.isLoading = false;

        ///console.log(this.packageId);

        if (this.$route.hash) {
            setTimeout(() => {
                const el = document.querySelector(this.$route.hash);
                if (el) {
                    el.scrollIntoView();
                }
            }, 600);
        }
    }
};
</script>

<style lang="scss" scoped>
.channel-header {
    .form-control {
        max-width: 695px;
    }
}

.large-text {
    font-size: 1.25rem;
}

.news__nav {
    max-width: 30rem;
    width: 100%;

    &__item {
        &:not(.active) {
            &::after {
                background: white !important;
            }
        }
    }
}

.packages-separator {
    font-size: 2.5rem;
    font-weight: bold;
}

.genres-container {
    transition: all 0.5s ease-out;
}

.genre {
    &__name {
        font-size: 1.3em;
        font-weight: 700;
        margin-bottom: 2rem;
        text-align: left;

        @media (max-width: 470px) {
            text-align: center;
        }
    }
}

.channel-grid {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(86px, 1fr));

    &__channel {
        text-align: center;
        transition: all 0.4s ease-out;
        margin-top: 1.1rem;

        &__logo {
            display: flex;
            align-items: center;
            height: 86px;
            width: 86px;
            justify-content: center;
            margin: 0 auto;
            border: 3px solid #f2f2f2;

            img {
                width: 53px;
                height: auto;
                max-height: 100%;
                object-fit: contain;
            }
        }

        &__name {
            display: inline-block;
            line-height: 1.4em;
            color: #333333;
        }

        &.hidden {
            opacity: 0.1;
        }
    }
}
</style>
