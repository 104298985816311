<template>
    <div class="container">
        <!-- Zariadenia -->
        <div class="section section--small">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-7 pr-lg-4 mb-lg-0 mb-4">
                        <div class="offer__wrapper" v-if="devices.length">
                            <!--  -->
                            <device-card
                                v-for="device in devices"
                                v-model="orderDevice[0]"
                                name="device"
                                :key="`input${device.id}`"
                                :device="device"
                                :checked="isDeviceActive(device.id)"
                                :promo="isDeviceEligableForPromo(device)"
                            >
                                <template
                                    v-slot:disclaimer
                                    v-if="
                                        !isDeviceEligableForPromo(device) &&
                                            device.selectedPrice.discounts
                                    "
                                >
                                    <!-- V pripade, ze je v sluzbe spustena promo akcia -->
                                    <template>
                                        <div class="offer__item__disclaimer">
                                            Objednajte si službu Plustelka
                                            Štandard na
                                            {{
                                                device.selectedPrice
                                                    .discounts[0].months
                                            }}
                                            mesiace a viac a získajte toto
                                            zariadenie za
                                            {{
                                                device.selectedPrice
                                                    .discounts[0].price
                                            }}€
                                            <button
                                                class="btn btn-primary mt-2"
                                                @click="
                                                    prevStep();
                                                    scrollToFooter();
                                                "
                                            >
                                                Späť na výber predplatného
                                            </button>
                                        </div>
                                    </template>
                                </template>
                            </device-card>

                            <!-- Static Device - zariadenie uz mam -->
                            <div class="device">
                                <!--                                    v-model="orderDevice[0]"-->
                                <input
                                    type="radio"
                                    class="card-input card-input--no-icon"
                                    name="staticAntenna"

                                    :id="`staticInput${staticDevice.id}`"
                                    :value="evaluateStaticDeviceValue"
                                    :checked="isDeviceActive(staticDevice.id)"
                                    hidden
                                />
                                <label
                                    class="card card--label mb-3"
                                    :for="`staticInput${staticDevice.id}`"
                                    :key="`staticLabel${staticDevice.id}`"
                                >
                                    <transition name="inter-fade" mode="out-in">
                                        <!--
                                                Default view - ak nieje zadane nic
                                                popr. ak dojde k chybe v kontakte s API serverom ukaze v spodnej casti, pod formularom error
                                            -->
                                        <div
                                            v-if="staticDeviceValidity == 0"
                                            class="slim__content"
                                            key="1"
                                        >
                                            <h3 class="h4">
                                                {{ staticDevice.title }}
                                            </h3>
                                            <p class="my-2">
                                                Overte si, či Vaše zariadenie je
                                                možné pripojiť k Plustelka
                                                službe
                                            </p>

                                            <div class="form-control-group">
                                                <input
                                                    class="form-control form-control-group__input"
                                                    type="text"
                                                    placeholder="Zadajte sériové číslo"
                                                    v-model="
                                                        staticDeviceSerialNo
                                                    "
                                                />
                                                <div class="input-group-append">
                                                    <button
                                                        @click="verifyDevice()"
                                                        class="btn btn-primary btn--big btn--full-h form-control-group__button"
                                                    >
                                                        <span>{{
                                                            validatingBtnContent
                                                        }}</span>
                                                        <img
                                                            src="img/icons/arrow-right.svg"
                                                            alt=""
                                                            class="btn__icon"
                                                        />
                                                    </button>
                                                </div>
                                            </div>

                                            <small
                                                v-if="!staticDeviceValidating"
                                                class="mt-2 form-msg"
                                            >
                                                {{ staticDeviceError }}
                                            </small>
                                        </div>

                                        <!--
                                                Success - ak je odpoved od API "zariadenie vyhovuje"
                                                treba ho aktivovat - odchod do klientskej zony
                                            -->
                                        <div
                                            v-if="staticDeviceValidity == 1"
                                            class="slim__content"
                                            key="2"
                                        >
                                            <h3 class="h4">
                                                Vaše zariadenie vyhovuje
                                            </h3>
                                            <p class="my-2">
                                                Výborne! Vaše zariadenie sa
                                                podarilo overiť a je možné ho
                                                využívať na sledovanie televízie
                                                so službou Plustelka Štandard.
                                                Prosím dokončite objednávku cez
                                                zákaznícku zónu.
                                            </p>
                                            <a
                                                href="https://moja.plustelka.sk/index.php/PrepaidServices/ActivateTv"
                                                target="_blank"
                                                class="btn btn-primary"
                                            >
                                                Zákaznícka zóna
                                            </a>
                                        </div>

                                        <!--
                                                Success - ale zariadenie nevyhovuje
                                                zakaznik si musi vybrat jedno z vyssich zariadeni
                                            -->
                                        <div
                                            v-if="staticDeviceValidity == 2"
                                            class="slim__content"
                                            key="3"
                                        >
                                            <h3 class="h4">
                                                Vaše zariadenie nevyhovuje
                                            </h3>
                                            <p class="my-2">
                                                Prepáčte, ale Vaše zariadenie
                                                nie je možné využiť na
                                                sledovanie televízie so službou
                                                Plustelka Štandard. Prosím
                                                zvoľte si jedno z vyššie
                                                uvedených zariadení a dokončite
                                                objednávku.
                                            </p>
                                        </div>

                                        <!--
                                                Success - ak je odpoved od API "zmluva"
                                                klient musi zavolat na klientske centrum
                                            -->
                                        <div
                                            v-if="staticDeviceValidity == 3"
                                            class="slim__content"
                                            key="4"
                                        >
                                            <h3 class="h4">
                                                Vaše zariadenie vyhovuje
                                            </h3>
                                            <p class="my-2">
                                                Vaše zariadenie je už viazané inou zmluvou. Prosím, prejdite do zákazníckej zóny.
                                            </p>
                                            <div class="text-highlighted">
                                                <a  target="_blank"
                                                    class="btn btn-primary"
                                                    href="https://moja.plustelka.sk/index.php/PrepaidServices/Check">
                                                    Zákaznícka zóna
                                                </a>
                                            </div>
                                        </div>
                                    </transition>
                                </label>
                            </div>
                            <!-- /Static device -->
                        </div>
                        <div class="mb-5 text-left">
                            <conditions :headlineCentered="true">
                                <template v-slot:headline>
                                    <div class="text-center">
                                        Podmienky
                                    </div>
                                </template>
                                <template v-slot:content>
                                    <p>
                                        Dodávateľom Koncového zariadenia je
                                        spoločnosť SORTEC EUROPE, s.r.o.., so
                                        sídlom Betliarska 4031/19, Bratislava -
                                        mestská časť Petržalka, 851 07 zap. v OR
                                        OS Bratislava I, oddiel Sro, vl. č.:
                                        60876/B, IČO: 45 001 103 (ďalej len
                                        „Dodávateľ“).
                                    </p>
                                    <br />
                                    <p>
                                        Poskytovateľ neposkytuje Užívateľovi
                                        Koncové zariadenia potrebné na
                                        zabezpečenie prístupu k Službe ako ani
                                        nezabezpečuje funkčnosť Koncového
                                        zariadenia Užívateľa a nezodpovedá za
                                        vady Koncového zariadenia. Právny vzťah
                                        týkajúci sa kúpy Koncového zriadenia
                                        vzniká výlučne medzi Dodávateľom a
                                        Užívateľom a spravuje sa
                                        <a
                                            href="/dokument/vseobecne-obchodne-podmienky"
                                            target="_blank"
                                            >Všeobecnými podmienkami</a
                                        >
                                        nákupu koncových zariadení SORTEC, ktoré
                                        sú zverejnené na Webovom sídle
                                        Poskytovateľa
                                        <a
                                            href="http://www.sortec.sk"
                                            target="_blank"
                                            >www.sortec.sk</a
                                        >.
                                    </p>
                                </template>
                            </conditions>
                        </div>
                    </div>

                    <!-- Sidebar -->
                    <div class="col-lg-5 pl-lg-4">
                        <div class="card-wrapper">
                            <CartComponent :add-item="orderDevice" :step="2">
                            </CartComponent>
                            <button
                                class="btn btn-primary btn--big btn--full mt-3"
                                :disabled="!stepFilledCorrectly"
                                @click="nextStep"
                            >
                                {{ nextBtnContent }}
                                <img
                                    src="/img/icons/arrow-right.svg"
                                    alt=""
                                    class="btn__icon"
                                />
                            </button>
                        </div>
                    </div>

                    <!-- Krok spat -->
                    <div
                        class="col-md-6 col-sm-4 order-sm-3 order-4 align-self-center"
                    >
                        <button class="step-back" @click="prevStep">
                            <img
                                src="/img/icons/back.svg"
                                alt=""
                                class="step-back__icon"
                            />
                            <span class="step-back__title">Krok späť</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import deviceCard from "../../components/vue/ponuka/device-card.vue";
import conditions from "../../components/vue/conditions/conditions.vue";

import CartComponent from "../../components/vue/CartComponent";
import { mapGetters } from "vuex";

//Mixins
import formStep from "../../mixins/formStep";
import { API_URL, DEFAULT_AUTH } from "../../config";

import ContactForm from "../ContactForm";

export default {
    //formStep - obsahuje spolocnu funkcnost ktora plati pre vsetky kroky objednavkoveho formulara ako pre Annetu tak aj pre Antenu
    mixins: [formStep],

    data() {
        return {
            //Vuex a Vue router nastavenia pre spravne fungovanie formularu
            stepName: "vyberZariadenia",
            prevStepName: "antenaStep1",
            nextStepName: "antenaStep4",

            //Lokalny "nakupny kosik" plni sa pomocou watchera a pri ulozeni kroku, sa posiela do Vuex
            cart: [],

            //Array do ktoreho budu padat vsetky objednane zariadenia
            orderDevice: [],

            //Array zariadeni, ktore su dostupne pod vybranym programom - plnia sa pri "mounted"
            devices: [],

            // Nastavenie statickeho zariadenia - "Zariadenie uz mam"
            // v pripade, ze to podmienky umoznia, tak tieto data budu pouzite na to aby sa
            // tato polozka pridala do "kosika" a bola sucastou zuctovania v poslednom kroku
            staticDevice: {
                id: 9999,
                title: "Zariadenie už mám",

                selectedPrice: {
                    interval: "ONCE",
                    discounted: 0,
                    regular: 0
                }
            },
            staticDeviceSerialNo: "",
            staticDeviceValidating: false,
            staticDeviceValidity: 0,
            staticDeviceError: ""
        };
    },

    components: {
        deviceCard,
        conditions,
        ContactForm,
        CartComponent
    },

    methods: {
        async verifyDevice() {
            //Zapneme stav, kedy sa formular tvari, ze validuje
            this.staticDeviceValidating = true;
            // Simulacia stavov success a fail

            // Success:
            // v pripade ze zadame "123" vrati sa stav 1 -> zariadenie je viazane inou zmluvou
            // v pripade ze zadame "456" vrati sa stav 2 -> zariadenie nieje mozne pripojit
            // v pripade ze zadame "789" vrati sa stav 3 -> zariadenie nieje mozne pripojit

            // Fail:
            // ak zadame akukolvek inu hodnotu, tak sa vrati fail (simulacia zlyhania callu na API)

            let data = {};
            data["method"] = "GET";
            data["headers"] = DEFAULT_AUTH;
            data["url"] = API_URL + "Devices/eshop/get-device/";
            data["data"] = { identifier: this.staticDeviceSerialNo };
            this.$http.post("/api/external", data).then(data => {
                console.log(data);
                if (data.data.success && data.data.result) {
                    let res = data.data.result;
                    if(res.location === "Sklad" && res.storeCode === "HS" && res.status ===  "Na sklade") {
                        this.staticDeviceValidity = 1;
                    }
                    if(res.location === "Zákazník") {
                        this.staticDeviceValidity = 3;
                    }

                    // this.orderDevice.push(this.staticDevice);
                } else {
                    this.staticDeviceValidity = 2;
                    //this.staticDeviceError = 'Pri validácií zariadenia došlo k chybe, skúste to prosím ešte raz. Poprípde nám zavolajte na tel.č. 0650 444 400'
                }
                //Vypneme stav, kedy sa formular tvari, ze validuje
                this.staticDeviceValidating = false;
            });
        },

        isDeviceActive(id) {
            if (this.orderDevice[0]) {
                return this.orderDevice[0].id == id;
            } else {
                return false;
            }
        },

        isDeviceEligableForPromo(device) {
            //vytiahneme si zo store pocet mesiacov, ktore si klient vybral v kroku 1
            const bindedMonths = this.$store.state.ponuka.stepState.vyberBalikov
                .bindedMonths;

            //Vrat true ak je na zariadenie zlava, je splneny minimalny pocet mesiacov z kroku 1 (vyber balikov)
            return (
                device.selectedPrice.discounts &&
                bindedMonths >= device.selectedPrice.discounts[0].months
            );
        }
    },

    computed: {
        ...mapGetters([
            "getStep",
            "getDevicesPricesUnderSelectedProgram",
            "getDevicesUnderProgram",
            "getAntennasUnderProgram"
        ]),
        stepFilledCorrectly() {
            return (
                // Bool
                // tu zhromazdujeme vsetky podmienky, ktore musia byt
                // splnene na to, aby formular mohol pokracovat dalej
                this.orderDevice[0]
            );
        },
        nextBtnContent() {
            if (this.stepFilledCorrectly) return "Ďalší krok";
            else return "Prosím vyberte si zariadenie";
        },
        currentStepStateToSave() {
            //Nastavenia, ktore sa maju ulozit
            return {
                step: "vyberZariadenia",
                state: {
                    orderDevice: this.orderDevice,
                    cart: this.cart
                }
            };
        },

        validatingBtnContent() {
            if (this.staticDeviceValidating) {
                return "Overujem ...";
            } else return "Overiť zariadenie";
        },

        evaluateStaticDeviceValue() {
            // Vracia vzdy false, lebo v pripade annety, nech skonci validacia akokolvek,
            // zakaznik nemoze pokracovat s vybratou moznostou "zariadenie uz mam"
            if (this.staticDeviceValidity == 1) {
                return true;
            }
            return false;
        }
    },

    watch: {
        //Sledujeme polozku additional services, a podla nej pridame polozky do arrayu cart z ktore sa na konci vypocita vysledna cena
        orderDevice: {
            handler(value) {
                this.cart = [];
                value.forEach(service => {
                    //console.log(service)
                    if (service != null && service != false) {
                        //Ak existuje sale price, uprednosti ju pred regular price a zapis ju do kosika, aby vysledna cena bola korektna
                        //console.log(service.price);
                        let finalPrice = service.selectedPrice.regular;

                        // Aplikacia beznej akciovej ceny
                        if (
                            service.selectedPrice.discounted != 0 &&
                            service.selectedPrice.discounted != ""
                        ) {
                            finalPrice = service.selectedPrice.discounted;
                        }

                        // Aplikacia promo akcnej ceny
                        // ak existuje pre dane zariadenie promo akcia a je splnena podmienka objednaneho poctu mesiacov
                        if (
                            service.selectedPrice.discounts &&
                            service.selectedPrice.discounts[0].price > 0 &&
                            this.isDeviceEligableForPromo(service)
                        ) {
                            finalPrice =
                                service.selectedPrice.discounts[0].price;
                        }

                        let accountedService = {
                            title: service.title,
                            price: parseFloat(finalPrice),
                            payment: service.selectedPrice.interval,
                            description: "zariadenie"
                        };
                        this.cart.push(accountedService);
                    }
                });
                this.saveStepState(this.currentStepStateToSave);
            },
            deep: true
        }
    },
    mounted() {

        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        const stepData = this.getStep("vyberZariadenia");
        if (stepData) {
            //this.location = stepData.location;
            this.orderDevice = stepData.orderDevice;
        }

        //Ak neexistuju zapisane data z predosleho kroku, tak vrat usera na predosly krok
        // if(!this.getStep('vyberBalikov')){
        //     this.prevStep();
        //     return
        // }

        //this.devices = this.getDevicesPricesUnderSelectedProgram();

        // Pri nacitani vytiahneme zariadenia ktore su pod programom (id)
        // id programu si vytiahneme pomocou getteru getSelectedAnnetaPackage
        this.devices = this.getDevicesUnderProgram(1);
        let antennas = this.getAntennasUnderProgram(1);
        if (antennas.length > 0) {
            this.nextStepName = "antenaStep4";
        }
    }
};
</script>

<style lang="scss" scoped>
.events-none {
    pointer-events: none;
}

.offer__wrapper {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;

    .offer__item {
        position: relative;

        &.slim {
            width: 100%;
            max-width: unset;
            margin: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            h3,
            p,
            .form-row {
                max-width: 500px;
            }

            .slim__content {
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;

                > * {
                    flex: 0 0;
                }
            }

            .form-row {
                display: flex;

                input {
                    flex: 1 0;
                }

                button {
                    min-width: 185px;
                    white-space: nowrap;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                }
            }

            .form-msg {
                //max-width: 500px;
            }

            &::after {
                position: absolute;
                right: 1rem;
                margin: 1rem;
            }

            .offer__item__heading {
                min-height: unset;
                margin-bottom: 0;
            }

            @media (max-width: 768px) {
                flex-direction: column;
                padding-bottom: 4rem;

                &::after {
                    margin: 1rem 1rem 0;
                    bottom: 1rem;
                    right: unset;
                }
            }
        }
    }

    // Zaistenie reaktivity prkov ako input a button iba v pripade, ze je nadradeny label sucastou checknuteho inputu
    // v opacnom pripade sa moze stat, ze zakaznik nezaskrtne samotny input, no aj napriek tomu moze interagovat s prvkami vo vnutri (input a button)
    input + label.offer__item.slim {
        input,
        button {
            opacity: 0.3;
            pointer-events: none;
            transition: all 0.3s ease-out;
        }
    }

    input:checked + label.offer__item.slim {
        input,
        button {
            opacity: 1;
            pointer-events: all;
        }
    }
}
</style>
