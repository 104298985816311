<template>
    <div class="container">
        <!-- Zariadenia -->
        <div class="offer mb-4">
            <div class="offer__wrapper" v-if="antennas.length">
                <device-card
                    v-for="device in antennas"
                    v-model="orderDevice[0]"
                    name="antenna"
                    :id="`input${device.id}`"
                    :key="`input${device.id}`"
                    :device="device"
                    :checked="isDeviceActive(device.id)"
                    :promo="isDeviceEligableForPromo(device)"
                >
                </device-card>

                <!-- Static antena -->
                <div
                    class="device"
                    v-for="(device, index) in staticAntennas"
                    :key="`staticInput${index}`"
                >
                    <input
                        type="radio"
                        class="custom-checkbox"
                        name="staticAntenna"
                        v-model="orderDevice[0]"
                        :id="`staticInput${index}`"
                        :value="device"
                    />
                    <label
                        class="offer__item slim"
                        :for="`staticInput${index}`"
                        :key="`staticLabel${index}`"
                    >
                        <div class="text-center">
                            <h3 class="offer__item__heading">
                                {{ device.title }}
                            </h3>
                        </div>
                    </label>
                </div>
            </div>
        </div>

        <div class="form__footer mt-5">
            <!-- Predosly krok -->
            <button class="btn btn-primary" @click="prevStep">
                Krok späť
            </button>
            <!-- Dalsi krok -->
            <button
                class="btn btn-primary"
                :disabled="!stepFilledCorrectly"
                @click="nextStep"
            >
                {{ nextBtnContent }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import deviceCard from "../../components/vue/ponuka/device-card.vue";

//Mixins
import formStep from "../../mixins/formStep";

export default {
    mixins: [formStep],
    data() {
        return {
            stepName: "vyberAnteny",
            prevStepName: "antenaStep2",
            nextStepName: "antenaStep4",
            cart: [],
            orderDevice: [],
            antennas: [],
            staticAntennas: [
                {
                    title: "Anténu už mám",
                    selectedPrice: {
                        interval: "ONCE",
                        discounted: 0,
                        regular: 0
                    }
                }
            ]
        };
    },

    components: {
        deviceCard
    },

    computed: {
        ...mapGetters(["getStep", "getAntennasUnderProgram"]),
        stepFilledCorrectly() {
            return (
                // Bool
                // tu zhromazdujeme vsetky podmienky, ktore musia byt
                // splnene na to, aby formular mohol pokracovat dalej
                this.orderDevice.length
            );
        },
        nextBtnContent() {
            if (this.stepFilledCorrectly) return "Ďalší krok";
            else return "Prosím vyberte si zariadenie";
        },
        currentStepStateToSave() {
            //Nastavenia, ktore sa maju ulozit
            return {
                step: "vyberAnteny",
                state: {
                    orderDevice: this.orderDevice,
                    cart: this.cart
                }
            };
        }
    },

    watch: {
        //Sledujeme polozku additional services, a podla nej pridame polozky do arrayu cart z ktore sa na konci vypocita vysledna cena
        orderDevice: {
            handler(value) {
                this.cart = [];
                value.forEach(service => {
                    if (service != null && service != false) {
                        //Ak existuje sale price, uprednosti ju pred regular price a zapis ju do kosika, aby vysledna cena bola korektna
                        //console.log(service.price);
                        let finalPrice = parseFloat(
                            service.selectedPrice.regular
                        );

                        // Aplikacia beznej akciovej ceny
                        if (
                            service.selectedPrice.discounted != 0 &&
                            service.selectedPrice.discounted != ""
                        ) {
                            finalPrice = service.selectedPrice.discounted;
                        }

                        // Aplikacia promo akcnej ceny
                        // ak existuje pre dane zariadenie promo akcia a je splnena podmienka objednaneho poctu mesiacov
                        if (
                            service.selectedPrice.discounts &&
                            service.selectedPrice.discounts[0].price > 0 &&
                            this.isDeviceEligableForPromo(service)
                        ) {
                            finalPrice =
                                service.selectedPrice.discounts[0].price;
                        }

                        let accountedService = {
                            title: service.title,
                            price: parseFloat(finalPrice),
                            payment: service.selectedPrice.interval,
                            description: "anténa"
                        };
                        this.cart.push(accountedService);
                    }
                });
            },
            deep: true
        }
    },

    methods: {
        isDeviceActive(id) {
            if (this.orderDevice[0]) {
                return this.orderDevice[0].id == id;
            } else return false;
        },
        isDeviceEligableForPromo(device) {
            //vytiahneme si zo store pocet mesiacov, ktore si klient vybral v kroku 1
            const bindedMonths = this.$store.state.ponuka.stepState.vyberBalikov
                .bindedMonths;

            //Vrat true ak je na zariadenie zlava, je splneny minimalny pocet mesiacov z kroku 1 (vyber balikov)
            return (
                device.selectedPrice.discounts &&
                bindedMonths >= device.selectedPrice.discounts[0].months
            );
        }
    },

    // ##     ##  #######  ##     ## ##    ## ######## ######## ########
    // ###   ### ##     ## ##     ## ###   ##    ##    ##       ##     ##
    // #### #### ##     ## ##     ## ####  ##    ##    ##       ##     ##
    // ## ### ## ##     ## ##     ## ## ## ##    ##    ######   ##     ##
    // ##     ## ##     ## ##     ## ##  ####    ##    ##       ##     ##
    // ##     ## ##     ## ##     ## ##   ###    ##    ##       ##     ##
    // ##     ##  #######   #######  ##    ##    ##    ######## ########
    mounted() {
        //Zisti, ci existuje ulozeny stav, ak ano nacitaj hodnoty do komponentu
        const stepData = this.getStep("vyberAnteny");
        if (stepData) {
            //this.location = stepData.location;
            this.orderDevice = stepData.orderDevice;
        }

        //Ak neexistuju zapisane data z predosleho kroku, tak vrat usera na predosly krok
        // if(!this.getStep('vyberZariadenia')){
        //     this.prevStep();
        //     return
        // }

        this.antennas = this.getAntennasUnderProgram(1);
    }
};
</script>

<style lang="scss" scoped>
.offer__wrapper {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;
}

.offer__item {
    &.slim {
        width: 100%;
        max-width: unset;
        margin: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        &::after {
            margin: 1rem;
        }

        .offer__item__heading {
            min-height: unset;
            margin-bottom: 0;
        }

        @media (max-width: 768px) {
            flex-direction: column;

            &::after {
                margin: 1rem 1rem 0;
            }
        }
    }
}
</style>
