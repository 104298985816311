<template>
    <article class="jkb-accordion">
        <!-- Hlavicka -->
        <div class="jkb-accordion__header" @click="toggleCollapse">
            <!-- Otazka -->
            <slot name="headline">
            </slot>

            <!-- Icon -->
            <div class="jkb-accordion__header__icon">
                <svg v-if="!collapseOpen" xmlns="http://www.w3.org/2000/svg" fill="none" class="w-7 h-7" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>

        </div>

        <!-- Collapse cast -->
        <jkb-collapse ref="collapse">
            <!-- Odpoved -->
            <div class="jkb-accordion__content">
                <slot name="content">
                </slot>
            </div>
        </jkb-collapse>
    </article>
</template>

<script>
    import jkbCollapse from './../collapse/collapse.vue';

    export default {
        components: {
            jkbCollapse,
        },
        data() {
            return {
                collapseOpen: false,
            }
        },
        methods: {
            toggleCollapse() {
                this.$refs.collapse.silentToggle();
                this.collapseOpen = !this.collapseOpen
            }
        },
    }
</script>

<style lang="scss">
    
</style>
