<template>
    <div>
        <modal ref="annetaModal" class="text-left">
            <search-channels :program-id="1" :show-public="true" />
        </modal>

        <h4
            class="program-widget__heading mb-3 pointer"
            @click="$refs.annetaModal.toggle()"
        >
            Až
            <span class="text-highlighted">
                {{ data.allChannelsCount }} najobľúbenejších</span
            ><br />
            programov cez anténu<br />
            ({{ data.hdChannelsCount }} v HD kvalite<template
                v-if="data.is4kChannelsCount != ''"
                >, {{ data.is4kChannelsCount }} v 4K kvalite</template
            >)
        </h4>
        <span class="program-widget__price mb-3">
            Už od <span>{{ evaluateServicePrice }} €</span> / mesačne
        </span>
        <button class="btn btn-primary" @click="$emit('page', 'evaluate')">
            Overiť dostupnosť
        </button>
    </div>
</template>

<script>
import modal from "../../modal/modal.vue";
import searchChannels from "../../search-channels/searchChannels.vue";
import { mapGetters } from "vuex";

export default {
    props: ["data"],
    components: {
        modal,
        searchChannels
    },
    computed: {
        ...mapGetters([
            "getSelectedCity",
            "isProgramAvailable",
            "getProgram",
            "getProgramsLowestPackagePrice"
        ]),
        service() {
            return this.getProgram(this.data.currentProgram.id);
        },
        evaluateServicePrice() {
            const service = this.getProgram(this.data.currentProgram.id);
            const packagePrice = this.getProgramsLowestPackagePrice(
                this.data.currentProgram.id
            );

            if (service.promo) {
                return service.promo.price;
            } else {
                return packagePrice;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
