<template>
    <div>
        <!--
            Ak je sluzba dostupna
         -->
        <div v-if="isProgramAvailable">
            <h4 class="program-widget__heading mb-3">
                Služba je dostupná vo Vašej lokalite
            </h4>

            <a :href="data.avaliabilityTrueURL" class="btn btn-primary">
                Toto chcem
            </a>
            <br />
            <button
                class="btn btn-secondary mt-2"
                @click="$emit('page', 'evaluate')"
            >
                Zmeniť lokalitu
            </button>
        </div>

        <!--
            Ak nieje dostupna sluzba
         -->
        <div v-else>
            <h4 class="program-widget__heading mb-3">
                Služba NIE je dostupná vo Vašej lokalite
            </h4>

            <p>...ale ANNETA je dostupná vždy</p>

            <a
                :href="data.avaliabilityFalseURL"
                class="btn btn-primary mt-3"
                @click="$emit('page', 'evaluate')"
            >
                Pozrieť ponuku ANNETY
            </a>
            <br />
            <button
                class="btn btn-secondary mt-2"
                @click="$emit('page', 'evaluate')"
            >
                Zmeniť lokalitu
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ["data"],
    computed: {
        isProgramAvailable() {
            let availablePrograms = [];

            // Rozhodni ktore programy budu dostupne, pre danu obec
            if (
                this.data.location.mux_1 > 50 &&
                this.data.location.mux_4 > 50
            ) {
                availablePrograms = [1, 2];
            } else if (
                (this.data.location.mux_1 > 30 &&
                    this.data.location.mux_1 < 49) ||
                (this.data.location.mux_4 > 30 && this.data.location.mux_4 < 49)
            ) {
                availablePrograms = [2];
            } else if (
                this.data.location.mux_1 < 30 ||
                this.data.location.mux_4 < 30
            ) {
                availablePrograms = [2];
            }

            return availablePrograms.includes(this.data.currentProgramId);
        }
    }
};
</script>

<style lang="scss" scoped></style>
