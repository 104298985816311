<template>
    <div>
        <div>
            <h4 class="program-widget__heading mb-3">
                Služba je dostupná vo Vašej lokalite
            </h4>

            <a
                href="${this.data.avaliabilityTrueURL}"
                class="btn btn-primary"
                @click="$emit('page', 'evaluate')"
            >
                Toto chcem
            </a>
            <br />
            <button
                class="btn btn-primary mt-2"
                @click="$emit('page', 'evaluate')"
            >
                Zmeniť lokalitu
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ["data"],
    computed: {
        isProgramAvaliable() {
            // let availablePrograms = [];

            // // Rozhodni ktore programy budu dostupne, pre danu obec
            // if (this.data.location.mux_1 > 50 && this.data.location.mux_4 > 50){
            //     availablePrograms = [1, 2]
            // }
            // else if ((this.data.location.mux_1 > 30 && this.data.location.mux_1 < 49) || (this.data.location.mux_4 > 30 && this.data.location.mux_4 < 49) )
            // {
            //     availablePrograms = [2]
            // }

            // else if (this.data.location.mux_1 < 30 || this.data.location.mux_4 < 30 )
            // {
            //     availablePrograms = [2]
            // }

            //return availablePrograms.includes(this.data.currentProgramId);
            return true;
        }
    }
};
</script>

<style lang="scss" scoped></style>
