<template>
    <div>
        <jkb-modal ref="idleModal" class="small">
            <idle-modal />
        </jkb-modal>
    </div>
</template>

<script>
    import jkbModal from '../modal/modal';
    import idleModal from './idleModal'

    export default {
        data() {
            return {
                timer: 30000,
                timeout: null,
                cookieName: '__leavingPrompt',
            }
        },
        components: {
            jkbModal,
            idleModal
        },
        methods: {
            resetTimer() {
                //console.log('timer reset');
                this.stopTimer();
                if(!this.isCookiePresent()){
                    this.startTimer();
                }
            },

            startTimer() {
                //console.log('starting timer ...');

                this.timeout = setTimeout(() => {
                    //console.log("timer expired !!!")

                    this.$refs.idleModal.toggle();
                    document.cookie = `${this.cookieName}=true`;
                }, this.timer)
            },

            stopTimer() {
                clearTimeout(this.timeout);
            },

            isCookiePresent() {
                // Vsetky cookies
                let cookies = document.cookie.split(";");

                // Zisti ci v cookies je hladana cookie
                let isCookiePresent = cookies.filter(el => {
                    let split = el.trim().split("=");

                    if(split[0] == this.cookieName){
                        return true
                    }
                    else return false
                })

                //
                return Boolean(isCookiePresent.length);
            },


        },

        mounted () {
            // Ak neexistuje cookie
            if(!this.isCookiePresent()){
                document.addEventListener('mousemove', this.resetTimer)
                document.addEventListener('touch', this.resetTimer)
                document.addEventListener('scroll', this.resetTimer)
            }
        },

        // Cleanup
        beforeDestroy () {
            document.removeEventListener('mousemove', this.resetTimer)
            document.removeEventListener('touch', this.resetTimer)
            document.removeEventListener('scroll', this.resetTimer)
        },
    }
</script>

<style lang="scss" scoped>

</style>
