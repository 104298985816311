// router Views
//import Antena from '../../components/vue/Antena.vue';
import antenaStep1 from "../../views/antena/1-vyber_balikov.vue";
import antenaStep2 from "../../views/antena/2-vyber_zariadenia.vue";
import antenaStep3 from "../../views/antena/3-vyber_anteny.vue";
import antenaStep4 from "../../views/antena/4-osobne_udaje.vue";
import antenaStep5 from "../../views/antena/5-suhrn_objednavky.vue";
import antenaStep6 from "../../views/antena/6-podakovanie.vue";

export default [
    {
        path: "/antena/objednavka-antena",
        name: "antenaStep1",
        component: antenaStep1,
        meta: {
            title: "Výber balíkov",
            subtitle: "Vyberte si vhodný balík dostupný vo vašej lokalite."
        }
    },
    {
        path: "/antena/vyber-zariadenia",
        name: "antenaStep2",
        component: antenaStep2,
        meta: {
            title: "Výber zariadenia",
            subtitle:
                "Vyberte si prijímač, ktorý bude najviac vyhovovať vašim potrebám."
        }
    },
    {
        path: "/antena/vyber-anteny",
        name: "antenaStep3",
        component: antenaStep3,
        meta: {
            title: "Výber antény"
        }
    },
    {
        path: "/antena/osobne-udaje",
        name: "antenaStep4",
        component: antenaStep4,
        meta: {
            title: "Osobné údaje",
            subtitle: "Vyplňte, prosím, vaše osobné a kontaktné údaje."
        }
    },
    {
        path: "/antena/suhrn-objednavky",
        name: "antenaStep5",
        component: antenaStep5,
        meta: {
            title: "Súhrn objednávky",
            subtitle: "Pozrite si sumár vašej objednávky."
        }
    },
    {
        path: "/antena/podakovanie",
        name: "antenaStep6",
        component: antenaStep6,
        meta: {
            title: "Objednávka odoslaná"
        }
    }
];
