// router Views
//import Anneta from '../../components/vue/Anneta.vue';

import annetaStep1 from '../../views/anneta/1-vyber_balikov.vue';
import annetaStep2 from '../../views/anneta/2-vyber_zariadenia.vue';
import annetaStep3 from '../../views/anneta/3-vyber_anteny.vue';
import annetaStep4 from '../../views/anneta/4-osobne_udaje.vue';
import annetaStep5 from '../../views/anneta/5-suhrn_objednavky.vue';
import annetaStep6 from '../../views/anneta/6-podakovanie.vue';



export default [

    {
        path : '/anneta/objednavka-anneta',
        name : 'annetaStep1',
        component: annetaStep1
    },
    {
        path : '/anneta/vyber-zariadenia',
        name : 'annetaStep2',
        component: annetaStep2
    },
    {
        path : '/anneta/vyber-anteny',
        name : 'annetaStep3',
        component: annetaStep3
    },
    {
        path : '/anneta/osobne-udaje',
        name : 'annetaStep4',
        component: annetaStep4
    },
    {
        path : '/anneta/suhrn-objednavky',
        name : 'annetaStep5',
        component: annetaStep5
    },
    {
        path : '/anneta/podakovanie',
        name : 'annetaStep6',
        component: annetaStep6
    }


]


