// VUE Main
import Vue from "vue";
import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router';
//Vue Plugins
import router from "./router";
import store from "./store";
import Axios from "axios";
import AxiosPlugin from "vue-axios-cors";
import Vuelidate from "vuelidate";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

//Vuex actions and getters
import { mapActions, mapGetters } from "vuex";

//Top lvl components
import Antena from "./components/vue/Antena.vue";
import Speedtest from "./components/vue/speedtest/Speedtest.vue";
import Anneta from "./components/vue/Anneta.vue";
import annetaWidget from "./components/vue/anneta-widget/AnnetaWidget.vue";
import antenaWidget from "./components/vue/antena-widget/AntenaWidget.vue";
import searchChannels from "./components/vue/search-channels/searchChannels.vue";
import modal from "./components/vue/modal/modal.vue";
import accordion from "./components/vue/accordion/accordion.vue";
import conditions from "./components/vue/conditions/conditions.vue";
import resellers from "./components/vue/resellers/Master.vue";
import customerHelper from "./components/vue/customer-helper/customerHelper.vue";
import currentPromotion from "./components/vue/current-promotion/currentPromotion.vue";
import availabilityButton from "./components/vue/availability-button/availabilityButton.vue";
import allChannelsButton from "./components/vue/all-channels-button/allChannelsButton.vue";
import allChannelsCard from "./components/vue/all-channels-card/allChannelsCard.vue";
import Notification from "./components/vue/notification/Notification";
import idleTimer from "./components/vue/idle-timer/idleTimer.vue";
import idleModal from "./components/vue/idle-timer/idleModal.vue";
import quizz from "./components/vue/quiz/quizz.vue";
import countdown from './components/vue/countdown/countdown';


//Lower lvl components
import offerCards from "./components/vue/ponuka/offer-cards.vue";

Vue.prototype.$http = Axios;
Vue.prototype.$annetaOrderUrl = "/objednavka-anneta";
Vue.prototype.$antenaOrderUrl = "/objednavka-antena";

Vue.use(AxiosPlugin);
Vue.use(Vuelidate);

Vue.use(VueGtm, {
    id: 'GTM-WWH8XC4', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        gtm_auth: 'GTM-WWH8XC4',
        gtm_preview: 'env-4',
        gtm_cookies_win: 'x'
    },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});
//START VUE
new Vue({
    router,
    store,
    components: {
        countdown,
        FontAwesomeIcon,
        Antena,
        Anneta,
        annetaWidget,
        antenaWidget,
        offerCards,
        searchChannels,
        resellers,
        modal,
        accordion,
        conditions,
        customerHelper,
        currentPromotion,
        Speedtest,
        availabilityButton,
        allChannelsButton,
        allChannelsCard,
        Notification,
        idleTimer,
        idleModal,
        quizz
    },
    methods: {
        ...mapActions(["setSelectedCity", "setSelectedAnnetaPackage"])
    },
    computed: {
        ...mapGetters(["getSelectedCity", "getSelectedAnnetaPackage"])
    },

    mounted() {
        //Global vue launched

        // Pri kazdom nacitani stranky, zisti, ci clovek nezadal uz raz svoju lokaciu/mesto
        const localStorageSelectedCity = window.localStorage.getItem(
            "selectedCity"
        );
        // Ak neni v store hodnota tak sa ju pokus dotiahnut z local storage
        if (!Object.values(this.getSelectedCity).length) {
            if (localStorageSelectedCity) {
                this.setSelectedCity(JSON.parse(localStorageSelectedCity));
            }
        }

        const localStorageSelectedAnnetaPackage = window.localStorage.getItem(
            "selectedAnnetaPackage"
        );
        // Ak neni v store hodnota tak sa ju pokus dotiahnut z local storage
        if (!Object.values(this.getSelectedAnnetaPackage).length) {
            if (localStorageSelectedAnnetaPackage) {
                this.setSelectedAnnetaPackage(
                    JSON.parse(localStorageSelectedAnnetaPackage)
                );
            }
        }

        //this.setSelectedCity();
    }
}).$mount("#vue-app");
