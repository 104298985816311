<template>
    <div class="col-lg-3 col-6">
        <modal ref="availability">
            <search-channels :program-id="1" :show-public="true" />
        </modal>

        <div class="benefits-item" @click="$refs.availability.toggle()">
            <div class="benefits-item__icon">
                <img
                    :src="'/storage/' + icon"
                    :alt="title"
                    class="benefits-item__icon__img"
                />
            </div>
            <div class="benefits-item__text">
                <p class="benefits-item__title">{{ title }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import modal from "../modal/modal.vue";
import searchChannels from "../search-channels/searchChannels.vue";

export default {
    props: ["title", "icon"],
    components: {
        modal,
        searchChannels
    }
};
</script>

<style lang="scss" scoped></style>
