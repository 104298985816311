<template>
    <div :class="classNames">
        <div class="notification__icon">
            <img :src="imageSrc" alt="" />
        </div>
        <div class="notification__title">
            <div v-if="messages">
                <p v-for="(message, index) in messages" :key="index">
                    {{ message }}
                </p>
            </div>
            <div v-else>
                <p>{{ text }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Notification",
    props: {
        type: {
            type: String
        },
        text: {
            type: String
        },
        messages: {
            type: Array
        }
    },
    computed: {
        classNames() {
            return "notification notification--" + this.type;
        },
        imageSrc() {
            return "img/icons/" + this.type + ".svg";
        }
    }
};
</script>

<style scoped></style>
