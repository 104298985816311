<template>
    <div class="w600 mx-auto text-center" >
        <h3 class="h3">Overenie dostupnosti</h3>

        <div class="select-row my-3">
            <search-select
                v-model="location"
                class="my-2 m-md-2"
                :listData="getCities"
                :selectedItem="location"
            />
            <button class="btn btn-primary btn-primary--active my-2 " @click="confirmSelection" :disabled="!Object.values(location).length">
                Potvrdiť výber
            </button>
        </div>

        <small class="my-3 w480 mx-auto">
            Vyberte si prosím mesto zo zoznamu v ktorom chcete zaviesť službu PLUSTELKA.
        </small>
    </div>
</template>

<script>
    //Components
    import searchSelect from "../search-select/searchSelect";

    //Vuex
    import { mapActions, mapGetters } from 'vuex';

    export default {
        components: {
            searchSelect,
        },
        data() {
            return {
                location: {},
            }
        },
        methods: {
            ...mapActions(['fetchCities', 'setSelectedCity']),
            confirmSelection(){
                // Nastavi Globalne do local storage
                this.setSelectedCity(this.location);
                //Zatvorenie modal okna v rodicovskom komponente
                this.$parent.toggle();
            }
        },
        computed: {
            ...mapGetters([
                            'isLoadingCities',
                            'getCities',
                            'getSelectedCity'
                        ])
        },
        created () {

            if(!this.isLoadingCities){
                //Store API call ktory fetchne zoznam vsetkych miest
                this.fetchCities().then(()=>{
                    if(this.getSelectedCity){
                        //console.log(this.getSelectedCity)
                        this.location = this.getSelectedCity;
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .select-row{
        display: flex;
        justify-items: stretch;

        .search-select{
            flex-grow: 1;
        }

        @media (max-width: 576px){
            flex-direction: column;
        }
    }
</style>
