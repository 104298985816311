import scrollTo from 'vue-scrollto';

export default {
    //********************************************************** */
    // Pre spravnu funkciu potrebuje mat komponent nasledujuce data
    //********************************************************** */
    // stepName: 'menoAktualnehoKomponentu(vue store identifikator)',
    // prevStepName: 'predchadzajuciKomponent(vue Route Name)',
    // nextStepName: 'nasledujuciKomponent(vue Route Name)',


    methods: {
        nextStep() {
            this.$router.push({name: this.nextStepName})

            this.scrollToNavigation();
        },

        scrollToNavigation(){
            const element = "#navigation";
            const duration = 800;
            const options = {
                easing: 'ease-in-out',
                offset: -150,
                lazy: true,
            }

            setTimeout(()=>{
                scrollTo.scrollTo(element, duration, options)
            }, 100)
        },

        scrollToFooter(){
            const element = "#footer";
            const duration = 800;
            const options = {
                easing: 'ease-in-out',
                lazy: true,
            }

            setTimeout(()=>{
                scrollTo.scrollTo(element, duration, options)
            }, 200)
        },

        prevStep() {
            this.$router.push({name: this.prevStepName})

            const element = "#navigation";
            const duration = 800;
            const options = {
                easing: 'ease-in-out',
                offset: -150,
                lazy: true,
            }
            setTimeout(()=>{
                scrollTo.scrollTo(element, duration, options)
            }, 100)

        },

        saveStepState(stepState){
            //Ulozi aktualny stav do storu
            //deje sa az po uspesnom validovani
            this.$store.commit('saveStep', stepState);
        },

        validateThisStep(){
            //Ak je tato podmienka splnena validuj tento krok
            if(this.stepFilledCorrectly){
                //this.$store.dispatch('validateStep', {step: this.stepName, valid: true});

                //Ulozi aktualny stav do store
                this.saveStepState(this.currentStepStateToSave);

                return true;
            }
            else {
                //this.$store.dispatch('validateStep', {step: this.stepName, valid: false});
                return false
            }

        },
    },

    //  ######  ########  ########    ###    ######## ######## ########
    // ##    ## ##     ## ##         ## ##      ##    ##       ##     ##
    // ##       ##     ## ##        ##   ##     ##    ##       ##     ##
    // ##       ########  ######   ##     ##    ##    ######   ##     ##
    // ##       ##   ##   ##       #########    ##    ##       ##     ##
    // ##    ## ##    ##  ##       ##     ##    ##    ##       ##     ##
    //  ######  ##     ## ######## ##     ##    ##    ######## ########
    created () {
        //console.log(this.$store);
        // Pri kazdom presmerovani na inu linku zvaliduje krok, ulozi stav a posle dalej
        this.$router.beforeEach((to, from, next) => {
            this.validateThisStep()
            next();
        })
    },

    // ##     ##  #######  ##     ## ##    ## ######## ######## ########
    // ###   ### ##     ## ##     ## ###   ##    ##    ##       ##     ##
    // #### #### ##     ## ##     ## ####  ##    ##    ##       ##     ##
    // ## ### ## ##     ## ##     ## ## ## ##    ##    ######   ##     ##
    // ##     ## ##     ## ##     ## ##  ####    ##    ##       ##     ##
    // ##     ## ##     ## ##     ## ##   ###    ##    ##       ##     ##
    // ##     ##  #######   #######  ##    ##    ##    ######## ########
    mounted () {
        // Validuj krok
        // skontroluje, ci su hodnoty v sulade s lokalnou validaciou
        this.validateThisStep();
    },
};
