<template>
    <div :class="'notification notification--' + type">
        <div class="notification__icon">
            <img
                src="/img/icons/warning.svg"
                alt=""
                v-if="type === 'warning'"
            />
            <img
                src="/img/icons/success.svg"
                alt=""
                v-else-if="type === 'success'"
            />
        </div>
        <div class="notification__title">
            <p v-if="type === 'warning'">
                Rýchlosť Vášho internetového pripojenia musí byť aspon 5Mbit/s
                aby ste vedeli využívať naše služby
            </p>
            <p v-if="type === 'success'">
                Rýchlosť Vášho internetového pripojenia je dostačujúce na
                využitie služby Anneta.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "SpeedtestNotification",
    props: ["type"]
};
</script>

<style scoped>
.notification {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}
</style>
