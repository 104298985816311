<template>
    <div>
        <div class="cell">
            <button @click="openInfoWindow" class="eye-btn" ref="btn">
                <img src="img/icons/eye.svg" alt="predajca na mape">
            </button>

            {{ reseller.name }}
        </div>

        <div>
            {{ reseller.address }} <br>
            {{ reseller.city }} <br>
            {{ reseller.zip }}
        </div>

        <div class="cell">
            <div v-html="resellerNumbers">
            </div>
        </div>

        <div class="cell">
            <div v-html="resellerAddresses">
            </div>
        </div>

        <div class="cell">
            <a :href="`//${reseller.web}`" target="_blank">{{ reseller.web }}</a>
        </div>

        <div v-html="services">
        </div>
    </div>
</template>

<script>
    export default {
        props: ['reseller', 'shared', 'google', 'map'],

        methods: {
            openInfoWindow(event) {
                //var markerLatLng = new this.google.maps.LatLng(  , );
                this.map.setZoom(16);
                this.map.setCenter({
                    lat: this.resolveGPS(this.reseller.gps_coordinates, 0),
                    lng: this.resolveGPS(this.reseller.gps_coordinates, 1)
                })

                this.google.maps.event.trigger(this.reseller.marker, 'click');


                //console.log('Presuvam mapu');

                this.$parent.$emit('switch-view', 'Resellers')



            },
            resolveGPS(gps, position){
                if(gps){
                    let gpsArray = gps.split( /, ?/ );
                    return parseFloat(gpsArray[position]);
                }
            },
        },

        computed: {
            services() {
                const services =    `<p>
                                        ${ this.reseller.service ? 'Servisné služby <br />' : '' }
                                        ${ this.reseller.store ? 'Kamenný obchod <br />' : '' }
                                        ${ this.reseller.unlocks ? 'Odblokovanie a aktivácia  <br />' : '' }
                                        ${ this.reseller.active ? 'Aktívny a dostupný predajca <br />' : '' }
                                    </p>`;

                return services;
            },

            resellerAddresses(){
                // Array kontaktnych emailov
                // ak su data array vypis ich za sebou spolocne s line breakami
                // ak nie, tak ich len jednoducho vypis
                let emailAdrs = ``;

                if(typeof this.reseller.email == 'object'){
                    this.reseller.email.forEach(el => {
                    if(el){
                        emailAdrs += `<a target="_blank" follow="nofollow" href="mailto:${el}">${el}</a><br />`;
                    }
                });
                }
                else{
                    emailAdrs = `<a target="_blank" follow="nofollow" href="mailto:${this.reseller.email}">${this.reseller.email}</a><br />`;
                }

                return emailAdrs;
            },

            resellerNumbers(){
                // Array kontaktnych cisiel
                    // ak su data array vypis ich za sebou spolocne s line breakami
                    // ak nie, tak ich len jednoducho vypis
                    let phoneNrs = ``;

                    if(typeof this.reseller.phone == 'object'){


                        this.reseller.phone.forEach(el => {
                            if(el){
                                phoneNrs += `<a target="_blank" follow="nofollow" href="tel:${el}">${el}</a><br />`;
                            }
                        });
                    }
                    else{
                        phoneNrs = `<a target="_blank" follow="nofollow" href="tel:${this.reseller.phone}">${this.reseller.phone}</a><br />`;
                    }

                    return phoneNrs;
            }
        },

    }
</script>

