<template>
    <article class="jkb-conditions">
        <!-- Hlavicka -->
        <div class="jkb-conditions__header" :class="{ 'center' : headlineCentered == true}" @click="toggleCollapse">
            <!-- Otazka -->
            <slot name="headline">
            </slot>

            <!-- Icon -->
            <!-- <div class="jkb-conditions__header__icon">
            </div> -->
        </div>

        <!-- Collapse cast -->
        <jkb-collapse ref="collapse">
            <!-- Odpoved -->
            <div class="jkb-conditions__content">
                <slot name="content">
                </slot>
            </div>
        </jkb-collapse>
    </article>
</template>

<script>
    import jkbCollapse from '../collapse/collapse.vue';

    export default {
        props: ['headlineCentered'],
        components: {
            jkbCollapse,
        },
        data() {
            return {
                collapseOpen: false,
            }
        },
        methods: {
            toggleCollapse() {
                this.$refs.collapse.silentToggle();
                this.collapseOpen = !this.collapseOpen
            }
        },
    }
</script>

<style lang="scss">
    .jkb-conditions{
        &__header{
            color: #15AD96;
            font-weight: bold;

            .orange-theme &{
                color: #EC9B52;
            }
        }
    }
</style>
