<template>
    <div>
        <transition
            name="inter-fade"
            mode="out-in"
        >
            <div class="current-promo">
                <div class="current-promo__popup">
                    <jkb-collapse ref="popup" style="position:relative;">
                        <div class="current-promo__popup__content">
                            <slot></slot>
                        </div>
                    </jkb-collapse>
                </div>
                <div class="current-promo__button" @click="togglePopup">
                    Aktuálna Akcia

                    <div class="icon">
                        <img src="/img/icons/bubble-action.svg" alt="">
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>
    import jkbCollapse from './../collapse/collapse.vue';

    export default {
        //  ######   #######  ##     ## ########   #######  ##    ## ######## ##    ## ########  ######
        // ##    ## ##     ## ###   ### ##     ## ##     ## ###   ## ##       ###   ##    ##    ##    ##
        // ##       ##     ## #### #### ##     ## ##     ## ####  ## ##       ####  ##    ##    ##
        // ##       ##     ## ## ### ## ########  ##     ## ## ## ## ######   ## ## ##    ##     ######
        // ##       ##     ## ##     ## ##        ##     ## ##  #### ##       ##  ####    ##          ##
        // ##    ## ##     ## ##     ## ##        ##     ## ##   ### ##       ##   ###    ##    ##    ##
        //  ######   #######  ##     ## ##         #######  ##    ## ######## ##    ##    ##     ######
        components: {
            jkbCollapse,
        },

        // ##     ## ######## ######## ##     ##  #######  ########   ######
        // ###   ### ##          ##    ##     ## ##     ## ##     ## ##    ##
        // #### #### ##          ##    ##     ## ##     ## ##     ## ##
        // ## ### ## ######      ##    ######### ##     ## ##     ##  ######
        // ##     ## ##          ##    ##     ## ##     ## ##     ##       ##
        // ##     ## ##          ##    ##     ## ##     ## ##     ## ##    ##
        // ##     ## ########    ##    ##     ##  #######  ########   ######
        methods: {
            togglePopup(){
                this.$refs.popup.silentToggle();
                this.isPopupOpen = !this.$refs.popup.show;

                if(this.isPopupOpen){
                    document.addEventListener('click', function(){
                        //console.log('pocuvam klikacku mimo')
                    }, {once: true})
                }
            },
        },
    }
</script>

<style lang="scss">
    .current-promo{
        position: fixed;
        left: 1.5rem;
        bottom: 0;
        z-index: 20;
        max-width: 300px;
        //margin-right: 1.5rem;

        @media (max-width: 900px){
            flex-direction: column;
            right: calc(50vw + .5rem);
            max-width: none;
            //margin-right: 6rem;
        }

        img{
            max-width: 100%;
            height: auto;
        }

        &__button{
            background: #0EA879;
            color: white;
            height: 3rem;
            border-radius: .25em .25em 0 0;

            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 1em;
            padding-right: 1em;

            margin-left: auto;

            @media (max-width: 576px){
                line-height: 1.2em;
                height: 3rem;
            }
            .icon{
                display: flex;
                justify-content: center;
                align-items: center;

                max-width: 2.5em;
                max-height: 2.5em;
                width: 100%;
                height: 100%;

                flex-shrink: 0;
                flex-grow: 0;
                //background: white;

                //margin-right: .5rem;
                margin-left: 1rem;

                img{
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                }

                @media (max-width: 450px){
                    display: none;
                }
            }
        }



        &__popup__content{
            position: relative;
            padding: 1rem;
            background: white;

            img{
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: auto;
            }
            //max-width: 400px;

            @media (max-width: 576px){
                position: absolute;
                width: calc(100vw - 3rem);
                //transform: translateX(-50vw);
                left: 0;
                bottom: 0;
            }

        }


    }
</style>
