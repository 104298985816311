<script>
    export default {
        props: {
            lat: { type: Number, required: true},
            lng: { type: Number, required: true},
            title: {type: String, required: false},
            google: {type: Object, required: true},
            map: {type: Object, required: true},
        },

        data() {
            return {
                debug: null
            }
        },

        mounted () {
            var marekrLatLng = new this.google.maps.LatLng( this.lat , this.lng);

            const infoWindowContent = `
                <h2>${this.title}</h2>
                <p>
                    Lorem ipsum
                </p>
            `;

            const infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent,
            });

            let marker =  new this.google.maps.Marker({
                position : marekrLatLng,
                title: this.title,
                map: this.map,
            })

            marker.addListener('click', ()=>{
                infoWindow.open(this.map, marker)
            })
        },
        render() {
            return null
        }
    }
</script>

