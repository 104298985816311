<template>
    <div>
        <input
            type="checkbox"
            class="card-input"
            v-bind="$attrs"
            ref="inputCheck"
            id="pckgFree"
            value="true"
            hidden
        />

        <label class="card card--label mb-3">
            <div class="package-card">
                <div class="package-card__header">
                    <div>
                        <span class="text-bigger">
                            {{ packageCategory }}
                        </span>
<!--                        <h3 class="h4">{{ pckg.name }}</h3>-->
                    </div>
                    <div class="package-card__price">
<!--                        <span>{{ pckg.price }} €</span>-->
<!--                        <span>/mesiac</span>-->
                    </div>
                </div>

                <ul class="package-card__list">
                    <li v-for="(channel, index) in items">
                        <img
                            :alt="channel.name"
                            :src="channel.logo"
                            :key="`img${index}`"
                            class="package-card__img"
                        />
                    </li>
                </ul>
            </div>
        </label>
    </div>
</template>

<script>

export default {
    props: ["items"],
    inheritAttrs: false,
    methods: {
        dataPass() {
            return {
                value: this.items,
                checked: true
            };
        }
    },

    watch: {

    },

    computed: {
        packageCategory() {
            return "Programová ponuka voľného vysielania cez anténu";
        },
    },

    mounted() {
        this.dataPass();
    }
};
</script>

<style lang="scss" scoped>
.package {
    @media (max-width: 576px) {
        max-width: 100% !important;
    }
}

.offer__item {
    text-align: center;
    width: auto;
    margin: 0;
    width: 100%;
    max-width: 100%;

    &__disclaimer {
        margin-top: 0.75em;
        font-weight: 700;
    }

    .main-package & {
        max-width: 100%;

        // Horizontalne zobrazenie baliku
        @media (min-width: 992px) {
            display: grid;
            grid-template-columns: 15% auto 20% min-content;
            align-items: center;
            padding: 3rem;

            &__heading {
                text-align: left;
                width: 13rem;
                margin: 0;
            }

            &__logoset {
                margin: 0 3rem;
            }

            &__footer {
                margin: 0 3rem 0 0;
            }
        }

        .offer__item {
            //background: hotpink;
            max-width: 100%;
        }
    }
}
</style>
