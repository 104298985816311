<template>
    <div class="search-select" v-if="filteredListData">

        <div class="search-select__input">
            <input
                id="pac-input"
                class="form-control"
                type="text"
                placeholder="Zadajte mesto alebo ulicu"
            />
            <!-- Input pre fulltext vyhladavanie -->
<!--            <input-->
<!--                ref="search"-->
<!--                @mousedown.prevent="openSearch"-->
<!--                @keyup.stop.prevent="focus"-->
<!--                @input="-->
<!--                    filterList();-->
<!--                    openSearch();-->
<!--                "-->
<!--                v-model="searchTerm"-->
<!--                class="form-control"-->
<!--                type="text"-->
<!--                placeholder="Zadajte mesto alebo ulicu"-->
<!--            />-->
<!--            <vue-google-autocomplete-->
<!--                id="map"-->
<!--                classname="form-control"-->
<!--                placeholder="Start typing"-->
<!--                v-on:placechanged="select"-->
<!--            >-->
<!--            </vue-google-autocomplete>-->

            <div
                v-show="searchOpen && searchTerm.length > 1"
                class="search-select__search"
            >
                <div class="search-select__list" ref="searchlist">
                    <div
                        class="search-list"
                        ref="search_list"
                        v-if="
                            filteredListData.length > 0 && searchTerm.length > 1
                        "
                    >
                        <!-- Data -->

                        <div
                            v-for="(data, index) in filteredListData"
                            :key="`list${index}`"
                            :class="{ 'active focused': data == selected }"
                            :data-city-id="data.id"
                            class="reseller-button"
                            tabindex="0"
                            @mouseup.prevent="select(data)"
                        >
                            <div>
                                <span v-html="boldFinding(data.city)"> </span>
                            </div>
                            <div>
                                <span v-html="boldFinding(data.address)">
                                </span>
                            </div>

                            <div @touchend.prevent="select(data)">
                                <span>
                                    <img
                                        src="img/icons/eye.svg"
                                        alt="predajca na mape"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div v-else ref="search_list" class="search-list">
                        <button class="focused">
                            Ľutujeme, no vami hľadaná lokalita nieje v našom
                            zozname
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//Vendors
import deburr from "lodash/deburr";
import scrollTo from "vue-scrollto";
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {

    props: {
        listData: Array,
        selectId: String,
        selectName: String,
        selectRequired: Boolean,
        selectResponse: String,
        selectedItem: Object,
        sharedData: Object
    },
    components: { VueGoogleAutocomplete },

    data() {
        return {
            selected: this.selectedItem,
            filteredListData: this.listData,
            searchTerm: "",
            searchOpen: false,
            // filterResellerService: '',
            filterResellerService: "onlyAntena",
            focusedItem: this.selectedItem
        };
    },

    methods: {
        select(selectedValue) {
            this.searchTerm = "";
            this.selected = selectedValue;
            this.searchOpen = false;

            this.$emit("input", selectedValue);
            this.openInfoWindow();

            const element = "#reseller-content";
            const duration = 800;
            const options = {
                easing: "ease-in-out",
                offset: -100,
                lazy: true
            };

            setTimeout(() => {
                scrollTo.scrollTo(element, duration, options);
            }, 100);
        },
        openInfoWindow(event) {
            //var markerLatLng = new this.google.maps.LatLng(  , );
            this.sharedData.map.setZoom(16);
            this.sharedData.map.setCenter({
                lat: this.resolveGPS(this.selected.gps_coordinates, 0),
                lng: this.resolveGPS(this.selected.gps_coordinates, 1)
            });

            this.sharedData.google.maps.event.trigger(
                this.selected.marker,
                "click"
            );

            //console.log('Presuvam mapu');

            this.$emit("switch-view", "Resellers");
        },
        resolveGPS(gps, position) {
            if (gps) {
                let gpsArray = gps.split(/, ?/);
                return parseFloat(gpsArray[position]);
            }
        },
        filterList() {
            //Fulltextova filtracia
            // this.sharedData.google.maps.sea
            let search = this.listData.filter(data => {
                const searchInCity = data.city
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                const searchInAddress = data.address
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");

                const term = this.searchTerm
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");

                return (
                    searchInCity.includes(term) ||
                    searchInAddress.includes(term)
                );
            });

            // Filtracia podla ponukanej sluzby Anneta+Antena, iba Anneta, iba Antena
            if (this.filterResellerService == "annetaAndAntena") {
                search = search.filter(arr => {
                    return arr.anneta == 1 && arr.antena == 1;
                });

                //console.log(search)
            } else if (this.filterResellerService == "onlyAnneta") {
                search = search.filter(arr => {
                    return arr.anneta == 1;
                });
            } else if (this.filterResellerService == "onlyAntena") {
                search = search.filter(arr => {
                    return arr.antena == 1;
                });
            }

            //console.log(search)

            //Vratime vysledok do filtered list data
            this.filteredListData = search;
        },
        boldFinding(value) {
            const searchIn = value
                .normalize("NFD")
                .toLowerCase()
                .replace(/[\u0300-\u036f]/g, "");
            const term = this.searchTerm
                .normalize("NFD")
                .toLowerCase()
                .replace(/[\u0300-\u036f]/g, "");
            //console.log(term);

            const regex = new RegExp(term, "gi");
            const normalizedSplit = searchIn.replace(
                regex,
                match => `%${match}%`
            );
            //console.log(normalizedSplit);

            //Pomocna funkcia na rozdelenie stringu na danych indexoch
            const splitAt = (slicable, indices) =>
                [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]));

            //Najdeme miesta kde je string rozdeleny znakom % aby sme vedeli kde mame vkladat html na nenormalizovany string
            let indexesOfSplit = [];

            [...normalizedSplit].forEach((letter, index) => {
                if (letter === "%") {
                    //let ind = index == 0 ? 0 : index+1;
                    if (index == 0) {
                        indexesOfSplit.push(0);
                    } else {
                        indexesOfSplit.push(index - indexesOfSplit.length);
                    }
                }
            });

            //Naparujeme splity na nenormalizovany string
            const boldedParts = splitAt(value, indexesOfSplit).filter(
                item => item != ""
            );

            let bolded = "";

            //pridame bold tagy
            boldedParts.map(el => {
                if (deburr(el).toLowerCase() == term) {
                    bolded += `<strong>${el}</strong>`;
                } else bolded += el;
            });

            //console.log(bolded);

            return bolded;
        },
        focus(e) {
            //Zisti ktory element ma classu focused, ten zadefinuj interne ako focusnuty - od neho sa bude odvijat, ktory prvok nasleduje pred nim a po nom
            let focused = this.$refs.search_list.querySelector(".focused");

            // V pripade ze ziaden nenajdes zadefinuj prvy v liste ako focusnuty
            // moze nastat v pripade:
            // - input je novovytvoreny a nema ziadnu defaultnu hodnotu
            // - input list bol vyfiltrovany a element tym padom neexistuje

            if (!focused) {
                focused = this.$refs.search_list.firstChild;
                focused.classList.add("focused");
                //console.log(focused);
            }

            // AK EXISTUJE KEY EVENT
            // Filtrovanie Eventov
            if (e) {
                //SIPKA DOLE
                if (e.code == "ArrowDown") {
                    const next = focused.nextSibling;
                    //V pripade ze tento element neexistuje, tak nerob nic
                    //moze nastat v pripade, ze bol focusnuty posledny element a po nom uz nic neexistuje
                    if (next) {
                        focused.classList.remove("focused");
                        focused = next;
                    }
                    focused.classList.add("focused");
                    //console.log(next);
                }

                // SIPKA HORE
                if (e.code == "ArrowUp") {
                    const previous = focused.previousSibling;
                    //V pripade ze tento element neexistuje, tak nerob nic
                    //moze nastat v pripade, ze bol focusnuty prvy element a pred nim uz nic neexistuje
                    if (previous) {
                        focused.classList.remove("focused");
                        focused = previous;
                    }
                    focused.classList.add("focused");

                    //console.log(previous);
                }

                // ESCAPE
                // Zatvorenie search overlayu
                if (e.code == "Escape") {
                    this.searchOpen = false;
                }

                // ENTER
                // Potvrdenie vyberu
                if (e.code == "Enter") {
                    const focusedId = focused.dataset.cityId;
                    const findReseller = this.listData.filter(el => {
                        return el.id == focusedId;
                    });
                    this.select(findReseller[0]);
                }
            }

            // Upravenie zobrazenia, tak aby sa vybrany (focused) element zobrazoval vzdy v strede viewportu
            const listData = this.$refs.search_list.getBoundingClientRect();
            const listHeight = listData.height;

            const focusedData = focused.getBoundingClientRect();
            const focusedHeight = focusedData.height;

            const centerScroll =
                focused.offsetTop - focusedHeight / 2 - listHeight / 2;
            const topScroll = focused.offsetTop - focusedHeight;

            this.$refs.search_list.scrollTop = centerScroll;
        },
        openSearch() {
            this.searchOpen = true;

            this.$nextTick(() => {
                this.$refs.search.focus();
                this.focus();
                this.filterList();
            });
        },
        closeSearch(e) {
            if (!this.$el.contains(e.target)) {
                this.searchOpen = false;

                //Vyresetujeme vyhladavacie pole po jeho zavreti
                this.searchTerm = "";
                this.filterList();
            }
        }
    },

    filters: {
        normalize(str) {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
    },

    mounted() {
        document.addEventListener("click", this.closeSearch);
    },

    beforeDestroy() {
        document.removeEventListener("click", this.closeSearch);
    }
};
</script>

<style lang="scss" scoped>
.search-select {
    display: flex;

    &__filter {
        flex: 0 0 30%;
        margin-right: 1em;
    }

    &__input {
        position: relative;
        flex: 1 1 100%;
    }
}

.search-list {
    .reseller-button {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr 1fr auto;
    }
}
</style>
