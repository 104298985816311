<template>
    <div class="container">
        <div v-if="sharedData.google">
            <div class="text-center w600 mx-auto my-5">
                <h3 class="h3 mb-3">Vyhľadajte svojho predajcu</h3>
                <search-reseller
                    :sharedData="sharedData"
                    :listData="sharedData.resellers"
                    @switch-view="switchView($event)"
                />
            </div>

            <div class="text-center">
                <div class="tabs mb-3">
                    <button
                        class="tabs__button-tab"
                        :class="{ active: currentView == view.viewName }"
                        v-for="(view, index) in views"
                        @click="switchView(view.viewName)"
                        :key="index"
                    >
                        {{ view.name }}
                    </button>
                </div>
            </div>

            <transition name="inter-fade" mode="out-in">
                <div
                    id="reseller-content"
                    v-if="sharedData.google"
                    class="mb-5"
                >
                    <keep-alive>
                        <component
                            @switch-view="switchView($event)"
                            :is="currentView"
                            :shared="sharedData"
                        ></component>
                    </keep-alive>
                </div>
            </transition>

            <!-- <div v-if="sharedData.google">
            <resellers :shared="sharedData" />
            <resellers-table :shared="sharedData" />
        </div> -->
        </div>
        <div class="loading-container" v-else>
            <div class="loading-pulse__dark">
                Načítavam ...
            </div>
        </div>
    </div>
</template>

<script>
//Vendors
import axios from "axios";
import GoogleMapsApiLoader from "google-maps-api-loader";
import searchReseller from "./components/searchResseler.vue";

//Views
import Resellers from "./views/Resellers.vue";
import ResellersTable from "./views/ResellersTable.vue";

export default {
    components: {
        Resellers,
        ResellersTable,
        searchReseller
    },

    data() {
        return {
            apiKey: "AIzaSyC2zgb-plbiw5AJyeG2ivRTE-44Sm-w1Ns",

            sharedData: {
                google: null,
                map: null,
                resellers: [],
                markers: [],
                infoWindows: [],
                activeInfoWindow: null
            },

            currentView: "Resellers",
            views: [
                {
                    name: "Mapa predajní",
                    viewName: "Resellers"
                },
                {
                    name: "Tabuľka predajní",
                    viewName: "ResellersTable"
                }
            ]
        };
    },


    methods: {
        switchView(viewName) {
            this.currentView = viewName;
        }
    },

    async mounted() {
        // Fetch resellers
        const resellers = await axios.post("api/resellers");
        this.sharedData.resellers = resellers.data.data;

        const googleMapApi = await GoogleMapsApiLoader({
            apiKey: this.apiKey,
            libraries: ['places']
        });

        //Start Google map
        this.sharedData.google = googleMapApi;
    }
};
</script>

<style lang="scss">
.tabs {
    display: inline-flex;
    margin: 0 auto;
    border-radius: 0.25em;
    justify-content: center;
    background: linear-gradient(135deg, #0ea879 0%, #26b8eb 100%);

    &__button-tab {
        background: white;
        padding: 0.75em 2em;
        border: 0;
        outline: none;
        margin: 2px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 1em;

        transition: all 0.2s ease-out;

        &:focus {
            outline: none;
        }

        &.active {
            background-color: transparent;
            color: white;
        }
    }
}
</style>
