<template>
    <div>
        <!-- Offer item -->
        <input
            type="radio"
            class="card-input"
            v-bind="$attrs"
            :id="`input${device.id}`"
            :value="device"
            @change="$emit('input', device)"
            hidden
        />
        <label
            :for="`input${device.id}`"
            :key="`label${device.id}`"
            class="card card--label mb-3"
        >
            <div class="device-card">
                <div class="device-card__header">
                    <div>
                        <img
                            :src="device.illustration"
                            alt=""
                            class="device-card__img"
                        />
                        <h3 class="h6">{{ device.title }}</h3>
                        <ul class="device-card__list">
                            <li
                                v-for="(feature, index) in device.labels"
                                :key="`feature${index}`"
                            >
                                {{ feature }}
                            </li>
                        </ul>
                    </div>
                    <div class="device-card__price">
                        <span v-if="parseFloat(device.selectedPrice.discounted) > 0" class="line_through">{{ parseFloat(device.selectedPrice.regular).toFixed(2) }}€</span>
                        <span v-if="parseFloat(device.selectedPrice.discounted) > 0" class="text">{{ parseFloat(device.selectedPrice.discounted).toFixed(2) }}€</span>
                        <span v-else class="text">{{ parseFloat(device.selectedPrice.regular).toFixed(2) }}€</span>
                        <span class="device-card__price-dph">s DPH</span>
                        <div class="badge__wrap-relative" v-if="device.is_new">
                            <div class="badge__text badge__text--sm d-flex align-items-center justify-content-center badge__position-eshop-device-list">
                                <div class="badge__content badge__content--sm">Uvádzacia<br />cena</div>
                            </div>
                        </div>
                        <div class="badge__wrap-relative" v-if="parseFloat(device.selectedPrice.discounted) > 0">
                            <div class="badge__text badge__text--sm badge__text--blue d-flex align-items-center justify-content-center badge__position-eshop-device-list">
                                <div class="badge__content badge__content--sm">Výpredaj</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex justify-content-between align-items-center flex-wrap"
                >
                    <a
                        :href="'/detail-zariadenia/' + device.slug"
                        class="device-card__btn mr-3"
                        target="_blank"
                    >
                        <span>Zobraziť detail</span>
                    </a>
                    <span class="btn btn-primary btn--big mt-3">
                        <span>Kúpiť</span>
                        <img
                            src="/img/icons/arrow-right.svg"
                            alt=""
                            class="btn__icon"
                        />
                    </span>
                </div>
            </div>
        </label>
        <!-- /Offer item -->
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: ["device", "currentProgram", "promo"],

    mounted() {
        console.log(this.device);
    },
    computed: {
        interval() {
            if (this.device.selectedPrice.interval == "ONCE")
                return "jednorazovo";
            if (this.device.selectedPrice.interval == "PM") return "mesačne";
        }
    }
};
</script>

<style lang="scss" scoped>
.offer__wrapper {
    .offer__item {
        display: grid;
        grid-template-columns: repeat(3, 1fr) auto;
        grid-gap: 1rem;
        width: auto;
        margin: 0;
        align-items: center;
        max-width: unset;

        &__disclaimer {
            text-align: center;
            font-weight: 700;
            margin: 0.75em 1rem 0;
        }

        &__footer {
            margin-top: 0;
        }

        &::after {
            grid-column: 4;
        }

        @media (max-width: 768px) {
            display: flex;
        }
    }
}
</style>
