var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{ref:"availability",staticClass:"body-visible"},[_c('check-availability')],1),_vm._v(" "),_vm._l((_vm.antenaServices),function(service){return _c('modal',{key:service.id,ref:`modal${service.id}`,refInFor:true},[_c('search-channels',{attrs:{"program-id":service.id,"show-public":true}})],1)}),_vm._v(" "),_vm._l((_vm.annetaServices),function(service){return _c('modal',{key:service.id,ref:`modal${service.id}`,refInFor:true},[_c('search-channels',{attrs:{"program-id":service.id,"show-filter":true}})],1)}),_vm._v(" "),_c('transition',{attrs:{"name":"inter-fade","mode":"out-in"}},[(_vm.isLoadingData)?_c('div',{key:"1",staticClass:"row"},[_vm._l((_vm.annetaServices),function(service,index){return [_c('div',{key:service.id,staticClass:"col-lg-6 my-2"},[_c('div',{staticClass:"offer-card offer-card--primary",class:{
                            featured: service.featured,
                            hidden: service.hiddenService
                        }},[(service.featured)?_c('span',{staticClass:"offer-card__tag offer-card__tag--primary"},[_vm._v("\n                            Obľúbená TV\n                        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"offer-card__content"},[_c('h2',{staticClass:"h4 mb-3"},[_vm._v(_vm._s(service.name))]),_vm._v(" "),_c('p',{staticClass:"mb-3"},[_c('span',[_vm._v("až do\n                                    "+_vm._s(_vm.getAllProgramsChannelCounts(
                                            service.id
                                        ))+"\n                                    programov\n                                    "),_c('span',{staticClass:"d-inline-block"},[_vm._v("\n                                        (\n                                        "+_vm._s(_vm.getHdProgramsChannelCounts(
                                                service.id
                                            ))+"\n                                        v HD kvalite\n                                        "),(
                                                _vm.get4KProgramsChannelCounts(
                                                    service.id
                                                )
                                            )?[_vm._v("\n                                            ,\n                                            "+_vm._s(_vm.get4KProgramsChannelCounts(
                                                    service.id
                                                ))+"\n                                            v 4K kvalite ")]:_vm._e(),_vm._v(")\n                                    ")],2)])]),_vm._v(" "),_c('div',{staticClass:"offer-card__show-channels"},[_c('span',{on:{"click":function($event){return _vm.openModal(service.id)}}},[_vm._v("Zobraziť programy")])]),_vm._v(" "),_c('a',{staticClass:"btn btn-primary mt-4",attrs:{"href":`objednavka-antena`}},[_c('span',[_vm._v("Pozrieť ponuku")]),_vm._v(" "),_c('img',{staticClass:"btn__icon",attrs:{"src":"/img/icons/arrow-right.svg","alt":""}})])]),_vm._v(" "),_c('div',{staticClass:"offer-card__aside"},[_c('span',[_c('span',{staticClass:"h3 offer-card__price"},[_vm._v(_vm._s(_vm.evaluateServicePrice(service.id))+"€")]),_vm._v("\n                                /mesiac\n                            ")]),_vm._v(" "),(service.promo)?_c('small',[_vm._v("\n                                Od\n                                "+_vm._s(parseInt(service.promo.months) + 1)+".\n                                mesiaca "+_vm._s(service.price)+" €\n                            ")]):_vm._e()])])])]}),_vm._v(" "),_vm._l((_vm.antenaServices),function(service,index){return [_c('div',{key:`antena${index}`,staticClass:"my-2",class:_vm.antenaServices.length == 1 ? 'col-lg-7' : 'col-lg-6'},[_c('div',{staticClass:"offer-card offer-card--secondary",class:{
                            hidden: service.hiddenService,
                            single: _vm.antenaServices.length == 1
                        }},[(service.featured)?_c('span',{staticClass:"offer-card__tag offer-card__tag--secondary"},[_vm._v("\n                            Obľúbená TV\n                        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"offer-card__content"},[_c('h2',{staticClass:"h4 mb-3"},[_vm._v(_vm._s(service.name))]),_vm._v(" "),_c('p',{staticClass:"mb-3"},[_c('span',[_vm._v("až do\n                                    "+_vm._s(_vm.getAllProgramsChannelCounts(
                                            service.id,
                                            { includePublic: true }
                                        ))+"\n                                    programov\n                                ")]),_vm._v(" "),_c('span',{staticClass:"d-inline-block"},[_vm._v("\n                                    (\n                                    "+_vm._s(_vm.getHdProgramsChannelCounts(
                                            service.id,
                                            { includePublic: true }
                                        ))+"\n                                    v HD kvalite\n                                    "),(
                                            _vm.get4KProgramsChannelCounts(
                                                service.id,
                                                { includePublic: false }
                                            )
                                        )?[_vm._v("\n                                        ,\n                                        "+_vm._s(_vm.get4KProgramsChannelCounts(
                                                service.id,
                                                { includePublic: false }
                                            ))+"\n                                        v 4K kvalite ")]:_vm._e(),_vm._v(")\n                                ")],2)]),_vm._v(" "),_c('div',{staticClass:"offer-card__show-channels"},[_c('span',{on:{"click":function($event){return _vm.openModal(service.id)}}},[_vm._v("Zobraziť programy")])]),_vm._v(" "),_c('a',{staticClass:"btn btn-secondary mt-4",attrs:{"href":`objednavka-antena`}},[_c('span',[_vm._v("Pozrieť ponuku")]),_vm._v(" "),_c('img',{staticClass:"btn__icon",attrs:{"src":"/img/icons/arrow-right.svg","alt":""}})])]),_vm._v(" "),_c('div',{staticClass:"offer-card__aside"},[_c('span',[_c('span',{staticClass:"h3 offer-card__price"},[_vm._v(_vm._s(_vm.evaluateServicePrice(service.id))+"€")]),_vm._v("\n                                /mesiac\n                            ")]),_vm._v(" "),_c('a',{staticClass:"btn btn-secondary mt-4",attrs:{"href":`objednavka-antena`}},[_c('span',[_vm._v("Pozrieť ponuku")]),_vm._v(" "),_c('img',{staticClass:"btn__icon",attrs:{"src":"/img/icons/arrow-right.svg","alt":""}})])])])])]}),_vm._v(" "),(_vm.antenaServices.length == 1)?_c('div',{staticClass:"col-lg-5 my-2"},[_c('a',{attrs:{"href":"/objednavka-antena?mesiace=12"}},[_c('div',{staticClass:"offer-promo"},[_c('span',{staticClass:"offer-promo__text"},[_vm._v("V našom e-shope pri objednaní")]),_vm._v(" "),_c('span',{staticClass:"offer-promo__text offer-promo__text--bigger"},[_vm._v("12 mesiacov")]),_vm._v(" "),_c('span',{staticClass:"offer-promo__text"},[_vm._v("získate 1 mesiac zadarmo")])])])]):_vm._e()],2):_c('div',{key:"2",staticClass:"loading-container"},[_c('div',{staticClass:"loading-pulse__dark"},[_vm._v("\n                Načítavam ...\n            ")])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }